import { useState } from "react";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { Box, Typography, useTheme } from "@mui/material";
import { Edit } from "@mui/icons-material";
import moment, { Moment } from "moment";
import DateUtil, { dateUtil } from "../../../../utils/dateHelper";

interface CustomDateCalendarProps {
  date: Moment;
  onChange: (newDate: Moment) => void;
}

const CustomDateCalendar = ({ date, onChange }: CustomDateCalendarProps) => {
  const [value, setValue] = useState<Moment | null>(moment());
  const theme = useTheme();
  const { palette, breakpoints } = theme;

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: palette.common.white,
          borderRadius: 3,
        }}
      >
        <Box sx={{ pt: 4, px: 3 }}>
          <Typography variant="body1" fontSize={"14px"} color={"#49454F"}>
            Select date
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 3,
            }}
          >
            <Typography variant="h4">
              {dateUtil.formatDate(date, DateUtil.DateFormats.DDD_MMM_DD)}
            </Typography>
            <Edit />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateCalendar
              value={date ?? value}
              onChange={(newValue) => {
                if (onChange && newValue) onChange(newValue);
                else setValue(newValue);
              }}
              views={["day", "month", "year"]}
              openTo="day"
            />
          </LocalizationProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomDateCalendar;
