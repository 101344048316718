import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ForgotResetInput, ResetInput, resetSchema } from "./resetSchema";
import { useResetPasswordMutation } from "../../../../app/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../hooks/useStoreHook";
import { getErrorMessage } from "../../../../utils/serviceHelper";
import { useToast } from "../../../../hooks/useToast";

export function useResetFunction(props: any) {
  const forgotData = useAppSelector((state) => state.forgot);
  const methods = useForm<ResetInput>({
    resolver: zodResolver(resetSchema),
  });

  const [resetPassword, { isLoading, isError, error, isSuccess }] =
    useResetPasswordMutation();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  const navigate = useNavigate();
  const { displayToast } = useToast();

  useEffect(() => {
    if (isSuccess) {
      navigate("/");
      reset({ password: "", passwordConfirm: "" });
    }
    if (isError) {
      const errorMessage = getErrorMessage(error);
      displayToast({ message: errorMessage, type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onSubmitHandler: SubmitHandler<ResetInput> = (resetInput) => {
    // 👇 Executing the forgotPassword Mutation
    const payload = {
      ...forgotData,
      ...resetInput,
    } as unknown as ForgotResetInput;
    resetPassword(payload);
  };

  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  return {
    isLoading,
    showPassword,
    methods,
    t,
    handleSubmit,
    onSubmitHandler,
    handleShowPasswordClick,
  };
}
