import { boolean, object, string, TypeOf, ZodIssueCode } from "zod";
import { ForgotInput } from "../forgot/forgotSchema";

export const resetSchema = object({
  password: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters")
    .max(32, "Password must be less than 32 characters"),
  passwordConfirm: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters")
    .max(32, "Password must be less than 32 characters"),
}).superRefine(({ password, passwordConfirm }, ctx) => {
  if (password !== passwordConfirm) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      message: "Passwords do not match.",
      path: ["passwordConfirm", "password"],
    });
  }
});

export type ResetInput = TypeOf<typeof resetSchema>;
export type ForgotResetInput = ForgotInput & ResetInput;
