import ArchivedList from "./ArchivedList";

const Archived = () => {
  const header = [
    { title: "Patients", flex: 2 },
    { title: "Consultation", flex: 1 },
    { title: "Cancellation Date", flex: 1 },
    { isEmpty: true },
  ];
  return <ArchivedList header={header} />;
};

export default Archived;
