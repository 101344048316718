import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CheckColonscopyRequiredState {
  isRequired?: boolean | null;
  checked?: boolean;
}

export const checkColonscopyRequiredInitialState: CheckColonscopyRequiredState =
  {
    isRequired: null,
    checked: false,
  };

export const CheckColonscopyRequiredSlice = createSlice({
  name: "checkColonscopyRequired",
  initialState: checkColonscopyRequiredInitialState,
  reducers: {
    setColonoscopyRequired: (
      _state,
      action: PayloadAction<CheckColonscopyRequiredState>
    ) => ({
      ...checkColonscopyRequiredInitialState,
      ...action.payload,
    }),
    resetColonoscopyRequired: (state, _) => ({
      ...state,
      ...checkColonscopyRequiredInitialState,
    }),
  },
});
export const { resetColonoscopyRequired, setColonoscopyRequired } =
  CheckColonscopyRequiredSlice.actions;

export const checkColonscopyRequiredReducer =
  CheckColonscopyRequiredSlice.reducer;
