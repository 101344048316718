import { Box, Typography } from "@mui/material";
import moment from "moment";

interface HeaderProps {
  label: string;
  date: Date;
}
const MyDayHeader = (props: HeaderProps) => {
  const { label, date } = props;
  return (
    <Box
      sx={{
        minHeight: "96px",
        textAlign: "start",
      }}
    >
      <Typography variant="caption" color={"#71717A"}>
        {moment(date).format("ddd")}
      </Typography>
      <Typography variant="subtitle1">{moment(date).format("DD")}</Typography>
    </Box>
  );
};

export default MyDayHeader;
