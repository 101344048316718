import { object, string, TypeOf, z, array, number } from "zod";

export const imageUploadSchema = object({
  documents: array(z.instanceof(File)),
  insuranceProvider: string(),
  insuranceNumber: string()
    .min(5, {
      message: "Provide valid insurance number.",
    })
    .max(100),
  insuranceNotes: string().optional(),
});

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
);

export const profileSchema = object({
  firstname: string()
    .min(3, {
      message: "First name should have minimum 3 characters.",
    })
    .max(100),
  middlename: string().optional(),
  surname: string()
    .min(3, "Last name should have minimum 3 characters.")
    .max(100),
  dateOfBirth: z
    .date()
    .min(new Date("1920-01-01"), { message: "Too old" })
    .max(new Date(), { message: "Please provide proper date" }),
  gender: number(),
  bloodGroup: string(),
  height: string(),
  weight: string(),
  contactNumber: z.string().regex(phoneRegex, "Invalid Number!"),
  email: string()
    .min(1, "Email address is required")
    .email("Email Address is invalid"),
  streetLineOne: string(),
  streetLineTwo: string().optional(),
  stateOrRegion: string().optional(),
  city: string().optional(),
  zipCode: string(),
  addressType: number().optional(),
});

export const userProfileSchema = object({
  id: number(),
});

export type IUploadImage = TypeOf<typeof imageUploadSchema>;
export type IProfileSchema = TypeOf<typeof profileSchema>;
export type IUserProfileSchema = TypeOf<typeof userProfileSchema>;
export type ICompleteProfileSchema = IUserProfileSchema & IProfileSchema;
