import { Box, IconButton, SxProps, Typography } from "@mui/material";
import FilterIcon from "@mui/icons-material/FilterList";

import classes from "./PhysiciansByArea.module.css";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import Dropdown, { DropdownValue } from "../../../components/Dropdown";
import { useIsExplorePhysician } from "./useIsExplorePhysician";
import Breadcrumb from "../../../components/Breadcrumb";

interface Props {
  totalCount: number;
  selected: DropdownValue;
  isDropdown: boolean;
  dropdownData: Array<DropdownValue>;
  onDropdownChange: (value: DropdownValue) => void;
  onFilterCLick: () => void;
}

const iconStyle: SxProps = {
  width: 40,
  height: 40,
  borderRadius: 20,
  backgroundColor: "#DFF2FF",
};

const PhysiciansByAreaHeader = (props: Props) => {
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const isDetailedView = useIsExplorePhysician();

  let titleText: JSX.Element | null = (
    <Typography variant={isSM ? "body1" : "subtitle1"}>
      0{props.totalCount} Physicians Available in your area:
    </Typography>
  );
  let iconButton: JSX.Element | null = (
    <IconButton sx={iconStyle} onClick={props.onFilterCLick}>
      <FilterIcon />
    </IconButton>
  );

  if (isDetailedView) {
    iconButton = null;
    titleText = <Breadcrumb marginTop={0} paths={["Explore Physicians"]} />;
  }

  if (isSM && isDetailedView) {
    titleText = null;
  }

  return (
    <>
      <div className={classes.headerContainer}>
        {titleText}

        <Box display={"flex"} alignItems={"center"}>
          {!isSM && props.isDropdown && !isDetailedView && (
            <Dropdown
              id="map-radius-dropdown"
              value={props.selected}
              data={props.dropdownData}
              onChange={props.onDropdownChange}
            />
          )}
          {iconButton}
        </Box>
      </div>

      {isSM && props.isDropdown && !isDetailedView && (
        <Box padding="0px 4px 12px 12px">
          <Dropdown
            id="map-radius-dropdown"
            value={props.selected}
            data={props.dropdownData}
            onChange={props.onDropdownChange}
          />
        </Box>
      )}
    </>
  );
};

export default PhysiciansByAreaHeader;
