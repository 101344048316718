import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useAppBarRemainingHeight from "../hooks/useAppBarRemainingHeight";
import config from "../config/config";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateAppointment } from "../hooks/useCreateAppointmentDrawer";
import { useState } from "react";
import { useAppSelector } from "../hooks/useStoreHook";
import ProfileNavIcon from "./ProfileNavIcon";

interface ProviderNavigatorProps {
  children: JSX.Element;
}
const ProviderNavigator = ({ children }: ProviderNavigatorProps) => {
  const appBarHeight = useAppBarRemainingHeight(16);
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { openDrawer } = useCreateAppointment();
  const { palette, breakpoints } = theme;
  const fullScreen = useMediaQuery(breakpoints.down("sm"));
  const profileState: number = useAppSelector(
    (state) => state.userState.profileState
  );

  const [value, setValue] = useState(pathname);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
    setValue(newValue);
  };

  const isNavHide = profileState < 5;

  const handleClick = (nav: any) => {
    if (nav.type === "drawer") {
      openDrawer({});
    } else {
      navigate(nav.value);
    }
  };

  if (fullScreen) {
    return (
      <>
        <Box sx={{ pb: 8 }}>{children}</Box>
        {!isNavHide && (
          <Paper
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1000,
              boxShadow: "rgba(9, 30, 66, 0.25) 0px 6px 30px 2px",
            }}
          >
            <BottomNavigation value={value} onChange={handleChange}>
              {config.drawerLinks.map((nav, index) => {
                let node = <Icon>{nav.icon}</Icon>;
                if (nav.icon === "settings") {
                  node = (
                    <ProfileNavIcon
                      isSelected={
                        nav.value === pathname ||
                        pathname.includes("complete-profile")
                      }
                      onClick={() => handleClick(nav)}
                    />
                  );
                }
                return (
                  <BottomNavigationAction
                    key={nav.name}
                    label={""}
                    value={nav.value}
                    icon={node}
                  />
                );
              })}
            </BottomNavigation>
          </Paper>
        )}
      </>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: appBarHeight,
      }}
    >
      {!isNavHide && (
        <Box
          sx={{
            backgroundColor: palette.common.background,
            borderRadius: "0px 52px 52px 0px",
          }}
        >
          <List
            sx={{
              alignItems: "center",
              marginTop: 4,
            }}
          >
            {config.drawerLinks.map((nav, index) => (
              <ListItem key={nav.icon} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: "center",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    {nav.icon === "settings" ? (
                      <ProfileNavIcon
                        isSelected={
                          nav.value === pathname ||
                          pathname.includes("complete-profile")
                        }
                        onClick={() => handleClick(nav)}
                      />
                    ) : (
                      <IconButton
                        sx={{
                          bgcolor:
                            nav.value === pathname
                              ? palette.secondary.main
                              : nav.type === "drawer"
                              ? palette.primary.light
                              : "transparent",
                          borderRadius: 24,
                          "&:hover": {
                            background:
                              nav.value === pathname
                                ? palette.primary.light
                                : palette.grey[300],
                          },
                        }}
                        onClick={() => handleClick(nav)}
                      >
                        <Icon
                          color="secondary"
                          sx={
                            nav.value === pathname
                              ? {
                                  color: palette.common.white,
                                }
                              : {}
                          }
                        >
                          {nav.icon}
                        </Icon>
                      </IconButton>
                    )}
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      <Box
        sx={{
          overflowY: "auto",
          height: appBarHeight,
          display: "flex",
          flex: 1,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ProviderNavigator;
