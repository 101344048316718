import { object, string, TypeOf, ZodIssueCode } from "zod";

export const registerSchema = object({
  firstname: string()
    .min(3, {
      message: "First name should have minimum 3 characters.",
    })
    .max(100),
  middlename: string().optional(),
  surname: string()
    .min(3, "Last name should have minimum 3 characters.")
    .max(100),
  email: string()
    .min(1, "Email address is required")
    .email("Email Address is invalid"),
  password: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters")
    .max(32, "Password must be less than 32 characters"),
  passwordConfirm: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters")
    .max(32, "Password must be less than 32 characters"),
}).superRefine(({ password, passwordConfirm }, ctx) => {
  if (password !== passwordConfirm) {
    ctx.addIssue({
      code: ZodIssueCode.custom,
      message: "Passwords do not match.",
      path: ["passwordConfirm", "password"],
    });
  }
});

export type RegisterInput = TypeOf<typeof registerSchema>;
