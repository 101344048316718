import { Fragment } from "react";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs, { Dayjs } from "dayjs";

import classes from "./BookingSlots.module.css";
import Slot, { SlotType } from "./Slot";
import { useAppDispatch } from "../../../../hooks/useStoreHook";
import { SlotItem, bookAppointmentActions } from "../bookAppointmentSlice";
import CenteredBox from "../../../../components/CenteredBox";
import { useBreakpoints } from "../../../../hooks/useBreakpoints";

interface Props {
  date: Dayjs;
  slots: SlotItem[];
  isLoading: boolean;
  isError: boolean;
}

const BookingSlots = ({ date, isLoading, isError, slots }: Props) => {
  const dispatch = useAppDispatch();
  const [{ isMatchDownSM: isSM }] = useBreakpoints();

  const handleSlotSelection = (item: SlotItem) => {
    const { scheduleDetailsId: id, isAvailable } = item;
    if (isAvailable) dispatch(bookAppointmentActions.setSelectedSlot(id));
  };

  return (
    <Fragment>
      <div className={classes.bookingLabelContainer}>
        <div className={classes.bookingLabel}>
          <div className={`${classes.bookingDot} ${classes.selected}`}></div>
          <Typography paddingLeft={1} variant="caption">
            Selected
          </Typography>
        </div>
        <div className={classes.bookingLabel}>
          <div className={`${classes.bookingDot} ${classes.available}`}></div>
          <Typography paddingLeft={1} variant="caption">
            Available
          </Typography>
        </div>
        <div className={classes.bookingLabel}>
          <div className={`${classes.bookingDot} ${classes.occupied}`}></div>
          <Typography paddingLeft={1} variant="caption">
            Occupied
          </Typography>
        </div>
      </div>

      <Typography paddingX={2} variant="subtitle2">
        {date.format("dddd, MMM YY")}
      </Typography>
      <div
        className={classes.slotsContainer}
        style={{ overflowY: slots.length > 5 ? "scroll" : "hidden" }}
      >
        {slots.length === 0 && (
          <CenteredBox height={isSM ? 280 : 380}>
            {!isLoading && (
              <Typography variant="body2">
                No Slots available for the selected date
              </Typography>
            )}
            {isLoading && <CircularProgress size={60} />}
            {isError && (
              <Typography variant="body2">
                Some Error occured while fetching he slots for the selected date
              </Typography>
            )}
          </CenteredBox>
        )}
        {slots.map((slot, index) => {
          let type: SlotType = slot.isAvailable ? "available" : "occupied";
          if (slot.isSelected) type = "selected";
          return (
            <Slot
              key={index}
              isStart={index === 0}
              isEnd={slots.length - 1 === index}
              type={type}
              slotNumber={index + 1}
              slotTime={dayjs(slot.slotDateTime).format("hh:mm A")}
              onClick={() => handleSlotSelection(slot)}
            />
          );
        })}
      </div>
    </Fragment>
  );
};

export default BookingSlots;
