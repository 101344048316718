import { useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Rating,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import {
  EmailOutlined,
  LocalHospitalOutlined,
  LocationOnOutlined,
  PhoneOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";

import Breadcrumb from "../../components/Breadcrumb";
import LabelWithIcon from "../../components/LabelWithIcon";
import LabelWithTitle from "../../components/LabelWithTitle";
import Subtitle from "../../components/Subtitle";
import PhysicianReviewCard from "../../components/PhysicianReviewCard";
import PhysicianReviews from "../../features/patient/physician/PhysicianReviews";
import { useGetPhysicianQuery } from "../../features/patient/home/physiciansByAreaAPI";
import CenteredBox from "../../components/CenteredBox";
import { getClinicAddress } from "../../features/provider/complete-profile/ProviderProfileForm/providerProfileHelper";
import BookAppointment from "../../features/patient/book-appointment/BookAppointment";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { useLazyGetProfilePicQuery } from "../../features/patient/dashboard/profilePictureAPI";

interface PhysicianProps {}

function ViewPhysician(props: PhysicianProps) {
  const { palette } = useTheme();
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const [getProfilePic, status] = useLazyGetProfilePicQuery();

  const params = useParams();
  const id = params.id ?? "";

  const { data, isLoading, isError, isSuccess } = useGetPhysicianQuery(id);
  const [viewMore, setViewmore] = useState(!isSM);

  const avatarSxProps: SxProps = {
    height: 192,
    width: 192,
    borderRadius: 96,
    backgroundColor: palette.common.image,
  };
  const ratingSxProps: SxProps = {
    width: isSM ? "100%" : 200,
    display: "flex",
    alignItems: "center",
    justifyContent: isSM ? "center" : "flex-start",
    mt: 2,
  };
  const emailPhoneSxProps: SxProps = {
    display: "flex",
    flexDirection: isSM ? "column" : "row",
    mt: 4,
  };
  const avatarContainerSxProps: SxProps = {
    display: "flex",
    alignItems: "center",
    justifyContent: isSM ? "center" : "flex-start",
  };
  const iconSxProps: SxProps = { width: 20, height: 24 };

  useEffect(() => {
    if (data?.profileImage) getProfilePic(data.profileImage);
  }, [getProfilePic, data?.profileImage]);

  if (isLoading) {
    return (
      <CenteredBox>
        <CircularProgress size={60} />
      </CenteredBox>
    );
  }

  if (isError) {
    return (
      <CenteredBox>
        <Typography variant="body1">
          Some error occured while fetching Physician Details.
        </Typography>
      </CenteredBox>
    );
  }

  let providerName = "";
  let training: string[] = [];

  if (isSuccess) {
    providerName = `Dr. ${data.fullName}`;
    const { medicalSchool, residency, fellowship } = data.professionalDetail;
    if (medicalSchool) training.push(medicalSchool);
    if (residency) training.push(residency);
    if (fellowship) training.push(fellowship);
  }

  const profilePic = status.data;

  const avatar = (
    <Grid item xs={12} sm={3} sx={avatarContainerSxProps}>
      {!profilePic && (
        <Avatar sx={avatarSxProps}>
          <AccountCircleOutlined
            sx={{ height: 192, width: 192 }}
            color="secondary"
          />
        </Avatar>
      )}
      {profilePic && (
        <Box
          component="img"
          sx={avatarSxProps}
          alt="Profile Pic"
          src={profilePic}
        />
      )}
    </Grid>
  );
  //rating and review count is not available in API

  let address = getClinicAddress(data?.professionalDetail?.businessAddress);

  if (!address) address = "Not Provided";

  const basicDetails = (
    <Grid item xs={12} sm={9}>
      <Subtitle heading={providerName} isDivider={!isSM} />
      <Box sx={ratingSxProps}>
        <Rating name="read-only" value={0} readOnly />
        <Box>({0})</Box>
      </Box>
      <Box sx={emailPhoneSxProps}>
        <LabelWithIcon
          icon={<EmailOutlined sx={iconSxProps} />}
          boxSx={{
            height: 24,
            justifyContent: isSM ? "flex-start" : "center",
            marginBottom: isSM ? 2 : 0,
          }}
          value={data?.emailAddress ?? "Not Provided"}
          valueVariant="body1"
        />
        <LabelWithIcon
          icon={<PhoneOutlined sx={iconSxProps} />}
          boxSx={{
            height: 24,
            marginLeft: isSM ? 0 : 16,
            justifyContent: isSM ? "flex-start" : "center",
          }}
          value={data?.phoneNumber ?? "Not Provided"}
          valueVariant="body1"
        />
      </Box>
      <LabelWithIcon
        icon={<LocalHospitalOutlined sx={iconSxProps} />}
        boxSx={{ height: 24, justifyContent: "start", marginTop: 2 }}
        value={data?.professionalDetail?.medicalSchool ?? "Not Provided"}
        valueVariant="body1"
      />
      <LabelWithIcon
        icon={<LocationOnOutlined sx={iconSxProps} />}
        boxSx={{ height: 24, justifyContent: "start", marginTop: 2 }}
        value={address}
        valueVariant="body1"
      />
    </Grid>
  );

  const otherDetails = (
    <Box paddingX={isSM ? 1 : 0} paddingBottom={isSM ? 2 : 0}>
      {data?.aboutMe && (
        <Typography component="div" variant="body1">
          {data?.aboutMe}
        </Typography>
      )}
      <LabelWithTitle
        titleFont="body1"
        valueFont="body2"
        title="Experience:"
        value={data?.professionalDetail?.experience ?? "Not Provided"}
      />
      <LabelWithTitle
        titleFont="body1"
        valueFont="body2"
        title="NPI Number:"
        value={data?.npi ?? "Not Provided"}
      />
      <LabelWithTitle
        titleFont="body1"
        valueFont="body2"
        title={"Awards:"}
        value={
          data &&
          data?.professionalDetail &&
          data?.professionalDetail.awardsList
            ? data?.professionalDetail?.awardsList?.join(", ")
            : "Not Provided"
        }
      />
      <LabelWithTitle
        titleFont="body1"
        valueFont="body2"
        type={"chip"}
        title={"Training:"}
        value={training ?? "Not Provided"}
      />
    </Box>
  );

  return (
    <Grid container>
      <Grid item xs={12} sm={8}>
        <Box sx={{ mx: isSM ? 2 : 6 }}>
          {!isSM && <Breadcrumb paths={[providerName]} />}
          <Grid container sx={{ my: isSM ? 2 : 6 }}>
            {avatar}
            {basicDetails}
          </Grid>
          {viewMore && otherDetails}
          {isSM && (
            <Button variant="text" onClick={() => setViewmore(!viewMore)}>
              {viewMore ? "Less Details" : "More Details"}
            </Button>
          )}

          {/*  <PhysicianReviews
              itemsOnPage={4}
              items={[
                1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3,
                4, 5, 6, 7, 8, 9,
              ]}
              entryProp="review"
              children={<PhysicianReviewCard />}
            /> */}
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <BookAppointment physicianId={id} />
      </Grid>
    </Grid>
  );
}

export default ViewPhysician;
