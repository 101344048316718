import { Modal, SxProps, Typography } from "@mui/material";
import { FC } from "react";
import { Box } from "@mui/system";

import { useBreakpoints } from "../hooks/useBreakpoints";
import { animations } from "../assets/animations";
import IconButton from "./IconButton";
import Lottie from "./Lottie";

type Props = {
  open: boolean;
  onClose: () => void;
  message: string;
};

const ConfirmModal: FC<Props> = (props) => {
  const [{ isMatchDownSM: isSM }] = useBreakpoints();

  const modalStyle: SxProps = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSM ? "90vw" : 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: isSM ? 2 : 4,
    borderRadius: 2,
  };

  const iconSize = isSM ? 100 : 120;

  return (
    <Modal open={props.open} onClose={() => props.onClose()}>
      <Box sx={modalStyle}>
        <Box position="absolute" right={16} top={16}>
          {/* <Close height={24} sx={{ cursor: "pointer" }} /> */}
          <IconButton
            sx={{ p: isSM ? 0.5 : 1 }}
            variant="secondary"
            icon="close"
            onClick={props.onClose}
          ></IconButton>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          pt={4}
        >
          <Lottie json={animations.tick} size={iconSize} />
          <Typography
            variant={isSM ? "h6" : "h4"}
            textAlign="center"
            mt={isSM ? 1 : 2.5}
            mb={1}
            color="#455A64"
          >
            {props.message}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
