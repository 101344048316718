import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { Variant } from "@mui/material/styles/createTypography";
import { SxProps } from "@mui/material";

import { generateTwoLetters } from "../utils/stringHelper";
import { useBreakpoints } from "../hooks/useBreakpoints";
import classes from "./PhysicianCard.module.css";
import { useLazyGetProfilePicQuery } from "../features/patient/dashboard/profilePictureAPI";
import { useEffect } from "react";

interface PhysicianProps {
  salutation?: string;
  name: string;
  field: string;
  rating?: number;
  reviewCount?: number;
  isRatingCard?: boolean;
  picPath?: string;
  onClick?: () => void;
}
export default function PhysicianCard(props: PhysicianProps) {
  const [{ isMatchDownSM }] = useBreakpoints();
  const [getProfilePic, status] = useLazyGetProfilePicQuery();
  const { salutation, name, field } = props;
  const { rating, reviewCount, isRatingCard = true } = props;

  useEffect(() => {
    if (props.picPath) getProfilePic(props.picPath);
  }, [getProfilePic, props.picPath]);

  let fieldVeriant: Variant = "body1";
  let sxProps: SxProps = { textDecoration: "underline", fontSize: 18 };
  let avatarSX: SxProps = {
    bgcolor: "#BBE0FF",
    width: 90,
    height: 90,
    borderRadius: 45,
  };

  if (isMatchDownSM) {
    fieldVeriant = "caption";
    sxProps.fontSize = 16;
    avatarSX.width = 70;
    avatarSX.height = 70;
    avatarSX.borderRadius = 35;
  }

  const profilePic = status.data;

  return (
    <div
      className={classes.cardContent}
      style={{ cursor: props.onClick ? "pointer" : "default" }}
      onClick={props.onClick}
    >
      {!profilePic && (
        <Avatar sx={avatarSX}>
          <Typography variant="h4">{generateTwoLetters(name)}</Typography>
        </Avatar>
      )}
      {profilePic && (
        <Box component="img" sx={avatarSX} alt="Profile Pic" src={profilePic} />
      )}
      <Box sx={{ mx: 2 }}>
        <Typography variant="subtitle1" sx={sxProps} color={"primary"}>
          {`${salutation ?? ""} ${name ?? ""}`}
        </Typography>
        <Typography component="legend" variant={fieldVeriant}>
          {field}
        </Typography>
        {isRatingCard && (
          <div className={classes.ratingContainer}>
            <Rating name="read-only" value={rating} readOnly />
            <Box>({reviewCount})</Box>
          </div>
        )}
      </Box>
    </div>
  );
}
