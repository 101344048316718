import { Close } from "@mui/icons-material";
import { Box, Divider, Drawer, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import NotifySetupSlot from "./NotifySetupSlot";
import NotifyUser from "./NotifyUser";
import NotifyGeneral from "./NotifyGeneral";
import { useNotification } from "../../hooks/useNotification";
import { useAppSelector } from "../../hooks/useStoreHook";
import {
  INotification,
  notificationAdapter,
  notificationSelector,
  useGetAllNotificationQuery,
} from "./notificationAPI";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { useAuth } from "../../hooks/useAuthUser";

const limit = 10;
const Notification = () => {
  const _notification = useAppSelector((state) => state.notification);
  const { closeNotification } = useNotification();
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const auth = useAuth();
  const {
    notification,
    hasNextPage,
    isFetching: isInitialFetching,
  } = useGetAllNotificationQuery(
    {
      pageNumber: 1,
      limit: limit,
    },
    {
      skip: auth?.user?.id ? false : true,
      selectFromResult: ({ data, ...otherParams }) => {
        return {
          hasNextPage: data?.hasMorePages,
          notification: notificationSelector.selectAll(
            data ?? notificationAdapter.getInitialState()
          ),
          ...otherParams,
        };
      },
    }
  );
  return (
    <Drawer
      PaperProps={{
        sx: { backgroundColor: "#F4FAFF", width: isSM ? "100%" : 440 },
      }}
      anchor="right"
      role="presentation"
      open={_notification.open}
      onClose={() => closeNotification()}
    >
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", mx: 2, my: 3 }}>
          <Typography variant="h5">Notifications</Typography>
          <IconButton
            sx={{ marginLeft: "auto", color: "white" }}
            onClick={() => closeNotification()}
          >
            <Close color="primary" />
          </IconButton>
        </Box>
        <Divider
          orientation="vertical"
          sx={{ borderWidth: 1, backgroundColor: "#C5C6D0", height: "1px" }}
        />
        {notification.map((noti: INotification) => (
          <Box key={noti.id}>
            <NotifyUser title={noti.message} />
          </Box>
        ))}
        {/* <NotifySetupSlot />
          <NotifyGeneral
            title="5 new patients are waiting for Ugent approval"
            showWarning
            count={"05"}
            showCount
          />
          <NotifyGeneral
            title="23 new patients did not show up this week"
            count={"23"}
            showIconWithCount
            iconName="block"
          />
          <NotifyGeneral
            title="10 new Appointment Requests"
            count={"10"}
            showCount
          />
          <NotifyUser
            title="Martha Mill has uploaded new report."
            showButton
            buttonValue="View"
          /> */}
      </Box>
    </Drawer>
  );
};

export default Notification;
