import { useCallback, useMemo } from "react";
import moment from "moment";
import Toolbar from "./Toolbar";

import {
  Calendar as BigCalendar,
  CalendarProps,
  momentLocalizer,
} from "react-big-calendar";
import withDragAndDrop, {
  withDragAndDropProps,
} from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "./calendar.css";
import MyDayHeader from "./MyDayHeader";
import { EventItem } from "../utils/event-types";
import AppointmentEvent from "./AppointmentEvent";

const DnDCalendar = withDragAndDrop(BigCalendar);

type DnDType = CalendarProps & withDragAndDropProps;
type CustomCalendarProps = Omit<
  DnDType,
  | "components"
  | "localizer"
  | "titleAccessor"
  | "tooltipAccessor"
  | "allDayAccessor"
  | "startAccessor"
  | "endAccessor"
  | "resourceAccessor"
  | "dragFromOutsideItem"
  | "draggableAccessor"
  | "resizableAccessor"
>;
// const toSuperFilter = (sup: string) => {
//   let res = sup.replace(/(?:st)/i, "ˢᵗ");
//   res = res.replace(/(?:nd)/i, "ⁿᵈ");
//   res = res.replace(/(?:rd)/i, "ʳᵈ");
//   res = res.replace(/(?:th)/i, "ᵗʰ");
//   return res;
// };

// moment.locale("en", {
//   ordinal: (num) => {
//     const b = num % 10;
//     const output =
//       ~~((num % 100) / 10) === 1
//         ? "th"
//         : b === 1
//         ? "st"
//         : b === 2
//         ? "nd"
//         : b === 3
//         ? "rd"
//         : "th";
//     return num + `<sup>${output}</sup>`;
//   },
// });

const localizer = momentLocalizer(moment);

export default function Calendar(props: CustomCalendarProps) {
  const { components, formats } = useMemo(
    () => ({
      components: {
        toolbar: Toolbar,
        week: {
          header: MyDayHeader,
        },
        event: ({ event }: { event: any }) => {
          const data = event?.data;
          if (data) return <AppointmentEvent event={event} />;
          // if (data?.blockout)
          // return <BlockoutEvent blockout={data?.blockout} />;

          return null;
        },
      },
      formats: {
        dayRangeHeaderFormat: (
          { start, end }: any,
          culture: any,
          localizer: any
        ) =>
          localizer.format(start, "Do MMM YYYY", culture) +
          " - " +
          localizer.format(end, "Do MMM YYYY", culture),
        timeGutterFormat: (date: any, culture: any, localizer: any) =>
          localizer.format(date, "h a", culture),
      },
    }),
    []
  );

  // const { appointments, blockouts } = (props.events as EventItem[])?.reduce(
  //   (acc, event) => {
  //     if (event?.data?.appointment) {
  //       acc.appointments.push(event);
  //     }
  //     if (event?.data?.blockout) acc.blockouts.push(event);
  //     return acc;
  //   },
  //   { appointments: [] as EventItem[], blockouts: [] as EventItem[] }
  // ) ?? { appointments: [] as EventItem[], blockouts: [] as EventItem[] };

  return (
    <DnDCalendar
      {...props}
      localizer={localizer}
      defaultView={"week"}
      views={["day", "week"]}
      components={components}
      formats={formats}
      step={30}
      timeslots={2}
      events={props.events}
      popup={true}
      style={{ width: "100%" }}
      draggableAccessor={"isDraggable" as any}
      startAccessor={(event: any) => {
        return new Date(event.start);
      }}
      endAccessor={(event: any) => {
        return new Date(event.end);
      }}
    />
  );
}
