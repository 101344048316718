import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../../hooks/useToast";
import {
  useGetScheduleByDateQuery,
  useLazyGetScheduleByDateQuery,
} from "./calendarScheduleAPI";
import DateUtil, { dateUtil } from "../../../../utils/dateHelper";
import { useEffect } from "react";

export function useCalendarFunction() {
  const [getScheduleByDate, { data, isFetching }] =
    useLazyGetScheduleByDateQuery();
  useEffect(() => {
    getScheduleByDate({
      scheduleDate: dateUtil.formatDate(
        dateUtil.getToday(),
        DateUtil.DateFormats.YEAR_MONTH_DATE
      ),
    });
  }, []);

  const navigate = useNavigate();
  // const location = useLocation();

  // const from = ((location.state as any)?.from.pathname as string) || "/profile";
  const { displayToast } = useToast();

  //   useEffect(() => {
  // if (isError) {
  // const errorMessage = getErrorMessage(error);
  //   displayToast({ message: errorMessage, type: "error" });
  // }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [isLoading]);

  //   const onSubmitHandler: SubmitHandler<LoginInput> = (values) => {
  //     // 👇 Executing the loginUser Mutation
  //     loginUser({ ...values, isProvider: Boolean(props.index) });
  //   };

  const { t } = useTranslation();

  return {
    scheduleData: data,
    isFetching,
    getScheduleByDate,
    t,
  };
}
