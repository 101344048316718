import { Box, Chip, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

interface LabelWithTitleProps {
  type?: "text" | "chip";
  title: string;
  titleFont?: Variant;
  value: string | string[];
  valueFont?: Variant;
}
export default function LabelWithTitle(props: LabelWithTitleProps) {
  const { type = "text", title, value } = props;
  const { titleFont = "subtitle1", valueFont = "body1" } = props;

  if (type === "text")
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        sx={{ mt: 2 }}
      >
        <Typography component="div" variant={titleFont}>
          {title}
        </Typography>
        <Typography component="div" variant={valueFont} sx={{ mx: 2 }}>
          {value}
        </Typography>
      </Box>
    );
  else
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        sx={{ mt: 2 }}
      >
        <Typography component="div" variant={titleFont}>
          {title}
        </Typography>
        <Box sx={{ mx: 2 }}>
          {Array.isArray(value) &&
            value.map((entity: string) => (
              <Chip
                key={entity}
                sx={{
                  m: 0.4,
                  borderRadius: 100,
                }}
                label={entity}
                variant="outlined"
              />
            ))}
        </Box>
      </Box>
    );
}
