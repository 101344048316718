import { Box, Divider, Icon, Typography, useTheme } from "@mui/material";
import IconButton from "./IconButton";
import { Fragment, forwardRef, useState } from "react";
import AppointmentNotes from "./AppointmentNotes";
import DateUtil, { dateUtil } from "../utils/dateHelper";
import { getAppointmentStatus } from "../features/provider/appointment/appointmentRequestsAPI";

interface IAppointmentOverview {
  appointmentId: string;
  appointmentStatus: number;
  scheduleDetails?: any;
  actionEnabled?: boolean;
  isPast?: boolean;
  onApproveReject?: (appointmentId: string, _status: number) => void;
}
const AppointmentOverview = forwardRef(
  (
    {
      appointmentId,
      appointmentStatus,
      scheduleDetails,
      actionEnabled = false,
      isPast,
      onApproveReject,
    }: IAppointmentOverview,
    ref
  ) => {
    const { palette } = useTheme();
    const [open, setOpen] = useState(false);

    const handleDialogClick = () => {
      setOpen(!open);
    };
    return (
      <Fragment>
        <Box
          ref={ref ?? null}
          sx={{
            width: "48%",
            m: "1%",
            border: "1px solid #CDE5FF",
            p: 3,
            borderRadius: 2,
            backgroundColor: "#FCFCFF",
          }}
        >
          <Typography variant="subtitle2">
            {scheduleDetails
              ? `${dateUtil.formatDate(
                  scheduleDetails?.startTime,
                  DateUtil.DateFormats.DATE_TIME
                )} - ${dateUtil.formatDate(
                  scheduleDetails?.endTime,
                  DateUtil.DateFormats.TIME
                )}`
              : ""}
          </Typography>
          <Divider sx={{ my: 2, borderColor: "#CDE5FF" }} />
          <Box
            sx={{ display: "flex" }}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {isPast ? (
              <Fragment />
            ) : actionEnabled ? (
              <Box sx={{ display: "flex" }}>
                <IconButton
                  icon="close"
                  variant="secondary"
                  onClick={() => {
                    if (onApproveReject && appointmentId)
                      onApproveReject(appointmentId, 2);
                  }}
                  sx={{
                    borderRadius: 8,
                    border: "1px solid #75777F",
                    color: palette.common.editColor,
                    mr: 2,
                  }}
                  title="Reject"
                  iconSx={{ mx: 1, fontSize: 20 }}
                  titleSx={{ mr: 1.5 }}
                  titleVariant="body2"
                />
                <IconButton
                  icon="check"
                  onClick={() => {
                    if (onApproveReject && appointmentId)
                      onApproveReject(appointmentId, 1);
                  }}
                  sx={{
                    borderRadius: 8,
                    color: "white",
                  }}
                  title="Approve"
                  iconSx={{ mx: 1, fontSize: 20 }}
                  titleSx={{ mr: 1.5 }}
                  titleVariant="body2"
                />
              </Box>
            ) : (
              <IconButton
                icon=""
                variant="primary"
                onClick={() => {}}
                sx={{
                  borderRadius: 8,
                  backgroundColor: "#1D1B2012",
                }}
                title={getAppointmentStatus(appointmentStatus)}
                iconSx={{ mx: 1, fontSize: 20 }}
                titleSx={{ mr: 1.5, fontSize: 14 }}
                titleVariant="body1"
              />
            )}
            <Box>
              <IconButton
                icon="note"
                onClick={handleDialogClick}
                sx={{
                  bgcolor: "#F1F3FF",
                  borderRadius: 3,
                  color: palette.common.editColor,
                }}
                title="View/Add Notes"
                iconSx={{ mx: 1, fontSize: 20 }}
                titleSx={{ mr: 1.5, fontSize: 14 }}
                titleVariant="body1"
              />
            </Box>
          </Box>
        </Box>
        {open && (
          <AppointmentNotes
            open={open}
            handleClose={handleDialogClick}
            appointmentId={appointmentId}
          />
        )}
      </Fragment>
    );
  }
);
export default AppointmentOverview;
