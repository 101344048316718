import React, { Fragment, useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { resetStateAction } from "../app/action";
import { useAppDispatch } from "../hooks/useStoreHook";
import { useNavigate } from "react-router-dom";

const DrawerComp = ({ links }: { links: any[] }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(resetStateAction());
    navigate("/");
  };

  return (
    <>
      <Drawer
        PaperProps={{
          sx: { backgroundColor: "##ffffff80", width: "320px" },
        }}
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <List>
          {links.map((item, index) => {
            if (item.type == "link" || item.type == "logout") {
              return (
                <ListItemButton
                  onClick={() => {
                    setOpen(false);
                    if (item.type == "logout") {
                      onLogout();
                    }
                  }}
                  key={index}
                  divider
                >
                  <ListItemIcon>
                    <ListItemText sx={{ color: palette.primary.main }}>
                      {t(item.name)}
                    </ListItemText>
                  </ListItemIcon>
                </ListItemButton>
              );
            } else {
              return <Fragment key={index} />;
            }
          })}
        </List>
      </Drawer>
      <IconButton
        sx={{ marginLeft: "auto", color: "white" }}
        onClick={() => setOpen(!open)}
      >
        <MenuIcon color="primary" />
      </IconButton>
    </>
  );
};

export default DrawerComp;
