import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import TimeslotContainer from "./components/TimeslotContainer";
import TimeslotHeader from "./components/TimeslotHeader";
import {
  AccessTime,
  Circle,
  Close,
  Language,
  PermContactCalendarOutlined,
} from "@mui/icons-material";
import { FormInputDropdown } from "../../../../components/form/FormDropdown";
import { WEEKDAYS } from "./components/constants";
import FormSwitch from "../../../../components/form/FormSwitch";
import { useTimeSlotFunction } from "./useTimeSlotFunction";
import { FormProvider } from "react-hook-form";
import { FormMultiSelectDropdown } from "../../../../components/form/FormMultiSelectDropdown";
import { FormInputDate } from "../../../../components/form/FormDate";
import LabelWithIcon from "../../../../components/LabelWithIcon";
import { useAppSelector } from "../../../../hooks/useStoreHook";
import { useDateSetter } from "../../../../hooks/useDateSetter";
import DateUtil, { dateUtil } from "../../../../utils/dateHelper";
import { useBreakpoints } from "../../../../hooks/useBreakpoints";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { useEffect } from "react";

const TimeSlotDrawer = ({
  open,
  handleDrawer,
  isEdit = false,
  currentTimeslots = [],
  getSchedule,
}: {
  open: boolean;
  handleDrawer: (open: boolean) => void;
  isEdit?: boolean;
  currentTimeslots?: any;
  getSchedule: ({ scheduleDate }: { scheduleDate: string }) => void;
}) => {
  const { palette } = useTheme();
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const {
    methods,
    timezones,
    watch,
    onReset,
    onSubmitHandler,
    generateStartTime,
    generateEndTime,
    handleSubmit,
    onClearTimeslotsSelection,
    isLoading,
  } = useTimeSlotFunction(open, isEdit, currentTimeslots, handleDrawer);
  const selectedDate = useAppSelector((state) => state.dateSetter.selectedDate);
  const { setDate } = useDateSetter();
  const isSameOrAfter = dateUtil.checkDate(selectedDate, "isSameOrAfter");

  const repeat = watch("repeat");
  let tillLabel = "Till";
  let repeteLabel = "Select Repetition";
  const selectedWeekDay = WEEKDAYS[moment(selectedDate).weekday()];

  useEffect(() => {
    if (repeat) {
      tillLabel += "*";
      repeteLabel += "*";
      methods.setValue("weekdays", [selectedWeekDay]);
    } else {
      methods.setValue("weekdays", []);
    }
  }, [repeat]);

  if (repeat) {
    tillLabel += "*";
    repeteLabel += "*";
  }

  return (
    <Drawer
      PaperProps={{
        sx: { backgroundColor: "##ffffff80", width: isSM ? "100%" : "640px" },
      }}
      anchor={isSM ? "bottom" : "right"}
      open={open}
      onClose={() => {
        handleDrawer(false);
        onReset();
      }}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmitHandler)}
          sx={{
            m: 2,
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography
                  variant="body1"
                  fontSize={"20px"}
                  fontWeight={600}
                  paddingRight={2}
                  color={palette.common.black}
                >
                  {isEdit ? "Edit Time Slots" : "Add Time Slots"}
                </Typography>
                <IconButton
                  sx={{ color: "#323232" }}
                  onClick={() => {
                    handleDrawer(false);
                    onReset();
                  }}
                >
                  <Close />
                </IconButton>
              </Box>
              <Divider
                orientation="vertical"
                sx={{
                  borderWidth: "1px",
                  backgroundColor: "#C5C6D0",
                  mt: 1,
                  mb: 2,
                }}
              />
            </Box>
            <TimeslotHeader
              selectedDate={dateUtil.createMoment(selectedDate)}
              onSelectedDate={setDate}
              showDivider
              onReset={onReset}
              getSchedule={getSchedule}
            />
            {isSM && (
              <Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <AccessTime sx={{ fontSize: 24 }} />
                  <Box flexGrow={"0.03"} />
                  <Typography variant="body2" mr="auto" fontSize={14}>
                    Working Hours:
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormInputDropdown
                    name="startTime"
                    label="Start Time"
                    sx={{
                      width: 128,
                      borderRadius: 20,
                      height: 42,
                    }}
                    inlineSx={{ top: "-6px" }}
                    options={generateStartTime}
                  />

                  <Typography variant="body1">to</Typography>

                  <FormInputDropdown
                    name="endTime"
                    label="End Time"
                    sx={{ width: 128, borderRadius: 20, height: 42 }}
                    inlineSx={{ top: "-6px" }}
                    options={generateEndTime}
                  />
                </Box>
              </Box>
            )}
            {!isSM && (
              <Box
                display={"flex"}
                flex={1}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <AccessTime sx={{ fontSize: 24 }} />
                <Box flexGrow={"0.03"} />
                <Typography variant="body2" mr="auto" fontSize={14}>
                  Working Hours:
                </Typography>
                <FormInputDropdown
                  name="startTime"
                  label="Start Time"
                  sx={{
                    width: 128,
                    borderRadius: 20,
                    height: 42,
                  }}
                  inlineSx={{ top: "-6px" }}
                  options={generateStartTime}
                />
                <Box flexGrow={"0.1"} />
                <Typography variant="body1">to</Typography>
                <Box flexGrow={"0.1"} />
                <FormInputDropdown
                  name="endTime"
                  label="End Time"
                  sx={{ width: 128, borderRadius: 20, height: 42 }}
                  inlineSx={{ top: "-6px" }}
                  options={generateEndTime}
                />
                <Box mr="auto" flexGrow={"0.67"} />
              </Box>
            )}
            <Box
              display={"flex"}
              flex={1}
              alignItems={"center"}
              justifyContent={"center"}
              my={2}
            >
              <Language sx={{ fontSize: 24 }} />
              <Box flexGrow={"0.03"} />
              <Typography variant="body2" mr="auto" fontSize={14}>
                Timezone
              </Typography>
              <FormInputDropdown
                name="timezone"
                label="TimeZone"
                sx={{ width: 128, borderRadius: 20, height: 42 }}
                inlineSx={{ top: "-6px" }}
                options={timezones}
              />
              {!isSM && <Box mr="auto" flexGrow={"0.87"} />}
            </Box>
            <Divider
              orientation="horizontal"
              sx={{
                borderWidth: "1px",
                backgroundColor: "#C5C6D0",
                my: 2,
              }}
            />
            {!isSM && (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <FormSwitch
                  name="repeat"
                  label={"Repeat"}
                  labelPlacement="end"
                />
                <FormMultiSelectDropdown
                  name="weekdays"
                  label={repeteLabel}
                  sx={{ width: 180, borderRadius: 20, height: 42 }}
                  inlineSx={{ top: "-6px" }}
                  options={WEEKDAYS.map((weekday) => ({
                    label: weekday,
                    value: weekday,
                    disabled: weekday === selectedWeekDay,
                  }))}
                  withLabel
                  disabled={!repeat}
                />
                <Typography variant="body2" fontSize={14}>
                  {tillLabel}
                </Typography>
                <FormInputDate
                  name="slotValidity"
                  disabled={!repeat}
                  minDate={dateUtil
                    .createMoment(
                      selectedDate,
                      DateUtil.DateFormats.YEAR_MONTH_DATE
                    )
                    .add(1, "day")
                    .toDate()}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "100px",
                      height: "42px",
                    },
                  }}
                />
                <Box flex={0.4}></Box>
              </Box>
            )}
            {isSM && (
              <Box>
                <Box display="flex" alignItems="center">
                  <FormSwitch
                    name="repeat"
                    label={"Repeat"}
                    labelPlacement="end"
                  />
                  <FormMultiSelectDropdown
                    style={{ width: "100%" }}
                    name="weekdays"
                    label={repeteLabel}
                    sx={{ borderRadius: 20, height: 42 }}
                    inlineSx={{ top: "-6px" }}
                    options={WEEKDAYS.map((weekday) => ({
                      label: weekday,
                      value: weekday,
                    }))}
                    withLabel
                    disabled={!repeat}
                  />
                </Box>
                <Box display="flex" alignItems="center" pt={2}>
                  <Typography variant="body2" fontSize={14} mr={2}>
                    {tillLabel}
                  </Typography>
                  <FormInputDate
                    name="slotValidity"
                    disabled={!repeat}
                    minDate={dateUtil
                      .createMoment(
                        selectedDate,
                        DateUtil.DateFormats.YEAR_MONTH_DATE
                      )
                      .add(1, "day")
                      .toDate()}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderRadius: "100px",
                        height: "42px",
                      },
                    }}
                  />
                </Box>
              </Box>
            )}
            <Divider
              orientation="horizontal"
              sx={{
                borderWidth: "1px",
                backgroundColor: "#C5C6D0",
                my: 2,
              }}
            />
            {!isSM && (
              <Box
                display={"flex"}
                flex={1}
                my={2}
                justifyContent={"space-between"}
              >
                <Box display={"flex"} flex={1} justifyContent={"space-between"}>
                  <LabelWithIcon
                    icon={
                      <Circle
                        sx={{
                          width: 8,
                          height: 8,
                          border: "4px solid #266D00",
                          borderRadius: 4,
                        }}
                      />
                    }
                    boxSx={{ alignItems: "center" }}
                    value="Active Slots"
                    valueVariant="body1"
                  />
                  <LabelWithIcon
                    icon={
                      <Circle
                        sx={{
                          width: 8,
                          height: 8,
                          color: "transparent",
                          border: "1px solid #000",
                          borderRadius: 4,
                        }}
                      />
                    }
                    boxSx={{ alignItems: "center" }}
                    value="Available Slots"
                    valueVariant="body1"
                  />

                  <LabelWithIcon
                    icon={
                      <PermContactCalendarOutlined
                        sx={{
                          width: 16,
                          height: 16,
                          color: "#5C5F61",
                        }}
                      />
                    }
                    boxSx={{ alignItems: "center" }}
                    value="Preoccupied"
                    valueVariant="body1"
                  />
                  <Box />
                  <Box />
                  <Box />
                  <Box />
                  <Box />
                </Box>
                <Button onClick={onClearTimeslotsSelection}>Clear Slots</Button>
              </Box>
            )}
            {isSM && (
              <Box>
                <Box display="flex" justifyContent="space-between">
                  <LabelWithIcon
                    icon={
                      <Circle
                        sx={{
                          width: 8,
                          height: 8,
                          border: "4px solid #266D00",
                          borderRadius: 4,
                        }}
                      />
                    }
                    boxSx={{ alignItems: "center" }}
                    value="Active Slots"
                    valueVariant="body1"
                  />
                  <LabelWithIcon
                    icon={
                      <Circle
                        sx={{
                          width: 8,
                          height: 8,
                          color: "transparent",
                          border: "1px solid #000",
                          borderRadius: 4,
                        }}
                      />
                    }
                    boxSx={{ alignItems: "center" }}
                    value="Available Slots"
                    valueVariant="body1"
                  />
                  <LabelWithIcon
                    icon={
                      <PermContactCalendarOutlined
                        sx={{
                          width: 16,
                          height: 16,
                          color: "#5C5F61",
                        }}
                      />
                    }
                    boxSx={{ alignItems: "center" }}
                    value="Preoccupied"
                    valueVariant="body1"
                  />
                </Box>
                <Box display="flex" justifyContent="center">
                  <Button onClick={onClearTimeslotsSelection}>
                    Clear Slots
                  </Button>
                </Box>
              </Box>
            )}
            <TimeslotContainer isEdit={isEdit} name="timeSlots" />
          </Box>
          {isSameOrAfter && (
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              fullWidth
              disabled={!methods.formState.isValid}
              sx={{ mt: 2.5, bottom: 0 }}
            >
              {isEdit ? "Update" : "Save"}
            </LoadingButton>
          )}
        </Box>
      </FormProvider>
    </Drawer>
  );
};

export default TimeSlotDrawer;
