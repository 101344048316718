import { useMediaQuery, useTheme } from "@mui/material";

export default function useAppBarRemainingHeight(reduce = 16): string {
  const {
    mixins: { toolbar },
    breakpoints,
    spacing,
  } = useTheme();

  const queryDesktop = breakpoints.up("sm");
  // const queryLandscape = `${breakpoints.up("xs")} and (orientation: landscape)`;
  let toolbarMinHeight = 0;
  const isDesktop = useMediaQuery(queryDesktop);
  // const isLandscape = useMediaQuery(queryLandscape);

  // const cssToolbar =
  //   toolbar[isDesktop ? queryDesktop : isLandscape ? queryLandscape : ""];
  toolbarMinHeight = (toolbar?.minHeight as number) ?? 0;

  if (isDesktop) {
    return `calc(100vh - ${toolbarMinHeight}px - ${reduce}px)`;
  } else {
    return `calc(100vh - ${toolbarMinHeight}px -  8px)`;
  }
}
