import { NavigateBefore, NavigateNext, Today } from "@mui/icons-material";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";

const TimeslotNavigator = ({
  label,
  navigatePrevious,
  navigateNext,
  children,
  onClick,
}: {
  label: string;
  navigatePrevious: () => void;
  navigateNext: () => void;
  onClick: () => void;
  children: JSX.Element;
}) => {
  const { breakpoints, palette } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("sm"));

  return (
    <Box
      sx={[
        {
          display: "flex",
          position: "relative",
          flex: 1,
          flexDirection: "row",
          backgroundColor: "#C9E6FF",
          py: 1,
          borderRadius: 12,
          justifyContent: "space-between",
          cursor: "pointer",
        },
        fullScreen ? {} : { px: 1 },
      ]}
    >
      <IconButton
        sx={{
          fontSize: "18px",
          marginRight: "4px",
        }}
        onClick={() => navigatePrevious()}
      >
        <NavigateBefore />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        onClick={onClick}
      >
        <Today
          sx={{
            fontSize: "18px",
            marginRight: "4px",
          }}
        />
        <Box>{label}</Box>
      </Box>
      <IconButton
        sx={{
          fontSize: "18px",
        }}
        onClick={() => navigateNext()}
      >
        <NavigateNext />
      </IconButton>
      {children}
    </Box>
  );
};

export default TimeslotNavigator;
