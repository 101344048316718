import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Procedure } from "../../provider/home/addAppointment/addAppointmentAPI";

const initialState: BookAppointmentState = { slots: [], procedures: [] };

const bookAppointmentSlice = createSlice({
  name: "bookAppointment",
  initialState,
  reducers: {
    setSlots: (state, action: PayloadAction<SlotItem[]>) => {
      state.slots = action.payload;
    },
    setProcedures: (state, action: PayloadAction<Procedure[]>) => {
      state.procedures = action.payload;
    },
    setSelectedSlot: (state, action: PayloadAction<string>) => {
      state.slots = state.slots.map((slot) => {
        if (slot.scheduleDetailsId !== action.payload) {
          return { ...slot, isSelected: false };
        }
        return { ...slot, isSelected: !slot.isSelected };
      });
    },
    updateConfirmation: (state, action: PayloadAction<string>) => {
      state.slots = state.slots.map((slot) => {
        if (slot.scheduleDetailsId !== action.payload) return slot;
        return { ...slot, isSelected: false, isAvailable: false };
      });
    },
    reset: (state) => {
      state.slots = state.slots.map((slot) => ({ ...slot, isSelected: false }));
    },
  },
});

export const bookAppointMentReducer = bookAppointmentSlice.reducer;
export const bookAppointmentActions = bookAppointmentSlice.actions;
export const bookAppointmentSelectors = bookAppointmentSlice.selectors;

export interface BookAppointmentState {
  slots: SlotItem[];
  procedures: Procedure[];
}

export interface SlotItem {
  scheduleId: string;
  scheduleDetailsId: string;
  slotDateTime: string;
  isAvailable: boolean;
  userTimeZone: string;
  isSelected?: boolean;
}
