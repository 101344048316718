import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface SubscriptionPlanProps {}

const SubscriptionPlan = (props: SubscriptionPlanProps) => {
  const navigate = useNavigate();

  return (
    <Grid container direction="row" marginTop={4}>
      <Typography variant="body2">
        Select our custom subscription plans and become a verified member of
        MyPCT
      </Typography>
      <Grid container display="flex" justifyContent="flex-end" marginTop={2}>
        <Box marginRight={2}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => navigate("/provider")}
          >
            Skip to Try Demo
          </Button>
        </Box>
        <Button type="button" variant="contained" onClick={() => {}}>
          Proceed to Payment
        </Button>
      </Grid>
    </Grid>
  );
};

export default SubscriptionPlan;
