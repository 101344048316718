import { Box, Container, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Labeler from "../../../components/Labeler";
import LabelWithIcon from "../../../components/LabelWithIcon";
import PhoneOutlined from "@mui/icons-material/PhoneOutlined";
import EmailOutlined from "@mui/icons-material/EmailOutlined";
import LocationOnOutlined from "@mui/icons-material/LocationOnOutlined";
import { useAppSelector } from "../../../hooks/useStoreHook";
import DateUtil, { dateUtil } from "../../../utils/dateHelper";
import { IUser } from "../../user/auth/authtypes";
import DataUtil from "../../../utils/dataHelper";

export default function ProfileDetails() {
  const { t } = useTranslation();
  const userData = useAppSelector((state) => state.userState.user) as IUser;
  return (
    <Box>
      <Typography variant="body1" sx={{ mb: 1 }}>
        {t("patient.profile.generalDetails.personal.title")}
      </Typography>
      <Box
        flexDirection={"row"}
        display={"flex"}
        sx={{
          justifyContent: "space-between",
          my: 1,
        }}
      >
        <Labeler
          label={t("patient.profile.generalDetails.personal.name")}
          value={userData?.fullName ?? ""}
          labelerVariant="body2"
          valueVariant="body1"
          sx={{ display: "flex" }}
          valueSxProps={{ ml: 1 }}
        />
        <Labeler
          label={t("patient.profile.generalDetails.personal.birthDate")}
          value={
            userData?.dateOfBirth
              ? dateUtil.formatDate(
                  userData?.dateOfBirth,
                  DateUtil.DateFormats.MONTH_DATE_YEAR
                )
              : ""
          }
          labelerVariant="body2"
          valueVariant="body1"
          sx={{ display: "flex" }}
          valueSxProps={{ ml: 1 }}
        />
        <Labeler
          label={t("patient.profile.generalDetails.personal.gender")}
          value={userData?.gender ?? ""}
          labelerVariant="body2"
          valueVariant="body1"
          sx={{ display: "flex" }}
          valueSxProps={{ ml: 1 }}
        />
        <Box />
        <Box />
        <Box />
        <Box />
      </Box>
      <Box
        flexDirection={"row"}
        display={"flex"}
        sx={{
          justifyContent: "space-between",
          my: 1,
        }}
      >
        <LabelWithIcon
          icon={
            <EmailOutlined
              sx={{
                width: 20,
                height: 24,
              }}
            />
          }
          boxSx={{ height: 24 }}
          iconSx={{ paddingLeft: 0 }}
          value={userData?.email ?? ""}
          valueVariant="body1"
        />
        <LabelWithIcon
          icon={
            <PhoneOutlined
              sx={{
                width: 20,
                height: 24,
              }}
            />
          }
          boxSx={{ height: 24 }}
          value={userData?.phoneNumber ?? ""}
          valueVariant="body1"
        />
        <LabelWithIcon
          icon={
            <LocationOnOutlined
              sx={{
                width: 20,
                height: 24,
              }}
            />
          }
          boxSx={{ height: 24 }}
          value={DataUtil.formatAddress(userData?.address) ?? ""}
          valueVariant="body1"
        />
        <Box />
        <Box />
        <Box />
        <Box />
      </Box>
      <Box
        flexDirection={"row"}
        display={"flex"}
        sx={{
          justifyContent: "space-between",
          my: 1,
        }}
      >
        <Labeler
          label={t("patient.profile.generalDetails.personal.height")}
          value={userData?.height ?? ""}
          labelerVariant="body2"
          valueVariant="body1"
          sx={{ display: "flex" }}
          valueSxProps={{ ml: 1 }}
        />
        <Labeler
          label={t("patient.profile.generalDetails.personal.bloodGroup")}
          value={userData?.bloodGroup ?? ""}
          labelerVariant="body2"
          valueVariant="body1"
          sx={{ display: "flex" }}
          valueSxProps={{ ml: 1 }}
        />
        <Box />
        <Box />
        <Box />
        <Box />
      </Box>
      <Divider sx={{ my: 2 }} />
      <Typography variant="body1" sx={{ mb: 1 }}>
        {t("patient.profile.generalDetails.insurance.title")}
      </Typography>
      <Box
        flexDirection={"row"}
        display={"flex"}
        sx={{
          justifyContent: "space-between",
          my: 1,
        }}
      >
        <Labeler
          label={t("patient.profile.generalDetails.insurance.provider")}
          value={userData?.insuranceProvider ?? ""}
          labelerVariant="body2"
          valueVariant="body1"
          sx={{ display: "flex" }}
          valueSxProps={{ ml: 1 }}
        />
        <Labeler
          label={t("patient.profile.generalDetails.insurance.number")}
          value={userData?.insuranceNumber ?? ""}
          labelerVariant="body2"
          valueVariant="body1"
          sx={{ display: "flex" }}
          valueSxProps={{ ml: 1 }}
        />
        <Box />
        <Box />
        <Box />
        <Box />
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
        <Typography variant="body1">
          {t("patient.profile.generalDetails.additional.notes")}
        </Typography>
        <Typography variant="body2">
          {userData?.insuranceNotes ?? ""}
        </Typography>
      </Box>
    </Box>
  );
}
