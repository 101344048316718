import { ArrowBack } from "@mui/icons-material";
import { Box, Container, Grid, IconButton, Tab, Tabs } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ProfilePicture from "../../components/ProfilePicture";
import PatientDetail from "../../features/provider/patients/patient-details/PatientDetail";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PatientUpcomingAppointments from "../../features/provider/patients/patient-details/PatientUpcomingAppointments";
import PatientPastAppointment from "../../features/provider/patients/patient-details/PatientPastAppointment";
import CustomTabPanel, { addIdProps } from "../../components/CustomTabPanel";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { useGetMyPatientDetailsQuery } from "../../features/provider/patients/providerPatientsAPI";

const tabs = [
  {
    title: "provider.patient.appointment.tabs.0",
  },
  {
    title: "provider.patient.appointment.tabs.1",
  },
];
const PatientDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [breakPoints] = useBreakpoints();
  const { isMatchDownMD } = breakPoints;
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { id } = useParams();
  const { data, isLoading } = useGetMyPatientDetailsQuery({
    id: id ?? null,
  });

  return (
    <Box sx={{ m: 2, flex: 1 }}>
      <IconButton
        sx={{ borderRadius: 8, border: "1px solid #323232" }}
        onClick={() => navigate(-1)}
      >
        <ArrowBack />
      </IconButton>
      <Box sx={{ flex: 1, mx: 8 }}>
        <Box sx={{ flex: 1 }}>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={4} md={5} lg={3} xl={2}>
              <ProfilePicture
                width={isMatchDownMD ? 160 : 194}
                height={isMatchDownMD ? 160 : 194}
                name="User Image"
                loadUserPic
              />
            </Grid>
            <Grid item xs={12} sm={8} md={7} lg={9} xl={10}>
              <PatientDetail patientData={data} isLoading={isLoading} />
            </Grid>
          </Grid>
        </Box>
        {data && (
          <Box sx={{ mt: 3 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleChange} scrollButtons={false}>
                {tabs.map((tab) => (
                  <Tab
                    key={tab.title}
                    label={t(tab.title)}
                    {...addIdProps(tab.title)}
                    wrapped
                  />
                ))}
              </Tabs>
            </Box>
            <CustomTabPanel key={0} value={value} index={0}>
              {value === 0 && (
                <PatientUpcomingAppointments emailId={data?.emailAddress} />
              )}
            </CustomTabPanel>
            <CustomTabPanel key={1} value={value} index={1}>
              {value === 1 && (
                <PatientPastAppointment emailId={data?.emailAddress} />
              )}
            </CustomTabPanel>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PatientDetails;
