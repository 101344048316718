import { Box } from "@mui/material";
import { CSSProperties } from "react";

import { FormInputText } from "./FormInput";
import classes from "./FormTimeInput.module.css";

interface Props {
  hourName?: string;
  minuteName?: string;
  hourLabel?: string;
  minuteLabel?: string;
  style?: CSSProperties;
}

const FormTimeInput = (props: Props) => {
  const {
    hourName = "hour",
    minuteName = "minute",
    hourLabel = "Hour",
    minuteLabel = "Min",
  } = props;

  return (
    <Box component="div" display="flex" alignItems="center" style={props.style}>
      <FormInputText
        type="number"
        style={{ width: 70 }}
        name={hourName}
        label={hourLabel}
      />
      <div>
        <div className={classes.dot} style={{ marginBottom: 16 }}></div>
        <div className={classes.dot}></div>
      </div>
      <FormInputText
        type="number"
        style={{ width: 70 }}
        name={minuteName}
        label={minuteLabel}
      />
    </Box>
  );
};

export default FormTimeInput;
