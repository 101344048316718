import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
type IFormInputProps = {
  name: string;
  label?: string;
  options: any;
} & RadioGroupProps;

export const FormInputRadio: React.FC<IFormInputProps> = ({
  name,
  label,
  options,
  ...otherProps
}) => {
  const { control } = useFormContext();
  const generateRadioOptions = () => {
    return options.map((singleOption: any) => (
      <FormControlLabel
        key={singleOption.value}
        value={singleOption.value}
        label={singleOption.label}
        control={<Radio />}
      />
    ));
  };
  return (
    <FormControl component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => {
          const handleChange = (value: string) => {
            if (isNaN(+value)) onChange(value);
            else onChange(+value);
          };
          return (
            <>
              <RadioGroup
                value={value}
                onChange={(_, value) => handleChange(value)}
                {...otherProps}
              >
                {generateRadioOptions()}
              </RadioGroup>
              {error ? (
                <FormHelperText error>{error?.message}</FormHelperText>
              ) : null}
            </>
          );
        }}
      />
    </FormControl>
  );
};
