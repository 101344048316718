import { Box, Button, Divider, Typography } from "@mui/material";
import TimeslotHeader from "./components/TimeslotHeader";
import DateUtil, { dateUtil } from "../../../../utils/dateHelper";
import { useState } from "react";
import ViewTimeslotContainer from "./components/ViewTimeslotContainer";
import TimeSlotDrawer from "./TimeSlotDrawer";
import { useAppSelector } from "../../../../hooks/useStoreHook";
import { useDateSetter } from "../../../../hooks/useDateSetter";
import EmptyTimeSlot from "./EmptyTimeSlot";
import DataUtil from "../../../../utils/dataHelper";
import TimeSlotSkeleton from "./TimeSlotSkeleton";

const ViewTimeSlot = ({
  timeSlots,
  getSchedule,
  isScheduleFetching,
}: {
  timeSlots: any;
  getSchedule: ({ scheduleDate }: { scheduleDate: string }) => void;
  isScheduleFetching?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const selectedDate = useAppSelector((state) => state.dateSetter.selectedDate);
  const { setDate } = useDateSetter();
  const isSameOrAfter = dateUtil.checkDate(selectedDate, "isSameOrAfter");

  const getSlots = (startTime: string, endTime: string, _timeSlots?: any) => {
    let currentTime = dateUtil.createMoment(startTime, "HH:mm");
    let slots = [];
    do {
      const currentKey = dateUtil.formatDate(
        currentTime,
        DateUtil.DateFormats.MILIARY_TIME
      );
      slots.push({
        key: currentKey,
        value: dateUtil.formatDate(currentTime, DateUtil.DateFormats.TIME),
        isOccupied: _timeSlots
          ? _timeSlots.find(
              (slot: any) =>
                dateUtil.formatDate(
                  slot.startTime,
                  DateUtil.DateFormats.MILIARY_TIME
                ) === currentKey
            ) ?? false
          : false,
      }); // Push formatted time to array
      currentTime.add(30, "minutes"); // Add 30 minutes to currentTime
    } while (
      currentTime.isSameOrBefore(dateUtil.createMoment(endTime, "HH:mm"))
    );
    return slots;
  };

  const handleDrawer = (value: boolean) => {
    setOpen(value);
  };

  return (
    <Box>
      <TimeslotHeader
        getSchedule={getSchedule}
        selectedDate={dateUtil.createMoment(selectedDate)}
        onSelectedDate={setDate}
        showDivider
        onReset={() => {}}
      />
      {isScheduleFetching && <TimeSlotSkeleton />}
      {!isScheduleFetching && DataUtil.isEmptyObject(timeSlots) && (
        <EmptyTimeSlot isSameOrAfter={isSameOrAfter} />
      )}
      {!isScheduleFetching && !DataUtil.isEmptyObject(timeSlots) && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box display={"flex"} flex={1} mb={1.5}>
              <Typography variant="body2" mr="auto">
                Selected Working Hours:
              </Typography>
              <Typography variant="body1">
                {dateUtil.formatDate(
                  timeSlots?.startTime,
                  DateUtil.DateFormats.TIME
                )}{" "}
                -{" "}
                {dateUtil.formatDate(
                  timeSlots?.endTime,
                  DateUtil.DateFormats.TIME
                )}
              </Typography>
              <Box flexGrow={"0.1"} />
              <Typography variant="body1">
                {timeSlots?.userTimeZone ?? ""}
              </Typography>
              <Box mr="auto" flexGrow={"0.8"} />
            </Box>
            {/* <Box display={"flex"} flex={1} my={1.5}>
          <Typography variant="body2" mr="auto">
            Valid Till:
          </Typography>
          <Typography variant="body1">23 Oct 2024</Typography>
          <Box mr="auto" flexGrow={"0.9"} />
        </Box> */}
            <ViewTimeslotContainer
              timeSlots={getSlots(
                dateUtil.formatDate(
                  timeSlots?.startTime,
                  DateUtil.DateFormats.MILIARY_TIME
                ),
                dateUtil.formatDate(
                  timeSlots?.endTime,
                  DateUtil.DateFormats.MILIARY_TIME
                ),
                timeSlots?.timeSlots ?? null
              )}
            />
          </Box>
          {isSameOrAfter && (
            <>
              <Divider
                orientation="vertical"
                sx={{ borderWidth: "1px", backgroundColor: "#C5C6D0", mt: 2 }}
              />
              <Button
                variant="contained"
                fullWidth
                sx={{ mt: 2.5 }}
                onClick={() => handleDrawer(true)}
              >
                Edit Time Slots
              </Button>
            </>
          )}
          <TimeSlotDrawer
            open={open}
            handleDrawer={handleDrawer}
            isEdit
            getSchedule={getSchedule}
            currentTimeslots={timeSlots}
          />
        </>
      )}
    </Box>
  );
};

export default ViewTimeSlot;
