import { MoreVert } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Modal,
  Popover,
  Skeleton,
  SxProps,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";
import { FormInputText } from "./form/FormInput";
import {
  useDeleteNoteMutation,
  useUpdateNoteMutation,
} from "../features/provider/patients/providerPatientsAPI";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { getErrorMessage } from "../utils/serviceHelper";
import { useToast } from "../hooks/useToast";
import { TypeOf, object, string } from "zod";
import { LoadingButton } from "@mui/lab";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

export const appointmentNoteSchema = object({
  editNotes: string(),
});

export type AppointmentNoteInput = TypeOf<typeof appointmentNoteSchema>;

function EditModal({
  noteId,
  appointmentId,
  appointmentNote,
  open,
  handleClose,
  updateNote,
  isLoading,
  methods,
}: {
  noteId: string;
  appointmentId: string;
  appointmentNote: string;
  open: boolean;
  handleClose: () => void;
  updateNote: any;
  isLoading: boolean;
  methods: any;
}) {
  const { breakpoints } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("sm"));
  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
    setValue,
  } = methods;

  useEffect(() => {
    console.log("useEffect::notes::appointmentNote", appointmentNote);
    setValue("editNotes", appointmentNote);
  }, []);

  const onSubmitHandler: SubmitHandler<AppointmentNoteInput> = (values) => {
    // 👇 Executing the loginUser Mutation
    updateNote({
      notes: values.editNotes,
      noteId: noteId,
      appointmentId: appointmentId,
    });
  };

  return (
    <Fragment>
      <Dialog
        onClose={handleClose}
        open={open}
        fullScreen={fullScreen}
        fullWidth
        PaperProps={{
          sx: { backgroundColor: "transparent" },
        }}
      >
        <FormProvider {...methods}>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Box
              sx={{
                height: "calc(416px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box onClick={handleClose} display={"flex"} flex={1} />
              <Box bgcolor={"#fff"}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  m={2}
                >
                  <FormInputText
                    sx={{
                      border: "none",
                      backgroundColor: "transparent",
                      mr: 4,
                    }}
                    placeholder="Type your text..."
                    color="primary"
                    variant="outlined"
                    size="medium"
                    margin="normal"
                    type="text"
                    label=""
                    fullWidth
                    name="editNotes"
                    required
                  />
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    type="submit"
                  >
                    Update
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </FormProvider>
      </Dialog>
    </Fragment>
  );
}

const AppointmentNote = ({
  isPatient,
  noteId,
  appointmentId,
  title,
  note,
  sx,
}: {
  isPatient?: boolean;
  noteId: string;
  appointmentId: string;
  title: string;
  note: string;
  sx?: SxProps;
}) => {
  const [openEdit, setOpenEdit] = useState(false);
  const handleEditOpen = () => {
    setOpenEdit(true);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const methods = useForm<AppointmentNoteInput>({
    resolver: zodResolver(appointmentNoteSchema),
  });

  const open = Boolean(anchorEl);
  const id = open ? "popover-edit-delete" : undefined;
  const [
    updateNote,
    {
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      isLoading: isUpdateLoading,
      error: updateError,
    },
  ] = useUpdateNoteMutation();

  const [deleteNote, { isSuccess, isError, isLoading, error }] =
    useDeleteNoteMutation();

  const { displayToast } = useToast();

  useEffect(() => {
    if (isSuccess) {
    }
    if (isError) {
      const errorMessage = getErrorMessage(error);
      displayToast({ message: errorMessage, type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isUpdateSuccess) {
      handleEditClose();
      methods.reset({ editNotes: "" });
    }
    if (isUpdateError) {
      handleEditClose();
      methods.reset({ editNotes: "" });
      const errorMessage = getErrorMessage(updateError);
      displayToast({ message: errorMessage, type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateLoading]);

  return (
    <Fragment>
      {isLoading || isUpdateLoading ? (
        <Skeleton
          variant="rounded"
          width={"90%"}
          height={70}
          sx={{ my: 0.5, borderRadius: 3 }}
        />
      ) : (
        <Box
          sx={{ p: 2, width: "90%", ...sx }}
          bgcolor={"#D8E2FF"}
          borderRadius={3}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography>{title}</Typography>
            {!isPatient && (
              <IconButton
                sx={{ padding: 0 }}
                color="secondary"
                onClick={handlePopoverOpen}
              >
                <MoreVert />
              </IconButton>
            )}
          </Box>
          <Typography variant="body1" sx={{ fontSize: 14 }}>
            {note}
          </Typography>
        </Box>
      )}
      {openEdit && (
        <EditModal
          noteId={noteId}
          appointmentId={appointmentId}
          appointmentNote={note}
          open={openEdit}
          handleClose={handleEditClose}
          updateNote={updateNote}
          methods={methods}
          isLoading={isUpdateLoading}
        />
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <IconButton
            sx={{ padding: 0, alignSelf: "flex-end", color: "#323232" }}
            onClick={handlePopoverClose}
          >
            <MoreVert />
          </IconButton>
          <Button
            onClick={() => {
              handleEditOpen();
              handlePopoverClose();
            }}
            sx={{ justifyContent: "flex-start" }}
          >
            <Typography variant="body1" sx={{ fontSize: 14 }}>
              Edit
            </Typography>
          </Button>
          <Button
            sx={{ justifyContent: "flex-start" }}
            onClick={() => {
              deleteNote({ noteId: noteId });
              handlePopoverClose();
            }}
          >
            <Typography variant="body1" sx={{ fontSize: 14 }}>
              Delete
            </Typography>
          </Button>
        </Box>
      </Popover>
    </Fragment>
  );
};

export default AppointmentNote;
