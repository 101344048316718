import { encrypt } from "../../../utils/codec";
import config from "../../../config/config";
import { providerAPI } from "../providerAPI";
import {
  IReqGetAllMyAppointments,
  appointmentsAdapter,
  providerPatientsAPI,
} from "../patients/providerPatientsAPI";
import { IGetCalendarView, calendarAPI } from "../calendar/calendarAPI";

export function getAppointmentStatus(status: number) {
  switch (status) {
    case 0:
      return "Pending";
    case 1:
      return "Approved";
    case 2:
      return "Rejected";
    case 3:
      return "Completed";
    case 4:
      return "Rescheduled";
    case 5:
      return "Rescheduled";
    case 6:
      return "DidNotShowUp";
    default:
      return "";
  }
}

export function getAppStatus(status: number) {
  switch (status) {
    case 0:
      return "RQ";
    case 1:
      return "U";
    case 2:
      return "NA";
    case 3:
      return "PA";
    case 4:
      return "U";
    case 5:
      return "RQ";
    case 6:
      return "NA";
    default:
      return "NA";
  }
}

export function getIsActionEnabled(status: number) {
  switch (status) {
    case 0:
      return true;
    case 1:
      return false;
    case 2:
      return false;
    case 3:
      return false;
    case 4:
      return false;
    case 5:
      return true;
    case 6:
      return false;
    default:
      return false;
  }
}

interface IAppointmentApproval {
  appointmentId: string;
  status: number;
}

interface _IAppointmentApproval
  extends IReqGetAllMyAppointments,
    IAppointmentApproval {}

interface IAppointmentCalendarApproval
  extends IGetCalendarView,
    IAppointmentApproval {}

interface IAppointmentReview {
  appointmentId: string;
  rating: string;
}

export const appointmentAPI = providerAPI.injectEndpoints({
  endpoints: (builder) => ({
    appointmentApproval: builder.mutation<{}, _IAppointmentApproval>({
      query(data) {
        const request = {
          appointmentId: data.appointmentId,
          status: data.status,
        };

        const _data = encrypt(request, config.secret);
        return {
          url: "/api/services/app/AppointmentService/AcceptRejectRequest",
          method: "POST",
          credentials: "include",
          body: _data,
        };
      },
      onQueryStarted(
        { appointmentId, status, ...refetchParams },
        { dispatch, queryFulfilled }
      ) {
        // For update query data to work we need to pass the exact query param
        // on the search screen
        const update = dispatch(
          providerPatientsAPI.util.updateQueryData(
            "getAllMyAppointments",
            refetchParams,
            (draft) => {
              appointmentsAdapter.removeOne(draft, appointmentId);
            }
          )
        );
        queryFulfilled.catch(() => {
          update.undo();
        });
      },
    }),
    appointmentCalendarApproval: builder.mutation<
      {},
      IAppointmentCalendarApproval
    >({
      query(data) {
        const request = {
          appointmentId: data.appointmentId,
          status: data.status,
        };

        const _data = encrypt(request, config.secret);
        return {
          url: "/api/services/app/AppointmentService/AcceptRejectRequest",
          method: "POST",
          credentials: "include",
          body: _data,
        };
      },
      async onQueryStarted(
        { appointmentId, status, ...refetchParams },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            calendarAPI.endpoints.getCalendarView.initiate(refetchParams, {
              forceRefetch: true,
            })
          );
        } catch (error) {
          console.log("error", error);
        }
      },
    }),
    cancelAppointment: builder.mutation<{}, _IAppointmentApproval>({
      query(data) {
        const request = {
          appointmentId: data.appointmentId,
          status: data.status,
        };

        const _data = encrypt(request, config.secret);
        return {
          url: "/api/services/app/AppointmentService/CancelRequestByPatient",
          method: "POST",
          credentials: "include",
          body: _data,
        };
      },
      onQueryStarted(
        { appointmentId, status, ...refetchParams },
        { dispatch, queryFulfilled }
      ) {
        // For update query data to work we need to pass the exact query param
        // on the search screen
        const update = dispatch(
          providerPatientsAPI.util.updateQueryData(
            "getAllMyAppointments",
            refetchParams,
            (draft) => {
              appointmentsAdapter.removeOne(draft, appointmentId);
            }
          )
        );
        queryFulfilled.catch(() => {
          update.undo();
        });
      },
    }),
    reviewAppointment: builder.mutation<{}, IAppointmentReview>({
      query(data) {
        const request = {
          appointmentId: data.appointmentId,
          review: "",
          rating: Number(data.rating),
        };

        const _data = encrypt(request, config.secret);
        return {
          url: "/api/services/app/AppointmentService/PostReview",
          method: "POST",
          credentials: "include",
          body: _data,
        };
      },
      //   onQueryStarted(
      //     { appointmentId, status, ...refetchParams },
      //     { dispatch }
      //   ) {
      //     // For update query data to work we need to pass the exact query param
      //     // on the search screen
      //     dispatch(
      //       providerPatientsAPI.util.updateQueryData(
      //         "getAllMyAppointments",
      //         refetchParams,
      //         (draft) => {
      //           appointmentsAdapter.removeOne(draft, appointmentId);
      //         }
      //       )
      //     );
      //   },
    }),
  }),
});

export const {
  useAppointmentApprovalMutation,
  useCancelAppointmentMutation,
  useReviewAppointmentMutation,
  useAppointmentCalendarApprovalMutation,
} = appointmentAPI;
