import { createApi } from "@reduxjs/toolkit/query/react";
import config from "../../../config/config";
import { LoginInput } from "./signin/signinSchema";
import { encrypt } from "../../../utils/codec";
import { ForgotInput } from "./forgot/forgotSchema";
import baseQueryWithReauth from "../../../app/store/slices/apiSlice";
import { RegisterInput } from "./signup/signupSchema";
import { setCredentials } from "../../../app/store";
import { userApi } from "../userAPI";
import { ForgotResetInput } from "./reset/resetSchema";

export interface Login {
  email: string;
  password: string;
}

interface RegisterInputData extends RegisterInput {
  isProvider: boolean;
}
interface LoginInputData extends LoginInput {
  isProvider: boolean;
}

const authAPI = createApi({
  reducerPath: "auth",
  baseQuery: baseQueryWithReauth,
  endpoints(builder) {
    return {
      registerUser: builder.mutation<
        { data: any; status: string },
        RegisterInputData
      >({
        query: (user) => {
          const request = {
            name: user.firstname,
            middlename: user.middlename ?? "",
            surname: user.surname,
            emailAddress: user.email,
            password: user.password,
            isProvider: user.isProvider,
          };
          const _data = encrypt(request, config.secret);
          return {
            url: "/api/services/app/Account/Register",
            method: "POST",
            body: _data,
          };
        },
      }),
      loginUser: builder.mutation<
        { result: any; status: string },
        LoginInputData
      >({
        query: (data) => {
          const request = {
            userNameOrEmailAddress: data.email,
            password: data.password,
            rememberClient: data.remember ?? false,
          };
          const _data = encrypt(request, config.secret);
          return {
            url: "/api/TokenAuth/Authenticate",
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: _data,
          };
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setCredentials(data?.result));
            if (args.isProvider) {
              await dispatch(userApi.endpoints.getMeAsProvider.initiate(null));
            } else {
              await dispatch(userApi.endpoints.getMe.initiate(null));
            }
          } catch (error) {
            console.log("error", error);
          }
        },
      }),

      userOTP: builder.mutation<
        { message: string; status: string },
        ForgotInput
      >({
        query: (data) => {
          const request = {
            email: data.email,
          };
          const _data = encrypt(request, config.secret);
          return {
            url: "/api/services/app/Account/RequestOtp",
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: _data,
            credentials: "include",
          };
        },
      }),

      resetPassword: builder.mutation<{ status: string }, ForgotResetInput>({
        query: (data) => {
          const request = {
            email: data.email,
            otp: data.otp,
            newPassword: data.password,
            confirmPassword: data.passwordConfirm,
          };
          const _data = encrypt(request, config.secret);
          return {
            url: "/api/services/app/Account/ResetPasswordWithOtp",
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: _data,
            credentials: "include",
          };
        },
      }),
    };
  },
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useUserOTPMutation,
  useResetPasswordMutation,
} = authAPI;

export default authAPI;
