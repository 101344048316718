import BasicDetails from "./components/BasicDetails";
import ProfessionalDetails from "./components/ProfessionalDetails";
import Review from "./components/Review";
import SubscriptionPlan from "./components/SubscriptionPlan";

interface ProviderProfileFormProps {
  progress: number;
  onNextClick: () => void;
  onBackClick: () => void;
}

const ProviderProfileForm = (props: ProviderProfileFormProps) => {
  const { progress, onNextClick, onBackClick } = props;
  // const { t } = useTransition();

  return (
    <>
      {progress === 12.5 && <BasicDetails onNextClick={onNextClick} />}
      {progress === 37.5 && (
        <ProfessionalDetails
          onNextClick={onNextClick}
          onBackClick={onBackClick}
        />
      )}
      {props.progress === 62.5 && (
        <Review onNextClick={onNextClick} onBackClick={onBackClick} />
      )}
      {props.progress === 87.5 && <SubscriptionPlan />}
    </>
  );
};

export default ProviderProfileForm;
