import { forwardRef, useEffect } from "react";
import { Box, SxProps, Typography, useTheme } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import MessageIcon from "@mui/icons-material/Message";
import EmailIcon from "@mui/icons-material/Email";
import AccessTime from "@mui/icons-material/AccessTime";

import { useLazyGetProfilePicQuery } from "../../../patient/dashboard/profilePictureAPI";
import { useBreakpoints } from "../../../../hooks/useBreakpoints";
import ProfilePicture from "../../../../components/ProfilePicture";
import IconRounder from "../../../../components/IconRounder";

type Props = {
  name: string;
  age: number;
  gender: string;
  location: string;
  consultedTaxonamy: string;
  primaryTileCaption: string;
  contactNumber?: string;
  profileImage: string;
  consultedTaxonamyTitle?: string;
  primaryTileCaptionTitle?: string;
  lastAppointmentDate?: string;
  phoneNumber: string;
  emailAddress: string;
};

const PatientCard = forwardRef((props: Props, ref) => {
  const [getProfilePic, status] = useLazyGetProfilePicQuery();
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const { palette } = useTheme();

  useEffect(() => {
    if (props.profileImage) getProfilePic(props.profileImage);
  }, [getProfilePic, props.profileImage]);

  const cardContainerSx: SxProps = {
    width: "100%",
    border: "1px solid #A3C9DE",
    borderRadius: isSM ? "20px" : "28px",
    my: isSM ? 1 : 2,
    p: isSM ? 1.5 : 2,
  };

  const iconSx: SxProps = { bgcolor: "#CFE5FF", color: "#006398" };
  const smallIconSx: SxProps = { width: 24, height: 24 };
  const iconSize = isSM ? 32 : 40;

  return (
    <Box ref={ref ?? null} sx={cardContainerSx}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <ProfilePicture width={40} height={40} name="User Image" />
          <Typography paddingLeft={2} variant="body1">
            {props.name}
          </Typography>
        </Box>
        {isSM && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            minWidth="25%"
          >
            <a href={`tel:${props.phoneNumber}`}>
              <IconRounder sx={iconSx} size={iconSize}>
                <CallIcon sx={{ height: 20 }} />
              </IconRounder>
            </a>

            <a href={`sms:${props.phoneNumber}`}>
              <IconRounder sx={iconSx} size={iconSize}>
                <MessageIcon sx={{ height: 20 }} />
              </IconRounder>
            </a>
          </Box>
        )}
      </Box>

      <Box display="flex" alignItems="center" my={isSM ? 1 : 1.5}>
        <AccessTime sx={smallIconSx} />
        <Typography pl={isSM ? 1 : 1.5} variant="body1">
          Last Consulted: {props.lastAppointmentDate}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mb={isSM ? 1 : 1.5}>
        <CallIcon sx={smallIconSx} />
        <Typography pl={isSM ? 1 : 1.5} variant="body1">
          {props.phoneNumber}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <EmailIcon sx={smallIconSx} />

        <Typography pl={isSM ? 1 : 1.5} variant="body1">
          <a
            href={`mailto:${props.emailAddress}`}
            style={{ color: palette.primary.main }}
          >
            {props.emailAddress}
          </a>
        </Typography>
      </Box>
    </Box>
  );
});

export default PatientCard;
