import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../components/Breadcrumb";
import ProfilePicture from "../../components/ProfilePicture";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import ProfileDetails from "../../features/patient/profile/ProfileDetails";
import { useState } from "react";
import CustomTabPanel, { addIdProps } from "../../components/CustomTabPanel";
import Appointments from "../../features/patient/appointments/Appointments";
import EditProfileButton from "../../features/provider/settings/ProfileSetting/components/EditProfileButton";
import { useNavigate } from "react-router-dom";

export default function Profile(props: any) {
  const [breakPoints] = useBreakpoints();
  const { isMatchDownMD } = breakPoints;

  const navigate = useNavigate();

  return (
    <Box sx={{ m: 4 }}>
      <Breadcrumb />
      <Box sx={{ flex: 1, mx: 8 }}>
        <Box sx={{ flex: 1 }}>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={4} md={5} lg={3} xl={2}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <ProfilePicture
                  width={isMatchDownMD ? 160 : 194}
                  height={isMatchDownMD ? 160 : 194}
                  name="profilePic"
                />
                <Box sx={{ marginTop: 1 }}>
                  <EditProfileButton
                    onClick={() => navigate("/complete-profile")}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={7} lg={9} xl={10}>
              <ProfileDetails />
            </Grid>
          </Grid>
        </Box>
        <Appointments />
      </Box>
    </Box>
  );
}
