import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "../../../app/store/slices/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { encrypt } from "../../../utils/codec";
import config from "../../../config/config";

interface IFAQ {
  id: string;
  type: number;
  message: string;
  isRead: boolean;
  timeRead: Date;
  creationTime: Date;
  additionalInfo: any;
}

const faqAdapter = createEntityAdapter({
  selectId: (item: IFAQ) => item.id,
});

const faqSelector = faqAdapter.getSelectors();

export const faqAPI = createApi({
  reducerPath: "faqAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllFAQs: builder.query<
      any,
      { pageNumber: number; limit: number; type: number[] }
    >({
      query(data) {
        const { pageNumber, limit } = data;
        const request = {
          MaxResultCount: limit,
          SkipCount: (pageNumber - 1) * limit,
        };

        return {
          url: "/api/services/app/FaqService/GetAll",
          method: "GET",
          credentials: "include",
          params: request,
        };
      },
      transformResponse: (response: any, _: any, { pageNumber, limit }) => {
        return faqAdapter.addMany(
          faqAdapter.getInitialState({
            hasMorePages: pageNumber * limit < response?.result?.totalCount,
          }),
          response?.result?.items ?? []
        );
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.pageNumber !== previousArg?.pageNumber;
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        //filter values should be added here
        return `${endpointName} ${JSON.stringify(queryArgs.type)}`;
      },
      merge: (currentState, incomingState) => {
        faqAdapter.addMany(currentState, faqSelector.selectAll(incomingState));
        currentState.hasMorePages = incomingState.hasMorePages;
      },
    }),
  }),
});

export const { useGetAllFAQsQuery } = faqAPI;

export { faqAdapter, faqSelector };
