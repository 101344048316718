import {
  Box,
  Button,
  Dialog,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Calendar } from "../../components/event-calendar";
import moment from "moment";
import { useLazyGetCalendarViewQuery } from "../../features/provider/calendar/calendarAPI";
import DateUtil, { dateUtil } from "../../utils/dateHelper";
import { View, Views } from "react-big-calendar";
import { LocationOnOutlined } from "@mui/icons-material";
import ProfilePicture from "../../components/ProfilePicture";
import { useNavigate } from "react-router-dom";
import PatientDetails from "../../components/PatientDetails";
import { useBreakpoints } from "../../hooks/useBreakpoints";

export default function CalendarPage() {
  const today = dateUtil.getToday();

  const [date, setDate] = useState(today.toDate());
  const [view, setView] = useState<View>(Views.WEEK);
  const [popup, setPopup] = useState(false);
  const [id, setId] = useState<string | null>(null);
  const [event, setEvent] = useState<any | null>(null);
  const [{ isMatchDownSM: isSM }] = useBreakpoints();

  const handleClose = () => {
    setPopup(false);
  };
  const [getCalendarView, { data }] = useLazyGetCalendarViewQuery();
  useEffect(() => {
    const weekStart = today.startOf("week").format("YYYY-MM-DD");
    const weekEnd = today.endOf("week").format("YYYY-MM-DD");
    getCalendarView({
      startDate: weekStart,
      endDate: weekEnd,
      search: "",
    });
  }, []);

  useEffect(() => {
    if (isSM) setView(Views.DAY);
    else setView(Views.WEEK);
  }, [isSM]);

  // const moveEvent = useCallback(
  //   (start: Date, end: Date, appointmentId: number | undefined) => {
  //     setEvents((prevEvents) => {
  //       return prevEvents.map((event) =>
  //         event?.data?.appointment?.id === appointmentId
  //           ? {
  //               ...event,
  //               start,
  //               end,
  //             }
  //           : event
  //       );
  //     });
  //   },
  //   [setEvents]
  // );

  // const resizeEvent = useCallback(
  //   (start: Date, end: Date, appointmentId: number | undefined) => {
  //     setEvents((prevEvents) => {
  //       return prevEvents.map((event) =>
  //         event?.data?.appointment?.id === appointmentId
  //           ? {
  //               ...event,
  //               start,
  //               end,
  //             }
  //           : event
  //       );
  //     });
  //   },
  //   [setEvents]
  // );

  const handleSelectEvent = useCallback((event: any) => {
    setEvent(event);
    setId(event?.data?.customer?.id ?? null);
    setPopup(true);
  }, []);

  const onNavigate = useCallback(
    (newDate: any, view: any, action: any) => {
      setDate(newDate);
      const weekStart = moment(newDate).startOf("week").format("YYYY-MM-DD");
      const weekEnd = moment(newDate).endOf("week").format("YYYY-MM-DD");
      if (view === "week") {
        if (action == "PREV") {
          getCalendarView({
            startDate: weekStart,
            endDate: weekEnd,
            search: "",
          });
        } else {
          getCalendarView({
            startDate: weekStart,
            endDate: weekEnd,
            search: "",
          });
        }
      } else if (view === "day") {
        const day = moment(newDate).format("YYYY-MM-DD");
        if (action == "PREV") {
          getCalendarView({
            startDate: day,
            endDate: day,
            search: "",
          });
        } else {
          getCalendarView({
            startDate: day,
            endDate: day,
            search: "",
          });
        }
      }
    },
    [setDate]
  );

  const onView = useCallback(
    (newView: any) => {
      setView(newView);
      const day = moment(date).format(DateUtil.DateFormats.YEAR_MONTH_DATE);
      const weekStart = moment(date).startOf("week").format("YYYY-MM-DD");
      const weekEnd = moment(date).endOf("week").format("YYYY-MM-DD");
      if (newView === Views.WEEK) {
        getCalendarView({
          startDate: weekStart,
          endDate: weekEnd,
          search: "",
        });
      } else if (newView === Views.DAY) {
        getCalendarView({
          startDate: day,
          endDate: day,
          search: "",
        });
      }
    },
    [setView, date]
  );

  return (
    <Box sx={{ padding: 2, width: "100%" }}>
      <Calendar
        popup={false}
        events={data}
        date={date}
        view={view}
        onNavigate={onNavigate}
        onView={onView}
        // onEventDrop={({ start, end, event }) =>
        //   moveEvent(
        //     moment(start).toDate(),
        //     moment(end).toDate(),
        //     (event as EventItem)?.data?.appointment?.id
        //   )
        // }
        // onEventResize={({ start, end, event }) =>
        //   resizeEvent(
        //     moment(start).toDate(),
        //     moment(end).toDate(),
        //     (event as EventItem)?.data?.appointment?.id
        //   )
        // }
        onSelectEvent={handleSelectEvent}
      />
      {popup && (
        <PatientDetails
          event={event}
          patientId={id}
          open={popup}
          handleClose={handleClose}
          date={date}
          view={view}
        />
      )}
    </Box>
  );
}
