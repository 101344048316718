import { Box, Typography } from "@mui/material";
import { useArchived } from "./useArchived";
import { getGender } from "../../../../utils/serviceHelper";
import moment from "moment";
import DateUtil, { dateUtil } from "../../../../utils/dateHelper";
import DataUtil from "../../../../utils/dataHelper";
import CenteredBox from "../../../../components/CenteredBox";
import PatientCard from "../components/PatientCard";
import PatientCardSkeleton from "../components/PatientCardSkeleton";
import { useBreakpoints } from "../../../../hooks/useBreakpoints";

const ArchivedList = ({ header }: { header: any }) => {
  const { isLoading, lastPostRef, appointments, isError } = useArchived();
  const [{ isMatchDownSM: isSM }] = useBreakpoints();

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        {Array.from({ length: 12 }).map((el, index) => {
          return <PatientCardSkeleton key={index} />;
        })}
      </Box>
    );
  }

  if (isError) {
    return (
      <CenteredBox height="30vh">
        <Typography>Something went wrong while fetching the data</Typography>
      </CenteredBox>
    );
  }

  if (appointments.length === 0) {
    return (
      <CenteredBox height="30vh">
        <Typography>No archived appointments</Typography>
      </CenteredBox>
    );
  }

  return (
    <Box
      sx={{
        overflowY: "auto",
        minHeight: "30vh",
        maxHeight: isSM ? "60vh" : "120vh",
        p: 1,
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
      className="fixed-size"
    >
      {appointments.map((appointment: any, index: number) => {
        return (
          <Box
            key={appointment.id ?? index}
            sx={{ width: isSM ? "100%" : "49%" }}
          >
            {index === appointments.length - 1 ? (
              <PatientCard
                ref={lastPostRef}
                name={appointment.customer?.fullName ?? ""}
                age={dateUtil.calculateAgeFromDOB(
                  appointment.customer?.dateOfBirth
                )}
                profileImage={appointment.customer?.profileImage ?? ""}
                gender={getGender(appointment.customer.gender ?? null)}
                location={DataUtil.formatAddress(appointment.customer?.address)}
                consultedTaxonamy={
                  appointment.procedureOrServiceToPerform?.name ?? ""
                }
                primaryTileCaption={moment(
                  appointment.scheduleDetails?.startTime ?? null
                ).format(DateUtil.DateFormats.F_MONTH_DATE_YEAR)}
                consultedTaxonamyTitle={header[1]?.title}
                primaryTileCaptionTitle={header[2]?.title}
                lastAppointmentDate={moment(
                  appointment.customer.lastAppointmentDate
                ).format(DateUtil.DateFormats.MONTH_DATE_YEAR)}
                emailAddress={appointment.customer.emailAddress ?? ""}
                phoneNumber={appointment.customer.phoneNumber ?? ""}
              />
            ) : (
              <PatientCard
                name={appointment.customer?.fullName ?? ""}
                age={dateUtil.calculateAgeFromDOB(
                  appointment.customer?.dateOfBirth
                )}
                profileImage={appointment.customer?.profileImage ?? ""}
                gender={getGender(appointment.customer.gender ?? null)}
                location={DataUtil.formatAddress(appointment.customer?.address)}
                consultedTaxonamy={
                  appointment.procedureOrServiceToPerform?.name ?? ""
                }
                primaryTileCaption={moment(
                  appointment.scheduleDetails?.startTime ?? null
                ).format(DateUtil.DateFormats.F_MONTH_DATE_YEAR)}
                consultedTaxonamyTitle={header[1].title}
                primaryTileCaptionTitle={header[2].title}
                lastAppointmentDate={moment(
                  appointment.customer.lastAppointmentDate
                ).format(DateUtil.DateFormats.MONTH_DATE_YEAR)}
                emailAddress={appointment.customer.emailAddress ?? ""}
                phoneNumber={appointment.customer.phoneNumber ?? ""}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default ArchivedList;
