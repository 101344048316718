import {
  Circle,
  DoNotDisturbAlt,
  NavigateBefore,
  NavigateNext,
  Today,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Icon,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import { navigate } from "../utils/constants";
import moment, { Moment } from "moment";
import SearchInput from "../../SearchInput";
import LabelWithIcon from "../../LabelWithIcon";
import { useState } from "react";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import WeekCalendar from "../../../features/provider/settings/SetUpTimeSlot/components/WeekCalendar";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

const Toolbar = (props: any) => {
  const theme = useTheme();
  const { palette } = useTheme();
  const [timezone, setTimezone] = useState("EST GMT-5");
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const [open, setOpen] = useState(false);
  const ref = useOutsideClick(() => setOpen(false));

  let {
    localizer: { messages },
    label,
    date,
  } = props;

  const _view = (view: any) => {
    props.onView(view);
  };

  const _navigate = (action: string) => {
    props.onNavigate(action);
  };

  const viewNamesGroup = (messages: any) => {
    let viewNames = props.views;
    const view = props.view;

    if (viewNames.length > 1) {
      return viewNames.map((name: string, index: number) => (
        <Button
          variant="contained"
          key={name}
          sx={[
            {
              color: "#001D33",
              display: "inline-block",
              margin: 0,
              textAlign: "center",
              verticalAlign: "middle",
              backgroundColor: "transparent",
              border: "1px solid #75777F",
              borderRadius: "12px",
              lineHeight: "normal",
              whiteSpace: "nowrap",
              fontFamily: "Jost",
              fontSize: 14,
              fontWeight: 500,
              minWidth: "104px",
              [theme.breakpoints.down("xs")]: {
                fontSize: 12,
                minWidth: "84px",
              },
              ":hover": {
                backgroundColor: "#CFE5FF70",
              },
            },
            view === name && { backgroundColor: "#CFE5FF" },
          ]}
          onClick={_view.bind(null, name)}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {view === name && (
              <Icon
                sx={{
                  alignSelf: "center",
                  fontSize: "18px",
                  marginRight: "4px",
                }}
              >
                done
              </Icon>
            )}
            <Typography>{messages[name]}</Typography>
          </Box>
        </Button>
      ));
    }
  };

  function changeLanguage(e: SelectChangeEvent) {
    setTimezone("EST GMT-5");
  }
  const selectTimeZone = (timezones: any) => {
    return timezones.map((timezone: any, timezoneIndex: number) => {
      return (
        <MenuItem key={timezoneIndex} value={timezone.value}>
          {timezone.value}
        </MenuItem>
      );
    });
  };

  const timeZoneSelect = (
    <Select
      value={timezone}
      onChange={changeLanguage}
      sx={{
        color: palette.primary.main,
        ml: 2,
        "& .MuiSelect-select": {
          paddingLeft: 3,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiSvgIcon-root": {
          left: 0,
          color: palette.primary.main,
          mx: 0,
        },
        alignItems: "center",
        typography: "body1",
      }}
    >
      {selectTimeZone([{ value: "EST GMT-5", id: "EST GMT-5" }])}
    </Select>
  );

  const handleChange = (date?: Moment) => {
    if (date) props.onNavigate(navigate.DATE, new Date(date.toISOString()));
    setOpen(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSM ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          my: 2,
        }}
      >
        <Box className="rbc-btn-group" mb={isSM ? 2 : 0}>
          {viewNamesGroup(messages)}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexShrink: 1,
            flexDirection: "row",
            backgroundColor: "#C9E6FF",
            py: 1,
            borderRadius: 12,
            cursor: "pointer",
            position: "relative",
          }}
        >
          <IconButton
            sx={{
              fontSize: "18px",
              marginRight: "4px",
            }}
            onClick={_navigate.bind(null, navigate.PREVIOUS)}
          >
            <NavigateBefore />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            onClick={() => setOpen(true)}
          >
            <Today
              sx={{
                fontSize: "18px",
                marginRight: "4px",
              }}
            />
            <Box>{label}</Box>
          </Box>
          <IconButton
            sx={{
              fontSize: "18px",
            }}
            onClick={_navigate.bind(null, navigate.NEXT)}
          >
            <NavigateNext />
          </IconButton>
          {props.view === "week" && (
            <WeekCalendar
              ref={ref}
              open={open}
              value={moment(date)}
              onChange={handleChange}
            />
          )}
        </Box>
        {/* <Box width={"220px"}> <SearchInput /> </Box> */}
      </Box>
      <Box
        sx={{
          mb: 3,
          display: "flex",
          flexDirection: isSM ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" mb={2}>
          {moment(date).format("ddd, MMM DD")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <LabelWithIcon
            icon={
              <Circle
                sx={{
                  width: 16,
                  height: 16,
                  color: "#266D00",
                }}
              />
            }
            boxSx={{ alignItems: "center" }}
            value="Upcoming"
            valueVariant="caption"
          />
          <LabelWithIcon
            icon={
              <Circle
                sx={{
                  width: 16,
                  height: 16,
                  color: "#AAABAD",
                }}
              />
            }
            boxSx={{ alignItems: "center" }}
            value="Past Appointments"
            valueVariant="caption"
          />
          <LabelWithIcon
            icon={
              <Circle
                sx={{
                  width: 16,
                  height: 16,
                  color: "#790006",
                }}
              />
            }
            boxSx={{ alignItems: "center" }}
            value="Requested"
            valueVariant="caption"
          />
          {!isSM && timeZoneSelect}
        </Box>
        {isSM && timeZoneSelect}
      </Box>
    </Box>
  );
};

export default Toolbar;
