import { boolean, object, string, TypeOf, z } from "zod";

export const forgotSchema = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email Address is invalid"),
  otp: string().optional(),
});

export type ForgotInput = TypeOf<typeof forgotSchema>;
