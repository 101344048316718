import { Navigate, Outlet, useLocation } from "react-router-dom";
import FullScreenLoader from "../components/FullScreenLoader";
import { useAuthUser } from "../hooks/useAuthUser";

const ProtectedRoute = ({
  allowedRoles,
}: {
  allowedRoles: (string | null)[];
}) => {
  const user = useAuthUser();
  const location = useLocation();

  if (allowedRoles.length > 0) {
    return allowedRoles.includes(user?.role as unknown as string) ? (
      <Outlet />
    ) : user ? (
      <Navigate to="/unauthorized" state={{ from: location }} replace />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  } else {
    return <Outlet />;
  }
};

export default ProtectedRoute;
