import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { InputAdornment, Container } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { useForgotFunction } from "./useForgotFunction";
import { FormInputText } from "../../../../components/form/FormInput";
import { LoadingButton } from "@mui/lab";

function ForgotPasswordForm(props: any) {
  const {
    minutes,
    seconds,
    isLoading,
    methods,
    t,
    handleSubmit,
    getOTP,
    onSubmitHandler,
  } = useForgotFunction(props);

  return (
    <Container sx={{ mt: 2 }}>
      <FormProvider {...methods}>
        <Box component="form" noValidate>
          <FormInputText
            sx={{
              border: "none",
              backgroundColor: "transparent",
            }}
            color="primary"
            variant="outlined"
            type="email"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={handleSubmit(getOTP)}
                    disabled={seconds > 0 || minutes > 0}
                  >
                    {seconds > 0 || minutes > 0
                      ? (minutes < 10 ? `0${minutes}:` : `${minutes}:`) +
                        (seconds < 10 ? `0${seconds}` : seconds)
                      : t("auth.getOTP")}
                  </Button>
                </InputAdornment>
              ),
            }}
            label={t("auth.email")}
            size="medium"
            margin="normal"
            fullWidth
            name="email"
            required
          />
          <FormInputText
            sx={{
              border: "none",
              backgroundColor: "transparent",
            }}
            color="primary"
            variant="outlined"
            type="text"
            label={t("auth.enterOTP")}
            size="medium"
            margin="normal"
            fullWidth
            name="otp"
            required
          />
          <LoadingButton
            loading={isLoading}
            fullWidth
            variant="contained"
            onClick={handleSubmit(onSubmitHandler)}
            sx={{ mt: 3, mb: 2 }}
          >
            {t("auth.confirmButton")}
          </LoadingButton>
        </Box>
      </FormProvider>
    </Container>
  );
}

export default ForgotPasswordForm;
