import { TypeOf, date, number, object, string } from "zod";

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
);

export const addAppointmentSchema = object({
  patientType: number(),
  email: string(),
  contactNumber: string().regex(phoneRegex, "Invalid Number!"),
  procedure: string(),
  note: string().optional(),
  date: date(),
  timeslot: string(),
});

export type IAddAppointment = TypeOf<typeof addAppointmentSchema>;
