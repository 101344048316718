import { Breadcrumbs, Button, ButtonProps, Typography } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { breadcrumbNameMap } from "../config/Constant";

interface BreadcrumbProps {
  paths?: string[];
  marginTop?: number;
}

interface LinkRouterProps extends ButtonProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Button component={RouterLink} {...props} />;
}

export default function Breadcrumb(props: BreadcrumbProps) {
  const { paths, marginTop = 2 } = props;
  const location = useLocation();
  const pathnames = paths
    ? paths
    : location.pathname.split("/").filter((x) => x);

  return (
    <Breadcrumbs
      sx={{ mt: marginTop }}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      <LinkRouter color="primary" to="/">
        Home
      </LinkRouter>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = paths
          ? value
          : `/${pathnames.slice(0, index + 1).join("/")}`;

        return last ? (
          <Typography color="text.primary" key={to}>
            {breadcrumbNameMap[to] ?? to}
          </Typography>
        ) : (
          <LinkRouter color="primary" to={to} key={to}>
            {breadcrumbNameMap[to] ?? to}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
}
