import { Box, Button, Divider, SxProps, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs, { Dayjs } from "dayjs";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import classes from "./BookAppointment.module.css";
import { useEffect, useState } from "react";
import BookingSlots from "./BookingSlots";
import {
  ApointmentPayload,
  useBookAppointmentWithoutAuthMutation,
  useGetPhysicianTimelineMutation,
  useLazyGetProceduresQuery,
} from "./bookAppointmentAPIs";
import {
  BookAppointmentState,
  SlotItem,
  bookAppointmentActions,
} from "./bookAppointmentSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHook";
import { LoadingButton } from "@mui/lab";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import PatientInfoModal from "./PatientInfoModal";
import { PatientFormType } from "./PatientInfoForm";
import ConfirmModal from "../../../components/ConfirmModal";

interface Props {
  physicianId: string;
}

const MIN_DATE = dayjs().add(1);

const BookAppointment = ({ physicianId }: Props) => {
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const dispatch = useAppDispatch();
  const state: BookAppointmentState = useAppSelector(
    (state) => state.bookAppointment
  );
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs(new Date()));
  const [getProcedures] = useLazyGetProceduresQuery();
  const [bookAppointment, { isLoading, isSuccess }] =
    useBookAppointmentWithoutAuthMutation();

  const [getPhysicianTimeline, result] = useGetPhysicianTimelineMutation();
  const [open, setOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(!isSM);

  const isSlotSelected = state.slots.some((slot) => slot.isSelected);

  let padding = "24px";
  if (!calendarOpen) padding = "12px";
  if (calendarOpen && isSM) padding = "12px 12px 0px 12px";

  const calenderHeaderSx: SxProps = {
    padding,
    display: isSM ? "flex" : "block",
    alignItems: isSM ? "center" : "start",
    justifyContent: isSM ? "center" : "start",
  };

  useEffect(() => {
    getProcedures();
  }, [getProcedures]);

  useEffect(() => {
    if (isSuccess) setSuccessOpen(true);
  }, [isSuccess]);

  useEffect(() => {
    getPhysicianTimeline({
      providerId: physicianId,
      startDate: selectedDate.format("YYYY-MM-DD"),
    });
  }, [getPhysicianTimeline, physicianId, selectedDate]);

  const handleDateChange = (date: Dayjs | null) => {
    if (date) setSelectedDate(date);
    if (isSM) setCalendarOpen(false);
  };

  const handleClear = () => {
    // setSelectedDate(dayjs(new Date()));
    dispatch(bookAppointmentActions.reset());
  };

  const handleModalClose = (formData?: PatientFormType) => {
    setOpen(false);
    if (formData) handleBookAppointment(formData);
  };

  const handleBookAppointment = (formData: PatientFormType) => {
    const { slots, procedures } = state;
    const selectedSlot = slots.find((slot) => slot.isSelected) as SlotItem;
    const body: ApointmentPayload = {
      procedureOrServiceId: procedures[0].id,
      providerId: physicianId,
      scheduleId: selectedSlot.scheduleId,
      scheduleDetailsId: selectedSlot.scheduleDetailsId,
      customerName: formData.name,
      email: formData.email,
      phoneNumber: formData.phone,
      notes: "",
      appointmentType: formData.appointmentType,
    };
    bookAppointment(body);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#EBF5FF",
        height: "100%",
        paddingY: isSM ? 2 : 6,
        border: isSM ? "solid 1px #C5C6D0" : "none",
        borderTopLeftRadius: isSM ? 0 : 52,
        borderBottomLeftRadius: isSM ? 0 : 52,
      }}
    >
      <Box sx={{ paddingX: isSM ? 2 : 6 }}>
        <Typography marginBottom={isSM ? 2 : 4} variant={"h4"}>
          Book Appointment
        </Typography>

        <div className={classes.calendarContainer}>
          <Box
            sx={calenderHeaderSx}
            onClick={() => isSM && setCalendarOpen(!calendarOpen)}
          >
            <Typography
              variant={isSM ? "subtitle2" : "body1"}
              marginBottom={isSM ? 0 : 4}
              textAlign={isSM ? "center" : "start"}
            >
              {isSM ? selectedDate.format("DD MMM YY") : "Select date"}
            </Typography>
            {isSM && (
              <Box display="flex" alignItems="center" paddingLeft={2}>
                <CalendarTodayIcon />
              </Box>
            )}
            {!isSM && (
              <Typography variant="h4">
                {selectedDate.format("ddd, MMM YY")}
              </Typography>
            )}
          </Box>
          {calendarOpen && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                minDate={MIN_DATE}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </LocalizationProvider>
          )}
        </div>
      </Box>
      <Box sx={{ paddingX: isSM ? 0 : 4 }}>
        <BookingSlots
          date={selectedDate}
          isLoading={result.isLoading}
          isError={result.isError}
          slots={state.slots}
        />
      </Box>
      <Box sx={{ paddingX: 4 }}>
        <Divider />
      </Box>
      <Box
        sx={{ padding: isSM ? 2 : 4 }}
        display="flex"
        justifyContent="space-between"
      >
        <Button
          sx={{ width: "30%" }}
          type="button"
          variant="outlined"
          onClick={handleClear}
        >
          Clear
        </Button>
        <LoadingButton
          loading={isLoading}
          sx={{ width: "60%" }}
          type="button"
          variant="contained"
          disabled={!isSlotSelected}
          onClick={() => setOpen(true)}
        >
          Request Appointment
        </LoadingButton>
      </Box>
      {/* <ConfirmationModal open={open} onClose={() => setOpen(false)} /> */}
      <PatientInfoModal open={open} onClose={handleModalClose} />
      <ConfirmModal
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        message="Your Appointment Request has been Submitted"
      />
    </Box>
  );
};

export default BookAppointment;
