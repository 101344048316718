export const getClinicAddress = (values: any) => {
  if (!values) return "";
  const { streetLineOne, streetLineTwo, city, stateOrRegion, zipCode } = values;
  const address = [];
  address.push(streetLineOne.trim());
  address.push(streetLineTwo.trim());
  address.push(city.trim());
  address.push(stateOrRegion.trim());
  address.push(zipCode.trim());
  return address.join(", ");
};

const extractAddress = (results: any) => {
  const address = results[0].formatted_address;
  const { city, state, country, zip } = results[0].address_components.reduce(
    (acc: any, component: any) => {
      if (component.types.includes("locality")) acc.city = component.long_name;
      else if (component.types.includes("administrative_area_level_1"))
        acc.state = component.short_name;
      else if (component.types.includes("country"))
        acc.country = component.short_name;
      else if (component.types.includes("postal_code"))
        acc.zip = component.long_name;
      return acc;
    },
    {}
  );
  return { address, city, state, country, zip };
};

export async function getAddress(lat: number, lng: number) {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCDFRdwJEM6ieccLdkPyEN422Ay7bjTFPg`
    );
    const { results } = await response.json();
    return extractAddress(results);
  } catch (error) {
    return null;
  }
}
