interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

function addIdProps(index: number | string) {
  return {
    id: `simple-tab-${index}`,
  };
}

export { addIdProps };
export default CustomTabPanel;
