import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { ConfigureStoreOptions, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import rootReducer, { additionalMiddlewares } from "./reducers";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const createStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) =>
  configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(additionalMiddlewares),
    ...options,
  });
export const store = createStore();

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export {
  useRegisterUserMutation,
  useLoginUserMutation,
  useResetPasswordMutation,
  useUserOTPMutation,
} from "../../features/user/auth/authAPI";

export { submitForReset } from "./slices/forgotSlice";

export {
  setCredentials,
  setUser,
  setProfilePicture,
  updateProfileState,
} from "./slices/userSlice";
