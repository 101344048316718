import { Box, useTheme } from "@mui/material";
import FAQs from "../../features/patient/dashboard/FAQs";
import InfowithAction from "../../components/carousel/InfowithAction";
import AppointmentInfo from "../../components/carousel/AppointmentInfo";
import PhysiciansByArea from "../../features/patient/home/PhysiciansByArea";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const slides = [
  <InfowithAction />,
  // <AppointmentInfo />,
  // Add more slides as needed
];
export default function Home() {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const path = localStorage.getItem("postLoginRoute");

  useEffect(() => {
    if (!path) return;
    navigate(path);
    localStorage.removeItem("postLoginRoute");
  }, [path, navigate]);

  return (
    <>
      {/* <Box sx={{ paddingTop: 2 }}>
        <Calendar
          events={events}
          onEventDrop={({ start, end, event }) =>
            moveEvent(
              moment(start).toDate(),
              moment(end).toDate(),
              (event as EventItem)?.data?.appointment?.id
            )
          }
          onEventResize={({ start, end, event }) =>
            resizeEvent(
              moment(start).toDate(),
              moment(end).toDate(),
              (event as EventItem)?.data?.appointment?.id
            )
          }
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
        />
      </Box> */}
      <Box
        sx={{
          backgroundColor: palette.common.background,
          width: "fit-content",
        }}
      >
        <InfowithAction />
        <PhysiciansByArea />
      </Box>
      <FAQs />
    </>
  );
}
