import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export interface DropdownValue {
  id: string;
  value: string;
}

interface Props {
  id: string;
  lable?: string;
  value: DropdownValue;
  onChange: (value: DropdownValue) => void;
  data: DropdownValue[];
}

export default function Dropdown({ id, lable, onChange, value, data }: Props) {
  const handleChange = (event: SelectChangeEvent) => {
    const foundItem = data.find((item) => item.id === event.target.value);
    if (foundItem) onChange(foundItem);
  };

  return (
    <Box sx={{ minWidth: 120, marginRight: 1 }}>
      <FormControl fullWidth>
        {lable && <InputLabel id={id + "-label"}>{lable}</InputLabel>}
        <Select
          labelId={id + "-label"}
          id={id}
          value={value.id}
          label={lable}
          onChange={handleChange}
        >
          {data.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id}>
                {item.value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
