import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import AuthLayout from "../../components/AuthLayout";
import SigninForm from "../../features/user/auth/signin/SigninForm";
import { useTranslation } from "react-i18next";
import CustomTabPanel, { addIdProps } from "../../components/CustomTabPanel";

export default function Signin() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { t } = useTranslation();

  return (
    <AuthLayout>
      <Box marginTop={8}>
        <Typography variant="body1">Sign in as physician</Typography>
        <SigninForm index={1} />
        {/*  <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          scrollButtons={false}
        >
          <Tab label={t("auth.signin.patientTab")} {...addIdProps(0)} wrapped />
          <Tab
            label={t("auth.signin.physicianTab")}
            {...addIdProps(1)}
            wrapped
          />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <SigninForm index={0} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <SigninForm index={1} />
        </CustomTabPanel> */}
      </Box>
    </AuthLayout>
  );
}
