import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "../../../../app/store/slices/apiSlice";
import { encrypt } from "../../../../utils/codec";
import config from "../../../../config/config";
import { TimeSlotInput } from "./timeSlotSchema";
import DateUtil, { dateUtil } from "../../../../utils/dateHelper";
import { Moment } from "moment";
import { popUpActions } from "../../../../app/store/slices/popupSlice";

interface IScheduleInput extends TimeSlotInput {
  date: Moment;
  isEdit: boolean;
}

interface IGetSchedule {
  scheduleDate: string;
}
export const settingsAPI = createApi({
  reducerPath: "settingsAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["TimeSlots"],
  endpoints: (builder) => ({
    createSchedule: builder.mutation<{}, IScheduleInput>({
      query(data) {
        const _day = `${dateUtil.formatDate(
          data.date,
          DateUtil.DateFormats.WEEK_DAY
        )}`;
        const _date = `${dateUtil.formatDate(
          data.date,
          DateUtil.DateFormats.YEAR_MONTH_DATE
        )}`;
        const workingStartTime = `${_date}T${data.startTime}`;
        const workingEndTime = `${_date}T${data.endTime}`;
        const request = {
          scheduleDate: `${dateUtil.formatDate(
            data.date,
            DateUtil.DateFormats.YEAR_MONTH_DATE
          )}`,
          startTime: workingStartTime,
          endTime: workingEndTime,
          userTimeZone: data?.timezone ?? "",
          scheduleEndDate: data.repeat
            ? `${dateUtil.formatDate(
                data.slotValidity as unknown as string,
                DateUtil.DateFormats.YEAR_MONTH_DATE
              )}`
            : _date,
          dayOfWeeks: data.repeat ? data.weekdays : [_day],
          timeSlots: data.timeSlots?.filter((slot: any) => {
            let currentTime = dateUtil.createMoment(slot.key, "HH:mm");
            if (
              slot.isOccupied &&
              currentTime.isBefore(dateUtil.createMoment(data.endTime, "HH:mm"))
            ) {
              const startTime = `${dateUtil.formatDate(
                data.date,
                DateUtil.DateFormats.YEAR_MONTH_DATE
              )}T${slot.key}`;
              const endTime = `${dateUtil.formatDate(
                dateUtil
                  .createMoment(
                    startTime,
                    DateUtil.DateFormats.YEAR_MONTH_DATE_TIME
                  )
                  .add("30", "minutes"),
                DateUtil.DateFormats.YEAR_MONTH_DATE_TIME
              )}`;
              slot.startTime = startTime;
              slot.endTime = endTime;
              delete slot.key;
              delete slot.value;
              delete slot.isOccupied;
              return slot;
            }
          }),
        };

        const _data = encrypt(request, config.secret);
        return {
          url: "/api/services/app/ScheduleService/Create",
          method: "POST",
          credentials: "include",
          body: _data,
        };
      },
      async onQueryStarted({ isEdit }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          let message = "Your Time Slot created successfully.";
          if (isEdit) message = "Your Time Slot has been updated.";
          dispatch(popUpActions.setPopState({ message, status: true }));
        } catch (error) {}
      },
      invalidatesTags: ["TimeSlots"],
    }),

    getaSchedule: builder.query<any, IGetSchedule>({
      query(data) {
        const request = {
          SearchScheduleDate: data.scheduleDate,
          SkipCount: 0,
          MaxResultCount: 1,
        };
        return {
          url: "/api/services/app/ScheduleService/GetAll",
          method: "GET",
          credentials: "include",
          params: request,
        };
      },
      providesTags: ["TimeSlots"],
      transformResponse: (res: any) => {
        return res.result?.items[0] ?? {};
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        return `${endpointName}${queryArgs.scheduleDate}`;
      },
    }),
  }),
});

export const {
  useGetaScheduleQuery,
  useLazyGetaScheduleQuery,
  useCreateScheduleMutation,
} = settingsAPI;
