import { Box, Typography, Grid, useTheme } from "@mui/material";
import Questions from "../../../components/Questions";
import { faqs } from "../../../config/Constant";
import { useState } from "react";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import CustomAccordion from "../../../components/CustomAccordion";

function FAQs() {
  const homeFAQs = faqs.slice(0, 5);
  const [active, setActive] = useState(0);

  const { palette } = useTheme();
  const [breakPoints] = useBreakpoints();
  const { isMatchDownLG, isMatchDownSM: isSM } = breakPoints;

  return (
    <Grid container direction={isSM ? "column" : "row"}>
      <Grid item xs={12} sm={0.4} />
      <Grid item xs={12} sm={11.2}>
        <Box sx={{ my: { xs: 2, sm: 4 }, mx: { xs: 2 } }}>
          <Typography
            component="div"
            variant={isMatchDownLG ? "subtitle1" : "h5"}
            sx={{ color: "#455A64" }}
          >
            FAQs
          </Typography>
          {isMatchDownLG ? (
            <CustomAccordion data={homeFAQs} />
          ) : (
            <Box sx={{ display: "flex", flexDirection: "row", my: 3 }}>
              <Box sx={{ pt: 4, pb: 13, zIndex: 1 }}>
                <Box
                  sx={{
                    width: "640px",
                    boxShadow: "1px 2px rgba(0,0,0,0.15)",
                    borderWidth: 0,
                    marginBottom: 2,
                  }}
                >
                  {homeFAQs.map((faq, index) => {
                    const onSelection = () => {
                      setActive(index);
                    };
                    return (
                      <Questions
                        key={index}
                        question={faq.question}
                        active={index == active}
                        onSelection={onSelection}
                      />
                    );
                  })}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  marginLeft: -16,
                  backgroundColor: palette.common.faqColor,
                  borderRadius: "16px",
                }}
              >
                <Box sx={{ marginLeft: 16, px: 8, mt: 4 }}>
                  <Typography
                    component="div"
                    variant="h6"
                    sx={{ ml: 2, fontSize: 18, fontWeight: 600 }}
                  >
                    {faqs[active].question}
                  </Typography>
                  <Typography
                    component="div"
                    variant="body2"
                    sx={{ ml: 2, mt: 5, color: "#455A64" }}
                  >
                    {faqs[active].answer}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={0.4} />
    </Grid>
  );
}

export default FAQs;
