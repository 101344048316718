import {
  Box,
  Button,
  Dialog,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { RatingForm } from "./form/RatingForm";
import { TypeOf, number, object, string } from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { useEffect, useMemo } from "react";
import { useReviewAppointmentMutation } from "../features/provider/appointment/appointmentRequestsAPI";
import { useToast } from "../hooks/useToast";
import { getErrorMessage } from "../utils/serviceHelper";

const WriteReview = ({
  appointmentId,
  open,
  handleClose,
}: {
  appointmentId: string;
  open: boolean;
  handleClose: () => void;
}) => {
  const { breakpoints, palette } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("sm"));

  const [reviewAppointment, { isLoading, isSuccess, isError, error }] =
    useReviewAppointmentMutation();

  const ratingSchema = object({
    rating: string(),
  });

  type RatingInput = TypeOf<typeof ratingSchema>;

  const methods = useForm<RatingInput>({
    resolver: zodResolver(ratingSchema),
    defaultValues: useMemo(() => {
      return {
        rating: "0",
      };
    }, []),
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  const { displayToast } = useToast();

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      displayToast({
        message: "Review submitted successfully for the appointment.",
        type: "success",
      });
    }
    if (isError) {
      handleClose();
      const errorMessage = getErrorMessage(error);
      displayToast({ message: errorMessage, type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onSubmitHandler: SubmitHandler<RatingInput> = (values) => {
    reviewAppointment({ appointmentId: appointmentId, ...values });
  };

  return (
    <Dialog onClose={handleClose} open={open} fullScreen={fullScreen} fullWidth>
      <Box sx={{ margin: 2 }}>
        <Box
          display={"flex"}
          flex={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h3" color={"#455A64"}>
            Write Review
          </Typography>
        </Box>
        <FormProvider {...methods}>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Box
              sx={{
                my: 2,
                justifyContent: "center",
                flex: 1,
                display: "flex",
              }}
            >
              <RatingForm name="rating" label="rating" maxRating={5} />
            </Box>

            <Divider sx={{ borderColor: "#CDE5FF", my: 3 }} />
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-around"}
            >
              <Button
                onClick={handleClose}
                variant="outlined"
                sx={{ flex: 1, mr: 1 }}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isLoading}
                type="submit"
                variant="contained"
                sx={{ flex: 1 }}
              >
                Send
              </LoadingButton>
            </Box>
          </Box>
        </FormProvider>
      </Box>
    </Dialog>
  );
};

export default WriteReview;
