import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Theme,
  Typography,
  LinearProgress,
  linearProgressClasses,
  Slide,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import PersonalDetails from "../../features/patient/complete-profile/PesonalDetails";
import RelatedDocuments from "../../features/patient/complete-profile/RelatedDocuments";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../hooks/useStoreHook";
import { useBreakpoints } from "../../hooks/useBreakpoints";

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme }: { theme: Theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
    },
  })
);

function CompleteProfile() {
  const [checked, setChecked] = useState(false);

  const handleNext = () => {
    setChecked(true);
  };

  const handlePrevious = () => {
    setChecked(false);
  };

  const profileState = useAppSelector((state) => state.userState.profileState);

  useEffect(() => {
    if (profileState == 1) {
      setChecked(true);
    }
  }, [profileState]);

  const { t } = useTranslation();
  const theme = useTheme();
  const [breakPoints] = useBreakpoints();
  const { isMatchDownSM } = breakPoints;
  return (
    <Fragment>
      <Container>
        <Typography sx={{ mt: 4 }}>{t("completeprofile.heading")}</Typography>
        <Box sx={{ flex: 1, mt: 3 }}>
          <BorderLinearProgress
            variant="determinate"
            value={!checked ? 27.5 : 100}
            theme={theme}
            sx={isMatchDownSM ? { width: "100%" } : { width: "calc(40%)" }}
          />
          <Grid
            container
            flex={1}
            width={isMatchDownSM ? "100%" : "calc(40%)"}
            textAlign="center"
            sx={{ mt: 1 }}
          >
            <Grid item flex={1}>
              <Typography>{t("completeprofile.subHeading.step1")}</Typography>
            </Grid>
            <Grid item flex={1}>
              <Typography>{t("completeprofile.subHeading.step2")}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexDirection: "row", display: "flex", flex: 1 }}>
          <Slide in={!checked} direction="right" timeout={500} appear={false}>
            {!checked ? (
              <Box sx={{ flex: 1 }}>
                <PersonalDetails handleNext={handleNext} />
              </Box>
            ) : (
              <div />
            )}
          </Slide>
          <Slide in={checked} direction="left" timeout={500}>
            {checked ? (
              <Box sx={{ flex: 1 }}>
                <RelatedDocuments handlePrevious={handlePrevious} />
              </Box>
            ) : (
              <div />
            )}
          </Slide>
        </Box>
      </Container>
    </Fragment>
  );
}

export default CompleteProfile;
