import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  ISetUpTimeSlotSchema,
  setUpTimeSlotSchema,
} from "./setUpTimeSlotSchema";
import ProfileSettingHeader from "../ProfileSetting/components/ProfileSettingHeader";
import ViewTimeSlot from "./ViewTimeSlot";
import { useLazyGetaScheduleQuery } from "./timeSlotAPI";
import DateUtil, { dateUtil } from "../../../../utils/dateHelper";
import { useAppSelector } from "../../../../hooks/useStoreHook";

const SetUpTimeSlot = () => {
  const { breakpoints } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("sm"));

  const selectedDate = useAppSelector((state) => state.dateSetter.selectedDate);
  const [getSchedule, { isFetching, data }] = useLazyGetaScheduleQuery();

  useEffect(() => {
    getSchedule({
      scheduleDate: dateUtil
        .createMoment(selectedDate, DateUtil.DateFormats.YEAR_MONTH_DATE)
        .format(DateUtil.DateFormats.YEAR_MONTH_DATE),
    });
  }, []);

  const methods = useForm<ISetUpTimeSlotSchema>({
    resolver: zodResolver(setUpTimeSlotSchema),
    defaultValues: useMemo(() => {
      return {
        repeatDaily: false,
        startTime: "",
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  });

  return (
    <Box
      sx={[
        {
          px: 3,
          py: 2,
          border: "1px solid #93CCFF",
          borderRadius: 4,
          backgroundColor: "#FCFCFF",
        },
        fullScreen && { px: 1.5 },
      ]}
    >
      <ProfileSettingHeader title={"Current Time Slots"} color="#191C1D" />
      <FormProvider {...methods}>
        <ViewTimeSlot
          timeSlots={data}
          getSchedule={getSchedule}
          isScheduleFetching={isFetching}
        />
      </FormProvider>
    </Box>
  );
};

export default SetUpTimeSlot;
