import React, { ReactNode } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

type IFormControlLabelProps = {
  name: string;
  label: ReactNode | string;
} & Omit<FormControlLabelProps, "control">;

export const FormCheckbox: React.FC<IFormControlLabelProps> = ({
  name,
  label,
  ...otherProps
}) => {
  const { control } = useFormContext();
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          render={({ field: { onChange, value } }) => {
            return (
              <Checkbox value={value} onChange={onChange} color="primary" />
            );
          }}
          control={control}
        />
      }
      label={label}
      {...otherProps}
    />
  );
};
