import {
  Box,
  Divider,
  Icon,
  Typography,
  useTheme,
  IconButton as MuiIconButton,
  Dialog,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import AppointmentNote from "./AppointmentNote";
import {
  useCreateNoteMutation,
  useGetAppointmentNotesQuery,
} from "../features/provider/patients/providerPatientsAPI";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { TypeOf, object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormInputText } from "./form/FormInput";
import { dataUtil } from "../utils/dataHelper";
import DateUtil, { dateUtil } from "../utils/dateHelper";
import { useEffect } from "react";
import { getErrorMessage } from "../utils/serviceHelper";
import { useToast } from "../hooks/useToast";
import { LoadingButton } from "@mui/lab";

export const appointmentNoteSchema = object({
  notes: string(),
});

export type AppointmentNoteInput = TypeOf<typeof appointmentNoteSchema>;

const AppointmentNotes = ({
  isPatient = false,
  appointmentId,
  open,
  handleClose,
}: {
  isPatient?: boolean;
  appointmentId: string;
  open: boolean;
  handleClose: () => void;
}) => {
  const { breakpoints, palette } = useTheme();
  const { displayToast } = useToast();

  const fullScreen = useMediaQuery(breakpoints.down("sm"));
  const { data, isLoading: isDataLoading } = useGetAppointmentNotesQuery({
    appointmentId: appointmentId,
  });

  const [createNote, { isSuccess, isError, isLoading, error }] =
    useCreateNoteMutation();

  const methods = useForm<AppointmentNoteInput>({
    resolver: zodResolver(appointmentNoteSchema),
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  const onSubmitHandler: SubmitHandler<AppointmentNoteInput> = (values) => {
    // 👇 Executing the loginUser Mutation
    if (!isPatient) {
      createNote({ ...values, appointmentId: appointmentId });
    }
  };

  useEffect(() => {
    reset({ notes: "" });
    if (isError) {
      const errorMessage = getErrorMessage(error);
      displayToast({ message: errorMessage, type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const EmptyNote = () => {
    return (
      <Box display={"flex"} borderRadius={4}>
        <Typography variant="body2" fontSize={14}>
          No notes added yet
        </Typography>
      </Box>
    );
  };
  return (
    <Dialog onClose={handleClose} open={open} fullScreen={fullScreen} fullWidth>
      <Box sx={{ margin: 2 }}>
        <Box
          display={"flex"}
          flex={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h3" color={"#455A64"}>
            {isPatient ? "View Note" : "View/Add Note"}
          </Typography>
          <MuiIconButton onClick={handleClose}>
            <Icon>close</Icon>
          </MuiIconButton>
        </Box>

        <Divider sx={{ my: 1, borderColor: "#CDE5FF" }} />
        <Box
          sx={{
            overflowY: "auto",
            height: "calc(240px)",
            pr: 1,
            py: 1,
            mb: 1,
          }}
          className="fixed-size"
        >
          {isDataLoading ? (
            Array.from({ length: 12 }).map((el) => {
              return (
                <Skeleton
                  variant="rounded"
                  width={"90%"}
                  height={70}
                  sx={{ my: 0.5 }}
                />
              );
            })
          ) : data && data?.length > 0 ? (
            data.map((item: any) => (
              <AppointmentNote
                isPatient={isPatient}
                noteId={item.id}
                appointmentId={item.appointmentId}
                title={dateUtil.formatDate(
                  item.creationTime,
                  DateUtil.DateFormats.DATE_TIME
                )}
                note={item.notes}
                sx={{ my: 0.5 }}
              />
            ))
          ) : (
            <EmptyNote />
          )}
        </Box>
        <Divider sx={{ borderColor: "#CDE5FF" }} />
        {!isPatient && (
          <FormProvider {...methods}>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmitHandler)}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <FormInputText
                  sx={{
                    border: "none",
                    backgroundColor: "transparent",
                    mr: 4,
                  }}
                  placeholder="Type your text..."
                  color="primary"
                  variant="outlined"
                  size="medium"
                  margin="normal"
                  type="text"
                  label=""
                  fullWidth
                  name="notes"
                  required
                />
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  type="submit"
                >
                  Submit
                </LoadingButton>
              </Box>
            </Box>
          </FormProvider>
        )}
      </Box>
    </Dialog>
  );
};

export default AppointmentNotes;
