import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CreateAppointmentState {
  open?: boolean;
}

export const createAppointmentInitialState: CreateAppointmentState = {
  open: false,
};

export const CreateAppointmentSlice = createSlice({
  name: "createAppointment",
  initialState: createAppointmentInitialState,
  reducers: {
    openDrawer: (_state, action: PayloadAction<CreateAppointmentState>) => ({
      ...createAppointmentInitialState,
      ...action.payload,
      open: true,
    }),
    closeDrawer: (state) => ({ ...state, open: false }),
  },
});

export const createAppointmentActions = CreateAppointmentSlice.actions;
export const createAppointmentReducer = CreateAppointmentSlice.reducer;
