import {
  Box,
  Button,
  Divider,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ProfilePicture from "./ProfilePicture";
import { useNavigate } from "react-router-dom";
import LabelWithIcon from "./LabelWithIcon";
import { useTranslation } from "react-i18next";
import IconPopup from "./IconPopup";
import { useGetMyPatientDetailsQuery } from "../features/provider/patients/providerPatientsAPI";
import { useLazyGetProfilePicQuery } from "../features/patient/dashboard/profilePictureAPI";
import { useEffect, useState } from "react";
import BaseModal from "./BaseModal";
import IconButton from "./IconButton";
import { useBreakpoints } from "../hooks/useBreakpoints";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import DateUtil, { dateUtil } from "../utils/dateHelper";
import { useAppointmentCalendarApprovalMutation } from "../features/provider/appointment/appointmentRequestsAPI";
import { View } from "react-big-calendar";
import moment from "moment";
import { useToast } from "../hooks/useToast";
import { getErrorMessage } from "../utils/serviceHelper";
import { images } from "../assets";
import { AppointmentStatusCode } from "./event-calendar/utils/constants";

const PatientDetails = ({
  patientId,
  event,
  open,
  date,
  view,
  handleClose,
}: {
  patientId: string | null;
  event: any;
  open: boolean;
  date: Date;
  view: View;
  handleClose: () => void;
}) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const [approvalStatus, setApprovalStatus] = useState<number | null>(null);
  const { data, isLoading } = useGetMyPatientDetailsQuery({
    id: patientId ?? null,
  });
  const [getProfilePic, status] = useLazyGetProfilePicQuery();
  const smallIconSx: SxProps = { width: 24, height: 24 };
  useEffect(() => {
    if (data?.profileImage) getProfilePic(data?.profileImage);
  }, [getProfilePic, data?.profileImage]);

  const [
    appointmentApproval,
    { isLoading: isApprovalLoading, isSuccess, isError, error },
  ] = useAppointmentCalendarApprovalMutation();

  const { displayToast } = useToast();

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      setApprovalStatus(null);
    }
    if (isError) {
      handleClose();
      const errorMessage = getErrorMessage(error);
      displayToast({ message: errorMessage, type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApprovalLoading]);

  let type = "";
  if (event.data.appointmentType === 1) type = images.app.walkin;
  if (event.data.appointmentType === 2) type = images.app.telephonic;
  const iconSx: SxProps = {
    ...smallIconSx,
    pr: 3,
    display: "flex",
    alignItems: "center",
  };
  const boxSx: SxProps = {
    height: 24,
    justifyContent: "flex-start",
    mt: isSM ? 1 : 2,
    ml: 1,
  };

  const email = (
    <a
      href={`mailto:${data?.emailAddress}`}
      style={{ color: palette.primary.main }}
    >
      {data?.emailAddress}
    </a>
  );

  if (patientId != null) {
    return (
      <BaseModal
        onClose={handleClose}
        open={open}
        width={600}
        modalStyle={{ borderRadius: 8, p: 3 }}
      >
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <ProfilePicture
                width={40}
                height={40}
                name="User Image"
                loadUserPic
                userPic={status.data ?? ""}
              />
              <Typography paddingLeft={2} variant="body1">
                {data?.fullName ?? ""}
              </Typography>
            </Box>
            {type && <Box component="img" src={type} />}
          </Box>
          <LabelWithIcon
            iconSx={iconSx}
            icon={<CallIcon sx={smallIconSx} />}
            boxSx={boxSx}
            value={data?.phoneNumber}
            valueVariant="body1"
          />
          <LabelWithIcon
            iconSx={iconSx}
            icon={<EmailIcon sx={smallIconSx} />}
            boxSx={boxSx}
            value={email}
            valueVariant="body1"
          />
          {!dateUtil.getToday().isSameOrAfter(event?.startTime) &&
            event.data.status == AppointmentStatusCode.Requested && (
              <>
                <Divider
                  orientation="horizontal"
                  sx={{ borderWidth: 1, backgroundColor: "#C5C6D0", my: 2 }}
                />
                <Box sx={{ display: "flex" }}>
                  <IconButton
                    isLoading={approvalStatus === 2 && isApprovalLoading}
                    disabled={approvalStatus !== 2 && isApprovalLoading}
                    icon="close"
                    variant="secondary"
                    onClick={() => {
                      setApprovalStatus(2);
                      appointmentApproval({
                        appointmentId: event?.data?.id,
                        status: 2,
                        startDate: moment(date)
                          .startOf("week")
                          .format("YYYY-MM-DD"),
                        endDate: moment(date)
                          .endOf("week")
                          .format("YYYY-MM-DD"),
                        search: "",
                      });
                    }}
                    sx={{
                      borderRadius: 8,
                      border: "1px solid #75777F",
                      color: palette.common.editColor,
                      mr: 2,
                    }}
                    title="Reject"
                    iconSx={{ mx: 1, fontSize: 20 }}
                    titleSx={{ mr: 1.5 }}
                    titleVariant="body2"
                  />
                  <IconButton
                    isLoading={approvalStatus === 1 && isApprovalLoading}
                    disabled={approvalStatus !== 1 && isApprovalLoading}
                    icon="check"
                    onClick={() => {
                      setApprovalStatus(1);
                      appointmentApproval({
                        appointmentId: event?.data?.id,
                        status: 1,
                        startDate: moment(date)
                          .startOf("week")
                          .format("YYYY-MM-DD"),
                        endDate: moment(date)
                          .endOf("week")
                          .format("YYYY-MM-DD"),
                        search: "",
                      });
                    }}
                    sx={{
                      borderRadius: 8,
                      color: "white",
                    }}
                    title="Approve"
                    iconSx={{ mx: 1, fontSize: 20 }}
                    titleSx={{ mr: 1.5 }}
                    titleVariant="body2"
                  />
                </Box>
              </>
            )}
        </Box>
      </BaseModal>
    );
  }
  return null;
};

export default PatientDetails;
