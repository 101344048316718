import { Moment } from "moment";
import config from "../../../../config/config";
import { encrypt } from "../../../../utils/codec";
import DateUtil, { dateUtil } from "../../../../utils/dateHelper";
import { calendarAPI } from "../../calendar/calendarAPI";

export const calendatScheduleAPI = calendarAPI.injectEndpoints({
  endpoints: (builder) => ({
    getScheduleByDate: builder.query<[], { scheduleDate: string }>({
      query(data) {
        const request = {
          searchDate: data.scheduleDate,
        };

        return {
          url: "/api/services/app/ScheduleService/GetScheduleByDay",
          method: "GET",
          credentials: "include",
          params: request,
        };
      },
      transformResponse: (res: any) => {
        return res.result;
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        return `${endpointName}${queryArgs.scheduleDate}`;
      },
      // forceRefetch({ currentArg, previousArg }) {
      //   return currentArg?.scheduleDate !== previousArg?.scheduleDate;
      // },
    }),
  }),
});

export const { useGetScheduleByDateQuery, useLazyGetScheduleByDateQuery } =
  calendatScheduleAPI;
