import { useDispatch } from "react-redux";
import {
  notificationActions,
  NotificationState,
} from "../app/store/slices/notificationSlice";

export const useNotification = () => {
  const dispatch = useDispatch();

  const openNotification = (notification: NotificationState) => {
    dispatch(notificationActions.openNotification(notification));
  };

  const closeNotification = () => {
    dispatch(notificationActions.closeNotification());
  };

  return { openNotification, closeNotification } as const;
};
