import { Box, Button, Grid, Typography } from "@mui/material";

import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { LoadingButton } from "@mui/lab";

import ProfilePicture from "../../../../../components/ProfilePicture";
import { useAppSelector } from "../../../../../hooks/useStoreHook";
import { IProvider } from "../../../../user/auth/authtypes";
import DateUtil, { dateUtil } from "../../../../../utils/dateHelper";
import { getClinicAddress } from "../providerProfileHelper";
import Awards from "./Awards";
import { useReviewProfileMutation } from "../../completeProfileAPI";
import { useEffect } from "react";
import { getErrorMessage } from "../../../../../utils/serviceHelper";
import { useToast } from "../../../../../hooks/useToast";
import { useBreakpoints } from "../../../../../hooks/useBreakpoints";

interface ReviewProps {
  onNextClick: () => void;
  onBackClick: () => void;
}

const Review = (props: ReviewProps) => {
  const userData = useAppSelector((state) => state.userState.user) as IProvider;
  const formatedAwards = userData?.professionalDetail?.awardsList ?? [];
  const formattedDOB = dateUtil.formatDate(
    userData?.dateOfBirth,
    DateUtil.DateFormats.DD_S_MMMM_S_YYYY
  );
  const formattedAddress =
    getClinicAddress(userData?.professionalDetail?.businessAddress) ??
    "Not Provided";

  const [completeReview, status] = useReviewProfileMutation();
  const { displayToast } = useToast();
  const [{ isMatchDownSM: isSM }] = useBreakpoints();

  const handleReviewSubmission = () => {
    completeReview({ acceptTC: true });
  };

  useEffect(() => {
    if (status.isSuccess) props.onNextClick();
    if (status.isError) {
      const errorMessage = getErrorMessage(status.error);
      displayToast({ message: errorMessage, type: "error" });
    }
  });

  return (
    <Grid container direction="row" marginTop={4}>
      <Grid item xs={12} sm={3} md={3} display="flex" justifyContent="center">
        <ProfilePicture width={176} height={176} name="add profile picture" />
      </Grid>
      <Grid item xs={12} sm={9} md={9}>
        <Typography variant="h4">Dr. {userData?.fullName}</Typography>
        <Grid
          container
          direction="row"
          alignItems="center"
          marginTop={isSM ? 1 : 3}
        >
          <CakeOutlinedIcon />
          <Typography variant="body2" paddingLeft={1}>
            {userData?.dateOfBirth ? formattedDOB : "Not Provided"}
          </Typography>
        </Grid>
        <Grid container direction="row" marginTop={isSM ? 1 : 3}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            xs={12}
            sm={6}
            md={6}
          >
            <EmailOutlinedIcon />
            <Typography variant="body2" paddingLeft={1}>
              {userData?.email}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            xs={12}
            sm={6}
            md={6}
            marginTop={isSM ? 1 : 0}
          >
            <LocalPhoneOutlinedIcon />
            <Typography variant="body2" paddingLeft={1}>
              {userData?.phoneNumber ?? "Not Provided"}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          marginTop={isSM ? 1 : 2}
        >
          <LocationOnOutlinedIcon />
          <Typography variant="body2" paddingLeft={1}>
            {formattedAddress}
          </Typography>
        </Grid>
        <Typography variant="body2" paddingTop={isSM ? 1 : 2}>
          {userData.aboutMe ?? "Not Provided"}
        </Typography>
        <Grid container direction="row" marginY={2}>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant="body1">Experience</Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <Typography variant="body2">
              {userData?.professionalDetail?.experience ?? "Not Provided"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" marginY={2}>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant="body1">Education qualification</Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <Typography variant="body2">
              {userData?.professionalDetail?.educationQualification ??
                "Not Provided"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" marginY={2}>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant="body1">Medical school</Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <Typography variant="body2">
              {userData?.professionalDetail?.medicalSchool ?? "Not Provided"}
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid container direction="row" marginY={2}>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant="body1">Training</Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <Typography variant="body2">
              {userData?.professionalDetail?.medicalSchool ?? "Not Provided"}
            </Typography>
          </Grid>
        </Grid> */}
        <Grid container direction="row" marginY={2}>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant="body1">Fellowship</Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <Typography variant="body2">
              {userData?.professionalDetail?.fellowship ?? "Not Provided"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" marginY={2}>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant="body1">Awards</Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            {formatedAwards.length === 0 ? (
              <Typography variant="body2">Not Provided</Typography>
            ) : (
              <Awards awards={formatedAwards} isDeletable={false} />
            )}
          </Grid>
        </Grid>
        <Grid container direction="row" marginY={2}>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant="body1">NPI Number</Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <Typography variant="body2">{userData?.npi}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          display="flex"
          justifyContent={isSM ? "space-between" : "flex-end"}
          marginTop={2}
        >
          <Box
            marginRight={isSM ? 0 : 2}
            style={{ width: isSM ? "45%" : "auto" }}
          >
            <Button
              fullWidth={isSM}
              type="button"
              variant="outlined"
              onClick={props.onBackClick}
            >
              Back
            </Button>
          </Box>
          <LoadingButton
            type="button"
            variant="contained"
            style={{ width: isSM ? "45%" : "auto" }}
            loading={status.isLoading}
            onClick={handleReviewSubmission}
          >
            Confirm
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Review;
