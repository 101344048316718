import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "../../../app/store/slices/apiSlice";
import { encrypt } from "../../../utils/codec";
import config from "../../../config/config";
import moment, { Moment } from "moment";
import DateUtil from "../../../utils/dateHelper";
import { AppointmentStatusCode } from "../../../components/event-calendar/utils/constants";

export interface IGetCalendarView {
  search: string;
  startDate: string;
  endDate: string;
}
function getEventStatus(status: number) {
  switch (status) {
    case 0:
      return AppointmentStatusCode.Requested;
    case 1:
      return AppointmentStatusCode.Upcoming;
    case 3:
      return AppointmentStatusCode.PastAppointments;
    case 4:
      return AppointmentStatusCode.Upcoming;
    case 5:
      return AppointmentStatusCode.Requested;
    default:
      return AppointmentStatusCode.NotApplicable;
  }
}
export const calendarAPI = createApi({
  reducerPath: "calendarAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getCalendarView: builder.query<[], IGetCalendarView>({
      query(data) {
        const request = {
          keyword: data.search ?? null,
          weekStartDate: data.startDate,
          weekEndDate: data.endDate,
          appointmentStatus: [0, 1, 3],
        };

        const _data = encrypt(request, config.secret);
        return {
          url: "/api/services/app/AppointmentService/CalendarViewWithFilter",
          method: "POST",
          credentials: "include",
          body: _data,
        };
      },
      transformResponse: (res: any) => {
        return res.result?.map((item: any) => {
          item.start = item.startTime;
          item.end = item.endTime;
          item.isDraggable = false;
          item.data.status = getEventStatus(item.data.status);
          item.data.title = item?.data?.customer?.name
            ? item?.data?.customer?.name + " " + item?.data?.customer?.surname
            : null;
          return item;
        });
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        return `${endpointName}${queryArgs.startDate}${queryArgs.endDate}${queryArgs.search}`;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.startDate !== previousArg?.startDate;
      },
    }),
  }),
});

export const { useLazyGetCalendarViewQuery } = calendarAPI;
