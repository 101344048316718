import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useIntersectionObserver } from "../../../../hooks/useIntersectionObserver";
import {
  appointmentsAdapter,
  appointmentsSelector,
  useGetAllMyAppointmentsQuery,
  useLazyGetAllMyAppointmentsQuery,
} from "../providerPatientsAPI";
import {
  useAppointmentApprovalMutation,
  useCancelAppointmentMutation,
} from "../../appointment/appointmentRequestsAPI";
import { useToast } from "../../../../hooks/useToast";
import { getErrorMessage } from "../../../../utils/serviceHelper";

const limit = 10;
export function usePatientAppointment(emailId: string, status: number[]) {
  const { t } = useTranslation();
  const currentPage = useRef(1);

  const [fetchAppointments, { isFetching }] =
    useLazyGetAllMyAppointmentsQuery();

  const {
    appointments,
    hasNextPage,
    isFetching: isInitialFetching,
    isError,
  } = useGetAllMyAppointmentsQuery(
    {
      pageNumber: 1,
      limit: limit,
      filterByPatient: emailId,
      startDate: "",
      endDate: "",
      appointmentStatus: status,
    },
    {
      selectFromResult: ({ data, ...otherParams }) => {
        return {
          hasNextPage: data?.hasMorePages,
          appointments: appointmentsSelector.selectAll(
            data ?? appointmentsAdapter.getInitialState()
          ),
          ...otherParams,
        };
      },
    }
  );

  const [appointmentApproval, { isLoading: isApporvalLoading }] =
    useAppointmentApprovalMutation();
  const [
    cancelAppointment,
    {
      isLoading: isCancelling,
      isSuccess: isCancellingSuccess,
      isError: isCancellingError,
      error: cancellingError,
    },
  ] = useCancelAppointmentMutation();

  const callAPI = async () => {
    if (!hasNextPage || isFetching) return;
    currentPage.current += 1;
    await fetchAppointments({
      pageNumber: currentPage.current,
      limit: limit,
      filterByPatient: emailId,
      startDate: "",
      endDate: "",
      appointmentStatus: status,
    });
  };

  const [lastPostRef] = useIntersectionObserver(
    isFetching || isInitialFetching,
    callAPI,
    hasNextPage
  );

  const onApproveReject = (appointmentId: string, _status: number) => {
    appointmentApproval({
      appointmentId,
      status: _status,
      pageNumber: currentPage.current,
      limit: limit,
      filterByPatient: emailId,
      startDate: "",
      endDate: "",
      appointmentStatus: status,
    });
  };

  const onCancelled = (appointmentId: string, _status: number) => {
    cancelAppointment({
      appointmentId,
      status: _status,
      pageNumber: currentPage.current,
      limit: limit,
      filterByPatient: emailId,
      startDate: "",
      endDate: "",
      appointmentStatus: status,
    });
  };

  const { displayToast } = useToast();

  useEffect(() => {
    if (isCancellingSuccess) {
      displayToast({
        message: "Your appointment is cancelled.",
        type: "success",
      });
    }
    if (isCancellingError) {
      const errorMessage = getErrorMessage(cancellingError);
      displayToast({ message: errorMessage, type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancelling]);

  return {
    t,
    isLoading: isFetching || isInitialFetching,
    lastPostRef,
    appointments,
    onApproveReject,
    onCancelled,
    isApporvalLoading,
    isCancelling,
    isError,
  };
}
