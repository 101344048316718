import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Theme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

type IFormInputProps = {
  name: string;
  label: string;
  sx?: SxProps<Theme> | undefined;
  inlineSx?: SxProps<Theme> | undefined;
  withLabel?: boolean;
  options: OptionProps[];
} & FormControlProps;

type OptionProps = {
  value: string | number;
  label: string;
};

export const FormInputDropdown: React.FC<IFormInputProps> = ({
  name,
  label,
  options,
  sx,
  inlineSx,
  withLabel = false,
  ...otherFormControlProps
}) => {
  const { control } = useFormContext();

  const generateSingleOptions = () => {
    return options.map((option: OptionProps) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl {...otherFormControlProps}>
      {withLabel && <InputLabel sx={inlineSx}>{label}</InputLabel>}
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <Select
              onChange={onChange}
              value={value}
              label={withLabel ? label : ""}
              sx={sx}
            >
              {generateSingleOptions()}
            </Select>
            {error ? (
              <FormHelperText error>{error?.message}</FormHelperText>
            ) : null}
          </>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};
