import { object, string, TypeOf, z, number, boolean } from "zod";

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
);

export const basicDetailsSchema = object({
  firstname: string()
    .min(3, {
      message: "First name should have minimum 3 characters.",
    })
    .max(100),
  middlename: string().optional(),
  surname: string()
    .min(3, "Last name should have minimum 3 characters.")
    .max(100),
  dateOfBirth: z
    .date()
    .min(new Date("1920-01-01"), { message: "Too old" })
    .max(new Date(), { message: "Please provide proper date" }),
  email: string()
    .min(1, "Email address is required")
    .email("Email Address is invalid"),
  contactNumber: z.string().regex(phoneRegex, "Invalid Number!"),
  about: string(),
});

export const professionalSchema = object({
  education: string().min(1, "Education is required"),
  experience: number()
    .min(0, "Experience should be more than 0.")
    .max(30, "Experience should be less than 30"),
  medicalSchool: string().min(1, "Medical School is required"),
  residency: string().min(1, "Residency is required"),
  fellowship: string().min(1, "Fellowship is required"),
  award: string().optional(),
  npi: string().optional(),
  clinicAddress: string().min(1, "Clinic Address is reuqired"),
  streetLineOne: string().min(1, "Street Line One is required"),
  streetLineTwo: string().optional(),
  stateOrRegion: string().optional(),
  city: string().optional(),
  zipCode: string().optional(),
  addressType: number().optional(),
  isCurrent: number().optional(),
  latitude: number().optional(),
  longitude: number().optional(),
});

export const reviewProfileSchema = object({
  acceptTC: boolean(),
});

export type IBasicDetailsSchema = TypeOf<typeof basicDetailsSchema>;
export type IProfessionalSchema = TypeOf<typeof professionalSchema>;
export type IReviewProfileSchema = TypeOf<typeof reviewProfileSchema>;
