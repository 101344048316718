import { useDispatch } from "react-redux";
import { toastActions, ToastState } from "../app/store/slices/toastSlice";

export const useToast = () => {
  const dispatch = useDispatch();

  const displayToast = (toast: ToastState) => {
    dispatch(toastActions.addToast(toast));
  };

  const clearToast = () => {
    dispatch(toastActions.clearToast());
  };

  return { displayToast, clearToast } as const;
};
