function generateTwoLetters(name: string) {
  // Check if the input name is empty or undefined
  if (!name) {
    return "";
  }

  const words = name.split(" ");

  // Extract the first letter of the first word
  let firstLetter = words[0][0].toUpperCase();

  // Extract the last word's first letter if there are more than two words
  if (words.length > 1) {
    const lastWordFirstLetter = words[words.length - 1][0].toUpperCase();
    return firstLetter + lastWordFirstLetter;
  }

  // Extract the first two letters if the name has only one word
  if (words[0].length >= 2) {
    return words[0].substring(0, 2).toUpperCase();
  } else {
    return "";
  }
}

function generateName(fn: string, mn?: string | null, ln?: string): string {
  let fullName = fn;
  if (mn) fullName += " " + mn;
  if (ln) fullName += " " + ln;
  return fullName;
}
function getProperty(obj: any, str: string) {
  if (obj && str) {
    return str.split(".").reduce(function (prev, curr) {
      return prev ? prev[curr] : undefined;
    }, obj);
  } else {
    return "";
  }
}

class StringUtil {
  static isNullOrEmpty(str: string) {
    return !str || !str.trim();
  }

  static areStringsEqual(str1: string, str2: string) {
    return str1 == str2;
  }

  static formatter(str: string, arg: string[]) {
    for (let k in arg) {
      str = str.replace("{" + k + "}", arg[k]);
    }
    return str;
  }
}
export default new StringUtil();

export { generateTwoLetters, generateName, getProperty };
