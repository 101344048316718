import { useDispatch } from "react-redux";
import {
  createAppointmentActions,
  CreateAppointmentState,
} from "../app/store/slices/createAppointmentSlice";

export const useCreateAppointment = () => {
  const dispatch = useDispatch();

  const openDrawer = (createAppointment: CreateAppointmentState) => {
    dispatch(createAppointmentActions.openDrawer(createAppointment));
  };

  const closeDrawer = () => {
    dispatch(createAppointmentActions.closeDrawer());
  };

  return { openDrawer, closeDrawer } as const;
};
