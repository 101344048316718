import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { images } from "../../../assets";
import { useAppSelector } from "../../../hooks/useStoreHook";

import LinearProgressBar from "../../../components/LinearProgressBar";
import ProviderProfileForm from "../../../features/provider/complete-profile/ProviderProfileForm";
import { useBreakpoints } from "../../../hooks/useBreakpoints";

const { completeProfile } = images.provider;
const sections = [
  "Basic Details",
  "Professional Details",
  "Review",
  "Subscription Plan",
];

const CompleteProviderProfile = () => {
  const [progress, setProgress] = useState(12.5);
  const profileState: number = useAppSelector(
    (state) => state.userState.profileState
  );

  const [searchParams] = useSearchParams();
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const isEdit = searchParams.get("isEdit") === "true";

  const handleNext = () => {
    if (progress < 87.5) setProgress(progress + 25);
  };

  const handleBack = () => {
    if (progress > 12.5) setProgress(progress - 25);
  };

  useEffect(() => {
    if (isEdit) return;
    const count = profileState - 2;
    if (count > 0) setProgress(12.5 + 25 * count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container padding={3} rowGap={2}>
      <Grid item xs={12} sm={4} md={4}>
        <img
          width="100%"
          src={completeProfile}
          alt="complete provider profile"
        />
      </Grid>
      <Grid item xs={12} sm={8} md={8} padding={isSM ? 0 : 3}>
        <LinearProgressBar progress={progress} />
        <Grid container>
          {sections.map((section, index) => {
            if (isSM) {
              if ((progress - 12.5) / 25 !== index) return null;
              return (
                <Grid
                  key={section}
                  item
                  sm={3}
                  md={3}
                  paddingTop={1}
                  className="flex-center"
                >
                  <Typography variant={isSM ? "h4" : "body1"}>
                    {section}
                  </Typography>
                </Grid>
              );
            }
            return (
              <Grid
                key={section}
                item
                sm={3}
                md={3}
                paddingTop={1}
                className="flex-center"
              >
                <Typography variant={isSM ? "h4" : "body1"}>
                  {section}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <ProviderProfileForm
          onNextClick={handleNext}
          onBackClick={handleBack}
          progress={progress}
        />
      </Grid>
    </Grid>
  );
};

export default CompleteProviderProfile;
