export const profile = [
  {
    title: "Profile",
    img: "https://i.imgur.com/EMcyIBn.png",
    text: "Extremely motivated to constantly develop my skills and grow professionally. I am confident in my ability to come up with new ideas and enhance  my UI and UX skills.",
    icon: "paint-brush",
  },
  {
    title: "Quotes",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed convallis ante nec imperdiet venenatis. Aenean sed consectetur enim. Donec pretium erat ut varius varius.",
    icon: "quote-left",
    img: "https://i.imgur.com/PldPiNS.png",
  },
  {
    title: "Inspiration",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed convallis ante nec imperdiet venenatis. Aenean sed consectetur enim. Donec pretium erat ut varius varius.",
    img: "https://i.imgur.com/ih8gwVB.png",
    icon: "lightbulb",
  },
];

export const faqs = [
  {
    question: "What is a MY PCT?",
    answer:
      "MY PCT is an application that helps in finding doctors and patient nearby.",
  },
  {
    question: "What is a colonoscopy?",
    answer:
      "Colonoscopy is a crucial diagnostic tool for detecting and preventing colorectal cancer, as well as other gastrointestinal conditions. However, not everyone may require or be eligible for this procedure. Factors such as age, medical history, and specific symptoms play a role in determining eligibility.",
  },
  {
    question: "How to book an appointment ?",
    answer:
      "Colonoscopy is a crucial diagnostic tool for detecting and preventing colorectal cancer, as well as other gastrointestinal conditions. However, not everyone may require or be eligible for this procedure. Factors such as age, medical history, and specific symptoms play a role in determining eligibility.",
  },
  {
    question: "How to prevent colon cancer ?",
    answer:
      "Colonoscopy is a crucial diagnostic tool for detecting and preventing colorectal cancer, as well as other gastrointestinal conditions. However, not everyone may require or be eligible for this procedure. Factors such as age, medical history, and specific symptoms play a role in determining eligibility.",
  },
  {
    question: "When are the appointments available for colonoscopy ?",
    answer:
      "Colonoscopy is a crucial diagnostic tool for detecting and preventing colorectal cancer, as well as other gastrointestinal conditions. However, not everyone may require or be eligible for this procedure. Factors such as age, medical history, and specific symptoms play a role in determining eligibility.",
  },
];

export const breadcrumbNameMap: { [key: string]: string } = {
  "/profile": "Profile",
  "/profile/pastappointments": "Past Appointments",
  "/trash": "Trash",
  "/spam": "Spam",
  "/drafts": "Drafts",
};

