import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller, useFormContext } from "react-hook-form";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";

type IFormInputProps = {
  name: string;
  label?: string;
  disabled?: boolean;
  sx?: SxProps<Theme> | undefined;
  withLabel?: boolean;
  minDate?: Date;
};

export const FormInputDate = ({
  name,
  label,
  sx,
  disabled = false,
  minDate,
}: IFormInputProps) => {
  const { control } = useFormContext();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        disabled={disabled}
        render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
          <DatePicker
            {...field}
            label={label ?? ""}
            inputRef={ref}
            slotProps={{
              textField: {
                error: !!fieldState.error,
                helperText: fieldState.error?.message,
                onBlur: onBlur,
                name: name,
              },
            }}
            sx={sx}
            minDate={minDate}
          />
        )}
      />
    </LocalizationProvider>
  );
};
