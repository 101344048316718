import { Check, Circle } from "@mui/icons-material";
import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepIconProps,
  StepLabel,
  TextField,
  Checkbox,
  styled,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { IQuestion } from "./surveyApi";
import { getProperty } from "../../utils/stringHelper";

interface _IQuestion {
  question: IQuestion;
  isDisabled: boolean;
  watch: any;
  activeStep: number;
  index: number;
  changeStep: (step: number) => void;
  totalItems: number;
}
const yesNo = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const Question = ({
  question,
  isDisabled,
  watch,
  activeStep,
  index,
  changeStep,
  totalItems,
}: _IQuestion) => {
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const answer = watch(`q_${question.id}`);

  const {
    register,
    formState: { errors },
  } = useFormContext();
  const onChangeStep = () => {
    if (activeStep <= index) {
      changeStep(index);
    }
  };

  const renderQuestion = () => {
    switch (question.questionType) {
      case 1:
        return (
          <>
            <RadioGroup sx={{ display: "flex", flexDirection: "row" }}>
              {yesNo.map((option) => (
                <FormControlLabel
                  key={option.value}
                  {...register(`q_${question.id}`, {
                    deps: [`q_${question.dependentOnQuestionId}`],
                  })}
                  disabled={isDisabled}
                  value={option.value}
                  control={<Radio sx={{ color: "#6297B5" }} />}
                  label={option.label}
                  onChange={(event: any) => {
                    onChangeStep();
                    register(`q_${question.id}`).onChange(event);
                  }}
                />
              ))}
            </RadioGroup>
            {errors && getProperty(errors, `q_${question.id}`)?.message ? (
              <FormHelperText error>
                {getProperty(errors, `q_${question.id}`)?.message ?? ""}
              </FormHelperText>
            ) : null}
          </>
        );
      case 2:
        return (
          <>
            <Select
              {...register(`q_${question.id}`, {
                deps: [`q_${question.dependentOnQuestionId}`],
              })}
              disabled={isDisabled}
              fullWidth
              sx={{ mt: 1 }}
              onChange={(event: any) => {
                onChangeStep();
                register(`q_${question.id}`).onChange(event);
              }}
              defaultValue={""}
            >
              {question.answerOptions!.map((option: string) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {errors && getProperty(errors, `q_${question.id}`)?.message ? (
              <FormHelperText error>
                {getProperty(errors, `q_${question.id}`)?.message ?? ""}
              </FormHelperText>
            ) : null}
          </>
        );
      case 3:
        return (
          <>
            <Box display="flex" flexDirection="column">
              {question.answerOptions!.map((option: string) => (
                <FormControlLabel
                  key={option}
                  {...register(`q_${question.id}`, {
                    deps: [`q_${question.dependentOnQuestionId}`],
                  })}
                  disabled={isDisabled}
                  value={option}
                  control={<Checkbox sx={{ color: "#6297B5" }} />}
                  label={option}
                  onChange={(event: any) => {
                    onChangeStep();
                    register(`q_${question.id}`).onChange(event);
                  }}
                />
              ))}
            </Box>
            {errors && getProperty(errors, `q_${question.id}`)?.message ? (
              <FormHelperText error>
                {getProperty(errors, `q_${question.id}`)?.message ?? ""}
              </FormHelperText>
            ) : null}
          </>
        );
      case 4:
        return (
          <>
            <TextField
              {...register(`q_${question.id}`, {
                deps: [`q_${question.dependentOnQuestionId}`],
              })}
              disabled={isDisabled}
              onChange={(event: any) => {
                onChangeStep();
                register(`q_${question.id}`).onChange(event);
              }}
            />
            {errors && getProperty(errors, `q_${question.id}`)?.message ? (
              <FormHelperText error>
                {getProperty(errors, `q_${question.id}`)?.message ?? ""}
              </FormHelperText>
            ) : null}
          </>
        );

      default:
        return null;
    }
  };

  const CheckerStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(() => ({
    zIndex: 1,
    border: "2px solid #6297B5",
    color: "#fff",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(activeStep + 1 === index && {
      color: "#6297B5",
    }),
    ...(answer &&
      activeStep >= index && {
        backgroundColor: "#56C1AF",
      }),
  }));

  const NoStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(() => ({
    zIndex: 1,
    color: "#fff",
    backgroundColor: "#6297B5",
    marginLeft: "13px",
    width: "2px",
    ...(isSM && {
      marginTop: "-3px",
      marginBottom: "-12.4px",
      minHeight: "calc(24px + 0.5rem)",
    }),
  }));

  function CheckerIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <CheckerStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {activeStep >= index ? <Check /> : <Circle />}
      </CheckerStepIconRoot>
    );
  }

  function NoIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <NoStepIconRoot ownerState={{ completed, active }} className={className}>
        <Box sx={{ height: "24px", width: "2px" }} />
      </NoStepIconRoot>
    );
  }

  return (
    <Box display={!isDisabled ? "block" : "none"}>
      <Step active={true} key={question.id}>
        {question.dependentOnQuestionId === null ? (
          <StepLabel StepIconComponent={CheckerIcon}>{question.text}</StepLabel>
        ) : (
          <StepLabel StepIconComponent={NoIcon}>
            <Box
              sx={{
                paddingLeft: "10px",
                opacity: isDisabled ? "40%" : "100%",
              }}
            >
              {question.text}
            </Box>
          </StepLabel>
        )}

        <StepContent
          sx={{
            borderColor: index < totalItems - 1 ? "#6297B5" : "transparent",
            borderWidth: "2px",
            marginLeft: "13px",
          }}
        >
          {renderQuestion()}
        </StepContent>
      </Step>
    </Box>
  );
};

export default Question;
