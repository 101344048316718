import { createSlice } from "@reduxjs/toolkit";
import { forgotReset } from "../../action";

const forgotSlice = createSlice({
  name: "forgot",
  initialState: {
    email: "",
    otp: 0,
  },
  reducers: {
    submitForReset(state, action) {
      state.email = action.payload.email;
      state.otp = action.payload.otp;
    },
  },
  extraReducers(builder) {
    builder.addCase(forgotReset, () => {
      return {
        email: "",
        otp: 0,
      };
    });
  },
});

export const { submitForReset } = forgotSlice.actions;

export default forgotSlice.reducer;
