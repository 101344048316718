import React from "react";
import { Container, Grid, useTheme } from "@mui/material";
import { CalendarAppointment } from "../../features/provider/home/calendar-schedule";
import { useBreakpoints } from "../../hooks/useBreakpoints";

interface TimelineContainerProps {
  children: React.ReactNode;
}
const TimelineContainer = ({ children }: TimelineContainerProps) => {
  const theme = useTheme();
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const { palette } = theme;
  return (
    <Grid container direction="row" alignItems={"stretch"}>
      <Grid item xs={12} lg={8} xl={9} px={isSM ? 1 : 4}>
        {children}
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
        xl={3}
        sx={{
          borderRadius: isSM ? 0 : "52px 0px 0px 52px",
          backgroundColor: palette.common.background,
        }}
      >
        <CalendarAppointment />
      </Grid>
    </Grid>
  );
};

export default TimelineContainer;
