import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import classes from "../ProfileSetting.module.css";
import { ButtonBaseProps, Typography } from "@mui/material";

const EditProfileButton = (props: ButtonBaseProps) => {
  return (
    <button className={classes["edit-profile-button"]} {...props}>
      <EditOutlinedIcon color="primary" style={{ marginRight: 4 }} />
      <Typography variant="body1" fontSize={"14px"} color="#005AC3">
        Edit Profile
      </Typography>
    </button>
  );
};

export default EditProfileButton;
