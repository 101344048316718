import { WEEKDAYS, Weekday, Weekdays } from "./constants";
import { Box, Typography } from "@mui/material";
import { Moment } from "moment";
import DateUtil from "../../../../../utils/dateHelper";

type Props = {
  today: Moment;
  selectedDate: Moment;
  dateRange: Moment[];
  onWeekDayChange: (weekdayValue: number) => void;
};

const WeekdayNavigator = (props: Props) => {
  const { today, selectedDate, onWeekDayChange, dateRange } = props;
  const weekdays: Weekday[] = WEEKDAYS.map((day, index) => {
    return { day, date: dateRange[index] };
  });

  const getWeekBorderStyle = (weekday: Weekdays) => {
    switch (weekday) {
      case "SUN":
        return {
          borderLeftWidth: "1px",
          borderRightWidth: "0.5px",
          borderStartStartRadius: 20,
          borderEndStartRadius: 20,
        };
      case "SAT":
        return {
          borderLeftWidth: "0.5px",
          borderRightWidth: "1px",
          borderStartEndRadius: 20,
          borderEndEndRadius: 20,
        };
      default:
        return {
          borderWidth: "1px 0.5px",
        }; // Return default style for weekdays
    }
  };
  const getWeekColorStyle = (weekday: Weekday) => {
    const formatStr = DateUtil.DateFormats.WEEK_DAY;
    const compareStr = weekday.day.toLowerCase();
    if (selectedDate.format(formatStr)?.toLowerCase() === compareStr) {
      return { backgroundColor: "#CFE5FF" };
    } else if (
      today.isSame(weekday.date, "date") &&
      today.format(formatStr)?.toLowerCase() === compareStr
    ) {
      return { backgroundColor: "#1D192B12" };
    }
    return {};
  };

  return (
    <Box display="flex" position="relative" flex={1} marginY={2.5}>
      {weekdays.map((weekday, index) => {
        return (
          <Box
            key={weekday.day}
            display={"flex"}
            flex={1}
            justifyContent={"center"}
            sx={{
              borderTopWidth: "1px",
              borderBottomWidth: "1px",
              borderColor: "#75777F",
              borderStyle: "solid",
              paddingY: "10px",
              cursor: "pointer",
              ...getWeekBorderStyle(weekday.day),
              ...getWeekColorStyle(weekday),
            }}
            onClick={() => onWeekDayChange(index)}
          >
            <Typography
              variant="body1"
              fontSize={"14px"}
              color={"#191C1D"}
              sx={{ paddingX: 1 }}
            >
              {weekday.day}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default WeekdayNavigator;
