import { encrypt } from "../../../utils/codec";
import config from "../../../config/config";
import { providerAPI } from "../providerAPI";
import { createEntityAdapter } from "@reduxjs/toolkit";

interface IPatient {
  height: number;
  weight: number;
  gender: number;
  address: string | null;
  lastAppointmentDate: Date;
  lastProcedureName: string;
  lastAppointmentStatus: number;
  name: string;
  surname: string;
  middleName: string | null;
  emailAddress: string;
  phoneNumber: string;
  dateOfBirth: string | null;
  fullName: string;
  id: number;
}

interface IAppointment {
  id: string;
}

export interface IReqGetAllMyAppointments {
  pageNumber: number;
  limit: number;
  filterByPatient: string;
  startDate: string;
  endDate: string;
  appointmentStatus: number[];
}

interface IGetPatient {
  id: string | null;
}

interface IGetNotes {
  appointmentId: string | null;
}

interface INotesInput {
  appointmentId: string;
  notes: string;
  document?: File[];
}

interface INotesUpdate {
  noteId: string;
  appointmentId: string;
  notes: string;
}

interface INotesDelete {
  noteId: string;
}

const patientsAdapter = createEntityAdapter({
  selectId: (item: IPatient) => item.id,
});

const appointmentsAdapter = createEntityAdapter({
  selectId: (item: IAppointment) => item.id,
});

const patientsSelector = patientsAdapter.getSelectors();
const appointmentsSelector = appointmentsAdapter.getSelectors();

export const providerPatientsAPI = providerAPI.injectEndpoints({
  endpoints: (builder) => ({
    getMyCustomers: builder.query<any, any>({
      query(data) {
        const { pageNumber, limit } = data;
        const request = {
          maxResultCount: limit,
          skipCount: (pageNumber - 1) * limit,
          keyword: data.keyword,
        };

        const _data = encrypt(request, config.secret);
        return {
          url: "/api/services/app/ProvidersCustomerService/GetMyCustomers",
          method: "POST",
          credentials: "include",
          body: _data,
        };
      },
      transformResponse: (response: any, _: any, { pageNumber, limit }) => {
        return patientsAdapter.addMany(
          patientsAdapter.getInitialState({
            hasMorePages: pageNumber * limit < response?.result?.totalCount,
          }),
          response?.result?.items ?? []
        );
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.pageNumber !== previousArg?.pageNumber;
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        //filter values should be added here
        return `${endpointName}${queryArgs.keyword}`;
      },
      merge: (currentState, incomingState) => {
        patientsAdapter.addMany(
          currentState,
          patientsSelector.selectAll(incomingState)
        );
        currentState.hasMorePages = incomingState.hasMorePages;
      },
    }),
    getAllMyAppointments: builder.query<any, IReqGetAllMyAppointments>({
      query(data) {
        const {
          pageNumber,
          limit,
          filterByPatient,
          startDate,
          endDate,
          appointmentStatus,
        } = data;
        const request = {
          maxResultCount: limit,
          skipCount: (pageNumber - 1) * limit,
          filterByPatient: filterByPatient,
          startDate: startDate,
          endDate: endDate,
          appointmentStatus: appointmentStatus,
        };

        const _data = encrypt(request, config.secret);
        return {
          url: "/api/services/app/AppointmentService/GetAll",
          method: "POST",
          credentials: "include",
          body: _data,
        };
      },
      transformResponse: (response: any, _: any, { pageNumber, limit }) => {
        return appointmentsAdapter.addMany(
          appointmentsAdapter.getInitialState({
            hasMorePages: pageNumber * limit < response?.result?.totalCount,
          }),
          response?.result?.items ?? []
        );
      },
      forceRefetch({ currentArg, previousArg }) {
        return true;
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        if (
          queryArgs?.appointmentStatus &&
          queryArgs?.appointmentStatus?.length > 0 &&
          queryArgs.filterByPatient
        ) {
          return `${endpointName}-${JSON.stringify(
            queryArgs.appointmentStatus
          )}-${queryArgs.filterByPatient}`;
        }
        if (queryArgs.filterByPatient) {
          return `${endpointName}-${queryArgs.filterByPatient}`;
        }
        if (queryArgs.startDate) {
          return `${endpointName}-${queryArgs.startDate}`;
        }
        if (
          queryArgs?.appointmentStatus &&
          queryArgs?.appointmentStatus?.length > 0
        ) {
          return `${endpointName}-${JSON.stringify(
            queryArgs.appointmentStatus
          )}`;
        }
        //filter values should be added here
        return `${endpointName}`;
      },
      merge: (currentState, incomingState) => {
        appointmentsAdapter.addMany(
          currentState,
          appointmentsSelector.selectAll(incomingState)
        );
        currentState.hasMorePages = incomingState.hasMorePages;
      },
    }),
    getMyPatientDetails: builder.query<any, IGetPatient>({
      query(data) {
        const request = {
          id: data.id,
        };

        const _data = encrypt(request, config.secret);
        return {
          url: "/api/services/app/ProvidersCustomerService/GetMyCustomer",
          method: "POST",
          credentials: "include",
          body: _data,
        };
      },
      transformResponse: (res: any) => {
        return res.result;
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        //filter values should be added here
        return `${endpointName}${queryArgs.id}`;
      },
    }),
    getAppointmentNotes: builder.query<[], IGetNotes>({
      query(data) {
        const request = {
          AppointmentId: data.appointmentId,
          SkipCount: 0,
          MaxResultCount: 50,
        };
        return {
          url: "/api/services/app/AppointmentNotes/GetAll",
          method: "GET",
          credentials: "include",
          params: request,
        };
      },
      providesTags: ["Appointment Notes"],
      transformResponse: (res: any) => {
        return res?.result?.items ?? {};
      },
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        return `${endpointName}${queryArgs.appointmentId}`;
      },
    }),
    createNote: builder.mutation<{}, INotesInput>({
      query(data) {
        const formData = new FormData();
        formData.append("AppointmentId", data.appointmentId);
        formData.append("Notes", data.notes);
        return {
          url: "/api/services/app/AppointmentNotes/Create",
          method: "POST",
          headers: {
            "content-type": "multipart/form-data",
          },
          credentials: "include",
          body: formData,
        };
      },
      invalidatesTags: ["Appointment Notes"],
    }),
    updateNote: builder.mutation<{}, INotesUpdate>({
      query(data) {
        const request = {
          Id: data.noteId,
          AppointmentId: data.appointmentId,
          Notes: data.notes,
        };

        const _data = encrypt(request, config.secret);
        return {
          url: "/api/services/app/AppointmentNotes/Update",
          method: "PUT",
          credentials: "include",
          body: _data,
        };
      },
      invalidatesTags: ["Appointment Notes"],
    }),
    deleteNote: builder.mutation<{}, INotesDelete>({
      query(data) {
        const request = {
          Id: data.noteId,
        };

        return {
          url: "/api/services/app/AppointmentNotes/Delete",
          method: "Delete",
          credentials: "include",
          params: request,
        };
      },
      invalidatesTags: ["Appointment Notes"],
    }),
  }),
});

export const {
  useGetMyCustomersQuery,
  useLazyGetMyCustomersQuery,
  useGetAllMyAppointmentsQuery,
  useLazyGetAllMyAppointmentsQuery,
  useGetMyPatientDetailsQuery,
  useGetAppointmentNotesQuery,
  useCreateNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
} = providerPatientsAPI;

export {
  patientsAdapter,
  patientsSelector,
  appointmentsAdapter,
  appointmentsSelector,
};
