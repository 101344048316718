import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LoginInput, loginSchema } from "./signinSchema";
// import { useLocation, useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "../../../../app/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { getErrorMessage } from "../../../../utils/serviceHelper";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../../hooks/useToast";
import { useAppSelector } from "../../../../hooks/useStoreHook";

export function useSigninFunction(props: any) {
  const methods = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });

  const [loginUser, { isLoading, isError, error, isSuccess }] =
    useLoginUserMutation();

  const navigate = useNavigate();
  // const location = useLocation();

  // const from = ((location.state as any)?.from.pathname as string) || "/profile";

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  const { displayToast } = useToast();

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        reset({ email: "", password: "", remember: false });
      }, 10);
    }
    if (isError) {
      const errorMessage = getErrorMessage(error);
      displayToast({ message: errorMessage, type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onSubmitHandler: SubmitHandler<LoginInput> = (values) => {
    // 👇 Executing the loginUser Mutation
    loginUser({ ...values, isProvider: Boolean(props.index) });
  };

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const { t } = useTranslation();

  return {
    isLoading,
    showPassword,
    methods,
    t,
    handleSubmit,
    onSubmitHandler,
    handleShowPasswordClick,
  };
}
