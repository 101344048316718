import { Box, Typography } from "@mui/material";
import { Fragment } from "react";
import { usePatientAppointment } from "../../provider/patients/patient-details/usePatientAppointment";
import AppointmentOverviewSkeleton from "../../../components/skeletons/AppointmentOverviewSkeleton";
import AppointmentPatientOverview from "../../../components/AppointmentPatientOverview";
import DataUtil from "../../../utils/dataHelper";

const PastAppointment = () => {
  const { appointments, isLoading, lastPostRef, isError } =
    usePatientAppointment("", [3, 6]);
  return (
    <>
      <Box my={2} display={"flex"} flexWrap={"wrap"}>
        {appointments.length > 0 ? (
          appointments.map((appointment, index) => {
            return (
              <Fragment key={appointment?.id ?? index}>
                {index === appointments.length - 1 ? (
                  <AppointmentPatientOverview
                    ref={lastPostRef}
                    appointmentId={appointment?.id}
                    appointmentStatus={(appointment as any)?.status}
                    scheduleDetails={(appointment as any)?.scheduleDetails}
                    physicanDetails={(appointment as any)?.provider}
                    procedureName={
                      (appointment as any)?.procedureOrServiceToPerform?.name ??
                      ""
                    }
                    key={appointment.id}
                    isPast
                  />
                ) : (
                  <AppointmentPatientOverview
                    appointmentId={appointment?.id}
                    appointmentStatus={(appointment as any)?.status}
                    scheduleDetails={(appointment as any)?.scheduleDetails}
                    physicanDetails={(appointment as any)?.provider}
                    procedureName={
                      (appointment as any)?.procedureOrServiceToPerform?.name ??
                      ""
                    }
                    isRated={
                      (appointment as any)?.feedback === null ? false : true
                    }
                    rating={(appointment as any)?.feedback?.rating ?? 0}
                    key={appointment.id}
                    isPast
                  />
                )}
              </Fragment>
            );
          })
        ) : (
          <Box>
            {isError ? (
              <Typography>
                Something went wrong while fetching the data
              </Typography>
            ) : (
              <Typography>No appointments found</Typography>
            )}
          </Box>
        )}
      </Box>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {Array.from({ length: 12 }).map((el) => {
            return <AppointmentOverviewSkeleton />;
          })}
        </Box>
      )}
    </>
  );
};

export default PastAppointment;
