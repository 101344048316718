import { Moment } from "moment";

export const WEEKDAYS = [
  "SUN",
  "MON",
  "TUE",
  "WED",
  "THU",
  "FRI",
  "SAT",
] as const;

export type Weekdays = (typeof WEEKDAYS)[number]; // This will give you "SUN" | "MON" | "TUE" | "WED" | "THU" | "FRI" | "SAT"
export type Weekday = {
  day: Weekdays;
  date: Moment;
};
