import { Button, Grid, Typography } from "@mui/material";
import { UseFormSetValue } from "react-hook-form";

import { FormInputText } from "../../../../../components/form/FormInput";
import { FormInputDropdown } from "../../../../../components/form/FormDropdown";
import Map from "../../../../../components/Map";
import { IProfessionalSchema } from "../../completeProfileSchema";
import { LatLng } from "../../../../../components/Map/Map";
import { getAddress } from "../providerProfileHelper";
import { useEffect, useState } from "react";
import { useBreakpoints } from "../../../../../hooks/useBreakpoints";
import BaseModal from "../../../../../components/BaseModal";

interface AddressModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onClose: (latlng: LatLng | null) => void;
  setValue: UseFormSetValue<IProfessionalSchema>;
}

const AddressModal = (props: AddressModalProps) => {
  const { isOpen, onCancel, onClose, setValue } = props;

  const [position, setPosition] = useState<null | LatLng>(null);
  const [{ isMatchDownSM: isSM }] = useBreakpoints();

  const handleDragEnd = async (latLng: LatLng) => {
    const result = await getAddress(latLng.lat, latLng.lng);
    if (!result) return;
    setValue("zipCode", result.zip);
    setValue("city", result.city);
    setValue("stateOrRegion", result.state);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      const latLng: LatLng = { lat: coords.latitude, lng: coords.longitude };
      setPosition(latLng);
      handleDragEnd(latLng);
    });
  }, []);

  return (
    <BaseModal open={isOpen} onClose={() => onClose(position)} width={600}>
      <Grid container spacing={1} direction={isSM ? "column" : "row"}>
        <Typography variant="h4">Clinic/Business Address</Typography>
        <Grid item md={12}>
          {position && (
            <Map
              position={position}
              radius={15}
              width={"100%"}
              height={250}
              draggable
              onDragEnd={handleDragEnd}
            />
          )}
        </Grid>
        <Grid item md={12}>
          <FormInputText name="streetLineOne" label="Street Line 1" fullWidth />
        </Grid>
        <Grid item md={12}>
          <FormInputText name="streetLineTwo" label="Street Line 2" fullWidth />
        </Grid>
        <Grid item container direction={isSM ? "column" : "row"} spacing={1}>
          <Grid item md={6}>
            <FormInputText name="city" label="City" fullWidth />
          </Grid>
          <Grid item md={6}>
            <FormInputText name="zipCode" label="Zipcode" fullWidth />
          </Grid>
        </Grid>
        <Grid item container direction={isSM ? "column" : "row"} spacing={1}>
          <Grid item md={6}>
            <FormInputDropdown
              name="addressType"
              label="Address Type"
              fullWidth
              options={[
                { label: "Present", value: 2 },
                { label: "Resident", value: 1 },
              ]}
              withLabel
            />
          </Grid>
          <Grid item md={6}>
            <FormInputDropdown
              name="isCurrent"
              label="Is Current"
              fullWidth
              options={[
                { label: "Yes", value: 1 },
                { label: "No", value: 0 },
              ]}
              withLabel
            />
          </Grid>
        </Grid>
        <Grid item md={12}>
          <FormInputText name="stateOrRegion" label="State/Region" fullWidth />
        </Grid>
        <Grid
          item
          md={12}
          display="flex"
          alignItems="center"
          justifyContent="space-around"
        >
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={() => onClose(position)}>Save</Button>
        </Grid>
      </Grid>
    </BaseModal>
  );
};

export default AddressModal;
