import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "../../../app/store/slices/apiSlice";
import config from "../../../config/config";
import { encrypt } from "../../../utils/codec";
import { Res } from "../../../types";

export const physiciansByAreaAPI = createApi({
  reducerPath: "physiciansByArea",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPhysicians: builder.mutation<FilterProviderData, FilterProviderPayload>({
      query: (payload) => ({
        url: "/api/services/app/Provider/FilterProvider",
        method: "POST",
        body: encrypt(payload, config.secret),
      }),
      transformResponse: (res: Res<FilterProviderData>) => res.result,
    }),
    getPhysician: builder.query<ProvdiderProfie, string>({
      query: (payload) => `/api/services/app/Provider/${payload}/Profile`,
      transformResponse: (res: Res<ProvdiderProfie>) => res.result,
    }),
  }),
});

export interface FilterProviderPayload {
  maxResultCount: number;
  skipCount: number;
  zipCode: string;
  radius: number;
  latitude?: number;
  longitude?: number;
  filterByDate: string;
  filterByHour: string;
  filterByMinute: string;
}

export interface FilterProviderData {
  totalCount: number;
  items: Item[];
}

export interface Item {
  name: string;
  surname: string;
  middleName: any;
  emailAddress: string;
  phoneNumber: string;
  fullName: string;
  profileTitle: string;
  profileImage: string | null;
  ratings: number;
  noOfRatings: number;
  latitude: number;
  longitude: number;
  id: number;
}

export interface ProvdiderProfie {
  fullName: string;
  name: string;
  surname: string;
  middleName: string;
  emailAddress: string;
  dateOfBirth: string;
  phoneNumber: string;
  aboutMe: string;
  npi: string;
  userTimeZone: any;
  gender: number;
  profileImage: string;
  notes: any;
  profileStep: number;
  professionalDetail: ProfessionalDetail;
  certificates: Certificate[];
  roleNames: string[];
  isActive: boolean;
  lastLoginTime: any;
  creationTime: string;
  id: number;
}

export interface ProfessionalDetail {
  educationQualification: string;
  experience: string;
  medicalSchool: string;
  residency: string;
  fellowship: string;
  npi: any;
  awardsList: string[];
  businessAddress: BusinessAddress;
}

export interface BusinessAddress {
  userId: number;
  streetLineOne: string;
  streetLineTwo: string;
  stateOrRegion: string;
  city: string;
  zipCode: string;
  addressType: number;
  isCurrent: boolean;
  latitude: number;
  longitude: number;
  id: string;
}

export interface Certificate {
  contentType: string;
  fileName: string;
  documentType: number;
  id: string;
}

export const { useGetPhysiciansMutation, useGetPhysicianQuery } =
  physiciansByAreaAPI;
