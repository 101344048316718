import { Box, Typography } from "@mui/material";
import AuthLayout from "../../components/AuthLayout";
import ForgotPasswordForm from "../../features/user/auth/forgot/ForgotPasswordForm";
import { useTranslation } from "react-i18next";
import { useBreakpoints } from "../../hooks/useBreakpoints";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const [{ isMatchDownSM: isSM }] = useBreakpoints();

  return (
    <AuthLayout>
      <Box marginTop={isSM ? 4 : 8}>
        <Typography variant="body1">{t("auth.forgotPassword")}</Typography>
        <ForgotPasswordForm />
      </Box>
    </AuthLayout>
  );
}
