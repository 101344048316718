import classes from "./LinearProgressBar.module.css";

const LinearProgressBar = ({ progress }: LinearProgressBarProps) => {
  return (
    <div className={classes["progress-bar"]}>
      <div
        className={classes["progress-fill"]}
        style={{ width: `${progress}%` }}
      >
        <div className={classes["progress-dot"]}></div>
      </div>
    </div>
  );
};

export default LinearProgressBar;

interface LinearProgressBarProps {
  progress: number;
}
