import { encrypt } from "../../../../utils/codec";
import config from "../../../../config/config";
import { providerAPI } from "../../providerAPI";

export interface PatientSearch {
  name: string;
  surname: string;
  middleName: string | null;
  emailAddress: string;
  id: number;
}

export interface Procedure {
  name: string;
  description: string;
  id: string;
}
export interface AddAppointment {
  isPatient: boolean;
  procedureId: string;
  customerId?: number;
  customerName?: string;
  email?: string;
  phoneNumber?: number;
  scheduleId: string;
  scheduleDetailsId: string;
  notes: string | null;
}

export const addAppointments = providerAPI.injectEndpoints({
  endpoints: (builder) => ({
    getPatientDetails: builder.mutation<PatientSearch[], { filter: string }>({
      query(data) {
        const request = { keyword: data.filter };
        //const _data = encrypt(request, config.secret);
        return {
          url: "/api/services/app/Provider/FilterMyCustomer",
          method: "POST",
          credentials: "include",
          body: request,
        };
      },
      transformResponse: (res: any) => {
        return res.result;
      },
    }),
    getProcedureForAppointment: builder.query<Procedure[], null>({
      query() {
        return {
          url: "/api/services/app/ProcedureService/GetAll",
          method: "GET",
          credentials: "include",
        };
      },
    }),
    addAppointment: builder.mutation<PatientSearch[], AddAppointment>({
      query(data) {
        let request = null;
        if (data.isPatient) {
          request = {
            procedureOrServiceId: data.procedureId,
            customerId: data.customerId,
            customerName: "",
            email: "",
            phoneNumber: "",
            scheduleId: data.scheduleId,
            scheduleDetailsId: data.scheduleDetailsId,
            notes: data.notes ?? null,
          };
        } else {
          request = {
            procedureOrServiceId: data.procedureId,
            customerId: 0,
            customerName: data.customerName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            scheduleId: "6c2d3ce8-91bb-4825-71f5-08dc244de3d2",
            scheduleDetailsId: "8041a9c9-e332-4484-dcb9-08dc244de3d5",
            notes: "These are my notes for the booking.",
          };
        }
        const _data = encrypt(request, config.secret);
        return {
          url: "/api/services/app/AppointmentService/Create",
          method: "POST",
          credentials: "include",
          body: _data,
        };
      },
    }),
  }),
});

export const {
  useGetPatientDetailsMutation,
  useLazyGetProcedureForAppointmentQuery,
  useAddAppointmentMutation,
} = addAppointments;
