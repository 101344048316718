import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "../../app/store/slices/apiSlice";
import { setColonoscopyRequired } from "../../app/store/slices/checkColonoscopyRequired";
import config from "../../config/config";
import { encrypt } from "../../utils/codec";

export interface Questionnaire {
  procedureOrServiceId: string;
  name: string;
  description: string;
  questions: IQuestion[];
  id: string;
}

export interface IQuestion {
  questionnaireId: string;
  questionType: number;
  text: string;
  helpText: null;
  answerOptions: any;
  order: number;
  dependentOnQuestionId: null | string;
  dependentOnQuestion: IQuestion | null;
  id: string;
}

export const surveyApi = createApi({
  reducerPath: "surveyApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Survey"],
  endpoints: (builder) => ({
    getQuestionnaire: builder.query<Questionnaire, any>({
      query() {
        return {
          url: "/api/services/app/QuestionnaireService/GetDefaultQuestionnaire",
          method: "GET",
          credentials: "include",
        };
      },
      transformResponse: (res: any) => {
        const data = res.result;
        return {
          ...data,
          questions: data.questions.map((question: IQuestion) => ({
            ...question,
            answerOptions:
              question.questionType === 2 || question.questionType === 3
                ? JSON.parse(
                    question.answerOptions.length > 0
                      ? question.answerOptions
                      : "[]"
                  )
                : null,
          })),
        };
      },
      // forceRefetch({ currentArg, previousArg }) {
      //   return true;
      // },
    }),
    validateQuestionnaire: builder.mutation<any, { [key: string]: any }[]>({
      query(data) {
        let request = [];
        for (const key in data) {
          const _key = key.slice(2, key.length);
          request.push({
            questionId: _key,
            text:
              typeof data[key] === "string"
                ? data[key]
                : JSON.stringify(data[key]),
            notesOrOther: "",
          });
        }
        const _data = encrypt(request, config.secret);

        return {
          url: "/api/services/app/QuestionnaireService/ValidateQuestionnaire",
          method: "POST",
          credentials: "include",
          body: _data,
        };
      },
      transformResponse: (res: any) => {
        return res.result;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            setColonoscopyRequired({
              isRequired: data?.isProcedureOrServiceRequired ?? false,
              checked: true,
            })
          );
        } catch (error) {
          //Need to retry and if not successful logout
          console.log(error);
        }
      },
    }),
  }),
});

export const { useGetQuestionnaireQuery, useValidateQuestionnaireMutation } =
  surveyApi;
