import { Fragment } from "react";
import AppointmentEvent from "./AppointmentEvent";
import { Box } from "@mui/material";
import EmptyTimeSlot from "../../settings/SetUpTimeSlot/EmptyTimeSlot";
import AppointmentEventSkeleton from "./AppointmentEventSkeleton";
import { getAppStatus } from "../../appointment/appointmentRequestsAPI";

interface TimeCalendarProps {
  scheduleData: any;
  isFetching: boolean;
}
const TimeCalendar = ({ scheduleData, isFetching }: TimeCalendarProps) => {
  if (isFetching) {
    return (
      <Box sx={{ my: 4 }}>
        {Array.from({ length: 12 }).map((el, index) => (
          <Box key={index}>
            <AppointmentEventSkeleton />
          </Box>
        ))}
      </Box>
    );
  } else if (scheduleData && scheduleData?.length > 0) {
    return (
      <Fragment>
        {scheduleData?.map((schedule: any, index: number) => (
          <Fragment key={schedule?.scheduleDetails?.id ?? index}>
            <AppointmentEvent
              appointment={schedule.appointment}
              status={getAppStatus(schedule.appStatus)}
              scheduleDetails={schedule.scheduleDetails}
            />
          </Fragment>
        ))}
      </Fragment>
    );
  } else {
    return (
      <Box sx={{ my: 4 }}>
        <EmptyTimeSlot noAction={true} />
      </Box>
    );
  }
};

export default TimeCalendar;
