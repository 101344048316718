import { object, TypeOf, z, ZodIssueCode } from "zod";

export const timeSlotSchema = object({
  startTime: z.any(),
  endTime: z.any(),
  timeSlots: z.any(),
  timezone: z.any(),
  weekdays: z.any(),
  repeat: z.boolean(),
  slotValidity: z.union([z.date(), z.null()]).optional(),
}).superRefine(({ repeat, timeSlots, slotValidity, weekdays }, ctx) => {
  if (!timeSlots.some((slot: any) => slot.isOccupied)) {
    ctx.addIssue({
      message: "At least one slot is required",
      code: ZodIssueCode.custom,
      path: ["timSlots"],
    });
  }
  if (repeat) {
    if (!slotValidity) {
      ctx.addIssue({
        message: "Till date is required.",
        code: ZodIssueCode.custom,
        path: ["slotValidity"],
      });
    } else if (slotValidity.getTime() < new Date().getTime()) {
      ctx.addIssue({
        message: "Till date should be a future date.",
        code: ZodIssueCode.custom,
        path: ["slotValidity"],
      });
    }

    if (weekdays.length === 0) {
      ctx.addIssue({
        message: "At least one Day has to be selected from Selected Repetition",
        code: ZodIssueCode.custom,
        path: ["weekdays"],
      });
    }
  }
});

export type TimeSlotInput = TypeOf<typeof timeSlotSchema>;
