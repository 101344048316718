import { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { TimeSlotInput, timeSlotSchema } from "./timeSlotSchema";
import DateUtil, { dateUtil } from "../../../../utils/dateHelper";
import { useCreateScheduleMutation } from "./timeSlotAPI";
import { getErrorMessage } from "../../../../utils/serviceHelper";
import { useToast } from "../../../../hooks/useToast";
import { useAppSelector } from "../../../../hooks/useStoreHook";

export function useTimeSlotFunction(
  open: boolean,
  isEdit: boolean,
  currentTimeslots: any,
  handleDrawer: (open: boolean) => void
) {
  const timezones = [{ label: "EST", value: "EST" }];
  const timeSlots = useMemo(() => dateUtil.generateTimeSlots(), []);
  const { displayToast } = useToast();
  const selectedDate = useAppSelector((state) => state.dateSetter.selectedDate);

  const [createSchedule, createScheduleStatus] = useCreateScheduleMutation();

  const { isLoading, isError, error, isSuccess } = createScheduleStatus;

  useEffect(() => {
    if (isSuccess) {
      handleDrawer(false);
    }
    if (isError) {
      handleDrawer(false);
      const errorMessage = getErrorMessage(error);
      displayToast({ message: errorMessage, type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const getSlots = (startTime: string, endTime: string, timeSlots?: any) => {
    let currentTime = dateUtil.createMoment(startTime, "HH:mm");
    let slots = [];
    do {
      const currentKey = dateUtil.formatDate(
        currentTime,
        DateUtil.DateFormats.MILIARY_TIME
      );
      if (isEdit) {
        slots.push({
          key: currentKey,
          value: dateUtil.formatDate(currentTime, DateUtil.DateFormats.TIME),
          isOccupied:
            currentTimeslots && currentTimeslots.timeSlots
              ? currentTimeslots.timeSlots.find(
                  (slot: any) =>
                    dateUtil.formatDate(
                      slot.startTime,
                      DateUtil.DateFormats.MILIARY_TIME
                    ) === currentKey
                ) ?? false
              : false,
        }); // Push formatted time to array
      } else {
        slots.push({
          key: currentKey,
          value: dateUtil.formatDate(currentTime, DateUtil.DateFormats.TIME),
          isOccupied: timeSlots
            ? timeSlots.find((slot: any) => slot.key === currentKey)
                ?.isOccupied ?? false
            : false,
        }); // Push formatted time to array
      }
      currentTime.add(30, "minutes"); // Add 30 minutes to currentTime
    } while (currentTime.isBefore(dateUtil.createMoment(endTime, "HH:mm")));
    return slots;
  };

  const methods = useForm<TimeSlotInput>({
    resolver: zodResolver(timeSlotSchema),
    defaultValues: useMemo(() => {
      return {
        startTime: timeSlots[0],
        endTime: timeSlots[0],
        timeSlots: getSlots(timeSlots[0], timeSlots[0]),
        timezone: timezones[0].value,
        repeat: false,
        slotValidity: null,
      };
    }, []),
  });

  const navigate = useNavigate();
  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
    getValues,
    setValue,
    watch,
  } = methods;
  const initialValues = getValues();
  const watchFields = watch(["startTime", "endTime"]);
  const generateStartTime = useMemo(() => {
    const newTimeSlots: any = [];
    timeSlots.map((time) => {
      newTimeSlots.push({
        label: dateUtil.formatDate(
          dateUtil.createMoment(time, DateUtil.DateFormats.TIME),
          DateUtil.DateFormats.TIME
        ),
        value: time,
      });
    });
    return newTimeSlots;
  }, []);

  const generateEndTime = useMemo(() => {
    const newTimeSlots: any = [];
    timeSlots.map((time) => {
      const timeToCheckMoment = dateUtil.createMoment(
        initialValues?.startTime ?? timeSlots[0],
        DateUtil.DateFormats.MILIARY_TIME
      );
      const givenTimeMoment = dateUtil.createMoment(
        time,
        DateUtil.DateFormats.MILIARY_TIME
      );
      if (timeToCheckMoment.isSameOrBefore(givenTimeMoment)) {
        newTimeSlots.push({
          label: dateUtil.formatDate(
            dateUtil.createMoment(time, DateUtil.DateFormats.TIME),
            DateUtil.DateFormats.TIME
          ),
          value: time,
        });
      }
    });
    return newTimeSlots;
  }, [initialValues.startTime]);

  useEffect(() => {
    const { startTime, endTime, timeSlots } = getValues();
    const _timeSlots = getSlots(startTime, endTime, timeSlots);
    setValue("timeSlots", _timeSlots);
  }, [watchFields]);

  useEffect(() => {
    const { timeSlots } = getValues();
    const _startTime =
      dateUtil.formatDate(
        currentTimeslots?.startTime,
        DateUtil.DateFormats.MILIARY_TIME
      ) ?? timeSlots[0];
    const _endTime =
      dateUtil.formatDate(
        currentTimeslots?.endTime,
        DateUtil.DateFormats.MILIARY_TIME
      ) ?? timeSlots[0];
    if (isEdit) {
      setValue("startTime", _startTime);
      setValue("endTime", _endTime);
    }
  }, [open, currentTimeslots?.startTime, currentTimeslots?.endTime]);

  const onClearTimeslotsSelection = () => {
    const { timeSlots: _timeSlots } = getValues();
    const newTimeSlots = _timeSlots?.map((slot: any) => ({
      key: slot.key,
      value: slot.value,
      isOccupied: false,
    }));
    setValue("timeSlots", newTimeSlots);
  };

  const onReset = () => {
    reset();
  };

  const onSubmitHandler: SubmitHandler<TimeSlotInput> = (values) => {
    // 👇 Executing the loginUser Mutation
    createSchedule({
      ...values,
      date: dateUtil.createMoment(selectedDate),
      isEdit,
    });
  };

  const { t } = useTranslation();

  return {
    methods,
    t,
    handleSubmit,
    getValues,
    setValue,
    getSlots,
    isLoading,
    watch,
    onReset,
    onSubmitHandler,
    onClearTimeslotsSelection,
    timezones,
    generateStartTime,
    generateEndTime,
  };
}
