import { createTheme } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import currentTheme from "../config/theme";
import { selectCurrentTheme } from "../app/store/slices/themeSlice";

const useCurrentTheme = () => {
  const mode = useSelector(selectCurrentTheme);

  return useMemo(() => ({ mode }), [mode]);
};

const pctPrimary = "#0079B8";
const pctSecondary = "#307DF6";
const pctError = "#d32f2f";
const pctWarning = "#ed6c02";
const pctInfo = "#0288d1";
const pctSuccess = "#2e7d32";
const pctDeselected = "#455A64";
const pctSelected = "#191C1D";
const pctBorder = "#E1E3E4";
const pctImage = "#D8E2FF";
const pctBackground = "#F2FAFF";
const pctPagination = "#C0C0C0";
const faqColor = "#DFF2FF";
const editColor = "#005AC3";

export function useTheme() {
  const _theme = useCurrentTheme();
  const theme = useMemo(
    () =>
      createTheme(currentTheme, {
        palette: {
          mode: _theme.mode,
          common: {
            deselected: `${pctDeselected}`,
            selected: `${pctSelected}`,
            border: `${pctBorder}`,
            image: `${pctImage}`,
            background: `${pctBackground}`,
            pagination: `${pctPagination}`,
            faqColor: `${faqColor}`,
            editColor: `${editColor}`,
          },
          primary: {
            main: `${pctPrimary}`,
          },
          secondary: {
            main: `${pctSecondary}`,
          },
          error: {
            main: `${pctError}`,
          },
          warning: {
            main: `${pctWarning}`,
          },
          info: {
            main: `${pctInfo}`,
          },
          success: {
            main: `${pctSuccess}`,
          },
        },
      }),
    [_theme.mode]
  );
  return [theme];
}
