import { encrypt } from "../../../utils/codec";
import config from "../../../config/config";
import { ICompleteProfileSchema } from "./completeProfileSchema";
import DateUtil, { dateUtil } from "../../../utils/dateHelper";
import { userApi } from "../../user/userAPI";

export const completeProfileAPI = userApi.injectEndpoints({
  endpoints: (builder) => ({
    completeProfile: builder.mutation<{}, ICompleteProfileSchema>({
      query(data) {
        const dateOfBirth = dateUtil.formatDate(
          data.dateOfBirth.toString(),
          DateUtil.DateFormats.MONTH_DATE_YEAR
        );
        const request = {
          id: data.id,
          userName: data.email,
          name: data.firstname,
          surname: data.surname,
          middleName: data.middlename,
          emailAddress: data.email,
          dateOfBirth: dateOfBirth,
          height: parseFloat(data.height),
          weight: parseFloat(data.weight),
          gender: data.gender,
          phoneNumber: data.contactNumber,
          address: {
            streetLineOne: data.streetLineOne,
            streetLineTwo: "",
            stateOrRegion: "FL",
            city: "St Augustine",
            zipCode: data.zipCode,
            addressType: 0,
            isCurrent: true,
            latitude: 29.880033,
            longitude: -81.286286,
          },
        };
        const _data = encrypt(request, config.secret);
        return {
          url: "/api/services/app/Customer/CompleteProfile",
          method: "POST",
          credentials: "include",
          body: _data,
        };
      },
      invalidatesTags: ["User"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {
          console.log("error", error);
        }
      },
    }),
    uploadDocument: builder.mutation<{}, FormData>({
      query(data) {
        return {
          url: "/api/services/app/MedicalDocument/Upload",
          method: "POST",
          headers: {
            "content-type": "multipart/form-data",
          },
          credentials: "include",
          body: data,
        };
      },
      invalidatesTags: ["User"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {
          console.log("error", error);
        }
      },
    }),
    deleteDocument: builder.mutation<{}, { id: string }>({
      query(data) {
        return {
          url: "/api/services/app/MedicalDocument/Delete",
          method: "DELETE",
          credentials: "include",
          params: { Id: data.id },
        };
      },
      invalidatesTags: ["User"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {
          console.log("error", error);
        }
      },
    }),
  }),
});

export const {
  useUploadDocumentMutation,
  useCompleteProfileMutation,
  useDeleteDocumentMutation,
} = completeProfileAPI;
