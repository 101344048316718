import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box, Button, Container, useTheme } from "@mui/material";
import { images } from "../assets";
import { useBreakpoints } from "../hooks/useBreakpoints";
import config from "../config/config";

export default function Footer() {
  const { palette } = useTheme();
  const [breakPoints] = useBreakpoints();
  const { isMatchDownSM, isMatchDownMD } = breakPoints;
  return (
    <Box
      component="footer"
      sx={{ backgroundColor: palette.common.background, pt: 4 }}
    >
      <Box mx={{ xs: 2 }}>
        <Grid container>
          <Grid item xs={12} sm={0.4} />
          <Grid item xs={12} sm={2.6}>
            <Box>
              <Typography variant="h6" color="text.primary" gutterBottom>
                Quick Links
              </Typography>
            </Box>
            <Grid container>
              {config.quickLinks.map((quickLink, index) => {
                return (
                  <Grid key={index} item xs={6}>
                    <Button
                      variant="text"
                      sx={{
                        textAlign: "start",
                        minWidth: 0,
                        paddingX: 0,
                      }}
                    >
                      {quickLink.name}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5}>
            {isMatchDownSM ? (
              <Box sx={{ mt: 4, mb: 1 }}>
                <Box
                  component="img"
                  sx={{
                    height: 52,
                    width: 120,
                  }}
                  alt="MyPCT Logo."
                  src={images.app.logo}
                />
                <Box
                  component="img"
                  sx={{
                    height: 52,
                    width: 120,
                  }}
                  alt="Hippa Compliant"
                  src={images.app.hippaCompliant}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: isMatchDownMD ? 180 : 220,
                    width: isMatchDownMD ? 325 : 375,
                  }}
                  alt="Hippa Compliant"
                  src={images.app.hippaCompliant}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {!isMatchDownSM && (
                <Box
                  component="img"
                  sx={{
                    height: 89,
                    width: 208,
                  }}
                  alt="MyPCT Logo."
                  src={images.app.logo}
                />
              )}
              <Box>
                <Typography variant="body1" color={palette.common.black}>
                  Contact Details
                </Typography>
                <Typography variant="body2" color={palette.common.black}>
                  1818 Short Branch Dr Suite 102 Trinity, FL 34655-4425
                </Typography>
                <Typography variant="body2" color={palette.common.black}>
                  Email: support@patientcaretracker.com
                </Typography>
                <Typography variant="body2" color={palette.common.black}>
                  Phone: 1-833-697-2812
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={0.4} />
        </Grid>
      </Box>
      <Box sx={{ backgroundColor: "#CDE5FF", px: { xs: 2, md: 4 }, mt: 4 }}>
        <Typography variant="body2" color={palette.common.black}>
          © 1995-2023 MyPCT All rights reserved
        </Typography>
      </Box>
    </Box>
  );
}
