import { Box, Grid } from "@mui/material";
import Timeslot from "./Timeslot";
import { Controller, useFormContext } from "react-hook-form";

const TimeslotContainer = ({
  name,
  disabled = false,
  isEdit,
}: {
  name: string;
  disabled?: boolean;
  isEdit?: boolean;
}) => {
  const { control, setValue } = useFormContext();
  return (
    <Box
      sx={{ overflowY: "auto", maxHeight: "36vh", pr: 1 }}
      className="fixed-size"
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Box sx={{ display: "flex", flex: 1 }}>
            <Grid container spacing={2}>
              {value?.map((slot: any) => {
                return (
                  <Grid key={slot.key} item xs={4}>
                    <Timeslot
                      id={slot.key}
                      label={slot.value}
                      disabled={disabled}
                      isOccupied={slot.isOccupied}
                      onClick={(id: string) => {
                        const newSlots = value.map((item: any) => {
                          if (item.key === id) {
                            item.isOccupied = !item.isOccupied;
                          }
                          return item;
                        });
                        setValue("timeSlots", newSlots, {
                          shouldValidate: isEdit ? false : true,
                        });
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
      />
    </Box>
  );
};

export default TimeslotContainer;
