import { useMemo } from "react";
import { LoadingButton } from "@mui/lab";
import { TypeOf, number, object, string } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Divider, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { FormInputText } from "../../../components/form/FormInput";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import { useMask } from "@react-input/mask";
import { FormInputRadio } from "../../../components/form/FormInputRadio";
import { images } from "../../../assets";

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
);

const formSchema = object({
  name: string().min(1, "Name is required"),
  phone: string()
    .regex(phoneRegex, "Invalid Number!")
    .min(1, "Phone number is required"),
  email: string()
    .min(1, "Email address is required")
    .email("Email Address is invalid"),
  appointmentType: number().min(1, "Appointment Type is required"),
});

export type PatientFormType = TypeOf<typeof formSchema>;

type Props = { onClose: (formData?: PatientFormType) => void };

const PatientInfoForm = (props: Props) => {
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const inputRef = useMask({ mask: "+1 (___) ___ ____", replacement: "_" });

  const methods = useForm<PatientFormType>({
    resolver: zodResolver(formSchema),
    defaultValues: useMemo(
      () => ({ name: "", email: "", phone: "", appointmentType: 1 }),
      []
    ),
  });

  const submitHandler = (values: PatientFormType) => {
    props.onClose(values);
  };

  const OptionLabel = (icon: string, value: string) => {
    return (
      <Box display="flex" alignItems="center">
        <Box component="img" src={icon}></Box>
        <Typography variant="body2" pl={1}>
          {value}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant={isSM ? "h5" : "h3"}>
          Patient Information
        </Typography>
        <CloseIcon onClick={() => props.onClose()} />
      </Box>
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submitHandler)}>
          <FormInputText
            type="text"
            name="name"
            label="Name"
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <FormInputText
            name="phone"
            label="Phone Number"
            fullWidth
            sx={{ marginBottom: 2 }}
            inputRef={inputRef}
          />
          <FormInputText
            name="email"
            label="Email Address"
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <Divider sx={{ mb: 2 }} />
          <Typography variant="body2">Appointment type:</Typography>
          <FormInputRadio
            name="appointmentType"
            sx={{ flexDirection: "row", mb: 2, mt: 1 }}
            options={[
              {
                value: 1,
                label: OptionLabel(images.app.walkin, "Walk-in"),
              },
              {
                value: 2,
                label: OptionLabel(images.app.telephonic, "Telephonic"),
              },
            ]}
          />
          <LoadingButton
            loading={false}
            type="submit"
            variant="contained"
            fullWidth
          >
            Submit
          </LoadingButton>
        </form>
      </FormProvider>
    </>
  );
};

export default PatientInfoForm;
