import * as CryptoJS from "crypto-js";

const encrypt = <T>(cipherText: T, secret: string) => {
  let iv = CryptoJS.lib.WordArray.random(16);
  let data = JSON.stringify(cipherText);
  console.log("encrypt", data);
  const encrypted = CryptoJS.AES.encrypt(
    data,
    CryptoJS.enc.Utf8.parse(secret),
    { iv }
  ).ciphertext;
  const transitmessage = iv.concat(encrypted);

  return transitmessage.toString(CryptoJS.enc.Base64);
};

const decrypt = (transitmessage: any, secret: string) => {
  const ciphertext = CryptoJS.enc.Base64.parse(transitmessage);

  // Extract IV and ciphertext
  const iv = ciphertext.clone();
  iv.sigBytes = 16; // IV is 16 bytes
  iv.clamp();

  const encryptedText = ciphertext.clone();
  encryptedText.words.splice(0, 4); // Removing IV from the ciphertext
  encryptedText.sigBytes -= 16;

  // Decrypt the data using AES decryption
  const decrypted = CryptoJS.AES.decrypt(
    encryptedText.toString(CryptoJS.enc.Base64),
    CryptoJS.enc.Utf8.parse(secret),
    { iv }
  );
  return JSON.parse(CryptoJS.enc.Utf8.stringify(decrypted));
};

export { encrypt, decrypt };
