import React, { useState } from "react";
import CustomDateCalendar from "./DateCalendar";
import { Box, Typography } from "@mui/material";
import TimeCalendar from "./TimeCalendar";
import DateUtil, { dateUtil } from "../../../../utils/dateHelper";
import { Moment } from "moment";
import { useCalendarFunction } from "./useCalendarScheduleFunction";

const CalendarAppointment = () => {
  const { scheduleData, isFetching, getScheduleByDate } = useCalendarFunction();

  const [currentDate, setCurrentDate] = useState(dateUtil.getToday());
  const onChangeDate = (newDate: Moment) => {
    setCurrentDate(newDate);
    getScheduleByDate({
      scheduleDate: dateUtil.formatDate(
        newDate,
        DateUtil.DateFormats.YEAR_MONTH_DATE
      ),
    });
  };
  return (
    <Box>
      <Typography sx={{ p: 4, pb: 0 }} variant="h4" color={"#455A64"}>
        My Calendar
      </Typography>
      <Box
        sx={{
          my: 4,
          mx: {
            md: 1,
            lg: 4,
          },
        }}
      >
        <CustomDateCalendar date={currentDate} onChange={onChangeDate} />
      </Box>
      <Box sx={{ mx: 4 }}>
        <Typography>
          {dateUtil.formatDate(currentDate, DateUtil.DateFormats.DAY_DATE)}
        </Typography>
        <TimeCalendar scheduleData={scheduleData} isFetching={isFetching} />
      </Box>
    </Box>
  );
};

export default CalendarAppointment;
