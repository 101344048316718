import { createApi } from "@reduxjs/toolkit/query/react";
import {
  setProfilePicture,
  setUser,
  updateProfileState,
} from "../../app/store";
import {
  IProvider,
  IResponseProvider,
  IResponseUser,
  IUser,
} from "./auth/authtypes";
import baseQueryWithReauth from "../../app/store/slices/apiSlice";
import { getGender } from "../../utils/serviceHelper";

export const cmToFeetAndInches = (cm: number | null) => {
  if (cm === null || cm <= 30) {
    return null;
  }
  const infoot = (cm * 0.3937) / 12;
  const feet = Math.floor(infoot);
  const remainingInches = Math.round((infoot - feet) * 12);
  return `${feet}' ${remainingInches}"`;
};

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["User", "Provider", "Profile Picture"],
  endpoints: (builder) => ({
    getMe: builder.query<IUser, null>({
      query() {
        return {
          url: "/api/services/app/Customer/GetProfile",
          method: "GET",
          credentials: "include",
        };
      },
      providesTags: ["User"],
      transformResponse: (result: { result: IResponseUser }) => {
        const {
          name,
          emailAddress,
          profileImage,
          roleNames,
          gender,
          height,
          insurance,
          notes,
          ...rest
        } = result.result;
        return {
          firstName: name,
          email: emailAddress,
          domainPic: profileImage,
          role: roleNames,
          gender: getGender(gender),
          height: cmToFeetAndInches(height),
          _height: height,
          _gender: gender,
          insuranceNumber: insurance?.insuranceIdNo ?? "",
          insuranceProvider: insurance?.insuranceProvider ?? "",
          insuranceNotes: notes ?? "",
          ...rest,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
          dispatch(updateProfileState(data.profileStep));
          await dispatch(userApi.endpoints.getProfilePicture.initiate(null));
          setTimeout(() => {
            if (data.profileStep == 0 || data.profileStep == 1) {
              window.location.replace("/complete-profile");
            } else {
              window.location.replace("/");
            }
          }, 1);
        } catch (error) {
          //Need to retry and if not successful logout
          console.log(error);
        }
      },
    }),
    getMeAsProvider: builder.query<IProvider, null>({
      query() {
        return {
          url: "/api/services/app/Provider/GetProfile",
          method: "GET",
          credentials: "include",
        };
      },
      providesTags: ["Provider"],
      transformResponse: (result: { result: IResponseProvider }) => {
        const {
          name,
          surname,
          emailAddress,
          profileImage,
          roleNames,
          gender,
          ...rest
        } = result.result;
        return {
          fullName: name + " " + surname,
          firstName: name,
          surname: surname,
          email: emailAddress,
          domainPic: profileImage,
          role: roleNames,
          gender: getGender(gender),
          _gender: gender,
          ...rest,
        };
      },
      forceRefetch: () => true,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
          dispatch(updateProfileState(data.profileStep));
          setTimeout(() => {
            if (
              data?.profileStep == 0 ||
              (data?.profileStep >= 2 && data?.profileStep < 5)
            ) {
              window.location.replace("/provider/complete-profile");
            } else {
              window.location.replace("/provider");
            }
          }, 1);
        } catch (error) {
          //Need to retry and if not successful logout
          console.log(error);
        }
      },
    }),
    uploadProfilePicture: builder.mutation<{}, FormData>({
      query(data) {
        return {
          url: "/api/services/app/Customer/UploadPicture",
          method: "POST",
          headers: {
            "content-type": "multipart/form-data",
          },
          credentials: "include",
          body: data,
        };
      },
      invalidatesTags: ["Profile Picture"],
    }),
    deleteProfilePicture: builder.mutation<{}, null>({
      query(data) {
        return {
          url: "/api/services/app/Customer/ProfilePicture",
          method: "DELETE",
          credentials: "include",
        };
      },
      invalidatesTags: ["Profile Picture"],
    }),
    getProfilePicture: builder.query<any, any>({
      query() {
        return {
          url: "/api/services/app/Customer/GetProfilePicture",
          method: "GET",
          credentials: "include",
        };
      },
      providesTags: ["Profile Picture"],
      transformResponse: (result: { result: string }) => {
        return {
          profilePicture: result.result,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setProfilePicture(data.profilePicture));
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const {
  useUploadProfilePictureMutation,
  useGetProfilePictureQuery,
  useLazyGetProfilePictureQuery,
  useDeleteProfilePictureMutation,
  useGetMeAsProviderQuery,
} = userApi;
