import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import UpcomingAppointments from "./UpcomingAppointment";
import CustomTabPanel, { addIdProps } from "../../../components/CustomTabPanel";
import PastAppointment from "./PastAppointments";

const tabs = [
  {
    title: "provider.patient.appointment.tabs.0",
    element: <UpcomingAppointments />,
  },
  {
    title: "provider.patient.appointment.tabs.1",
    element: <PastAppointment />,
  },
];

export default function Appointments() {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Box sx={{ mt: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} scrollButtons={false}>
            {tabs.map((tab) => (
              <Tab
                key={tab.title}
                label={t(tab.title)}
                {...addIdProps(tab.title)}
                wrapped
              />
            ))}
          </Tabs>
        </Box>
        {tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {value === index && tab.element}
          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
}
