import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type PopupState = {
  message: string;
  type?: "success" | "error" | "alert" | "info";
  status: boolean;
};

const initialState: PopupState = {
  message: "",
  status: false,
  type: "success",
};

const popupSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setPopState: (state, action: PayloadAction<PopupState>) => {
      state.message = action.payload.message;
      state.status = action.payload.status;
      state.type = action.payload.type;
    },
  },
});

export const popUpReducer = popupSlice.reducer;
export const popUpActions = popupSlice.actions;
