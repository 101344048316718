import { Box, Divider, Grid, Typography } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import { Fragment, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { selectCurrentUser } from "../../../app/store/slices/userSlice";
import { images } from "../../../assets";
import CenteredBox from "../../../components/CenteredBox";
import Map, { LatLng } from "../../../components/Map";
import PhysicianCard from "../../../components/PhysicianCard";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import { useAppSelector } from "../../../hooks/useStoreHook";
import AdvanceFilter, { FormValues } from "./AdvanceFilter";
import classes from "./PhysiciansByArea.module.css";
import PhysiciansByAreaHeader from "./PhysiciansByAreaHeader";
import {
  FilterProviderPayload,
  useGetPhysiciansMutation,
} from "./physiciansByAreaAPI";
import { useIsExplorePhysician } from "./useIsExplorePhysician";

const DROPDOWN_DATA: Array<{ id: string; value: string }> = [
  { id: "16", value: "Radius: 1 mile" },
  { id: "15", value: "Radius: 2 miles" },
  { id: "14", value: "Radius: 4 miles" },
];

const RADIUS_TO_DISTANCE: { [s: string]: number } = {
  "16": 1, // 1 mile
  "15": 2, // 2 mile
  "14": 4, // 4 mile
};

const THAMPA_COORDS: LatLng = { lat: 27.964157, lng: -82.452606 };

const PhysiciansByArea = () => {
  const [getPhysicians, results] = useGetPhysiciansMutation();
  const navigate = useNavigate();
  const isDetailedView = useIsExplorePhysician();
  const [selected, setSelected] = useState(DROPDOWN_DATA[0]);
  const [position, setPosition] = useState<LatLng>(THAMPA_COORDS);
  const [filter, setFilter] = useState(false);
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const user = useAppSelector(selectCurrentUser);

  const { isLoading, isError, data } = results;

  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#307df6",
    backgroundColor: "#fff",
    "&:hover": { backgroundColor: "#f1f1f1" },
    boxShadow: "0px 1px 2px 0px #0000004d",
    margin: "16px",
    borderRadius: 40,
    padding: "12px 24px",
  }));
  const maxResultCount = isDetailedView ? 20 : 6;
  const centerdBoxHeight = isDetailedView || isSM ? 200 : 400;

  useEffect(() => {
    function callGetPhysicians(coords?: GeolocationCoordinates) {
      const payload: FilterProviderPayload = {
        filterByDate: "",
        filterByHour: "",
        filterByMinute: "",
        latitude: coords ? coords.latitude : position.lat,
        longitude: coords ? coords.longitude : position.lng,
        maxResultCount,
        radius: RADIUS_TO_DISTANCE[selected.id],
        skipCount: 0,
        zipCode: "",
      };
      getPhysicians(payload);
    }
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        setPosition({ lat: coords.latitude, lng: coords.longitude });
        callGetPhysicians(coords);
      },
      () => {
        callGetPhysicians();
      }
    );
  }, [getPhysicians, selected.id, maxResultCount, user]);

  const handleChange = (value: { id: string; value: string }) => {
    setSelected(value);
  };

  const handleApplyfilter = (data: FormValues) => {
    const payload: FilterProviderPayload = {
      filterByDate: data.date?.toISOString() ?? "",
      filterByHour: data.hour ?? "",
      filterByMinute: data.minute ?? "",
      maxResultCount,
      radius: 100,
      skipCount: 0,
      zipCode: data.zipcode,
    };
    getPhysicians(payload);
  };

  const handleClearFilter = () => {
    const payload: FilterProviderPayload = {
      filterByDate: "",
      filterByHour: "",
      filterByMinute: "",
      latitude: position ? position.lat : 0,
      longitude: position ? position.lng : 0,
      maxResultCount,
      radius: RADIUS_TO_DISTANCE[selected.id],
      skipCount: 0,
      zipCode: "",
    };
    getPhysicians(payload);
  };

  let containerClass = classes.container;
  let locations: LatLng[] = [];
  let physicians: JSX.Element | null = null;

  if (data) {
    locations = data.items.map(({ latitude, longitude }) => {
      return { lat: latitude, lng: longitude };
    });
    if (data.items.length === 0) {
      physicians = (
        <CenteredBox height={centerdBoxHeight}>
          <Typography variant="body1">
            No Physicians found. Please try again.
          </Typography>
        </CenteredBox>
      );
    } else {
      physicians = (
        <Grid container marginY={isDetailedView && !isSM ? 4 : 0}>
          {data.items.map(({ id, ...rest }, index) => {
            return (
              <Grid key={id} item md={isDetailedView ? 4 : 6} xs={12}>
                <PhysicianCard
                  key={index}
                  salutation={"Dr."}
                  name={rest.fullName}
                  field={rest.profileTitle}
                  picPath={rest.profileImage ?? ""}
                  rating={rest.ratings}
                  reviewCount={rest.noOfRatings}
                  onClick={() => navigate(`/${id}/physician`)}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    }
  }

  let detailedView = (
    <Fragment>
      <Divider orientation="vertical" sx={{ borderWidth: "1px" }} />
      <Grid container flexDirection={isSM ? "column-reverse" : "row"}>
        <Grid
          item
          md={7}
          sm={12}
          position="relative"
          paddingBottom={isSM ? 8 : 2}
        >
          {physicians}
          {!isDetailedView && (
            <div className={classes.buttonContainer}>
              <ColorButton onClick={() => navigate("/explore-physicians")}>
                <Typography variant="body1">
                  {"Explore Physicians >"}
                </Typography>
              </ColorButton>
            </div>
          )}
        </Grid>
        <Grid item md={5} sm={12} padding={isSM ? 0 : 2} pb={2}>
          <Box sx={{ height: 420 }}>
            {position && !filter ? (
              <Map
                position={position}
                radius={+selected.id}
                nearbyCoords={locations}
                style={{ borderRadius: isSM ? 0 : 16 }}
              />
            ) : (
              <AdvanceFilter
                onApplyFilter={handleApplyfilter}
                onClearFilter={handleClearFilter}
              />
            )}
            {isSM && <Divider sx={{ pt: 1 }} />}
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );

  if (isLoading) {
    physicians = (
      <CenteredBox height={centerdBoxHeight}>
        <CircularProgress size={80} />
      </CenteredBox>
    );
  }

  if (isError) {
    physicians = (
      <CenteredBox height={centerdBoxHeight}>
        <Typography variant="body1">
          Some error occured while fetching nearby physicians.
        </Typography>
      </CenteredBox>
    );
  }

  if (isDetailedView) {
    containerClass = classes.detailedContainer;
    detailedView = (
      <Fragment>
        <Grid container alignItems="center" padding={isSM ? 0 : 2}>
          {!isSM && (
            <Grid
              item
              md={4}
              sm={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                component="img"
                sx={{ height: "100%" }}
                alt="Doctors Image"
                src={images.app.doctors}
              />
            </Grid>
          )}
          <Grid
            item
            md={4}
            sm={12}
            padding={isSM ? "12px" : 0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            {position && (
              <Map
                width={isSM ? "92vw" : "100%"}
                position={position}
                radius={+selected.id}
                nearbyCoords={locations}
                style={{ borderRadius: 16 }}
              />
            )}
          </Grid>
          <Grid
            item
            md={4}
            sm={12}
            height={isSM ? "100%" : 420}
            width="100%"
            padding={isSM ? "8px 8px 0px 8px" : "0px 16px"}
          >
            <AdvanceFilter
              selected={selected}
              dropdownData={DROPDOWN_DATA}
              onDropdownChange={handleChange}
              onApplyFilter={handleApplyfilter}
              onClearFilter={handleClearFilter}
            />
          </Grid>
        </Grid>
        {isSM && <Divider />}
        {physicians}
      </Fragment>
    );
  }

  return (
    <Grid container direction={isSM ? "column" : "row"}>
      <Grid item xs={12} sm={0.4} />
      <Grid item xs={12} sm={11.2}>
        <Box component={"div"} className={containerClass} mx={{ xs: 2 }}>
          <PhysiciansByAreaHeader
            totalCount={data?.totalCount ?? 0}
            isDropdown={!!position}
            selected={selected}
            dropdownData={DROPDOWN_DATA}
            onDropdownChange={handleChange}
            onFilterCLick={() => setFilter(!filter)}
          />
          {detailedView}
        </Box>
      </Grid>
      <Grid item xs={12} sm={0.4} />
    </Grid>
  );
};

export default PhysiciansByArea;
