import { ReactNode } from "react";
import { Grid, Typography } from "@mui/material";

import classes from "../ProfileSetting.module.css";

const ProfileLabelValue = (props: ProfileLabelValueProps) => {
  return (
    <Grid container direction="row" alignItems="center" marginY={2}>
      <Grid item md={3}>
        <Typography className={classes["title-width"]} variant="body1">
          {props.label}:
        </Typography>
      </Grid>
      <Grid item md={9}>
        {props.CustomeValue ?? (
          <Typography variant="body2">
            {props.value ?? "Not Provided"}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default ProfileLabelValue;

interface ProfileLabelValueProps {
  label: string;
  value?: string | null;
  CustomeValue?: ReactNode;
}
