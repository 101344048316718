import { Typography } from "@mui/material";
import classes from "./BookingSlots.module.css";

export type SlotType = "available" | "selected" | "occupied";

interface Props {
  type: SlotType;
  slotNumber: number;
  slotTime: string;
  isStart?: boolean;
  isEnd?: boolean;
  onClick?: () => void;
}

const Slot = (props: Props) => {
  const { type, isStart, isEnd, slotNumber, slotTime } = props;

  const handleClick = () => {
    if (props.onClick) props.onClick();
  };

  return (
    <div
      className={classes.slotContainer}
      style={{ cursor: type === "occupied" ? "not-allowed" : "pointer" }}
      onClick={handleClick}
    >
      <div className={`${classes.slot} ${classes[type]}`}>
        {isEnd && <div className={classes.slotTopLine}></div>}
        {!isEnd && !isStart && <div className={classes.slotTopLine}></div>}
        <div className={classes.slotDot}></div>
        {!isStart && !isEnd && <div className={classes.slotBottomLine}></div>}
        {isStart && <div className={classes.slotBottomLine}></div>}
        <Typography
          variant="body3"
          style={{ display: "inline-block" }}
          paddingRight={1}
        >
          Slot {slotNumber}:
        </Typography>
        <Typography variant="body2" style={{ display: "inline-block" }}>
          {slotTime}
        </Typography>
      </div>
    </div>
  );
};

export default Slot;
