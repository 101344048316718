import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ForgotInput, forgotSchema } from "./forgotSchema";
import { submitForReset, useUserOTPMutation } from "../../../../app/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../hooks/useStoreHook";
import {
  getErrorMessage,
  isErrorWithMessage,
  isFetchBaseQueryError,
} from "../../../../utils/serviceHelper";
import { useToast } from "../../../../hooks/useToast";

export function useForgotFunction(props: any) {
  const methods = useForm<ForgotInput>({
    resolver: zodResolver(forgotSchema),
  });

  const [getUserOTP, { isLoading, isError, error, isSuccess }] =
    useUserOTPMutation();

  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const { displayToast } = useToast();

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    if (isSuccess) {
      displayToast({
        message: "OTP has been send to the email.",
        type: "success",
      });
    }
    if (isError) {
      const errorMessage = getErrorMessage(error);
      displayToast({ message: errorMessage, type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const getOTP: SubmitHandler<ForgotInput> = (values) => {
    // 👇 Executing the getUserOTP
    setMinutes(0);
    setSeconds(30);
    console.log("getOTP", { values });
    getUserOTP(values);
  };

  const onSubmitHandler: SubmitHandler<ForgotInput> = (payload) => {
    if (payload.otp === undefined || payload.otp?.trim()?.length === 0) {
      methods.setError("otp", { type: "custom", message: "OTP is required" });
    } else {
      console.log("payload", payload);
      // 👇 Executing the forgotPassword Mutation
      dispatch(submitForReset(payload));
      navigate("/auth/reset");
    }
  };

  const { t } = useTranslation();

  return {
    seconds,
    minutes,
    methods,
    t,
    handleSubmit,
    getOTP,
    onSubmitHandler,
    isLoading,
    isError,
    error,
    isSuccess,
  };
}
