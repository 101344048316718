import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import SearchIcon from "@mui/icons-material/Search";
import LocationIcon from "@mui/icons-material/LocationOnOutlined";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import ClockIcon from "@mui/icons-material/AccessTime";

import { useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import classes from "./AdvanceFilter.module.css";
import { FormInputDate } from "../../../components/form/FormDate";
import FormIconInput from "../../../components/form/FormIconInput";
import FormTimeInput from "../../../components/form/FormTimeInput";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import Dropdown, { DropdownValue } from "../../../components/Dropdown";

export type FormValues = {
  zipcode: string;
  date: Date | null;
  hour: string;
  minute: string;
};

interface Props {
  onApplyFilter: SubmitHandler<FormValues>;
  onClearFilter: () => void;
  selected?: DropdownValue;
  dropdownData?: Array<DropdownValue>;
  onDropdownChange?: (value: DropdownValue) => void;
}

const AdvanceFilter = (props: Props) => {
  const { onApplyFilter, onClearFilter } = props;

  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const form = useForm<FormValues>({
    defaultValues: useMemo(() => {
      return { zipcode: "", date: null, hour: "", minute: "" };
    }, []),
  });

  const { handleSubmit, reset } = form;

  const handleClearFilter = () => {
    reset();
    onClearFilter();
  };

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton aria-label="location" edge="end">
        <SearchIcon />
      </IconButton>
    </InputAdornment>
  );

  let header = (
    <Typography variant="subtitle2" paddingBottom={2}>
      Search provider in your area
    </Typography>
  );

  if (props.dropdownData) {
    header = (
      <Box paddingBottom={2}>
        <Dropdown
          id="map-radius-dropdown"
          value={props.selected!}
          data={props.dropdownData}
          onChange={props.onDropdownChange!}
        />
      </Box>
    );
  }

  return (
    <FormProvider {...form}>
      <form className={classes.form} onSubmit={handleSubmit(onApplyFilter)}>
        {header}
        <Typography variant="body1" paddingBottom={2}>
          Search by Zipcode:
        </Typography>
        <div className={classes.zipcodeContainer}>
          <LocationIcon />
          <FormIconInput
            name="zipcode"
            label="Search by Zipcode"
            style={{ marginLeft: 8 }}
            endAdornment={endAdornment}
          />
        </div>

        <Typography variant="body1" paddingBottom={2}>
          Search Physicians by available slots:
        </Typography>
        <div
          style={{ display: "flex", flexDirection: isSM ? "column" : "row" }}
        >
          <div className={classes.dateContainer}>
            <CalendarIcon />
            <FormInputDate name="date" sx={{ marginLeft: 1, width: "100%" }} />
          </div>
          <div className={classes.hourContainer}>
            <ClockIcon />
            <FormTimeInput style={{ marginLeft: 8 }} />
          </div>
        </div>
        <div className={classes.footer}>
          {!isSM && <Divider />}
          <div className={classes.buttonContainer}>
            <Box marginRight={2}>
              <Button
                sx={{ width: 100 }}
                type="button"
                variant="outlined"
                onClick={handleClearFilter}
              >
                Clear
              </Button>
            </Box>
            <LoadingButton
              sx={{ width: 100 }}
              type="submit"
              variant="contained"
              loading={false}
            >
              Search
            </LoadingButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default AdvanceFilter;
