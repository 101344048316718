import Grid from "@mui/material/Grid";
import { images } from "../assets";
import { Box, Container, useTheme } from "@mui/material";
import useAppBarRemainingHeight from "../hooks/useAppBarRemainingHeight";

export default function AuthLayout(props: any) {
  const { users } = images;
  const { children } = props;
  const appBarHeight = useAppBarRemainingHeight();
  return (
    <Grid
      container
      direction="row"
      alignItems={"stretch"}
      sx={{
        height: appBarHeight,
        backgroundImage: `url(${users.background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Grid item xs={12} sm={8} md={1}></Grid>
      <Grid item xs={12} sm={8} md={5}>
        <Container>{children}</Container>
      </Grid>
      <Grid item xs={12} sm={4} md={6}>
        <Grid
          container
          sx={{
            height: "100%",
          }}
          alignContent={"center"}
          justifyContent={"center"}
        >
          <Box
            overflow={"hidden"}
            component="img"
            alt="The house from the offer."
            src={users.signupDoctor}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
