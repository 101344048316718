import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "../../../app/store/slices/apiSlice";

export const profilePictureAPI = createApi({
  reducerPath: "profilePictureAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getProfilePic: builder.query<string, string>({
      query: (payload) => ({
        url: `/api/services/app/Profile/Picture/${encodeURIComponent(payload)}`,
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
});

export const { useLazyGetProfilePicQuery } = profilePictureAPI;
