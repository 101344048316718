import Box from "@mui/material/Box";
import {
  Icon,
  IconButton,
  InputAdornment,
  Container,
  Typography,
  Button,
} from "@mui/material";
import { useSignupFunction } from "./useSignupFunction";
import { FormProvider } from "react-hook-form";
import { FormInputText } from "../../../../components/form/FormInput";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import TermsAndConditionModal from "./TermsAndConditionModal";

interface SignupFormProps {
  index: number;
}

function SignupForm(props: SignupFormProps) {
  const {
    isLoading,
    showPassword,
    methods,
    t,
    handleSubmit,
    onSubmitHandler,
    handleShowPasswordClick,
  } = useSignupFunction(props);

  const [open, setOpen] = useState(false);

  return (
    <Container sx={{ mt: 2 }}>
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmitHandler)}
          sx={{ mt: 1 }}
        >
          <FormInputText
            sx={{
              border: "none",
              backgroundColor: "transparent",
            }}
            color="primary"
            variant="outlined"
            size="medium"
            margin="normal"
            type="text"
            label={t("auth.signup.firstname")}
            fullWidth
            name="firstname"
            required
          />
          <FormInputText
            sx={{
              border: "none",
              backgroundColor: "transparent",
            }}
            color="primary"
            variant="outlined"
            size="medium"
            margin="normal"
            type="text"
            label={t("auth.signup.middlename")}
            fullWidth
            name="middlename"
          />
          <FormInputText
            sx={{
              border: "none",
              backgroundColor: "transparent",
            }}
            color="primary"
            variant="outlined"
            size="medium"
            margin="normal"
            type="text"
            label={t("auth.signup.surname")}
            fullWidth
            name="surname"
            required
          />
          <FormInputText
            sx={{
              border: "none",
              backgroundColor: "transparent",
            }}
            color="primary"
            variant="outlined"
            size="medium"
            margin="normal"
            type="email"
            label={t("auth.email")}
            fullWidth
            name="email"
            required
          />
          <FormInputText
            sx={{
              border: "none",
              backgroundColor: "transparent",
            }}
            color="primary"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleShowPasswordClick}
                    aria-label="toggle password visibility"
                  >
                    <Icon>
                      {showPassword ? "visibility_off" : "visibility"}
                    </Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={t("auth.password")}
            size="medium"
            margin="normal"
            name="password"
            required
            fullWidth
            autoComplete="current-password"
          />
          <FormInputText
            sx={{
              border: "none",
              backgroundColor: "transparent",
            }}
            color="primary"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleShowPasswordClick}
                    aria-label="toggle password visibility"
                  >
                    <Icon>
                      {showPassword ? "visibility_off" : "visibility"}
                    </Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={t("auth.signup.confirmPassword")}
            size="medium"
            margin="normal"
            name="passwordConfirm"
            required
            fullWidth
            autoComplete="current-password"
          />
          <LoadingButton
            loading={isLoading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("auth.signup.button")}
          </LoadingButton>
        </Box>
      </FormProvider>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body2">
          By continuing, you agree to MyPCT Colonoscopy’s
        </Typography>
        <Button variant="text" onClick={() => setOpen(true)}>
          Terms & Conditions , Privacy policy
        </Button>
      </Box>
      <TermsAndConditionModal isOpen={open} onClose={() => setOpen(false)} />
    </Container>
  );
}

export default SignupForm;
