import { Box } from "@mui/material";
import UpcomingAppointmentsList from "./UpcomingAppointments";
import "../../../../components/virtualized-list/virtualize.css";
import { useBreakpoints } from "../../../../hooks/useBreakpoints";

const UpcomingAppointments = () => {
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  return (
    <Box
      sx={{
        overflowY: "auto",
        minHeight: "30vh",
        maxHeight: isSM ? "60vh" : "120vh",
      }}
      className="fixed-size"
    >
      <UpcomingAppointmentsList />
    </Box>
  );
};

export default UpcomingAppointments;
