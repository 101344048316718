import { Fragment } from "react";
import Header from "../components/Header";
import { Outlet } from "react-router-dom";
import { decrypt, encrypt } from "../utils/codec";

export function AuthRootLayout() {
  return (
    <Fragment>
      <Header />
      <Outlet />
    </Fragment>
  );
}
