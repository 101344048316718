import { Chip, Stack, StackProps } from "@mui/material";

import MilitaryTechOutlinedIcon from "@mui/icons-material/MilitaryTechOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

interface AwardDetailsProps extends StackProps {
  awards: string[];
  isDeletable?: boolean;
  onDelete?: (id: string) => void;
}

const Awards = (props: AwardDetailsProps) => {
  const { awards, isDeletable = true, ...stackProps } = props;
  const { onDelete = () => {} } = props;

  return (
    <Stack direction="row" spacing={1} {...stackProps} flexWrap="wrap">
      {awards.map((award) => {
        if (isDeletable) {
          return (
            <Chip
              key={award}
              variant="outlined"
              label={award}
              style={{ margin: 4 }}
              deleteIcon={<ClearOutlinedIcon />}
              onDelete={() => onDelete(award)}
              icon={<MilitaryTechOutlinedIcon color="primary" />}
            />
          );
        }
        return (
          <Chip
            key={award}
            variant="outlined"
            label={award}
            style={{ margin: 4 }}
            icon={<MilitaryTechOutlinedIcon color="primary" />}
          />
        );
      })}
    </Stack>
  );
};

export default Awards;
