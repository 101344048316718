import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "../../app/store/slices/apiSlice";

export const providerAPI = createApi({
  reducerPath: "providerAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Appointment Notes"],
  endpoints: (builder) => ({}),
});

export const {} = providerAPI;
