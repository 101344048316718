import React from "react";
import { Box } from "@mui/material";

interface Props {
  height?: number | string;
  children: React.ReactNode;
}

const CenteredBox = ({ children, height = 500 }: Props) => {
  return (
    <Box
      height={height}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      {children}
    </Box>
  );
};

export default CenteredBox;
