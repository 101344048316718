import {
  Checkbox,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

type IFormInputProps = {
  name: string;
  label: string;
  sx?: SxProps<Theme> | undefined;
  inlineSx?: SxProps<Theme> | undefined;
  withLabel?: boolean;
  options: OptionProps[];
} & FormControlProps;

type OptionProps = {
  value: string | number;
  label: string;
  disabled?: boolean;
};

export const FormMultiSelectDropdown: React.FC<IFormInputProps> = ({
  name,
  label,
  options,
  sx,
  inlineSx,
  withLabel = false,
  disabled = false,
  ...otherFormControlProps
}) => {
  const { control } = useFormContext();
  const generateSingleOptions = (value: any) => {
    return options.map((option: OptionProps) => {
      const isDisabled = !!option.disabled;
      return (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={isDisabled}
          sx={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
        >
          <Checkbox
            disabled={isDisabled}
            checked={value.indexOf(option.value as string) > -1}
          />
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl {...otherFormControlProps}>
      {withLabel && <InputLabel sx={inlineSx}>{label}</InputLabel>}
      <Controller
        defaultValue={[]}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <Select
              disabled={disabled}
              value={value}
              onChange={onChange}
              label={withLabel ? label : ""}
              multiple
              renderValue={(selected) => selected.join(", ")}
              sx={sx}
            >
              {generateSingleOptions(value)}
            </Select>
            {error ? (
              <FormHelperText error>{error?.message}</FormHelperText>
            ) : null}
          </>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};
