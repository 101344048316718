import { Box, Button, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { resetColonoscopyRequired } from "../../app/store/slices/checkColonoscopyRequired";
import { images } from "../../assets";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { useAppDispatch, useAppSelector } from "../../hooks/useStoreHook";
import { useTranslation } from "react-i18next";

const QuestionnaireResponse = ({
  changeStep,
  handleClose,
  reset,
}: {
  changeStep: (step: number) => void;
  handleClose: () => void;
  reset: any;
}) => {
  const { doctorMobile, doctorMobile2 } = images.app;

  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const { palette } = useTheme();

  const checkColonscopyRequired = useAppSelector(
    (state) => state.checkColonscopyRequired
  );
  const mobileImg = checkColonscopyRequired?.isRequired
    ? doctorMobile
    : doctorMobile2;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ display: "flex", position: "relative" }}>
        <Box
          component="img"
          alt="Gastro image"
          src={isSM ? mobileImg : images.app.doctor}
        />
        {!isSM && (
          <Box
            sx={{
              position: "absolute",
              top: 60,
              left: 240,
            }}
          >
            <Box
              sx={{
                bgcolor: "#455A64",
                width: 200,
                p: 1,
                position: "relative",
                borderRadius: 2,
                ":before": {
                  content: '" "',
                  position: "absolute",
                  right: "100%",
                  top: "26px",
                  width: 0,
                  height: 0,
                  borderTop: "13px solid transparent",
                  borderRight: "26px solid #455A64",
                  borderBottom: "13px solid transparent",
                },
              }}
            >
              <Typography variant="body2" color={palette.common.white}>
                {checkColonscopyRequired?.isRequired
                  ? t("patient.colonoscopy.yes.content")
                  : t("patient.colonoscopy.no.content")}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          mt: isSM ? 8 : 2,
        }}
      >
        <Button
          variant="outlined"
          sx={{ flex: 1 }}
          onClick={() => {
            dispatch(resetColonoscopyRequired({}));
            reset({ questions: {} });
            changeStep(-1);
          }}
        >
          Retake
        </Button>
        <Button
          variant="contained"
          sx={{ ml: 2, flex: 1 }}
          onClick={() => {
            handleClose();
            navigate("/explore-physicians");
          }}
        >
          Search Providers
        </Button>
      </Box>
    </>
  );
};

export default QuestionnaireResponse;
