import {
  Icon,
  IconButton as CIconButton,
  useTheme,
  SxProps,
  Typography,
  TypographyVariant,
  CircularProgress,
} from "@mui/material";

type _IconButtonProps = {
  icon: string;
  title?: string;
  onClick: any;
  variant?: "primary" | "secondary";
  sx?: SxProps;
  iconSx?: SxProps;
  titleSx?: SxProps;
  titleVariant?: TypographyVariant;
  disabled?: boolean;
  isLoading?: boolean;
};

const IconButton = ({
  icon,
  title,
  onClick,
  variant = "primary",
  sx,
  iconSx,
  titleSx,
  titleVariant,
  disabled = false,
  isLoading = false,
}: _IconButtonProps) => {
  const { palette } = useTheme();
  return (
    <CIconButton
      disabled={disabled}
      onClick={onClick}
      sx={
        variant === "primary"
          ? {
              bgcolor: palette.primary.main,
              "&:hover": {
                background: palette.primary.light,
              },
              ...sx,
            }
          : { ...sx }
      }
    >
      {isLoading ? (
        <CircularProgress
          sx={iconSx}
          size={(iconSx as any)?.fontSize ?? 24}
          color="inherit"
        />
      ) : (
        <Icon sx={iconSx}>{icon}</Icon>
      )}
      {title && (
        <Typography sx={titleSx} variant={titleVariant}>
          {title}
        </Typography>
      )}
    </CIconButton>
  );
};

export default IconButton;
