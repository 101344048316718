import { Box, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Labeler from "../../../../components/Labeler";
import LabelWithIcon from "../../../../components/LabelWithIcon";
import {
  EmailOutlined,
  LocationOnOutlined,
  PhoneOutlined,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import DateUtil, { dateUtil } from "../../../../utils/dateHelper";
import { getGender } from "../../../../utils/serviceHelper";
import { cmToFeetAndInches } from "../../../user/userAPI";
import PatientDetailSkeleton from "./PatientDetailSkeleton";
import DataUtil from "../../../../utils/dataHelper";

const PatientDetail = ({
  patientData,
  isLoading,
}: {
  patientData: any;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  if (isLoading) {
    return <PatientDetailSkeleton />;
  }
  return (
    <Box>
      <Typography variant="body1" sx={{ mb: 1 }}>
        {t("patient.profile.generalDetails.personal.title")}
      </Typography>
      <Box
        flexDirection={"row"}
        display={"flex"}
        sx={{
          justifyContent: "space-between",
          my: 1,
        }}
      >
        <Labeler
          label={t("patient.profile.generalDetails.personal.name")}
          value={patientData?.fullName ?? ""}
          labelerVariant="body2"
          valueVariant="body1"
          sx={{ display: "flex" }}
          valueSxProps={{ ml: 1 }}
        />
        <Labeler
          label={t("patient.profile.generalDetails.personal.birthDate")}
          value={
            patientData?.dateOfBirth
              ? dateUtil.formatDate(
                  patientData?.dateOfBirth,
                  DateUtil.DateFormats.MONTH_DATE_YEAR
                )
              : ""
          }
          labelerVariant="body2"
          valueVariant="body1"
          sx={{ display: "flex" }}
          valueSxProps={{ ml: 1 }}
        />
        <Labeler
          label={t("patient.profile.generalDetails.personal.gender")}
          value={patientData ? getGender(Number(patientData?.gender)) : ""}
          labelerVariant="body2"
          valueVariant="body1"
          sx={{ display: "flex" }}
          valueSxProps={{ ml: 1 }}
        />
        <Box />
        <Box />
        <Box />
        <Box />
      </Box>
      <Box
        flexDirection={"row"}
        display={"flex"}
        sx={{
          justifyContent: "space-between",
          my: 1,
        }}
      >
        <LabelWithIcon
          icon={
            <EmailOutlined
              sx={{
                width: 20,
                height: 24,
              }}
            />
          }
          boxSx={{ height: 24 }}
          iconSx={{ paddingLeft: 0 }}
          value={patientData?.emailAddress ?? ""}
          valueVariant="body1"
        />
        <LabelWithIcon
          icon={
            <PhoneOutlined
              sx={{
                width: 20,
                height: 24,
              }}
            />
          }
          boxSx={{ height: 24 }}
          value={patientData?.phoneNumber ?? ""}
          valueVariant="body1"
        />
        <LabelWithIcon
          icon={
            <LocationOnOutlined
              sx={{
                width: 20,
                height: 24,
              }}
            />
          }
          boxSx={{ height: 24 }}
          value={DataUtil.formatAddress(patientData?.address) ?? ""}
          valueVariant="body1"
        />
        <Box />
        <Box />
        <Box />
        <Box />
      </Box>
      <Box
        flexDirection={"row"}
        display={"flex"}
        sx={{
          justifyContent: "space-between",
          my: 1,
        }}
      >
        <Labeler
          label={t("patient.profile.generalDetails.personal.height")}
          value={
            patientData?.height
              ? cmToFeetAndInches(patientData?.height) ?? ""
              : ""
          }
          labelerVariant="body2"
          valueVariant="body1"
          sx={{ display: "flex" }}
          valueSxProps={{ ml: 1 }}
        />
        <Labeler
          label={t("patient.profile.generalDetails.personal.bloodGroup")}
          value={patientData?.bloodGroup ?? ""}
          labelerVariant="body2"
          valueVariant="body1"
          sx={{ display: "flex" }}
          valueSxProps={{ ml: 1 }}
        />
        <Box />
        <Box />
        <Box />
        <Box />
      </Box>
      <Divider sx={{ my: 2 }} />
      <Typography variant="body1" sx={{ mb: 1 }}>
        {t("patient.profile.generalDetails.insurance.title")}
      </Typography>
      <Box
        flexDirection={"row"}
        display={"flex"}
        sx={{
          justifyContent: "space-between",
          my: 1,
        }}
      >
        <Labeler
          label={t("patient.profile.generalDetails.insurance.provider")}
          value={patientData?.insurance?.insuranceProvider ?? ""}
          labelerVariant="body2"
          valueVariant="body1"
          sx={{ display: "flex" }}
          valueSxProps={{ ml: 1 }}
        />
        <Labeler
          label={t("patient.profile.generalDetails.insurance.number")}
          value={patientData?.insurance?.insuranceIdNo ?? ""}
          labelerVariant="body2"
          valueVariant="body1"
          sx={{ display: "flex" }}
          valueSxProps={{ ml: 1 }}
        />
        <Box />
        <Box />
        <Box />
        <Box />
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
        <Typography variant="body1">
          {t("patient.profile.generalDetails.additional.notes")}
        </Typography>
        <Typography variant="body2">{patientData?.notes ?? ""}</Typography>
      </Box>
    </Box>
  );
};

export default PatientDetail;
