const HEADER_LINKS = [
  {
    type: "link",
    requiredTokenToDecide: true,
    showWhenNull: true,
    name: "header.aboutUs",
    value: "",
    showForPhysician: true,
  },
  {
    type: "notification",
    requiredTokenToDecide: true,
    showWhenNull: false,
    showForPhysician: true,
  },
  {
    type: "profile",
    requiredTokenToDecide: true,
    showWhenNull: false,
    showForPhysician: false,
  },
  {
    type: "link",
    requiredTokenToDecide: true,
    showWhenNull: true,
    name: "header.auth",
    value: "/auth",
    showForPhysician: true,
  },
  {
    type: "menu",
    requiredTokenToDecide: true,
    showWhenNull: false,
    showForPhysician: true,
  },
];

const QUICK_LINKS = [
  { name: "Home", value: "" },
  { name: "About Us", value: "" },
  { name: "Explore Physicians", value: "" },
  { name: "Services", value: "" },
  { name: "HIPAA Page", value: "" },
  { name: "Contact Us", value: "" },
  { name: "Privacy Policy", value: "" },
  { name: "Terms of Use", value: "" },
];

const DRAWER_LINKS = [
  // {
  //   type: "drawer",
  //   name: "drawer.createAppoitment",
  //   value: "createAppoitment",
  //   icon: "add",
  // },
  // {
  //   type: "link",
  //   name: "drawer.dashboard",
  //   value: "/provider",
  //   icon: "dashboard",
  // },
  {
    type: "link",
    name: "drawer.patients",
    value: "/provider",
    icon: "group",
  },
  {
    type: "link",
    name: "drawer.calendar",
    value: "/provider/calendar",
    icon: "calendar_today",
  },
  {
    type: "link",
    name: "drawer.allPatients",
    value: "/provider/all-patients",
    icon: "person_search",
  },
  {
    type: "link",
    name: "drawer.settings",
    value: "/provider/settings",
    icon: "settings",
  },
];

const HEADER_DRAWER_LINKS = [
  {
    type: "link",
    requiredTokenToDecide: true,
    showWhenNull: true,
    name: "header.aboutUs",
    value: "",
  },
  {
    type: "logout",
    requiredTokenToDecide: true,
    showWhenNull: true,
    name: "header.logout",
  },
];

const config = {
  s3: {
    BUCKET: "YOUR_DEV_S3_UPLOADS_BUCKET_NAME",
  },
  apiGateway: {
    REGION: "YOUR_DEV_API_GATEWAY_REGION",
    URL: process.env.REACT_APP_URL ?? "",
  },
  cognito: {
    REGION: "YOUR_DEV_COGNITO_REGION",
    USER_POOL_ID: "YOUR_DEV_COGNITO_USER_POOL_ID",
    APP_CLIENT_ID: "YOUR_DEV_COGNITO_APP_CLIENT_ID",
    IDENTITY_POOL_ID: "YOUR_DEV_IDENTITY_POOL_ID",
  },
  secret: process.env.REACT_APP_SECRET ?? "",
  mapApiKey: process.env.REACT_APP_MAP_API_KEY ?? "",
  mapId: process.env.REACT_APP_MAP_ID ?? "",
  headerLinks: HEADER_LINKS,
  quickLinks: QUICK_LINKS,
  drawerLinks: DRAWER_LINKS,
  headerDrawerLinks: HEADER_DRAWER_LINKS,
};

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
