import { Box, Button, Divider, Typography } from "@mui/material";
import { useState } from "react";
import { images } from "../../../../assets";
import TimeSlotDrawer from "./TimeSlotDrawer";

type Props = {
  noAction?: boolean;
  isSameOrAfter?: boolean;
};

const EmptyTimeSlot = (props: Props) => {
  const { noAction = false, isSameOrAfter = false } = props;
  const { emptyCalendar } = images.provider;
  const [open, setOpen] = useState(false);
  const handleDrawer = (value: boolean) => {
    setOpen(value);
  };
  let topMessage = "You do not have any active slots yet,";
  let message = "Click on Set up Time Slots";
  if (noAction) message = "Set your time slots today.";
  if (!isSameOrAfter) {
    message = "";
    topMessage = "You do not have any active slots for selected date.";
  }
  return (
    <Box>
      <Box
        sx={{
          p: 4,
          borderRadius: 2.5,
          backgroundColor: "#F2F4F6",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={emptyCalendar}
          alt="complete provider profile"
          width={"250px"}
          height={"250px"}
        />
        <Typography sx={{ textAlign: "center" }}>{topMessage}</Typography>
        <Typography sx={{ textAlign: "center" }}>{message}</Typography>
      </Box>
      {!noAction && isSameOrAfter && (
        <>
          <Divider
            orientation="vertical"
            sx={{ borderWidth: "1px", backgroundColor: "#C5C6D0", mt: 2 }}
          />
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2.5 }}
            onClick={() => handleDrawer(true)}
          >
            Set Up Time Slots
          </Button>
        </>
      )}
      <TimeSlotDrawer
        open={open}
        handleDrawer={handleDrawer}
        getSchedule={() => {}}
      />
    </Box>
  );
};

export default EmptyTimeSlot;
