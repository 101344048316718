import {
  AppBar,
  Button,
  Toolbar,
  Box,
  Popover,
  useTheme,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { images } from "../assets";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../hooks/useStoreHook";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { resetStateAction } from "../app/action";
import ProfilePicture from "./ProfilePicture";
import {
  useGetProfilePictureQuery,
  useLazyGetProfilePictureQuery,
} from "../features/user/userAPI";
import DrawerComp from "./DrawerComp";
import config from "../config/config";
import { useBreakpoints } from "../hooks/useBreakpoints";
import { useNotification } from "../hooks/useNotification";
import { useAuth } from "../hooks/useAuthUser";

export default function Header({
  isPhysician = false,
}: {
  isPhysician?: boolean;
}) {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = React.useState("en");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const auth = useAuth();
  const { isLoading } = useGetProfilePictureQuery(null, {
    skip: auth?.user?.id ? false : true,
  });

  function changeLanguage(e: SelectChangeEvent) {
    console.log(e.target.value);
    i18n.changeLanguage(e.target.value);
    setLanguage(e.target.value);
  }
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openNotification } = useNotification();

  const accessToken = useAppSelector((state) => state.userState.accessToken);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onLogout = () => {
    dispatch(resetStateAction());
    navigate("/");
  };

  const { palette } = useTheme();
  const chooseBasedOnType = (item: any, index: number) => {
    if (item.type === "link") {
      return (
        <Button
          key={index}
          variant="text"
          sx={{ my: 1 }}
          component={RouterLink}
          to={item?.value ?? ""}
        >
          <Typography variant="body1" fontSize={"14px"}>
            {t(item?.name as string)}{" "}
          </Typography>
        </Button>
      );
    } else if (item.type === "menu") {
      return (
        <Select
          key={index}
          value={language}
          onChange={changeLanguage}
          sx={{
            color: palette.primary.main,
            ml: 2,
            "& .MuiSelect-select": {
              paddingLeft: 3,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiSvgIcon-root": {
              left: 0,
              color: palette.primary.main,
            },
          }}
        >
          {item.options.map((menuItem: any, menuIndex: number) => {
            return (
              <MenuItem key={menuIndex} value={menuItem.value}>
                {t(menuItem.name)}
              </MenuItem>
            );
          })}
        </Select>
      );
    } else if (item.type === "notification") {
      return (
        <IconButton
          key={index}
          sx={{ my: 1, mr: 1 }}
          onClick={() => {
            openNotification({});
          }}
        >
          <NotificationsNoneIcon
            sx={{
              height: 24,
              width: 24,
              color: "#323222",
            }}
          />
        </IconButton>
      );
    } else if (item.type === "profile" && !isPhysician) {
      return (
        <Box key={index} sx={{ my: 1, mr: 1.5 }}>
          <ProfilePicture
            loading={isLoading}
            name="profilePict"
            width={36}
            height={36}
            isNavigate
            navigate={navigate}
          />
        </Box>
      );
    } else {
      return <Fragment key={index}></Fragment>;
    }
  };
  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: palette.common.white }}
        elevation={4}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <>
            <Grid container sx={{ placeItems: "center" }}>
              <Grid item xs={2}>
                <Box
                  component="img"
                  sx={{
                    minHeight: 64,
                    minWidth: 112,
                  }}
                  onClick={() => navigate("/")}
                  alt="My Colonoscopy logo."
                  src={images.app.logo}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={9} display={"flex"}>
                <>
                  <Box />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "auto",
                    }}
                  >
                    {config.headerLinks.map((item, index) => {
                      if (!item.requiredTokenToDecide) {
                        return chooseBasedOnType(item, index);
                      } else if (item.type == "menu") {
                        if (accessToken !== null && !item.showWhenNull) {
                          return (
                            <DrawerComp
                              key={index}
                              links={config.headerDrawerLinks}
                            />
                          );
                        } else {
                          return <Fragment key={index}></Fragment>;
                        }
                      } else {
                        if (accessToken === null && item.showWhenNull) {
                          return chooseBasedOnType(item, index);
                        } else if (accessToken !== null && !item.showWhenNull) {
                          return chooseBasedOnType(item, index);
                        } else {
                          return <Fragment key={index}></Fragment>;
                        }
                      }
                    })}
                  </Box>
                </>
              </Grid>
            </Grid>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Button variant="text" sx={{ my: 1, mx: 1.5 }} onClick={onLogout}>
                Logout
              </Button>
            </Popover>
          </>
        </Toolbar>
      </AppBar>
      <Toolbar sx={{ mb: 1 }} />
    </React.Fragment>
  );
}
