import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/patient/Home";
import Signin from "../pages/auth/Signin";
import CompleteProfile from "../pages/patient/CompleteProfile";
import Signup from "../pages/auth/Signup";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import { PatientRootLayout } from "./PatientRootLayout";
import Error from "../pages/error/Error";
import Profile from "../pages/patient/Profile";
import { AuthRootLayout } from "./AuthRootLayout";
import ViewPhysician from "../pages/patient/ViewPhysician";
import ProtectedRoute from "./ProtectedRoute";
import UnauthorizePage from "../pages/error/UnauthorizePage";
import { PhysicianRootLayout } from "./PhysicianRootLayout";
import Patients from "../pages/provider/Patients";
import CalendarPage from "../pages/provider/Calendar";
import Settings from "../pages/provider/Settings/Settings";
import CompleteProviderProfile from "../pages/provider/CompleteProviderProfile";
import PatientDetails from "../pages/provider/PatientDetails";
import PhysiciansByArea from "../features/patient/home/PhysiciansByArea";
import ValidateRoute from "./ValidateRoute";
import AllPatients from "../features/provider/patients/all-patients";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PatientRootLayout />,
    errorElement: <Error />,
    children: [
      {
        element: <ValidateRoute />,
        children: [{ index: true, element: <Home /> }],
      },
      {
        path: "/profile",
        children: [
          {
            element: <ProtectedRoute allowedRoles={[]} />,
            children: [{ index: true, element: <Profile /> }],
          },
        ],
      },
      {
        path: "/complete-profile",
        children: [{ index: true, element: <CompleteProfile /> }],
      },
      { path: "/explore-physicians", element: <PhysiciansByArea /> },
      {
        path: "/:id/physician",
        element: <ViewPhysician />,
      },
    ],
  },
  {
    path: "/provider",
    element: <PhysicianRootLayout />,
    errorElement: <Error />,
    children: [
      {
        element: <ProtectedRoute allowedRoles={[]} />,
        children: [
          // { index: true, element: <ProviderHome /> },
          { index: true, element: <Patients /> },
          { path: "calendar", element: <CalendarPage /> },
          { path: "all-patients", element: <AllPatients /> },
          { path: "settings", element: <Settings /> },
          {
            path: "complete-profile",
            element: <CompleteProviderProfile />,
          },
          {
            path: ":id/details",
            element: <PatientDetails />,
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    element: <AuthRootLayout />,
    errorElement: <Error />,
    children: [
      { index: true, element: <Signin /> },
      { path: "signup", element: <Signup /> },
      { path: "forgot", element: <ForgotPassword /> },
      { path: "reset", element: <ResetPassword /> },
    ],
  },
  {
    path: "/unauthorized",
    element: <UnauthorizePage />,
  },
]);

export default router;
