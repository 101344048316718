import { Drawer } from "@mui/material";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import QuestionnaireForm from "./QuestionnaireForm";
export interface _QuestionnaireForm {
  open: boolean;
  handleClose: () => void;
}

const Questionnaire = ({ open, handleClose }: _QuestionnaireForm) => {
  const [{ isMatchDownSM: isSM }] = useBreakpoints();

  return (
    <Drawer
      PaperProps={{
        sx: {
          backgroundColor: "##ffffff80",
          width: isSM ? "100%" : "680px",
          padding: 2,
        },
      }}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <QuestionnaireForm open={open} handleClose={handleClose} />
    </Drawer>
  );
};

export default Questionnaire;
