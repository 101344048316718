import moment, { Moment } from "moment";

type Check = "isSameOrAfter" | "isAfter" | "isBefore" | "isSameOrBefore";

class DateUtil {
  static DateFormats = {
    MONTH_DATE_YEAR: "MM/DD/YYYY",
    YEAR_MONTH_DATE: "YYYY-MM-DD",
    YEAR_MONTH_DATE_TIME: "YYYY-MM-DDTHH:mm",
    DD_S_MMMM_S_YYYY: "DD MMMM YYYY",
    DD_S_MMM_S_YYYY: "DD MMM YYYY",
    DD_S_MMM_S_YY: "DD MMM 'YY",
    F_MONTH_DATE_YEAR: "MMM/DD/YYYY",
    DDD_MMM_DD: "ddd, MMM DD",
    WEEK_DAY: "ddd",
    MILIARY_TIME: "HH:mm",
    TIME: "hh:mm A",
    DATE_TIME: "DD MMM YYYY, hh:mm A",
    DAY_DATE: "dddd, MMMM DD",
  };
  getToday() {
    return moment();
  }
  createMoment(date: Moment | string, format?: string | undefined) {
    return moment(date, format);
  }
  formatDate(
    date: Moment | string,
    format: string = DateUtil.DateFormats.MONTH_DATE_YEAR
  ) {
    return moment(date).format(format);
  }
  generateTimeSlots(): string[] {
    const timeSlots: string[] = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        timeSlots.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return timeSlots;
  }
  calculateAgeFromDOB(dateOfBirth: string): number {
    const dob = moment(dateOfBirth, "YYYY-MM-DD");
    const today = moment();
    const age = today.diff(dob, "years");
    return age;
  }
  getDateRange(startDate: moment.Moment, endDate: moment.Moment) {
    if (moment(startDate).isSame(moment(endDate), "day")) {
      return [endDate];
    }
    let date = startDate;
    const dates = [date];
    do {
      date = moment(date).add(1, "day");
      dates.push(date);
    } while (moment(date).isBefore(endDate));
    return dates;
  }
  checkDate(date: any, type: Check) {
    return moment(date)[type](moment(), "date");
  }
  getDate(
    type: "today" | "yesterday" | "tomorrow" | "somedate",
    value?: number
  ) {
    const dateObj = new Date();
    if (type === "today") return dateObj;
    let addOrSubstract = 0;
    if (type === "tomorrow") addOrSubstract = 1;
    if (type === "yesterday") addOrSubstract = -1;
    if (type === "somedate" && !value) {
      throw new Error("value is required for somedate");
    }
    if (type === "somedate" && value) addOrSubstract = value;
    return new Date(
      dateObj.getFullYear(),
      dateObj.getMonth(),
      dateObj.getDate() + addOrSubstract
    );
  }
}

export const dateUtil = new DateUtil();
export default DateUtil;
