import { useMemo } from "react";
import { useSelector } from "react-redux";

import { IUser } from "../features/user/auth/authtypes";
import { userApi } from "../features/user/userAPI";
import { selectCurrentUser } from "../app/store/slices/userSlice";

export const useAuthUser = (): IUser | undefined => {
  const state = userApi.endpoints.getMe.useQueryState(null);
  return state.data;
};

export const useAuth = () => {
  const user = useSelector(selectCurrentUser);

  return useMemo(() => ({ user }), [user]);
};
