import { AdvancedMarker } from "@vis.gl/react-google-maps";

interface Props {
  cord: google.maps.LatLngLiteral;
}

export const Marker = ({ cord }: Props) => {
  return (
    <AdvancedMarker position={cord}>
      <div
        style={{
          height: 14,
          width: 14,
          borderRadius: 7,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "#FFF",
          backgroundColor: "#307DF6",
        }}
      ></div>
    </AdvancedMarker>
  );
};
