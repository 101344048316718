import { FormControl, Rating, RatingProps } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
type IRatingFormProps = {
  name: string;
  label?: string;
  maxRating: any;
} & RatingProps;

export const RatingForm: React.FC<IRatingFormProps> = ({
  name,
  label,
  maxRating,
  ...otherProps
}) => {
  const { control } = useFormContext();
  return (
    <FormControl component="fieldset">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Rating {...otherProps} onChange={onChange} value={value} />
        )}
      />
    </FormControl>
  );
};
