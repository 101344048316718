import React, { useEffect } from "react";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useToast } from "../hooks/useToast";
import { useAppSelector } from "../hooks/useStoreHook";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Toast = (): JSX.Element => {
  const toast = useAppSelector((state) => state.toast);
  const { clearToast } = useToast();

  const handleClose = (_: unknown, reason?: SnackbarCloseReason) =>
    reason !== "clickaway" && clearToast();

  return (
    <>
      <Snackbar
        open={toast.open}
        autoHideDuration={toast.timeout}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={toast.type}
          sx={{ width: "100%" }}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Toast;
