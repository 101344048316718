import { encrypt } from "../../../utils/codec";
import config from "../../../config/config";
import DateUtil, { dateUtil } from "../../../utils/dateHelper";
import { userApi } from "../../user/userAPI";
import {
  IProfessionalSchema,
  IBasicDetailsSchema,
  IReviewProfileSchema,
} from "./completeProfileSchema";

interface ProfessionalFormData extends IProfessionalSchema {
  awards: string[];
}

export const completeProfileAPI = userApi.injectEndpoints({
  endpoints: (builder) => ({
    completeProviderProfile: builder.mutation<{}, IBasicDetailsSchema>({
      query(data) {
        const dateOfBirth = dateUtil.formatDate(
          data.dateOfBirth.toString(),
          DateUtil.DateFormats.MONTH_DATE_YEAR
        );
        const request = {
          name: data.firstname,
          surname: data.surname,
          middleName: data.middlename,
          emailAddress: data.email,
          dateOfBirth: dateOfBirth,
          phoneNumber: data.contactNumber,
          aboutMe: data.about,
        };
        const _data = encrypt(request, config.secret);
        return {
          url: "/api/services/app/Provider/CompleteBasicDetails",
          method: "POST",
          credentials: "include",
          body: _data,
        };
      },
      invalidatesTags: ["Provider"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userApi.endpoints.getMeAsProvider.initiate(null));
        } catch (error) {
          console.log("error", error);
        }
      },
    }),
    completeProfessionalProfile: builder.mutation<{}, ProfessionalFormData>({
      query(data) {
        const request = {
          educationQualification: data.education,
          experience: data.experience,
          medicalSchool: data.medicalSchool,
          residency: data.residency,
          fellowship: data.fellowship,
          npi: data.npi,
          businessAddress: {
            streetLineOne: data.streetLineOne,
            streetLineTwo: data.streetLineTwo,
            stateOrRegion: data.stateOrRegion,
            city: data.city,
            zipCode: data.zipCode,
            addressType: data.addressType,
            isCurrent: Boolean(data.isCurrent),
            latitude: data.latitude,
            longitude: data.longitude,
          },
          awardsList: data.awards,
        };

        const _data = encrypt(request, config.secret);
        return {
          url: "/api/services/app/Provider/CompleteProfessionalDetails",
          method: "POST",
          credentials: "include",
          body: _data,
        };
      },
      invalidatesTags: ["Provider"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userApi.endpoints.getMeAsProvider.initiate(null));
        } catch (error) {
          console.log("error", error);
        }
      },
    }),
    reviewProfile: builder.mutation<{}, IReviewProfileSchema>({
      query(data) {
        const request = {
          acceptTermsAndCondition: data.acceptTC,
        };
        const _data = encrypt(request, config.secret);
        return {
          url: "/api/services/app/Provider/SubmitProfile",
          method: "POST",
          credentials: "include",
          body: _data,
        };
      },
      invalidatesTags: ["Provider"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userApi.endpoints.getMeAsProvider.initiate(null));
        } catch (error) {
          console.log("error", error);
        }
      },
    }),
  }),
});

export const {
  useCompleteProviderProfileMutation,
  useCompleteProfessionalProfileMutation,
  useReviewProfileMutation,
} = completeProfileAPI;
