import { useEffect, useMemo, useState } from "react";
import { Grid, Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IProfileSchema, profileSchema } from "./completeProfileSchema";
import { useCompleteProfileMutation } from "./completeProfileAPI";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { FormInputText } from "../../../components/form/FormInput";
import { FormInputDate } from "../../../components/form/FormDate";
import { FormInputDropdown } from "../../../components/form/FormDropdown";
import { useAppSelector } from "../../../hooks/useStoreHook";
import { LoadingButton } from "@mui/lab";
import { getErrorMessage } from "../../../utils/serviceHelper";
import ProfilePicture from "../../../components/ProfilePicture";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import { IUser } from "../../user/auth/authtypes";
import { useToast } from "../../../hooks/useToast";

export default function PersonalDetails(props: any) {
  const { t } = useTranslation();
  const options = [
    { label: t("completeprofile.personalForm.gender.value.male"), value: 0 },
    { label: t("completeprofile.personalForm.gender.value.female"), value: 1 },
    { label: t("completeprofile.personalForm.gender.value.other"), value: 2 },
  ];

  const bloodOptions = [
    {
      label: t("completeprofile.personalForm.bloodGroup.value.a+"),
      value: "0",
    },
    {
      label: t("completeprofile.personalForm.bloodGroup.value.a-"),
      value: "1",
    },
    {
      label: t("completeprofile.personalForm.bloodGroup.value.b+"),
      value: "2",
    },
    {
      label: t("completeprofile.personalForm.bloodGroup.value.b-"),
      value: "3",
    },
    {
      label: t("completeprofile.personalForm.bloodGroup.value.ab+"),
      value: "4",
    },
    {
      label: t("completeprofile.personalForm.bloodGroup.value.ab-"),
      value: "6",
    },
    {
      label: t("completeprofile.personalForm.bloodGroup.value.o+"),
      value: "7",
    },
    {
      label: t("completeprofile.personalForm.bloodGroup.value.o-"),
      value: "8",
    },
  ];

  const [completeProfile, { isLoading, isError, error, isSuccess }] =
    useCompleteProfileMutation();

  const userData = useAppSelector((state) => state.userState.user) as IUser;
  console.log("userData", userData);
  const methods = useForm<IProfileSchema>({
    resolver: zodResolver(profileSchema),
    defaultValues: useMemo(() => {
      return {
        firstname: userData?.firstName ?? "",
        middlename: userData?.middleName ?? "",
        surname: userData?.surname ?? "",
        dateOfBirth: new Date(userData?.dateOfBirth ?? "") ?? new Date(),
        gender: userData?._gender ?? 4,
        bloodGroup: userData?.bloodGroup ?? "",
        height: userData?._height?.toString() ?? "",
        weight: userData?.weight?.toString() ?? "",
        contactNumber: userData?.phoneNumber ?? "",
        email: userData?.email,
        streetLineOne: userData?.address?.streetLineOne ?? "",
        streetLineTwo: userData?.address?.streetLineTwo ?? "",
        stateOrRegion: userData?.address?.stateOrRegion ?? "",
        city: userData?.address?.city ?? "",
        zipCode: userData?.address?.zipCode ?? "",
        addressType: 0,
      };
    }, [userData]),
  });

  const { reset, handleSubmit } = methods;
  const { displayToast } = useToast();

  useEffect(() => {
    if (isSuccess) {
      props.handleNext();
    }
    if (isError) {
      const errorMessage = getErrorMessage(error);
      displayToast({
        message: errorMessage,
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onSubmitHandler: SubmitHandler<IProfileSchema> = (values) => {
    if (userData && userData.id) {
      const payload = {
        ...values,
        id: userData.id,
      };
      completeProfile(payload);
    } else {
      //error
    }
  };
  const [breakPoints] = useBreakpoints();
  const { isMatchDownSM } = breakPoints;
  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid container direction="row" sx={{ my: 9 }}>
          <Grid item xs={12} sm={2} md={2}>
            <ProfilePicture
              name="profilePic"
              width={176}
              height={176}
              isEdit={true}
            />
          </Grid>
          <Grid item xs={12} sm={1} md={1} />
          <Grid item xs={12} sm={9} md={9}>
            <Stack
              direction={isMatchDownSM ? "column" : "row"}
              spacing={1}
              paddingY={1}
            >
              <FormInputText
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                }}
                color="primary"
                variant="outlined"
                size="medium"
                margin="normal"
                type="text"
                label={t("auth.signup.firstname")}
                fullWidth
                name="firstname"
                required
              />
              <FormInputText
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                }}
                color="primary"
                variant="outlined"
                size="medium"
                margin="normal"
                type="text"
                label={t("auth.signup.middlename")}
                fullWidth
                name="middlename"
              />
              <FormInputText
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                }}
                color="primary"
                variant="outlined"
                size="medium"
                margin="normal"
                type="text"
                label={t("auth.signup.surname")}
                fullWidth
                name="surname"
                required
              />
            </Stack>
            <Stack
              direction={isMatchDownSM ? "column" : "row"}
              spacing={1}
              paddingY={1}
            >
              <FormInputDate
                sx={{ width: "100%", my: 2 }}
                name={"dateOfBirth"}
                label={t("completeprofile.personalForm.dateOfBirth")}
              />
              <FormInputDropdown
                margin="normal"
                options={options}
                name={"gender"}
                label={t("completeprofile.personalForm.gender.title")}
                fullWidth
                withLabel
              />
              <FormInputDropdown
                margin="normal"
                options={bloodOptions}
                name={"bloodGroup"}
                label={t("completeprofile.personalForm.bloodGroup.title")}
                fullWidth
                withLabel
              />
            </Stack>
            <Stack
              direction={isMatchDownSM ? "column" : "row"}
              spacing={1}
              paddingY={1}
              display={"flex"}
            >
              <FormInputText
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                  flex: 1,
                }}
                defaultValue={userData?._height ?? null}
                color="primary"
                variant="outlined"
                size="medium"
                type="number"
                label={t("completeprofile.personalForm.height")}
                margin="normal"
                name="height"
                required
                fullWidth
              />
              <FormInputText
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                  flex: 1,
                }}
                defaultValue={userData?.weight ?? null}
                color="primary"
                variant="outlined"
                size="medium"
                type="number"
                label={t("completeprofile.personalForm.weight")}
                margin="normal"
                name="weight"
                required
                fullWidth
              />
              <Box sx={{ flex: 1 }} />
            </Stack>
            <Stack
              direction={isMatchDownSM ? "column" : "row"}
              spacing={1}
              paddingY={1}
            >
              <FormInputText
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                }}
                color="primary"
                variant="outlined"
                size="medium"
                type="number"
                label={t("completeprofile.personalForm.contactNumber")}
                margin="normal"
                name="contactNumber"
                required
                fullWidth
              />
              <FormInputText
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                }}
                defaultValue={userData?.email ?? ""}
                color="primary"
                variant="outlined"
                size="medium"
                type="email"
                label={t("completeprofile.personalForm.email")}
                margin="normal"
                name="email"
                required
                fullWidth
              />
            </Stack>
            <Stack
              direction={isMatchDownSM ? "column" : "row"}
              spacing={1}
              paddingY={1}
              display={"flex"}
            >
              <FormInputText
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                  flex: 2,
                }}
                color="primary"
                variant="outlined"
                type="text"
                defaultValue={userData?.address?.streetLineOne ?? ""}
                label={t("completeprofile.personalForm.address")}
                size="medium"
                margin="normal"
                name="streetLineOne"
                required
                fullWidth
              />
              <FormInputText
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                  flex: 1,
                }}
                defaultValue={userData?.address?.zipCode ?? ""}
                color="primary"
                variant="outlined"
                type="text"
                label={t("completeprofile.personalForm.zipCode")}
                size="medium"
                margin="normal"
                name="zipCode"
                required
                fullWidth
              />
            </Stack>
          </Grid>
        </Grid>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          sx={{ my: 2 }}
        >
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant="contained"
            sx={{
              alignSelf: "flex-end",
              justifyItems: "flex-end",
            }}
          >
            {t("completeprofile.next")}
          </LoadingButton>
        </Box>
      </Box>
    </FormProvider>
  );
}
