import background from "./user/background.svg";
import signupDoctor from "./user/signup-doctors.svg";
import logo from "./app/mypct-logo.svg";
import hippaComplaint from "./app/hippa_complaint.svg";
import temp from "./carousel/temp.svg";
import appointment from "./carousel/appointment.svg";
import completeProviderProfile from "./provider/complete-provider-profile.svg";
import emptyCalendar from "./provider/empty-calendar.svg";
import doctor from "./app/doctor.svg";
import doctorMobile from "./app/doctor-mobile.svg";
import doctorMobile2 from "./app/doctor-mobile-2.svg";
import doctors from "./app/doctors.svg";
import success from "./app/success.svg";
import walkin from "./app/walkin.svg";
import telephonic from "./app/telephonic.svg";

const images = {
  users: {
    background: background,
    signupDoctor: signupDoctor,
  },
  provider: {
    completeProfile: completeProviderProfile,
    emptyCalendar: emptyCalendar,
  },
  app: {
    logo: logo,
    hippaCompliant: hippaComplaint,
    doctor: doctor,
    doctorMobile,
    doctorMobile2,
    doctors,
    success,
    walkin,
    telephonic,
  },
  carousel: {
    temp: temp,
    appointment: appointment,
  },
};
export { images };
