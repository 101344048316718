import { Box, Divider, Skeleton } from "@mui/material";
import { Fragment } from "react";

const AppointmentOverviewSkeleton = () => {
  return (
    <Fragment>
      <Box
        sx={{
          width: "48%",
          m: "1%",
          border: "1px solid #CDE5FF",
          p: 3,
          borderRadius: 2,
          backgroundColor: "#FCFCFF",
        }}
      >
        <Skeleton variant="rectangular" width={"80%"} height={32} />
        <Divider sx={{ my: 2, borderColor: "#CDE5FF" }} />
        <Box
          sx={{ display: "flex" }}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Skeleton variant="rectangular" width={"100%"} height={40} />
        </Box>
      </Box>
    </Fragment>
  );
};
export default AppointmentOverviewSkeleton;
