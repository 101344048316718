import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    //   status: {
    //     danger: string;
    //   };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    //   status?: {
    //     danger?: string;
    //   };
  }
  interface CommonColors {
    deselected: string;
    selected: string;
    border: string;
    image: string;
    background: string;
    pagination: string;
    faqColor: string;
    editColor: string;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

let theme = createTheme({
  typography: {},
});

theme = createTheme(theme, {
  typography: {
    allVariants: {
      fontFamily: "Jost",
      fontSize: 16,
    },
    h1: {
      fontFamily: "Jost",
      fontSize: 36,
      fontWeight: 500,
      [theme.breakpoints.down("xs")]: {
        fontSize: 34,
      },
    },
    h2: {
      fontFamily: "Jost",
      fontSize: 36,
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: 32,
      },
    },
    h3: {
      fontFamily: "Jost",
      fontSize: 32,
      fontWeight: 500,
      [theme.breakpoints.down("xs")]: {
        fontSize: 30,
      },
    },
    h4: {
      fontFamily: "Jost",
      fontSize: 32,
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: 20,
        fontWeight: 300,
      },
    },
    h5: {
      fontFamily: "Jost",
      fontSize: 28,
      fontWeight: 500,
      [theme.breakpoints.down("xs")]: {
        fontSize: 26,
      },
    },
    h6: {
      fontFamily: "Jost",
      fontSize: 28,
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: 26,
      },
    },
    subtitle1: {
      fontFamily: "Jost",
      fontSize: 24,
      fontWeight: 500,
      [theme.breakpoints.down("xs")]: {
        fontSize: 22,
      },
    },
    subtitle2: {
      fontFamily: "Jost",
      fontSize: 24,
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: 22,
      },
    },
    body1: {
      fontFamily: "Jost",
      fontSize: 16,
      fontWeight: 500,
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
    },
    body2: {
      fontFamily: "Jost",
      fontSize: 16,
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
    },
    body3: {
      fontFamily: "Jost",
      fontSize: 16,
      fontWeight: 300,
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
    },
    caption: {
      fontFamily: "Jost",
      fontSize: 12,
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: 10,
      },
    },
    button: {
      textTransform: "none",
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        contained: { borderRadius: 28 },
        outlined: { borderRadius: 28 },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          border: 0,
          borderBottom: "2px solid",
          borderColor: theme.palette.common.border,
          color: theme.palette.common.deselected,
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 500,
          fontFamily: "Jost",
          "&.Mui-selected": {
            color: theme.palette.common.selected,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
});

export default theme;
