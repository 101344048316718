interface Address {
  streetLineOne: string;
  streetLineTwo?: string;
  stateOrRegion: string;
  city: string;
  zipCode: string;
}

class DataUtil {
  static isEmptyObject(obj: object | undefined) {
    return obj && Object.keys(obj).length === 0;
  }
  static formatAddress(address: Address): string {
    if (
      address === null ||
      address === undefined ||
      DataUtil.isEmptyObject(address)
    ) {
      return "";
    } else {
      let formattedAddress = `${address?.streetLineOne}, `;
      if (address.streetLineTwo) {
        formattedAddress += `${address?.streetLineTwo ?? ""}, `;
      }
      formattedAddress += `${address.city}${address.stateOrRegion} ${address.zipCode}`;
      return formattedAddress;
    }
  }
}

export const dataUtil = new DataUtil();
export default DataUtil;
