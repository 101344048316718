import { useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  patientsAdapter,
  patientsSelector,
  useGetMyCustomersQuery,
  useLazyGetMyCustomersQuery,
} from "../providerPatientsAPI";
import { useIntersectionObserver } from "../../../../hooks/useIntersectionObserver";

const limit = 10;
export function usePatientList() {
  const { t } = useTranslation();
  const currentPage = useRef(1);
  const keywordRef = useRef("");
  const [fetchPatients, { isFetching }] = useLazyGetMyCustomersQuery();
  const {
    patients,
    hasNextPage,
    isFetching: isInitialFetching,
    isError,
  } = useGetMyCustomersQuery(
    { pageNumber: 1, limit, keyword: keywordRef?.current },
    {
      selectFromResult: ({ data, ...otherParams }) => {
        return {
          hasNextPage: data?.hasMorePages,
          patients: patientsSelector.selectAll(
            data ?? patientsAdapter.getInitialState()
          ),
          ...otherParams,
        };
      },
    }
  );

  const callAPI = async () => {
    if (!hasNextPage || isFetching) return;
    currentPage.current += 1;
    await fetchPatients({
      pageNumber: currentPage.current,
      limit,
      keyword: keywordRef?.current,
    });
  };
  const searchKeyword = async (keyword: string) => {
    currentPage.current = 1;
    keywordRef.current = keyword;
    await fetchPatients({
      pageNumber: currentPage.current,
      limit,
      keyword: keywordRef?.current,
    });
  };

  const [lastPostRef] = useIntersectionObserver(
    isFetching || isInitialFetching,
    callAPI,
    hasNextPage
  );

  return {
    t,
    isLoading: isFetching || isInitialFetching,
    lastPostRef,
    patients,
    isError,
    searchKeyword,
  };
}
