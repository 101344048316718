import AllPatientList from "./AllPatientList";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import PatientHeader from "../components/PatientsHeader";
import { useBreakpoints } from "../../../../hooks/useBreakpoints";

const AllPatients = () => {
  const header = [
    { title: "Patients", flex: 2 },
    { title: "Consultation", flex: 1 },
    { title: "Last Consulted", flex: 1 },
    { isEmpty: true },
  ];

  const { breakpoints } = useTheme();
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const fullScreen = useMediaQuery(breakpoints.down("sm"));
  return (
    <Box
      sx={{
        overflowY: "auto",
        minHeight: "30vh",
        maxHeight: isSM ? "60vh" : "120vh",
        p: 1,
      }}
      className="fixed-size"
    >
      <AllPatientList header={header} />
    </Box>
  );
};

export default AllPatients;
