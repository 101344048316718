import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RegisterInput, registerSchema } from "./signupSchema";
import { useRegisterUserMutation } from "../../../../app/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "../../../../utils/serviceHelper";
import { useToast } from "../../../../hooks/useToast";

export function useSignupFunction(props: any) {
  const methods = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  });

  const [registerUser, { isLoading, isError, error, isSuccess, originalArgs }] =
    useRegisterUserMutation();

  const navigate = useNavigate();
  // const location = useLocation();

  // const from = ((location.state as any)?.from.pathname as string) || "/profile";

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;
  const { displayToast } = useToast();

  useEffect(() => {
    if (isSuccess) {
      reset({
        email: "",
        password: "",
        firstname: "",
        middlename: "",
        surname: "",
        passwordConfirm: "",
      });

      navigate("/auth", {
        state: {
          message: `Registration of ${originalArgs?.email} is successful.`,
          from: "signUp",
        },
      });
    }
    if (isError) {
      const errorMessage = getErrorMessage(error);
      displayToast({ message: errorMessage, type: "error" });
    }
  }, [isLoading]);

  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    registerUser({ ...values, isProvider: Boolean(props.index) });
  };

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const { t } = useTranslation();

  return {
    isLoading,
    showPassword,
    methods,
    t,
    handleSubmit,
    onSubmitHandler,
    handleShowPasswordClick,
  };
}
