import {
  Box,
  IconButton,
  SxProps,
  Theme,
  Typography,
  TypographyVariant,
} from "@mui/material";
import React, { Fragment } from "react";

interface LabelWithIconsProps {
  icon: JSX.Element;
  value: string | JSX.Element;
  valueVariant: TypographyVariant;
  boxSx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
}
export default function LabelWithIcon(props: LabelWithIconsProps) {
  const { icon, valueVariant, value, boxSx, iconSx } = props;
  return (
    <Box
      flexDirection={"row"}
      display={"flex"}
      justifyContent={"center"}
      sx={{ ...boxSx }}
    >
      <IconButton sx={iconSx}>{icon}</IconButton>
      <Typography variant={valueVariant}>{value}</Typography>
    </Box>
  );
}
