import AppointmentOverview from "../../../../components/AppointmentOverview";
import { Box } from "@mui/material";
import { usePatientAppointment } from "./usePatientAppointment";
import { Fragment } from "react";
import AppointmentOverviewSkeleton from "../../../../components/skeletons/AppointmentOverviewSkeleton";

const PatientPastAppointment = ({ emailId }: { emailId: string }) => {
  const { appointments, isLoading, lastPostRef } = usePatientAppointment(
    emailId,
    [3, 6]
  );
  return (
    <>
      <Box my={2} display={"flex"} flexWrap={"wrap"}>
        {appointments.map((appointment, index) => {
          return (
            <Fragment key={appointment?.id ?? index}>
              {index === appointments.length - 1 ? (
                <AppointmentOverview
                  ref={lastPostRef}
                  appointmentId={appointment?.id}
                  appointmentStatus={(appointment as any)?.status}
                  scheduleDetails={(appointment as any)?.scheduleDetails}
                  key={appointment.id}
                  isPast
                />
              ) : (
                <AppointmentOverview
                  appointmentId={appointment?.id}
                  appointmentStatus={(appointment as any)?.status}
                  scheduleDetails={(appointment as any)?.scheduleDetails}
                  key={appointment.id}
                  isPast
                />
              )}
            </Fragment>
          );
        })}
      </Box>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {Array.from({ length: 12 }).map((el) => {
            return <AppointmentOverviewSkeleton />;
          })}
        </Box>
      )}
    </>
  );
};

export default PatientPastAppointment;
