import { Box, Chip, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CloudUpload from "@mui/icons-material/CloudUpload";
import { Controller, useController, useFormContext } from "react-hook-form";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import UploadContainer from "../UploadContainer";

interface IFileUploadProps {
  limit: number;
  multiple: boolean;
  name: string;
  uploadedDocs?: any[] | null;
  onDeleteDoc: any;
}

// ? FileUpload Component
const FileUpload: React.FC<IFileUploadProps> = ({
  limit,
  multiple,
  uploadedDocs,
  onDeleteDoc,
  name = "fileUpload",
}) => {
  const { t } = useTranslation();
  // ? Form Context
  const {
    control,
    formState: { isSubmitting, errors },
  } = useFormContext();

  // ? State with useState()
  const { field } = useController({ name, control });
  const [singleFile, setSingleFile] = useState<File[]>([]);
  const [fileList, setFileList] = useState<File[]>([]);

  const disabled = false;

  const onDrop = (files: File[]) => {
    console.log(files);
    if (!files) return;
    if (limit === 1) {
      if (singleFile.length >= 1) return alert("Only a single image allowed");
      setSingleFile(files);
      console.log("Set single file", singleFile);
      field.onChange(files[0]);
    }

    if (multiple) {
      if (files) {
        const updatedList = [...fileList, ...files];
        if (updatedList.length > limit) {
          return alert(`Image must not be more than ${limit}`);
        }
        setFileList(updatedList);
        field.onChange(updatedList);
      }
    }
  };

  const { getRootProps, isDragAccept } = useDropzone({
    onDrop,
    disabled,
  });

  // ? remove multiple images
  const fileRemove = (file: File) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
  };

  // ? remove single image
  const fileSingleRemove = () => {
    setSingleFile([]);
  };

  // ? TypeScript Type
  type CustomType = "jpg" | "png" | "svg";

  // // ? Calculate Size in KiloByte and MegaByte
  // const calcSize = (size: number) => {
  //   return size < 1000000
  //     ? `${Math.floor(size / 1000)} KB`
  //     : `${Math.floor(size / 1000000)} MB`;
  // };

  // ? Reset the State
  useEffect(() => {
    if (isSubmitting) {
      setFileList([]);
      setSingleFile([]);
    }
  }, [isSubmitting]);

  // ? Actual JSX
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { name, onBlur, ref } }) => {
          return (
            <UploadContainer
              {...getRootProps({
                accepted: +isDragAccept,
                disabled,
              })}
            >
              <Stack justifyContent="center" sx={{ p: 1, textAlign: "center" }}>
                <Box>
                  <CloudUpload />
                </Box>
                <Typography>
                  {t("completeprofile.relatedForm.uploadPlaceholder")}
                </Typography>
                <Typography sx={{ mt: 2, color: "#888888" }}>
                  {t("completeprofile.relatedForm.allowedFiles")}
                </Typography>
              </Stack>
            </UploadContainer>
          );
        }}
      />

      {/* <FormHelperText
        sx={{ textAlign: "center", my: 1 }}
        error={!!errors[name]}
      >
        {errors[name] ? errors[name].message : ""}
      </FormHelperText> */}

      {/* ?Image Preview ? */}
      {fileList.length > 0 ||
      singleFile.length > 0 ||
      (uploadedDocs && uploadedDocs.length > 0) ? (
        <Stack spacing={2} sx={{}}>
          {uploadedDocs?.map((item, index) => {
            return (
              <Box key={index}>
                <Chip
                  sx={{ mr: 1, mt: 2 }}
                  label={item.fileName ?? ""}
                  onDelete={() => {
                    onDeleteDoc(item.id);
                  }}
                  onClick={() => {
                    console.log(item.name);
                  }}
                  deleteIcon={<Close />}
                  variant="outlined"
                />
              </Box>
            );
          })}
          {(multiple ? fileList : singleFile).map((item, index) => {
            return (
              <Box key={index}>
                <Chip
                  sx={{ mr: 1, mt: 2 }}
                  label={item.name ?? ""}
                  onDelete={() => {
                    if (multiple) {
                      fileRemove(item);
                    } else {
                      fileSingleRemove();
                    }
                  }}
                  deleteIcon={<Close />}
                  variant="outlined"
                />
              </Box>
            );
          })}
        </Stack>
      ) : null}
    </>
  );
};

export default FileUpload;
