import { Fragment } from "react";
import Header from "../components/Header";
import { Outlet } from "react-router-dom";
import ProviderNavigator from "../components/ProviderNavigator";
import CreateAppointment from "../pages/provider/CreateAppointment";
import ConfirmModal from "../components/ConfirmModal";
import { useAppDispatch, useAppSelector } from "../hooks/useStoreHook";
import { PopupState, popUpActions } from "../app/store/slices/popupSlice";
import Notification from "../features/notification/Notification";

export function PhysicianRootLayout() {
  const popupState: PopupState = useAppSelector((state) => state.popup);
  const dispatch = useAppDispatch();

  return (
    <Fragment>
      <Header isPhysician />
      <ProviderNavigator>
        <Outlet />
      </ProviderNavigator>
      {/* <CreateAppointment /> */}
      <Notification />
      <ConfirmModal
        open={popupState.status}
        onClose={() => {
          dispatch(popUpActions.setPopState({ status: false, message: "" }));
        }}
        message={popupState.message}
      />
    </Fragment>
  );
}
