import {
  Box,
  Divider,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { forwardRef, useEffect } from "react";
import ProfilePicture from "./ProfilePicture";
import { AccessTime } from "@mui/icons-material";
import LabelWithIcon from "./LabelWithIcon";
import IconButton from "./IconButton";
import moment from "moment";
import CallIcon from "@mui/icons-material/Call";
import MessageIcon from "@mui/icons-material/Message";
import EmailIcon from "@mui/icons-material/Email";
import AppointmentRequestSkeleton from "./skeletons/AppointmentRequestSkeleton";
import { useLazyGetProfilePicQuery } from "../features/patient/dashboard/profilePictureAPI";
import IconRounder from "./IconRounder";
import { useBreakpoints } from "../hooks/useBreakpoints";
import { images } from "../assets";

interface AppointmentRequestProps {
  id: number;
  isLoading: boolean;
  name: string;
  age: number;
  gender: string;
  location: string;
  phoneNumber: string;
  emailAddress: string;
  appointmentDate: string;
  insuranceProvider: string;
  insuranceNumber: string;
  picPath: string;
  appointmentType: 1 | 2;
  onApprove: () => void;
  onReject: () => void;
}

const AppointmentRequest = forwardRef(
  (
    {
      isLoading = false,
      id,
      name,
      age,
      gender,
      location,
      appointmentDate,
      insuranceProvider,
      insuranceNumber,
      appointmentType,
      onApprove,
      onReject,
      picPath,
      emailAddress,
      phoneNumber,
    }: AppointmentRequestProps,
    ref
  ) => {
    const { breakpoints, palette } = useTheme();

    const [getProfilePic, status] = useLazyGetProfilePicQuery();
    const [{ isMatchDownSM: isSM }] = useBreakpoints();

    const fullScreen = useMediaQuery(breakpoints.down("sm"));

    useEffect(() => {
      if (picPath) getProfilePic(picPath);
    }, [getProfilePic, picPath]);

    if (isLoading) {
      return <AppointmentRequestSkeleton />;
    }

    const cardContainerSx: SxProps = [
      {
        width: "100%",
        border: "1px solid #A3C9DE",
        borderRadius: isSM ? "20px" : "28px",
        my: 2,
        p: isSM ? 1.5 : 2,
      },
    ];

    const iconSx: SxProps = { bgcolor: "#CFE5FF", color: "#006398" };
    const smallIconSx: SxProps = { width: 24, height: 24 };
    const iconSize = isSM ? 32 : 40;
    let type = "";
    if (appointmentType === 1) type = images.app.walkin;
    if (appointmentType === 2) type = images.app.telephonic;

    return (
      <Box ref={ref ?? null} sx={cardContainerSx}>
        <Box display="flex" justifyContent="space-between">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box display="flex" alignItems="center">
              <ProfilePicture
                width={40}
                height={40}
                name="User Image"
                loadUserPic
                userPic={status.data ?? ""}
              />
              <Typography paddingLeft={2} variant="body1">
                {name}
              </Typography>
            </Box>
            {/* Change the icon to telephonic or walkin based on the API response */}
            {type && <Box component="img" src={type} />}
          </Box>
          {isSM && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              minWidth="25%"
            >
              <a href={`tel:${phoneNumber}`}>
                <IconRounder sx={iconSx} size={iconSize}>
                  <CallIcon sx={{ height: 20 }} />
                </IconRounder>
              </a>
              <a href={`sms:${phoneNumber}`}>
                <IconRounder sx={iconSx} size={iconSize}>
                  <MessageIcon sx={{ height: 20 }} />
                </IconRounder>
              </a>
            </Box>
          )}
        </Box>
        <LabelWithIcon
          iconSx={{
            ...smallIconSx,
            pr: 3,
            display: "flex",
            alignItems: "center",
          }}
          icon={<AccessTime sx={smallIconSx} />}
          boxSx={{ height: 24, justifyContent: "flex-start", my: isSM ? 1 : 2 }}
          value={moment(appointmentDate).format("DD MMM YYYY hh:mm A")}
          valueVariant="body1"
        />
        <LabelWithIcon
          iconSx={{
            ...smallIconSx,
            pr: 3,
            display: "flex",
            alignItems: "center",
          }}
          icon={<CallIcon sx={smallIconSx} />}
          boxSx={{ height: 24, justifyContent: "flex-start", my: isSM ? 1 : 2 }}
          value={phoneNumber}
          valueVariant="body1"
        />
        <LabelWithIcon
          iconSx={{
            ...smallIconSx,
            pr: 3,
            display: "flex",
            alignItems: "center",
          }}
          icon={<EmailIcon sx={smallIconSx} />}
          boxSx={{ height: 24, justifyContent: "flex-start", my: isSM ? 1 : 2 }}
          value={
            <a
              href={`mailto:${emailAddress}`}
              style={{ color: palette.primary.main }}
            >
              {emailAddress}
            </a>
          }
          valueVariant="body1"
        />
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: "flex" }}>
          <IconButton
            icon="close"
            variant="secondary"
            onClick={onReject}
            sx={{
              borderRadius: 8,
              border: "1px solid #75777F",
              color: palette.common.editColor,
              mr: 2,
            }}
            title="Reject"
            iconSx={{ mx: 1, fontSize: 20 }}
            titleSx={{ mr: 1.5 }}
            titleVariant="body2"
          />
          <IconButton
            icon="check"
            onClick={onApprove}
            sx={{
              borderRadius: 8,
              color: "white",
            }}
            title="Approve"
            iconSx={{ mx: 1, fontSize: 20 }}
            titleSx={{ mr: 1.5 }}
            titleVariant="body2"
          />
        </Box>
      </Box>
    );
  }
);

/* 
       



*/

export default AppointmentRequest;
