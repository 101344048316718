import { Box, Tab, Tabs } from "@mui/material";
import TimelineContainer from "./TimelineContainer";
import CustomTabPanel, { addIdProps } from "../../components/CustomTabPanel";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import UpcomingAppointments from "../../features/provider/patients/upcoming-appointments";
import Archived from "../../features/provider/patients/archived";
import PastRequest from "../../features/provider/patients/past-requests";

const tabs = [
  {
    title: "provider.patient.tabs.0",
    element: <UpcomingAppointments />,
  },
  {
    title: "provider.patient.tabs.1",
    element: <PastRequest />,
  },
  // {
  //   title: "provider.patient.tabs.2",
  //   element: <DidnotShowup />,
  // },
  {
    title: "provider.patient.tabs.3",
    element: <Archived />,
  },
];
const Patients = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { t } = useTranslation();

  return (
    <TimelineContainer>
      <Box sx={{ mt: 3 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          scrollButtons={false}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.title}
              label={t(tab.title)}
              {...addIdProps(tab.title)}
              wrapped
            />
          ))}
        </Tabs>
        {tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {value === index && tab.element}
          </CustomTabPanel>
        ))}
      </Box>
    </TimelineContainer>
  );
};

export default Patients;
