import { Box } from "@mui/material";
import AppointmentOverview from "../../../../components/AppointmentOverview";
import { usePatientAppointment } from "./usePatientAppointment";
import { Fragment } from "react";
import { getIsActionEnabled } from "../../appointment/appointmentRequestsAPI";
import AppointmentOverviewSkeleton from "../../../../components/skeletons/AppointmentOverviewSkeleton";

const PatientUpcomingAppointments = ({ emailId }: { emailId: string }) => {
  const { appointments, isLoading, lastPostRef, onApproveReject } =
    usePatientAppointment(emailId, [0, 1, 4, 5]);
  return (
    <>
      <Box my={2} display={"flex"} flexWrap={"wrap"}>
        {appointments.map((appointment, index) => {
          return (
            <Fragment key={appointment?.id ?? index}>
              {index === appointments.length - 1 ? (
                <AppointmentOverview
                  ref={lastPostRef}
                  appointmentId={appointment.id}
                  appointmentStatus={(appointment as any)?.status}
                  scheduleDetails={(appointment as any)?.scheduleDetails}
                  key={appointment.id}
                  actionEnabled={getIsActionEnabled(
                    (appointment as any)?.status
                  )}
                  onApproveReject={onApproveReject}
                />
              ) : (
                <AppointmentOverview
                  appointmentId={appointment.id}
                  appointmentStatus={(appointment as any)?.status}
                  scheduleDetails={(appointment as any)?.scheduleDetails}
                  key={appointment.id}
                  actionEnabled={getIsActionEnabled(
                    (appointment as any)?.status
                  )}
                  onApproveReject={onApproveReject}
                />
              )}
            </Fragment>
          );
        })}
      </Box>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {Array.from({ length: 12 }).map((el) => {
            return <AppointmentOverviewSkeleton />;
          })}
        </Box>
      )}
    </>
  );
};

export default PatientUpcomingAppointments;
