import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(
  error: unknown
): error is { message: string } {
  return (
    typeof error === "object" &&
    error != null &&
    "message" in error &&
    typeof (error as any).message === "string"
  );
}

/**
 * Returns error message from API error object
 */
export const getErrorMessage = (err: any) => {
  if (isFetchBaseQueryError(err)) {
    let errMsg = "";
    if ("error" in err) errMsg = err.error;
    else {
      let _err = (err?.data as any)?.error;
      errMsg = _err?.message + ": " + (_err?.details ?? "");
    }
    return errMsg;
  } else if (isErrorWithMessage(err)) return err.message;
  else return "Some Error Occured. Please try again.";
};

export const getGender = (gender: number) => {
  switch (gender) {
    case 0:
      return "Male";
    case 1:
      return "Female";
    case 2:
      return "Other";
    default:
      return "";
  }
};
