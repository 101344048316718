import { useDispatch } from "react-redux";
import {
  dateSetterActions,
  DateSetterState,
} from "../app/store/slices/dateSetter";
import { Moment } from "moment";
import { useAppSelector } from "./useStoreHook";
import DateUtil, { dateUtil } from "../utils/dateHelper";

export const useDateSetter = () => {
  const dispatch = useDispatch();

  const setDate = (selectedDate: Moment) => {
    dispatch(
      dateSetterActions.setDate({
        selectedDate: dateUtil.formatDate(
          selectedDate,
          DateUtil.DateFormats.YEAR_MONTH_DATE
        ),
      })
    );
  };

  const setDefaultDate = () => {
    dispatch(dateSetterActions.setDefault());
  };

  return { setDate, setDefaultDate } as const;
};
