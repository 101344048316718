import { FC } from "react";
import { Box, ButtonBase, useTheme } from "@mui/material";

import { useAppSelector } from "../hooks/useStoreHook";
import { generateTwoLetters } from "../utils/stringHelper";
import { useBreakpoints } from "../hooks/useBreakpoints";

type Props = {
  isSelected: boolean;
  onClick?: () => void;
};

const ProfileNavIcon: FC<Props> = ({ isSelected, onClick }) => {
  const profile = useAppSelector((state) => state.userState);
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const { palette } = useTheme();

  const initials = generateTwoLetters(profile?.user?.fullName ?? "");
  const isProfilePicture = profile?.profilePicture;
  const size = isSM ? 24 : 38;
  const borderRadius = size / 2;

  return (
    <ButtonBase centerRipple onClick={onClick}>
      {isProfilePicture ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={size + 8}
          width={size + 8}
          borderRadius={borderRadius + 4}
          sx={{
            backgroundColor: isSelected ? "#307DF6" : "transparent",
            "&:hover": {
              backgroundColor: isSelected
                ? palette.primary.light
                : palette.grey[300],
            },
          }}
        >
          <Box
            component="img"
            sx={{ height: size, width: size, borderRadius, objectFit: "cover" }}
            alt="Profile Pic"
            src={profile?.profilePicture}
          />
        </Box>
      ) : (
        <Box
          width={size}
          height={size}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={borderRadius}
          sx={{
            backgroundColor: isSelected ? "#307DF6" : "#307cf61a",
            "&:hover": {
              backgroundColor: isSelected
                ? palette.primary.light
                : palette.grey[300],
            },
          }}
        >
          <p
            style={{
              color: isSelected ? "#fff" : "#307DF6",
              fontFamily: "Jost",
              fontSize: isSM ? 12 : 16,
              fontWeight: 500,
            }}
          >
            {initials}
          </p>
        </Box>
      )}
    </ButtonBase>
  );
};

export default ProfileNavIcon;
