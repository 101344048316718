import { PersonOutline, Edit } from "@mui/icons-material";
import { useRef, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  ButtonBase,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  SxProps,
  useTheme,
} from "@mui/material";
import {
  useDeleteProfilePictureMutation,
  useUploadProfilePictureMutation,
} from "../features/user/userAPI";
import { useAppSelector } from "../hooks/useStoreHook";
import Icon from "@mui/material/Icon";
import { NavigateFunction } from "react-router-dom";

type IFormInputProps = {
  name?: string;
  label?: string;
  isEdit?: boolean;
  height: number;
  width: number;
  loadUserPic?: boolean;
  userPic?: string | null;
  loading?: boolean;
  isNavigate?: boolean;
  navigate?: NavigateFunction | null;
};

export interface SimpleDialogProps {
  open: boolean;
  onClose: (value: string) => void;
}

export const profile_pic_options = [
  { title: "Add Profile Picture", id: "add_profile_pic", iconName: "add" },
  {
    title: "Delete Profile Picture",
    id: "delete_profile_pic",
    iconName: "delete",
  },
];

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose("emptu");
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Choose from below</DialogTitle>
      <List sx={{ pt: 0 }}>
        {profile_pic_options.map((option) => (
          <ListItem disableGutters key={option.id}>
            <ListItemAvatar>
              <Avatar>
                <Icon>{option.iconName}</Icon>
              </Avatar>
            </ListItemAvatar>
            <ListItemButton onClick={() => handleListItemClick(option.id)}>
              <ListItemText primary={option.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

const ProfilePicture = ({
  name = "fileUpload",
  isEdit = false,
  height,
  width,
  loadUserPic = false,
  userPic = null,
  isNavigate = false,
  navigate = null,
}: IFormInputProps) => {
  const [open, setOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const profilePic = useAppSelector((state) => state.userState.profilePicture);
  const { palette } = useTheme();
  const [uploadProfilePicture] = useUploadProfilePictureMutation();

  const [deletePicture] = useDeleteProfilePictureMutation();

  const imageSX: SxProps = {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  };

  const onFileDrop = (e: React.SyntheticEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    if (!target.files) return;
    const newFile = Object.values(target.files).map((file: File) => file);
    const formData = new FormData();
    formData.append("profilePicture", newFile[0]);
    uploadProfilePicture(formData);
  };

  const handleClickOpen = () => {
    if (!isEdit) return;
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    if (value === "add_profile_pic") {
      inputRef.current?.click();
    } else {
      deletePicture(null);
    }
  };

  const getPic = () => {
    if (loadUserPic) {
      if (userPic) {
        return (
          <Box component="img" sx={imageSX} alt="User Pic" src={userPic} />
        );
      }
    } else {
      if (profilePic) {
        return (
          <Box
            component="img"
            sx={imageSX}
            alt="Profile Pic"
            src={profilePic}
          />
        );
      }
    }
    return (
      <PersonOutline
        sx={{
          height: height,
          width: width,
          color: palette.common.editColor,
        }}
      />
    );
  };

  return (
    <Box>
      <Badge
        overlap="circular"
        onClick={handleClickOpen}
        invisible={!isEdit}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        badgeContent={
          <Avatar
            sx={{
              height: 36,
              width: 36,
              backgroundColor: palette.common.editColor,
            }}
          >
            <Edit />
          </Avatar>
        }
      >
        <ButtonBase disableRipple={!isEdit}>
          <Box
            component={"div"}
            onClick={() => {
              if (isNavigate && navigate) {
                navigate("/profile");
              }
            }}
          >
            <Avatar
              sx={{
                height: height,
                width: width,
                backgroundColor: palette.common.image,
              }}
            >
              {getPic()}
            </Avatar>
          </Box>
        </ButtonBase>
      </Badge>
      <SimpleDialog open={open} onClose={handleClose} />
      <input
        ref={inputRef}
        type="file"
        name={name}
        onChange={onFileDrop}
        disabled={!isEdit}
        multiple={false}
        accept="image/jpg, image/png, image/jpeg"
        style={{
          opacity: 0,
          position: "absolute",
          top: 0,
          left: 0,
          cursor: "pointer",
        }}
      />
    </Box>
  );
};

export default ProfilePicture;
