import { Box, Divider, Skeleton, SxProps } from "@mui/material";
import { useBreakpoints } from "../../hooks/useBreakpoints";

const AppointmentRequestSkeleton = () => {
  const [{ isMatchDownSM: isSM }] = useBreakpoints();

  const cardContainerSx: SxProps = {
    width: isSM ? "100%" : "49%",
    border: "1px solid #A3C9DE",
    borderRadius: isSM ? "20px" : "28px",
    my: 2,
    p: isSM ? 1.5 : 2,
  };

  return (
    <Box sx={cardContainerSx}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton
            variant="rectangular"
            width={isSM ? 150 : 180}
            height={isSM ? 20 : 24}
            sx={{ ml: 2 }}
          />
        </Box>
        {isSM && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            minWidth="25%"
          >
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="circular" width={40} height={40} />
          </Box>
        )}
      </Box>

      <Skeleton
        variant="rectangular"
        width="80%"
        height={16}
        sx={{ pr: 2, my: isSM ? 1.5 : 2 }}
      />
      <Skeleton
        variant="rectangular"
        width="80%"
        height={16}
        sx={{ pr: 2, my: isSM ? 1.5 : 2 }}
      />
      <Skeleton
        variant="rectangular"
        width="80%"
        height={16}
        sx={{ pr: 2, my: isSM ? 1.5 : 2 }}
      />
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: "flex" }}>
        <Skeleton
          variant="rectangular"
          width={isSM ? 120 : 150}
          height={40}
          sx={{ mr: 2, borderRadius: 24 }}
        />
        <Skeleton
          variant="rectangular"
          width={isSM ? 120 : 150}
          height={40}
          sx={{ borderRadius: 24 }}
        />
      </Box>
    </Box>
  );
};

export default AppointmentRequestSkeleton;
