import { Box, Divider, Typography } from "@mui/material";
import ProfilePicture from "../../components/ProfilePicture";

interface NotifyUserProps {
  title: string;
  showButton?: boolean;
  buttonValue?: string;
}
const NotifyUser = ({
  title,
  showButton = false,
  buttonValue,
}: NotifyUserProps) => {
  return (
    <Box
      sx={{
        m: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            my: 1.5,
          }}
        >
          <Box>
            <ProfilePicture
              width={34}
              height={34}
              name="User Image"
              loadUserPic
              userPic=""
            />
          </Box>
          <Typography sx={{ ml: 1 }}>{title}</Typography>
        </Box>
        {showButton && (
          <Box sx={{ my: 1.5, mr: 2 }}>
            <Typography
              variant="body1"
              color="primary"
              fontSize={"14px"}
              letterSpacing={0.1}
            >
              {buttonValue}
            </Typography>
          </Box>
        )}
      </Box>
      <Divider
        orientation="vertical"
        sx={{ borderWidth: 1, backgroundColor: "#C5C6D0", mt: 1 }}
      />
    </Box>
  );
};

export default NotifyUser;
