import { createApi } from "@reduxjs/toolkit/query/react";

import { Procedure } from "./../../provider/home/addAppointment/addAppointmentAPI";
import { encrypt } from "../../../utils/codec";
import { Res } from "../../../types";
import { SlotItem, bookAppointmentActions } from "./bookAppointmentSlice";

import baseQueryWithReauth from "../../../app/store/slices/apiSlice";
import config from "../../../config/config";
import { toastActions } from "../../../app/store/slices/toastSlice";
import { getErrorMessage } from "../../../utils/serviceHelper";

export const bookAppointmentAPI = createApi({
  reducerPath: "bookAppointMentAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getProcedures: builder.query<Procedure[], void>({
      query: () => "/api/services/app/ProcedureService/GetAll",
      transformResponse: (res: Res<Procedure[]>) => res.result,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(bookAppointmentActions.setProcedures(data));
      },
    }),
    getPhysicianTimeline: builder.mutation<SlotItem[], GetTimelinePayload>({
      query: (payload) => ({
        url: "/api/services/app/ScheduleService/Availability",
        method: "POST",
        body: encrypt(payload, config.secret),
      }),
      transformResponse: (res: Res<SlotItem[]>) => {
        return res.result.map((item) => ({ ...item, isSelected: false }));
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(bookAppointmentActions.setSlots(data));
      },
    }),
    bookAppointment: builder.mutation<ApointmentData, ApointmentPayload>({
      query: (payload) => {
        return {
          url: "/api/services/app/AppointmentService/BookAppointment",
          method: "POST",
          body: encrypt(payload, config.secret),
        };
      },
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          const { scheduleDetailsId: id } = payload;
          dispatch(bookAppointmentActions.updateConfirmation(id));
          const message = "Your booking is Comfirmed!!!";
          dispatch(toastActions.addToast({ message, type: "success" }));
        } catch (error) {
          const message = getErrorMessage((error as unknown as any).error);
          dispatch(toastActions.addToast({ message, type: "error" }));
        }
      },
    }),
    bookAppointmentWithoutAuth: builder.mutation<
      ApointmentData,
      ApointmentPayload
    >({
      query: (payload) => {
        return {
          url: "/api/services/app/AppointmentService/BookNow",
          method: "POST",
          body: encrypt(payload, config.secret),
        };
      },
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          const { scheduleDetailsId: id } = payload;
          dispatch(bookAppointmentActions.updateConfirmation(id));
          const message = "Appointment Request has been submitted.";
          dispatch(toastActions.addToast({ message, type: "success" }));
        } catch (error) {
          const message = getErrorMessage((error as unknown as any).error);
          dispatch(toastActions.addToast({ message, type: "error" }));
        }
      },
    }),
  }),
});

export const {
  useGetPhysicianTimelineMutation,
  useLazyGetProceduresQuery,
  useBookAppointmentMutation,
  useBookAppointmentWithoutAuthMutation,
} = bookAppointmentAPI;

interface GetTimelinePayload {
  providerId: string;
  startDate: string;
}

export interface ApointmentPayload {
  procedureOrServiceId: string;
  providerId: string;
  scheduleId: string;
  scheduleDetailsId: string;
  customerName: string;
  email: string;
  phoneNumber: string;
  notes?: string;
  appointmentType: number;
}

interface ApointmentData {
  procedureOrServiceToPerform: null | string;
  procedureOrServiceId: string;
  provider: null | string;
  providerId: number;
  customer: null | string;
  customerId: number;
  notes: string;
  scheduleId: string;
  scheduleDetails: null | string;
  scheduleDetailsId: string;
  rescheduleAppointmentId: null | string;
  status: number;
  rescheduleReason: null | string;
  id: string;
}
