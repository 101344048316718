import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

type ThemeInterface = {
  mode: "light" | "dark";
};

const themeSlice = createSlice({
  name: "theme",
  initialState: { mode: "light" } as ThemeInterface,
  reducers: {
    setTheme: (state, { payload }: PayloadAction<"light" | "dark">) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
  },
});

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;

export const selectCurrentTheme = (state: RootState) => state.theme.mode;
