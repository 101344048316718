import Box from "@mui/material/Box";
import {
  Icon,
  IconButton,
  InputAdornment,
  Container,
  Typography,
} from "@mui/material";
import { FormProvider } from "react-hook-form";
import { useResetFunction } from "./useResetFunction";
import { FormInputText } from "../../../../components/form/FormInput";
import { LoadingButton } from "@mui/lab";

function ResetPasswordForm(props: any) {
  const {
    isLoading,
    showPassword,
    methods,
    t,
    handleSubmit,
    onSubmitHandler,
    handleShowPasswordClick,
  } = useResetFunction(props);

  return (
    <Container sx={{ my: 8 }}>
      <Typography variant="h6">{t("auth.resetPassword")}</Typography>
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmitHandler)}
          sx={{ mt: 1 }}
        >
          <FormInputText
            sx={{
              border: "none",
              backgroundColor: "transparent",
            }}
            color="primary"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleShowPasswordClick}
                    aria-label="toggle password visibility"
                  >
                    <Icon>
                      {showPassword ? "visibility_off" : "visibility"}
                    </Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={t("auth.password")}
            size="medium"
            margin="normal"
            name="password"
            required
            fullWidth
            autoComplete="current-password"
          />
          <FormInputText
            sx={{
              border: "none",
              backgroundColor: "transparent",
            }}
            color="primary"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleShowPasswordClick}
                    aria-label="toggle password visibility"
                  >
                    <Icon>
                      {showPassword ? "visibility_off" : "visibility"}
                    </Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={t("auth.signup.confirmPassword")}
            size="medium"
            margin="normal"
            name="passwordConfirm"
            required
            fullWidth
            autoComplete="current-password"
          />
          <LoadingButton
            loading={isLoading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("auth.saveButton")}
          </LoadingButton>
        </Box>
      </FormProvider>
    </Container>
  );
}

export default ResetPasswordForm;
