import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { IProvider, IUser } from "../../../features/user/auth/authtypes";

interface IUserState {
  user: IUser | IProvider | null;
  accessToken: string | null;
  refreshToken: string | null;
  accessTokenExpiry: number;
  refreshTokenExpiry: number;
  profilePicture: string | null;
  profileState: number;
}

const initialState: IUserState = {
  user: null,
  accessToken: null,
  refreshToken: null,
  accessTokenExpiry: 0,
  refreshTokenExpiry: 0,
  profilePicture: null,
  profileState: 6,
};

const userSlice = createSlice({
  name: "userState",
  initialState: initialState,
  reducers: {
    setCredentials: (state, action) => {
      console.log("[userSlice]setCredentials", action.payload);
      const {
        accessToken,
        refreshToken,
        expireInSeconds,
        refreshTokenExpiry,
        profileStep,
      } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.accessTokenExpiry = expireInSeconds;
      state.refreshTokenExpiry = refreshTokenExpiry;
      state.profileState = profileStep;
    },
    setUser: (state, action: PayloadAction<IUser | IProvider>) => {
      state.user = action.payload;
    },
    setProfilePicture: (state, action: PayloadAction<string>) => {
      state.profilePicture = action.payload;
    },
    updateProfileState: (state, action: PayloadAction<number>) => {
      state.profileState = action.payload;
    },
  },
});

export const {
  setCredentials,
  setUser,
  setProfilePicture,
  updateProfileState,
} = userSlice.actions;

export default userSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.userState.user;
export const selectCurrentToken = (state: RootState) =>
  state.userState.accessToken;

export const selectCurrentRefreshToken = (state: RootState) =>
  state.userState.refreshToken;
