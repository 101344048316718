import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query";
import config from "../../../config/config";
import { RootState } from "..";
import {
  selectCurrentRefreshToken,
  selectCurrentToken,
  setCredentials,
} from "./userSlice";
import { Mutex } from "async-mutex";
import { resetStateAction } from "../../action";
import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: config.apiGateway.URL,
  credentials: "include",
  prepareHeaders: (headers, { getState, ...rest }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).userState.accessToken;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    if (!headers.has("Content-Type")) {
      headers.set("Content-Type", "application/json");
    } else if (headers.get("Content-Type") === "multipart/form-data") {
      headers.delete("Content-Type");
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  let response = result.error as any;
  if (response?.data?.error && response?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        console.log("sending refresh token");
        // try to get a new token
        const body = {
          accessToken: selectCurrentToken,
          refreshToken: selectCurrentRefreshToken,
        };
        const refreshResult = await baseQuery(
          {
            method: "POST",
            url: "/api/TokenAuth/RefreshToken",
            body,
            credentials: "include",
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          // store the new token
          const user = (api.getState as unknown as RootState).userState.user;
          if (user !== null) {
            api.dispatch(setCredentials({ ...refreshResult.data }));
          }

          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(resetStateAction());
          window.location.href = "/auth";
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export default baseQueryWithReauth;
