import { LoadingButton } from "@mui/lab";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Icon, IconButton, InputAdornment, Container } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { FormInputText } from "../../../../components/form/FormInput";
import { useSigninFunction } from "./useSigninFunction";
import { FormCheckbox } from "../../../../components/form/FormCheckbox";
import { useEffect } from "react";
import { useToast } from "../../../../hooks/useToast";

function SigninForm(props: any) {
  const {
    isLoading,
    showPassword,
    methods,
    t,
    handleSubmit,
    onSubmitHandler,
    handleShowPasswordClick,
  } = useSigninFunction(props);

  const location = useLocation();
  const { displayToast } = useToast();

  useEffect(() => {
    if (location?.state?.from === "signUp") {
      window.history.replaceState({}, document.title);
      displayToast({
        message: location?.state?.message,
        type: "success",
      });
    }
  }, []);

  return (
    <Container sx={{ mt: 2 }}>
      {/* <SignLinks />
      <Divider flexItem sx={{ m: 2, mt: 6 }}>
        or
      </Divider> */}
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmitHandler)}
          // sx={{ mt: 1 }}
        >
          <FormInputText
            sx={{
              border: "none",
              backgroundColor: "transparent",
            }}
            color="primary"
            variant="outlined"
            size="medium"
            margin="normal"
            fullWidth
            name="email"
            label={t("auth.email")}
            type="email"
          />
          <FormInputText
            sx={{
              border: "none",
              backgroundColor: "transparent",
            }}
            color="primary"
            variant="outlined"
            size="medium"
            margin="normal"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleShowPasswordClick}
                    aria-label="toggle password visibility"
                  >
                    <Icon>
                      {showPassword ? "visibility_off" : "visibility"}
                    </Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            name="password"
            label={t("auth.password")}
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
          />
          <Grid container alignItems={"center"} sx={{ mb: 1 }}>
            <Grid item xs>
              <Link component={RouterLink} to="/auth/forgot" variant="body2">
                {t("auth.signin.forgotPassword")}
              </Link>
            </Grid>
            <Grid item xs>
              <FormCheckbox
                sx={{
                  width: "100%",
                  justifyContent: "flex-end",
                }}
                label={t("auth.signin.remember")}
                name="remember"
              />
            </Grid>
          </Grid>

          <Link component={RouterLink} to="/auth/signup">
            <Typography variant="button">{t("auth.signin.create")}</Typography>
          </Link>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            loading={isLoading}
            sx={{ mt: 3, mb: 2 }}
          >
            {t("auth.signin.button")}
          </LoadingButton>
        </Box>
      </FormProvider>
    </Container>
  );
}

export default SigninForm;
