import { forwardRef, useState } from "react";
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { Box, SxProps, styled, useTheme } from "@mui/material";
import { Moment } from "moment";
import { useBreakpoints } from "../../../../../hooks/useBreakpoints";

interface WeekCalendarProps {
  open: boolean;
  value: Moment;
  onChange: (date?: Moment) => void;
}

interface CustomPickerDayProps extends PickersDayProps<Moment> {
  isSelected: boolean;
  isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isHovered",
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),
  ...(day.day() === 0 && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(day.day() === 6 && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as React.ComponentType<CustomPickerDayProps>;

const isInSameWeek = (dayA: Moment, dayB: Moment | null | undefined) => {
  if (dayB == null) {
    return false;
  }

  return dayA.isSame(dayB, "week");
};

function Day(
  props: PickersDayProps<Moment> & {
    selectedDay?: Moment | null;
    hoveredDay?: Moment | null;
  }
) {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={isInSameWeek(day, selectedDay)}
      isHovered={isInSameWeek(day, hoveredDay)}
    />
  );
}

const WeekCalendar = forwardRef((props: WeekCalendarProps, ref) => {
  const [hoveredDay, setHoveredDay] = useState<Moment | null>(null);
  const theme = useTheme();
  const { palette } = theme;
  const [{ isMatchDownSM: isSM }] = useBreakpoints();

  const containerSx: SxProps = {
    position: "absolute",
    bgcolor: palette.common.white,
    zIndex: 10,
    top: 60,
    right: 0,
    left: 0,
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24,
  };

  const handleChange = (newValue: Moment | null) => {
    if (!newValue) return;
    props.onChange(newValue);
  };

  if (!props.open) return null;
  return (
    <Box ref={ref} sx={containerSx}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateCalendar
          //views={["year", "month", "day"]}
          value={props.value}
          onChange={handleChange}
          slots={{ day: Day }}
          slotProps={{
            day: (ownerState) =>
              ({
                selectedDay: props.value,
                hoveredDay,
                onPointerEnter: () => setHoveredDay(ownerState.day),
                onPointerLeave: () => setHoveredDay(null),
              } as any),
          }}
        />
      </LocalizationProvider>
    </Box>
  );
});

export default WeekCalendar;
