import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface NotificationState {
  open?: boolean;
}

export const notificationInitialState: NotificationState = {
  open: false,
};

export const NotificationSlice = createSlice({
  name: "notification",
  initialState: notificationInitialState,
  reducers: {
    openNotification: (_state, action: PayloadAction<NotificationState>) => ({
      ...notificationInitialState,
      ...action.payload,
      open: true,
    }),
    closeNotification: (state) => ({ ...state, open: false }),
  },
});

export const notificationActions = NotificationSlice.actions;
export const notificationReducer = NotificationSlice.reducer;
