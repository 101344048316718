import { Box, ButtonBase, Grid, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { images } from "../../assets";
import Questionnaire from "../../features/survey/Questionnaire";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { useAppSelector } from "../../hooks/useStoreHook";

export default function InfowithAction(props: any) {
  const { palette } = useTheme();
  const [breakPoints] = useBreakpoints();
  const { isMatchDownSM: isSM, isMatchDownMD } = breakPoints;

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const checkColonscopyRequired = useAppSelector(
    (state) => state.checkColonscopyRequired
  );

  return (
    <>
      <Grid
        container
        direction={isSM ? "column" : "row"}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} sm={0.4} />
        <Grid item xs={12} sm={4}>
          <Box
            component="img"
            sx={{
              height: isMatchDownMD ? 286 : 430,
              width: isMatchDownMD ? 286 : 430,
            }}
            alt="Gastro image"
            src={images.carousel.temp}
          />
        </Grid>
        <Grid item xs={12} sm={7.2}>
          <Box
            my={2}
            display={"flex"}
            flexDirection={"column"}
            color={palette.common.deselected}
            mx={{ xs: 2 }}
          >
            <Typography component="div" variant="h1" paddingBottom={1}>
              Take the quiz
            </Typography>
            <Typography
              component="div"
              variant="body1"
              color={palette.common.deselected}
            >
              Colonoscopy is a crucial diagnostic tool for detecting and
              preventing colorectal cancer, as well as other gastrointestinal
              conditions. However, not everyone may require or be eligible for
              this procedure. Factors such as age, medical history, and specific
              symptoms play a role in determining eligibility.
            </Typography>
            <ButtonBase
              onClick={() => setOpen(true)}
              sx={[
                {
                  flex: 1,
                  py: 1,
                  px: 2,
                  my: 2,
                  alignSelf: "flex-start",
                  "&.MuiButtonBase-root": {
                    backgroundColor: palette.primary.main,
                    width: "auto",
                    margin: "16px 0px",
                    borderRadius: "24px",
                  },
                },
                isSM && { alignSelf: "center" },
              ]}
            >
              <Typography component="div" variant="body2" color={"white"}>
                {checkColonscopyRequired.checked ? "View Status" : "Check Now"}
              </Typography>
            </ButtonBase>
          </Box>
        </Grid>
        <Grid item xs={12} sm={0.4} />
      </Grid>
      {open && <Questionnaire open={open} handleClose={handleClose} />}
    </>
  );
}
