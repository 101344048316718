import { Box, Divider, Typography } from "@mui/material";

interface SubtitleProps {
  heading: string;
  isDivider?: boolean;
}
export default function Subtitle(props: SubtitleProps) {
  const { heading, isDivider = true } = props;
  return (
    <Box
      sx={{
        flexDirection: "row",
        display: "flex",
        flex: 1,
        paddingTop: isDivider ? 0 : 2,
        justifyContent: isDivider ? "space-between" : "center",
        alignItems: "center",
      }}
    >
      <Typography component="div" variant="h5">
        {heading}
      </Typography>
      {isDivider && <Divider sx={{ flex: 1, ml: 4 }} />}
    </Box>
  );
}
