import { Box, Typography } from "@mui/material";
import {
  AppointmentStatusCode,
  EVENT_STATUS_COLORS,
} from "../../../../components/event-calendar/utils/constants";
import DateUtil, { dateUtil } from "../../../../utils/dateHelper";
import { images } from "../../../../assets";

interface AppointmentDataProps {
  title: string;
  customer: {
    fullName: string;
    emailAddress: string;
  };
  appointmentType: 1 | 2;
}
interface ScheduleDetails {
  startTime: string;
  endTime: string;
  id: string;
}
interface AppointmentEventProps {
  appointment: AppointmentDataProps | null;
  scheduleDetails: ScheduleDetails;
  status: string;
}

const AppointmentEvent = ({
  appointment,
  scheduleDetails,
  status,
}: AppointmentEventProps) => {
  const background =
    EVENT_STATUS_COLORS[status as AppointmentStatusCode]?.bgColor;
  const color = EVENT_STATUS_COLORS[status as AppointmentStatusCode]?.color;

  let type = "";
  if (appointment?.appointmentType === 1) type = images.app.walkin;
  if (appointment?.appointmentType === 2) type = images.app.telephonic;

  return (
    <Box sx={{ my: 2 }}>
      <Typography variant="body2">
        {dateUtil.formatDate(
          scheduleDetails.startTime,
          DateUtil.DateFormats.TIME
        )}{" "}
        -{" "}
        {dateUtil.formatDate(
          scheduleDetails.endTime,
          DateUtil.DateFormats.TIME
        )}
      </Typography>
      <Box
        mt={0.5}
        pl={0.4}
        bgcolor={`${background}70`}
        height={80}
        color="black"
      >
        <Box
          bgcolor={color}
          height="100%"
          display={"flex"}
          flexDirection={"column"}
        >
          <Box
            pl={0.4}
            ml={2}
            bgcolor={background}
            height="100%"
            color="black"
            borderRadius={"4px"}
          >
            <Box
              bgcolor={color}
              height="100%"
              borderRadius={"4px"}
              display={"flex"}
              position="relative"
              flexDirection={"column"}
            >
              <Box sx={{ mx: 2, my: 1 }}>
                <Typography variant="body1">
                  {appointment?.customer?.fullName ?? ""}
                </Typography>
                <Typography variant="body1">
                  {appointment?.customer?.emailAddress ?? ""}
                </Typography>
              </Box>
              {appointment && (
                <Box position="absolute" right={8} top={8}>
                  <Box component="img" src={type} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AppointmentEvent;
