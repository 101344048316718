import { Middleware, combineReducers } from "@reduxjs/toolkit";
import authAPI from "../../features/user/auth/authAPI";
import { userApi } from "../../features/user/userAPI";

import themeReducer from "./slices/themeSlice";
import userSlice from "./slices/userSlice";
import forgotReducer from "./slices/forgotSlice";

import { Reducer } from "redux";
import { resetStateAction } from "../action";
import { toastReducer } from "./slices/toastSlice";
import { createAppointmentReducer } from "./slices/createAppointmentSlice";
import { notificationReducer } from "./slices/notificationSlice";
import { providerAPI } from "../../features/provider/providerAPI";
import { faqAPI } from "../../features/patient/dashboard/faqAPI";
import { notificationAPI } from "../../features/notification/notificationAPI";
import { calendarAPI } from "../../features/provider/calendar/calendarAPI";
import { settingsAPI } from "../../features/provider/settings/SetUpTimeSlot/timeSlotAPI";
import { physiciansByAreaAPI } from "../../features/patient/home/physiciansByAreaAPI";
import { bookAppointmentAPI } from "../../features/patient/book-appointment/bookAppointmentAPIs";
import { bookAppointMentReducer } from "../../features/patient/book-appointment/bookAppointmentSlice";
import { surveyApi } from "../../features/survey/surveyApi";
import { checkColonscopyRequiredReducer } from "./slices/checkColonoscopyRequired";
import { profilePictureAPI } from "../../features/patient/dashboard/profilePictureAPI";
import { dateSetterReducer } from "./slices/dateSetter";
import { popUpReducer } from "./slices/popupSlice";

type RootState = ReturnType<typeof combinedReducer>;

const reducers = {
  [authAPI.reducerPath]: authAPI.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [providerAPI.reducerPath]: providerAPI.reducer,
  [faqAPI.reducerPath]: faqAPI.reducer,
  [notificationAPI.reducerPath]: notificationAPI.reducer,
  [calendarAPI.reducerPath]: calendarAPI.reducer,
  [settingsAPI.reducerPath]: settingsAPI.reducer,
  [physiciansByAreaAPI.reducerPath]: physiciansByAreaAPI.reducer,
  [bookAppointmentAPI.reducerPath]: bookAppointmentAPI.reducer,
  [surveyApi.reducerPath]: surveyApi.reducer,
  [profilePictureAPI.reducerPath]: profilePictureAPI.reducer,
  userState: userSlice,
  forgot: forgotReducer,
  theme: themeReducer,
  toast: toastReducer,
  createAppointment: createAppointmentReducer,
  notification: notificationReducer,
  checkColonscopyRequired: checkColonscopyRequiredReducer,
  bookAppointment: bookAppointMentReducer,
  dateSetter: dateSetterReducer,
  popup: popUpReducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === resetStateAction.type) {
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

export const additionalMiddlewares: Middleware[] = [
  authAPI.middleware,
  userApi.middleware,
  providerAPI.middleware,
  faqAPI.middleware,
  notificationAPI.middleware,
  calendarAPI.middleware,
  settingsAPI.middleware,
  physiciansByAreaAPI.middleware,
  bookAppointmentAPI.middleware,
  surveyApi.middleware,
  profilePictureAPI.middleware,
];

export default rootReducer;
