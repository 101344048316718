import { Box, Divider, Rating, Typography, useTheme } from "@mui/material";
import IconButton from "./IconButton";
import { Fragment, forwardRef, useState } from "react";
import AppointmentNotes from "./AppointmentNotes";
import DateUtil, { dateUtil } from "../utils/dateHelper";
import { getAppointmentStatus } from "../features/provider/appointment/appointmentRequestsAPI";
import Labeler from "./Labeler";
import { LoadingButton } from "@mui/lab";
import WriteReview from "./WriteReview";

interface IAppointmentOverview {
  appointmentId: string;
  appointmentStatus: number;
  physicanDetails?: any;
  scheduleDetails?: any;
  procedureName?: string;
  actionEnabled?: boolean;
  isPast?: boolean;
  isRated?: boolean;
  isLoading?: boolean;
  onCancelled?: (appointmentId: string, _status: number) => void;
  rating?: number;
}
const AppointmentPatientOverview = forwardRef(
  (
    {
      appointmentId,
      appointmentStatus,
      physicanDetails,
      procedureName,
      scheduleDetails,
      actionEnabled = false,
      isPast,
      isRated = false,
      onCancelled,
      isLoading = false,
      rating = 0,
    }: IAppointmentOverview,
    ref
  ) => {
    const { palette } = useTheme();
    const [open, setOpen] = useState(false);
    const [reviewOpen, setReviewOpen] = useState(false);

    const handleDialogClick = () => {
      setOpen(!open);
    };

    const handleReviewDialogClick = () => {
      setReviewOpen(!reviewOpen);
    };
    return (
      <Fragment>
        <Box
          ref={ref ?? null}
          sx={{
            width: "48%",
            m: "1%",
            border: "1px solid #CDE5FF",
            p: 3,
            borderRadius: 2,
            backgroundColor: "#FCFCFF",
          }}
        >
          <Typography variant="subtitle2">
            {scheduleDetails
              ? `${dateUtil.formatDate(
                  scheduleDetails?.startTime,
                  DateUtil.DateFormats.DATE_TIME
                )} - ${dateUtil.formatDate(
                  scheduleDetails?.endTime,
                  DateUtil.DateFormats.TIME
                )}`
              : ""}
          </Typography>
          <Divider sx={{ my: 2, borderColor: "#CDE5FF" }} />
          <Box sx={{ display: "flex", flexDirection: "column", mx: 3, my: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Labeler
                  label="Physician"
                  value={physicanDetails?.fullName ?? ""}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Labeler label="Treatment" value={procedureName ?? ""} />
              </Box>
            </Box>
            {/* <Box sx={{ flex: 1, mt: 2 }}>
              <Labeler
                label="Address"
                value="308 W 21st Street NYC, NY- 00000"
              />
            </Box> */}
          </Box>

          <Divider sx={{ my: 2, borderColor: "#CDE5FF" }} />
          <Box
            sx={{ display: "flex" }}
            alignItems={"center"}
            justifyContent={isPast && isRated ? "space-between" : "flex-start"}
          >
            <Box sx={{ mr: 2 }}>
              <IconButton
                icon="note"
                onClick={handleDialogClick}
                sx={{
                  bgcolor: "#F1F3FF",
                  borderRadius: 3,
                  color: palette.common.editColor,
                }}
                title="Notes"
                iconSx={{ mx: 1, fontSize: 20 }}
                titleSx={{ mr: 1.5, fontSize: 14 }}
                titleVariant="body1"
              />
            </Box>
            {isPast ? (
              !isRated ? (
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  onClick={handleReviewDialogClick}
                  sx={{ bgcolor: "#CFE5FF", color: "#001D33" }}
                >
                  Rate your experience
                </LoadingButton>
              ) : (
                <Rating name="read-only" value={rating} readOnly />
              )
            ) : actionEnabled ? (
              <LoadingButton
                loading={isLoading}
                variant="contained"
                onClick={() => {
                  if (onCancelled) {
                    onCancelled(appointmentId, 7);
                  }
                }}
                sx={{ bgcolor: "#FFDAD6", color: "#001D33" }}
              >
                Cancel Appointment
              </LoadingButton>
            ) : (
              <IconButton
                icon=""
                variant="primary"
                onClick={() => {}}
                sx={{
                  borderRadius: 8,
                  backgroundColor: "#1D1B2012",
                }}
                title={getAppointmentStatus(appointmentStatus)}
                iconSx={{ mx: 1, fontSize: 20 }}
                titleSx={{ mr: 1.5, fontSize: 14 }}
                titleVariant="body1"
              />
            )}
          </Box>
        </Box>
        {open && (
          <AppointmentNotes
            isPatient
            open={open}
            handleClose={handleDialogClick}
            appointmentId={appointmentId}
          />
        )}
        {reviewOpen && (
          <WriteReview
            open={reviewOpen}
            handleClose={handleReviewDialogClick}
            appointmentId={appointmentId}
          />
        )}
      </Fragment>
    );
  }
);
export default AppointmentPatientOverview;
