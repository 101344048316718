import { Box, Drawer, Modal, SxProps } from "@mui/material";

import { useBreakpoints } from "../../../hooks/useBreakpoints";
import PatientInfoForm, { PatientFormType } from "./PatientInfoForm";

interface Props {
  open: boolean;
  onClose: (formData?: PatientFormType) => void;
}

const PatientInfoModal = (props: Props) => {
  const [{ isMatchDownSM: isSM }] = useBreakpoints();

  const modalStyle: SxProps = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  if (isSM) {
    return (
      <Drawer
        open={props.open}
        onClose={() => props.onClose()}
        anchor="bottom"
        PaperProps={{
          style: { borderTopRightRadius: 28, borderTopLeftRadius: 28 },
        }}
      >
        <Box sx={{ p: 4 }}>
          <PatientInfoForm onClose={props.onClose} />
        </Box>
      </Drawer>
    );
  }

  return (
    <Modal open={props.open} onClose={() => props.onClose()}>
      <Box sx={modalStyle}>
        <PatientInfoForm onClose={props.onClose} />
      </Box>
    </Modal>
  );
};

export default PatientInfoModal;
