import { Box, Typography } from "@mui/material";
import AppointmentRequest from "../../../../components/AppointmentRequest";
import AppointmentRequestSkeleton from "../../../../components/skeletons/AppointmentRequestSkeleton";
import { useUpcomingAppointments } from "./useUpcomingAppointments";
import { getGender } from "../../../../utils/serviceHelper";
import DataUtil from "../../../../utils/dataHelper";
import { dateUtil } from "../../../../utils/dateHelper";
import CenteredBox from "../../../../components/CenteredBox";
import { useBreakpoints } from "../../../../hooks/useBreakpoints";

const UpcomingAppointmentsList = () => {
  const {
    isLoading,
    lastPostRef,
    appointments,
    onApproveReject,
    isApporvalLoading,
    isError,
  } = useUpcomingAppointments();
  const [{ isMatchDownSM: isSM }] = useBreakpoints();

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexWrap: "wrap",
          justifyContent: "space-between",
          pr: 1,
        }}
      >
        {Array.from({ length: 12 }).map((el, index) => {
          return <AppointmentRequestSkeleton key={index} />;
        })}
      </Box>
    );
  }

  if (isError) {
    return (
      <CenteredBox height="30vh">
        <Typography>Something went wrong while fetching the data</Typography>
      </CenteredBox>
    );
  }

  if (appointments.length === 0) {
    return (
      <CenteredBox height="30vh">
        <Typography>No appointments found</Typography>
      </CenteredBox>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        width: "100%",
        pr: 1,
      }}
    >
      {appointments.map((appointment: any, index: number) => {
        return (
          <Box
            key={appointment.id ?? index}
            sx={{ width: isSM ? "100%" : "49%" }}
          >
            {index === appointments.length - 1 ? (
              <AppointmentRequest
                ref={lastPostRef}
                id={appointment.customer?.id}
                appointmentType={appointment.appointmentType}
                isLoading={isApporvalLoading}
                name={appointment.customer?.fullName ?? ""}
                age={dateUtil.calculateAgeFromDOB(
                  appointment.customer?.dateOfBirth
                )}
                emailAddress={appointment?.customer?.emailAddress ?? ""}
                phoneNumber={appointment?.customer?.phoneNumber ?? ""}
                picPath={appointment.customer?.profileImage ?? ""}
                appointmentDate={appointment.scheduleDetails?.startTime ?? null}
                gender={getGender(appointment.customer.gender ?? null)}
                insuranceProvider={
                  appointment.customer?.insuranceProvider ?? ""
                }
                insuranceNumber={appointment.customer?.insuranceNumber ?? ""}
                location={DataUtil.formatAddress(appointment.customer?.address)}
                onApprove={() => onApproveReject(appointment.id, 1)}
                onReject={() => onApproveReject(appointment.id, 2)}
              />
            ) : (
              <AppointmentRequest
                isLoading={isApporvalLoading}
                id={appointment.customer?.id}
                name={appointment.customer?.fullName ?? ""}
                appointmentType={appointment.appointmentType}
                age={dateUtil.calculateAgeFromDOB(
                  appointment.customer?.dateOfBirth
                )}
                emailAddress={appointment?.customer?.emailAddress ?? ""}
                phoneNumber={appointment?.customer?.phoneNumber ?? ""}
                appointmentDate={appointment.scheduleDetails?.startTime ?? null}
                picPath={appointment.customer?.profileImage ?? ""}
                gender={getGender(appointment.customer.gender ?? null)}
                insuranceProvider={
                  appointment.customer?.insuranceProvider ?? ""
                }
                insuranceNumber={appointment.customer?.insuranceNumber ?? ""}
                location={DataUtil.formatAddress(appointment.customer?.address)}
                onApprove={() => onApproveReject(appointment.id, 1)}
                onReject={() => onApproveReject(appointment.id, 2)}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default UpcomingAppointmentsList;
