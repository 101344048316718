import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useIntersectionObserver } from "../../../../hooks/useIntersectionObserver";
import {
  appointmentsAdapter,
  appointmentsSelector,
  useGetAllMyAppointmentsQuery,
  useLazyGetAllMyAppointmentsQuery,
} from "../providerPatientsAPI";

const limit = 10;
export function usePastRequest() {
  const { t } = useTranslation();
  const currentPage = useRef(1);

  const [fetchAppointments, { isFetching }] =
    useLazyGetAllMyAppointmentsQuery();

  const {
    appointments,
    hasNextPage,
    isFetching: isInitialFetching,
    isError,
  } = useGetAllMyAppointmentsQuery(
    {
      pageNumber: 1,
      limit: limit,
      filterByPatient: "",
      startDate: "",
      endDate: "",
      appointmentStatus: [3],
    },
    {
      selectFromResult: ({ data, ...otherParams }) => {
        return {
          hasNextPage: data?.hasMorePages,
          appointments: appointmentsSelector.selectAll(
            data ?? appointmentsAdapter.getInitialState()
          ),
          ...otherParams,
        };
      },
    }
  );

  const callAPI = async () => {
    if (!hasNextPage || isFetching) return;
    currentPage.current += 1;
    await fetchAppointments({
      pageNumber: currentPage.current,
      limit: limit,
      filterByPatient: "",
      startDate: "",
      endDate: "",
      appointmentStatus: [2],
    });
  };

  const [lastPostRef] = useIntersectionObserver(
    isFetching || isInitialFetching,
    callAPI,
    hasNextPage
  );

  return {
    t,
    isLoading: isFetching || isInitialFetching,
    lastPostRef,
    appointments,
    isError,
  };
}
