import { Box, Typography, useTheme } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

interface QuestionProps {
  question: string;
  active?: boolean;
  onSelection: any;
}
function Questions(props: QuestionProps) {
  const { active = false, question, onSelection } = props;
  const { palette } = useTheme();

  return (
    <Box
      onClick={onSelection}
      sx={{
        display: "flex",
        flexDirection: "row",
        py: 3,
        px: 2,
        justifyContent: "space-between",
        backgroundColor: active
          ? palette.common.faqColor
          : palette.common.white,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            height: "24px",
            width: "24px",
            borderRadius: "12px",
            backgroundColor: active ? palette.primary[palette.mode] : "#CDE5FF",
          }}
        />
        <Typography component="div" variant="body2" sx={{ ml: 2 }}>
          {question}
        </Typography>
      </Box>
      <NavigateNextIcon fontSize="medium" color="primary" />
    </Box>
  );
}

export default Questions;
