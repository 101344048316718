import { Box, SxProps } from "@mui/material";
import { FC } from "react";

type Props = { children: JSX.Element; sx?: SxProps; size: number };

const IconRounder: FC<Props> = ({ children, sx, size }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={size}
      width={size}
      borderRadius={size / 2}
      sx={sx}
    >
      {children}
    </Box>
  );
};

export default IconRounder;
