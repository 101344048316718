import { useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Box, Button, Divider, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { zodResolver } from "@hookform/resolvers/zod";

import { FormInputText } from "../../../../../components/form/FormInput";
import { FormInputDropdown } from "../../../../../components/form/FormDropdown";
import {
  IProfessionalSchema,
  professionalSchema,
} from "../../completeProfileSchema";
import { useCompleteProfessionalProfileMutation } from "../../completeProfileAPI";
import { useAppSelector } from "../../../../../hooks/useStoreHook";
import { IProvider } from "../../../../user/auth/authtypes";
import { getClinicAddress } from "../providerProfileHelper";
import Awards from "./Awards";
import { getErrorMessage } from "../../../../../utils/serviceHelper";
import AddressModal from "./AddressModal";
import { useToast } from "../../../../../hooks/useToast";
import { LatLng } from "../../../../../components/Map";
import { useBreakpoints } from "../../../../../hooks/useBreakpoints";

interface ProfessionalDetailsProps {
  onNextClick: () => void;
  onBackClick: () => void;
}
const ProfessionalDetails = (props: ProfessionalDetailsProps) => {
  const [awards, setAwards] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const userData = useAppSelector((state) => state.userState.user) as IProvider;
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const methods = useForm<IProfessionalSchema>({
    resolver: zodResolver(professionalSchema),
    defaultValues: useMemo(() => {
      return {
        education: userData?.professionalDetail?.educationQualification ?? "",
        experience: +userData?.professionalDetail?.experience ?? null,
        medicalSchool: userData?.professionalDetail?.medicalSchool ?? "",
        residency: userData?.professionalDetail?.residency ?? "",
        fellowship: userData?.professionalDetail?.fellowship ?? "",
        award: "",
        clinicAddress:
          getClinicAddress(userData?.professionalDetail?.businessAddress) ?? "",
        npi: userData?.npi ?? "",
        streetLineOne:
          userData?.professionalDetail?.businessAddress?.streetLineOne ?? "",
        streetLineTwo:
          userData?.professionalDetail?.businessAddress?.streetLineTwo ?? "",
        city: userData?.professionalDetail?.businessAddress?.city ?? "",
        zipCode: userData?.professionalDetail?.businessAddress?.zipCode ?? "",
        addressType:
          userData?.professionalDetail?.businessAddress?.addressType ?? 0,
        isCurrent: userData?.professionalDetail?.businessAddress?.isCurrent
          ? 1
          : 0,
        latitude: 0,
        longitude: 0,
        stateOrRegion:
          userData?.professionalDetail?.businessAddress?.stateOrRegion ?? "",
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]),
  });
  const { handleSubmit, getValues, setValue } = methods;

  useEffect(() => {
    if (!userData?.professionalDetail?.awardsList) return;
    const { awardsList } = userData.professionalDetail;
    if (awardsList.length > 0) setAwards(awardsList);
  }, [userData]);

  const handleNPISubmit = () => {
    console.log("NPI Submit Clicked");
  };

  const getExperienceRange = (start: number, end: number) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push({ label: `${i}`, value: i });
    }
    return options;
  };

  const handleAwardAdd = () => {
    const addedAward = getValues().award as string;
    setValue("award", "");
    if (addedAward.trim().length === 0) return;
    setAwards((prevAwards) => [...prevAwards, addedAward]);
  };

  const handleAwardDelete = (deleteAward: string) => {
    setAwards((prevAwards) => {
      return prevAwards.filter((award) => award !== deleteAward);
    });
  };

  const handleAddressClick = () => {
    setIsOpen(true);
  };

  const [completeProfile, status] = useCompleteProfessionalProfileMutation();
  const { displayToast } = useToast();

  useEffect(() => {
    if (status.isSuccess) props.onNextClick();
    if (status.isError) {
      const errorMessage = getErrorMessage(status.error);
      displayToast({ message: errorMessage, type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.isLoading]);

  const onSubmitHandler: SubmitHandler<IProfessionalSchema> = (values) => {
    completeProfile({ ...values, awards });
  };

  const handleModalClose = (position: LatLng | null) => {
    setValue("clinicAddress", getClinicAddress(getValues()));
    setIsOpen(false);
    if (position) {
      setValue("latitude", position.lat);
      setValue("longitude", position.lng);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid
          container
          direction="row"
          spacing={2}
          marginTop={0.5}
          marginBottom={1.5}
        >
          <Grid item xs={9} sm={6} md={6}>
            <FormInputText name="npi" label="NPI Number" fullWidth />
          </Grid>
          <Grid item xs={3} sm={6} md={6} display="flex" alignItems="center">
            <Button type="button" variant="outlined" onClick={handleNPISubmit}>
              Submit
            </Button>
          </Grid>
        </Grid>
        {/* <Divider>or</Divider> */}
        <Grid container direction="row" spacing={2} marginY={0.5}>
          <Grid item xs={9} sm={10} md={10}>
            <FormInputText
              type="text"
              name="education"
              label="Education Qualification"
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={3} sm={2} md={2} display="flex" alignItems="center">
            <Button
              type="button"
              variant="outlined"
              fullWidth
              onClick={handleNPISubmit}
            >
              Add
            </Button>
          </Grid> */}
        </Grid>
        <Grid container direction="row" spacing={2} marginY={0.5}>
          <Grid item xs={12} sm={4} md={4}>
            <FormInputText
              name="medicalSchool"
              label="Medical School"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FormInputText name="residency" label="Residency" fullWidth />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FormInputText
              name="fellowship"
              label="Fellowship School"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} marginY={0.5}>
          <Grid item xs={12} sm={3} md={3}>
            <FormInputDropdown
              options={getExperienceRange(0, 30)}
              name="experience"
              label="Experience"
              fullWidth
              withLabel
            />
          </Grid>
          <Grid item xs={12} sm={9} md={9} onClick={handleAddressClick}>
            <FormInputText
              name="clinicAddress"
              label="Clinic/Business Address"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} marginY={0.5}>
          <Grid item xs={12} sm={6} md={6}>
            <FormInputText name="award" label="Awards" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6} md={6} display="flex" alignItems="center">
            <Button type="button" variant="outlined" onClick={handleAwardAdd}>
              Add
            </Button>
          </Grid>
        </Grid>
        <Awards awards={awards} onDelete={handleAwardDelete} marginBottom={4} />
        {!isSM && <Divider />}
        <Grid
          container
          display="flex"
          justifyContent={isSM ? "space-between" : "flex-end"}
          marginTop={2}
        >
          <Box
            marginRight={isSM ? 0 : 2}
            style={{ width: isSM ? "45%" : "auto" }}
          >
            <Button
              fullWidth={isSM}
              type="button"
              variant="outlined"
              onClick={props.onBackClick}
            >
              Back
            </Button>
          </Box>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={status.isLoading}
            style={{ width: isSM ? "45%" : "auto" }}
          >
            Next
          </LoadingButton>
        </Grid>
      </form>
      <AddressModal
        onCancel={() => setIsOpen(false)}
        onClose={handleModalClose}
        setValue={setValue}
        isOpen={isOpen}
      />
    </FormProvider>
  );
};

export default ProfessionalDetails;
