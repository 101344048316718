import { Box, Drawer, Modal, SxProps } from "@mui/material";

import { useBreakpoints } from "../hooks/useBreakpoints";
import React from "react";

interface Props {
  children: JSX.Element | JSX.Element[];
  open: boolean;
  width: number | string;
  onClose: () => void;
  modalStyle?: SxProps;
}

const BaseModal: React.FC<Props> = (props) => {
  const [{ isMatchDownSM: isSM }] = useBreakpoints();

  const modalStyle: SxProps = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: props.width,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    outline: "none",
    ...props.modalStyle,
  };

  if (isSM) {
    return (
      <Drawer
        open={props.open}
        onClose={props.onClose}
        anchor="bottom"
        PaperProps={{
          style: {
            borderTopRightRadius: 28,
            borderTopLeftRadius: 28,
            maxHeight: "80%",
          },
        }}
      >
        <Box sx={{ p: 2 }}>{props.children}</Box>
      </Drawer>
    );
  }

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box sx={modalStyle}>{props.children}</Box>
    </Modal>
  );
};

export default BaseModal;
