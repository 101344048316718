import TextField from "@mui/material/TextField";
import { TextFieldProps } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

type IFormInputProps = {
  name: string;
  label: string;
} & TextFieldProps;
export const FormInputText = ({
  name,
  label,
  ...otherProps
}: IFormInputProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : null}
          error={!!error}
          onChange={onChange}
          value={value}
          label={label}
          {...otherProps}
        />
      )}
    />
  );
};
