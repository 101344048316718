import { useEffect, useMemo, useState } from "react";
import { Container, Grid, Button, Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  useDeleteDocumentMutation,
  useUploadDocumentMutation,
} from "./completeProfileAPI";
import { IUploadImage, imageUploadSchema } from "./completeProfileSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import FileUpload from "../../../components/file-upload/FileUpload";
import { FormInputText } from "../../../components/form/FormInput";
import ProfilePicture from "../../../components/ProfilePicture";
import { getErrorMessage } from "../../../utils/serviceHelper";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { FormInputDropdown } from "../../../components/form/FormDropdown";
import { useAppSelector } from "../../../hooks/useStoreHook";
import { IUser } from "../../user/auth/authtypes";
import { useToast } from "../../../hooks/useToast";
import { useBreakpoints } from "../../../hooks/useBreakpoints";

const CHARACTER_LIMIT = 100;
function RelatedDocuments(props: any) {
  const { handlePrevious } = props;
  const options = [
    { label: "Aditya Birla", value: "Aditya Birla" },
    { label: "HDFC insurance", value: "HDFC insurance" },
    { label: "Health Insurance", value: "Health Insurance" },
    { label: "Other", value: "Other" },
  ];

  const { t } = useTranslation();

  const [uploadImage, { isLoading, isError, error, isSuccess }] =
    useUploadDocumentMutation();

  const [deleteDoc] = useDeleteDocumentMutation();

  const userData = useAppSelector((state) => state.userState.user) as IUser;
  const methods = useForm<IUploadImage>({
    resolver: zodResolver(imageUploadSchema),
    defaultValues: useMemo(() => {
      return {
        documents: userData?.medicalDocuments ?? [],
        insuranceNumber: userData?.insuranceNumber ?? "",
        insuranceProvider: userData?.insuranceProvider ?? "",
        insuranceNotes: userData?.insuranceNotes ?? "",
      };
    }, [userData]),
  });

  const { watch, reset } = methods;
  const navigate = useNavigate();
  const { displayToast } = useToast();

  const [breakPoints] = useBreakpoints();
  const { isMatchDownSM } = breakPoints;

  useEffect(() => {
    if (isSuccess) {
      navigate("/");
      // reset();
    }
    if (isError) {
      const errorMessage = getErrorMessage(error);
      displayToast({
        message: errorMessage,
        type: "error",
      });
    }
  }, [isLoading]);

  const onSubmitHandler: SubmitHandler<IUploadImage> = (values) => {
    const formData = new FormData();
    if (values.documents.length > 0) {
      values.documents.forEach((el) => formData.append("Files", el));
    }
    formData.append("InsuranceProvider", values.insuranceProvider);
    formData.append("InsuranceIdNo", values.insuranceNumber);
    formData.append("Notes", values?.insuranceNotes ?? "");
    uploadImage(formData);
  };
  const onDeleteDoc = (id: string) => {
    deleteDoc({ id });
  };

  return (
    <Container>
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          <Grid container direction="row" alignItems={"stretch"} sx={{ my: 9 }}>
            <Grid item xs={12} sm={2} md={2}>
              <ProfilePicture
                name="profilePic"
                width={176}
                height={176}
                isEdit={true}
              />
            </Grid>
            <Grid item xs={12} sm={1} md={1} />
            <Grid item xs={12} sm={9} md={9}>
              <Stack
                direction={isMatchDownSM ? "column" : "row"}
                spacing={1}
                paddingY={1}
              >
                <FormInputDropdown
                  margin="normal"
                  options={options}
                  name={"insuranceProvider"}
                  label={t("completeprofile.relatedForm.insuranceProvider")}
                  fullWidth
                  withLabel
                />
                <FormInputText
                  sx={{
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  color="primary"
                  variant="outlined"
                  type="text"
                  label={t("completeprofile.relatedForm.insuranceNumber")}
                  placeholder={t(
                    "completeprofile.relatedForm.insuranceNumberPlaceholder"
                  )}
                  id={`insuranceNumber`}
                  size="medium"
                  margin="normal"
                  name="insuranceNumber"
                  required
                  fullWidth
                />
              </Stack>
              <FileUpload
                limit={5}
                multiple={true}
                name="documents"
                uploadedDocs={userData?.medicalDocuments}
                onDeleteDoc={onDeleteDoc}
              />
              <FormInputText
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                }}
                multiline
                inputProps={{
                  maxLength: CHARACTER_LIMIT,
                }}
                helperText={`${
                  watch("insuranceNotes") ? watch("insuranceNotes")?.length : 0
                }/${CHARACTER_LIMIT}`}
                color="primary"
                variant="outlined"
                type="text"
                label={t("completeprofile.relatedForm.note")}
                size="medium"
                margin="normal"
                name="insuranceNotes"
                fullWidth
              />
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ my: 2 }}
          >
            <Button
              disabled={isLoading}
              variant="contained"
              type="submit"
              sx={{
                mx: 1,
              }}
              onClick={handlePrevious}
            >
              {t("completeprofile.back")}
            </Button>
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
              sx={{
                mx: 1,
              }}
            >
              {t("completeprofile.submit")}
            </LoadingButton>
          </Box>
        </Box>
      </FormProvider>
    </Container>
  );
}

export default RelatedDocuments;
