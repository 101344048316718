import { zodResolver } from "@hookform/resolvers/zod";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  IconButton,
  StepConnector,
  Stepper,
  Typography,
  stepConnectorClasses,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ZodIssueCode, any, object } from "zod";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { useAppSelector } from "../../hooks/useStoreHook";
import Question from "./Question";
import { _QuestionnaireForm } from "./Questionnaire";
import QuestionnaireResponse from "./QuestionnaireResponse";
import {
  IQuestion,
  useGetQuestionnaireQuery,
  useValidateQuestionnaireMutation,
} from "./surveyApi";
import { useTranslation } from "react-i18next";

const buildSchema = (questions: IQuestion[] | undefined) => {
  const schema: { [key: string]: any } = {};
  if (questions)
    questions.forEach((question) => {
      const questionKey = `q_${question.id}`;
      switch (question.questionType) {
        case 1:
          schema[questionKey] = any();
          break;
        case 2:
          schema[questionKey] = any();
          break;
        case 3:
          schema[questionKey] = any();
          break;
        case 4:
          schema[questionKey] = any();
          break;
        default:
          break;
      }
    });
  return object(schema).superRefine((data, ctx) => {
    if (questions)
      questions.forEach((question) => {
        let isRequired = true;

        if (question.dependentOnQuestionId) {
          const dependentAnswer = data[`q_${question.dependentOnQuestionId}`];
          isRequired = !(
            dependentAnswer === "No" ||
            dependentAnswer === "" ||
            dependentAnswer === null ||
            dependentAnswer === undefined
          ); //question.dependency.answer
        }
        if (
          isRequired &&
          question.questionType !== 3 &&
          !data[`q_${question.id}`]
        ) {
          ctx.addIssue({
            code: ZodIssueCode.custom,
            message:
              question.questionType === 4
                ? "Answer is required."
                : "You need to select an option.",
            path: [`q_${question.id}`],
          });
        } else if (
          (isRequired &&
            question.questionType === 3 &&
            typeof data[`q_${question.id}`] != "object") ||
          (isRequired &&
            question.questionType === 3 &&
            data[`q_${question.id}`].length < 1)
        ) {
          ctx.addIssue({
            code: ZodIssueCode.custom,
            message: "At least one option must be selected",
            path: [`q_${question.id}`],
          });
        }
      });
  });
};

const QuestionnaireForm = ({ handleClose }: _QuestionnaireForm) => {
  const [activeStep, setActiveStep] = useState(-1);

  const changeStep = (step: number) => {
    setActiveStep(step);
  };
  const checkColonscopyRequired = useAppSelector(
    (state) => state.checkColonscopyRequired
  );
  const { data } = useGetQuestionnaireQuery({});
  const [validateQuestionnaire, { isLoading, isError, error, isSuccess }] =
    useValidateQuestionnaireMutation();

  const methods = useForm({
    resolver: zodResolver(buildSchema(data?.questions)),
  });
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const { t } = useTranslation();

  const { handleSubmit, watch, reset } = methods;
  const answers = watch();

  useEffect(() => {
    reset({}, { keepValues: true });
  }, [reset]);

  const onSubmitHandler = async (data: any) => {
    validateQuestionnaire(data);
  };

  const CheckerConnector = styled(StepConnector)(({ theme }) => ({
    [`& .${stepConnectorClasses.line}`]: {
      width: 2,
      backgroundColor: "#6297B5",
      borderLeft: "0px",
      marginLeft: "1px",
    },
    ...(isSM && {
      marginTop: "-3px",
      marginBottom: "-12.4px",
      minHeight: "calc(24px + 0.5rem)",
    }),
  }));

  if (data && data?.questions) {
    return (
      <>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            variant={isSM ? "h5" : "h1"}
            color={"#455A64"}
            sx={{ my: 2 }}
          >
            {!checkColonscopyRequired?.checked
              ? t("patient.colonoscopy.need")
              : checkColonscopyRequired?.isRequired
              ? t("patient.colonoscopy.yes.title")
              : t("patient.colonoscopy.no.title")}
          </Typography>
          <IconButton sx={{ color: "#323232" }} onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        {!checkColonscopyRequired?.checked ? (
          <FormProvider {...methods}>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmitHandler)}
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Stepper
                orientation="vertical"
                sx={[
                  {
                    "& .MuiStepLabel-vertical": {
                      padding: 0,
                    },
                  },
                  isSM && {
                    "& .MuiStepLabel-vertical": {
                      height: "2rem",
                      lineHeight: "2rem",
                    },
                  },
                ]}
                connector={<CheckerConnector />}
              >
                {data?.questions.map((question, index) => {
                  const previousAnswer = question.dependentOnQuestionId
                    ? answers[`q_${question.dependentOnQuestionId}`]
                    : null;
                  const isDisabled =
                    question.dependentOnQuestionId &&
                    (previousAnswer === "No" ||
                      previousAnswer === "" ||
                      previousAnswer === null ||
                      previousAnswer === undefined); //question.dependency.answer

                  return (
                    <Question
                      key={question.id}
                      question={question}
                      isDisabled={isDisabled as boolean}
                      watch={watch}
                      activeStep={activeStep}
                      index={index}
                      changeStep={changeStep}
                      totalItems={data?.questions.length ?? 0}
                    />
                  );
                })}
              </Stepper>
              <Divider sx={{ my: 1, borderColor: "#CDE5FF" }} />
              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button variant="outlined" sx={{ mr: 2 }} onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={isLoading}
                >
                  Confirm
                </LoadingButton>
              </Box>
            </Box>
          </FormProvider>
        ) : (
          <QuestionnaireResponse
            changeStep={changeStep}
            reset={reset}
            handleClose={handleClose}
          />
        )}
      </>
    );
  }
  return null;
};

export default QuestionnaireForm;
