import { useCallback, useRef } from "react";

export function useIntersectionObserver(
  isLoading: boolean,
  callback: () => any,
  hasNextPage?: boolean
) {
  const intOberver = useRef<IntersectionObserver | null>(null);
  const lastPostRef = useCallback(
    (element: Element) => {
      if (isLoading && !hasNextPage) {
        return;
      }
      if (intOberver.current) intOberver.current.disconnect();
      intOberver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          callback();
        }
      });
      if (element) intOberver.current.observe(element);
    },
    [isLoading, hasNextPage]
  );
  return [lastPostRef];
}
