import SearchIcon from "@mui/icons-material/Search";
import { InputBase, InputBaseProps, Paper, debounce } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

type FormSearchInputProps = {
  name: string;
  label: string;
  onSearch: (searchValue: string) => void;
} & InputBaseProps;

export default function SearchInput(props: FormSearchInputProps) {
  const { name, label, onSearch, ...otherProps } = props;
  const { control } = useFormContext();

  const onDebounceSearch = useCallback(
    debounce((value: string) => {
      if (onSearch) onSearch(value);
    }, 500),
    []
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, ...fieldProps } }) => {
        return (
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              color: "#44474F",
              bgcolor: "#EDEEF0",
              borderRadius: 24,
              boxShadow: "none",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={label}
              id={name}
              value={value || ""}
              onChange={(e) => {
                onChange(e.target.value);
                onDebounceSearch(e.target.value);
              }}
              {...fieldProps}
              {...otherProps}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        );
      }}
    />
  );
}
