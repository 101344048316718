import { Box, Typography } from "@mui/material";
import { Fragment } from "react";
import { usePatientAppointment } from "../../provider/patients/patient-details/usePatientAppointment";
import AppointmentOverview from "../../../components/AppointmentOverview";
import { getIsActionEnabled } from "../../provider/appointment/appointmentRequestsAPI";
import AppointmentOverviewSkeleton from "../../../components/skeletons/AppointmentOverviewSkeleton";
import AppointmentPatientOverview from "../../../components/AppointmentPatientOverview";

const UpcomingAppointments = () => {
  const {
    appointments,
    isLoading,
    lastPostRef,
    onCancelled,
    isCancelling,
    isError,
  } = usePatientAppointment("", [0, 1, 4, 5]);
  return (
    <>
      <Box my={2} display={"flex"} flexWrap={"wrap"}>
        {appointments.length > 0 ? (
          appointments.map((appointment, index) => {
            return (
              <Fragment key={appointment?.id ?? index}>
                {index === appointments.length - 1 ? (
                  <AppointmentPatientOverview
                    ref={lastPostRef}
                    isLoading={isCancelling}
                    appointmentId={appointment.id}
                    appointmentStatus={(appointment as any)?.status}
                    scheduleDetails={(appointment as any)?.scheduleDetails}
                    physicanDetails={(appointment as any)?.provider}
                    procedureName={
                      (appointment as any)?.procedureOrServiceToPerform?.name ??
                      ""
                    }
                    key={appointment.id}
                    actionEnabled={getIsActionEnabled(
                      (appointment as any)?.status
                    )}
                    onCancelled={onCancelled}
                  />
                ) : (
                  <AppointmentPatientOverview
                    appointmentId={appointment.id}
                    isLoading={isCancelling}
                    appointmentStatus={(appointment as any)?.status}
                    scheduleDetails={(appointment as any)?.scheduleDetails}
                    physicanDetails={(appointment as any)?.provider}
                    procedureName={
                      (appointment as any)?.procedureOrServiceToPerform?.name ??
                      ""
                    }
                    key={appointment.id}
                    actionEnabled={getIsActionEnabled(
                      (appointment as any)?.status
                    )}
                    onCancelled={onCancelled}
                  />
                )}
              </Fragment>
            );
          })
        ) : (
          <Box>
            {isError ? (
              <Typography>
                Something went wrong while fetching the data
              </Typography>
            ) : (
              <Typography>No appointments found</Typography>
            )}
          </Box>
        )}
      </Box>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {Array.from({ length: 12 }).map((el, index) => {
            return <AppointmentOverviewSkeleton key={index} />;
          })}
        </Box>
      )}
    </>
  );
};

export default UpcomingAppointments;
