import moment from "moment";
import { EventItem } from "./event-types";

export let views = {
  MONTH: "month",
  WEEK: "week",
  DAY: "day",
};

export let navigate = {
  PREVIOUS: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
  DATE: "DATE",
};

export enum AppointmentStatusCode {
  Upcoming = "U",
  PastAppointments = "PA",
  Requested = "RQ",
  NotApplicable = "NA",
}

export const EVENT_STATUS_COLORS = {
  U: { bgColor: "#266D00", color: "#EEFFDE" },
  PA: { bgColor: "#AAABAD", color: "#E7E8EA" },
  RQ: { bgColor: "#790006", color: "#FFEDEA" },
  NA: { bgColor: "#B6D9F6", color: "#FFFFFF" },
};

