import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks/useStoreHook";

const ValidateRoute = () => {
  const userData = useAppSelector((state: any) => state.userState);
  const location = useLocation();

  const roles: string[] = userData?.user?.role ?? [];

  if (roles.some((role) => role.toLowerCase() === "provider")) {
    return <Navigate to="/provider" state={{ from: location }} replace />;
  } else {
    return <Outlet />;
  }
};

export default ValidateRoute;
