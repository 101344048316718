import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { TypeOf, object, string } from "zod";
import CenteredBox from "../../../../components/CenteredBox";
import SearchInput from "../../../../components/SearchInput";
import { useBreakpoints } from "../../../../hooks/useBreakpoints";
import DataUtil from "../../../../utils/dataHelper";
import DateUtil, { dateUtil } from "../../../../utils/dateHelper";
import { getGender } from "../../../../utils/serviceHelper";
import PatientCard from "../components/PatientCard";
import PatientCardSkeleton from "../components/PatientCardSkeleton";
import { usePatientList } from "./usePatientList";

export const patientSearchSchema = object({
  patient: string(),
});

export type PatientSearchInput = TypeOf<typeof patientSearchSchema>;

const AllPatientList = ({ header }: { header: any }) => {
  const { isLoading, lastPostRef, patients, isError, searchKeyword } =
    usePatientList();
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const methods = useForm<PatientSearchInput>({
    resolver: zodResolver(patientSearchSchema),
  });
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <FormProvider {...methods}>
        <Box sx={{ flex: 1, width: isSM ? "320px" : "400px", my: 2 }}>
          <SearchInput
            name="patient"
            label={"Search Patient"}
            onSearch={async (value: string) => await searchKeyword(value)}
          />
        </Box>
      </FormProvider>
      {isLoading && (
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          {Array.from({ length: 12 }).map((el, index) => {
            return <PatientCardSkeleton key={index} />;
          })}
        </Box>
      )}
      {!isLoading && isError && (
        <CenteredBox height="30vh">
          <Typography>Something went wrong while fetching the data</Typography>
        </CenteredBox>
      )}
      {!isLoading && !isError && patients.length === 0 && (
        <CenteredBox height="30vh">
          <Typography>No patients found</Typography>
        </CenteredBox>
      )}
      {!isLoading && !isError && patients.length > 0 && (
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          {patients.map((patient: any, index: number) => {
            return (
              <Box
                key={patient.id ?? index}
                sx={{ width: isSM ? "100%" : "49%" }}
              >
                {index === patients.length - 1 ? (
                  <PatientCard
                    ref={lastPostRef}
                    name={patient.name ?? ""}
                    profileImage={patient.profileImage ?? ""}
                    age={dateUtil.calculateAgeFromDOB(patient.dateOfBirth)}
                    gender={getGender(patient.gender ?? null)}
                    location={DataUtil.formatAddress(patient.address) ?? ""}
                    consultedTaxonamy={patient.lastProcedureName ?? ""}
                    primaryTileCaption={moment(
                      patient.lastAppointmentDate
                    ).format(DateUtil.DateFormats.F_MONTH_DATE_YEAR)}
                    lastAppointmentDate={moment(
                      patient.lastAppointmentDate
                    ).format(DateUtil.DateFormats.MONTH_DATE_YEAR)}
                    emailAddress={patient.emailAddress ?? ""}
                    phoneNumber={patient.phoneNumber ?? ""}
                    consultedTaxonamyTitle={header[1].title}
                    primaryTileCaptionTitle={header[2].title}
                  />
                ) : (
                  <PatientCard
                    name={patient.name ?? ""}
                    profileImage={patient.profileImage}
                    age={dateUtil.calculateAgeFromDOB(patient.dateOfBirth)}
                    gender={getGender(patient.gender ?? null)}
                    location={DataUtil.formatAddress(patient.address) ?? ""}
                    consultedTaxonamy={patient.lastProcedureName ?? ""}
                    primaryTileCaption={moment(
                      patient.lastAppointmentDate
                    ).format(DateUtil.DateFormats.F_MONTH_DATE_YEAR)}
                    consultedTaxonamyTitle={header[1].title}
                    primaryTileCaptionTitle={header[2].title}
                    lastAppointmentDate={moment(
                      patient.lastAppointmentDate
                    ).format(DateUtil.DateFormats.MONTH_DATE_YEAR)}
                    emailAddress={patient.emailAddress ?? ""}
                    phoneNumber={patient.phoneNumber ?? ""}
                  />
                )}
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default AllPatientList;
