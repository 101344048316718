import { Box, Divider, Skeleton } from "@mui/material";

const TimeSlotSkeleton = () => {
  return (
    <Box>
      <Skeleton width="80%" height={32} sx={{ mb: 1 }}></Skeleton>
      <Box display="flex" flexWrap="wrap" justifyContent="space-between">
        {Array.from({ length: 24 }).map((_, i) => {
          return (
            <Skeleton
              key={`${i}`}
              variant="rounded"
              height={36}
              sx={{ minWidth: "30%", borderRadius: 24, mb: 1 }}
            ></Skeleton>
          );
        })}
      </Box>
      <Divider
        orientation="vertical"
        sx={{ borderWidth: "1px", backgroundColor: "#C5C6D0", mt: 2 }}
      />
      <Skeleton
        variant="rounded"
        height={36}
        width="100%"
        sx={{ borderRadius: 24, my: 2 }}
      ></Skeleton>
    </Box>
  );
};

export default TimeSlotSkeleton;
