import { Divider, Typography } from "@mui/material";

import classes from "../ProfileSetting.module.css";
// import Toggle from "../../../../../components/Toggle";

const ProfileSettingHeader = ({
  title,
  color = "#5F5E5E",
}: {
  title: string;
  color?: string;
}) => {
  // const handleToggle = (status: boolean) => {
  //   console.log("Profile visiblity Status", status);
  // };

  return (
    <div className={classes.header}>
      <Typography
        variant="body1"
        fontSize={"20px"}
        fontWeight={600}
        paddingRight={2}
        color={color}
      >
        {title}
      </Typography>
      <Divider
        orientation="vertical"
        sx={{ borderWidth: "1px", backgroundColor: "#C5C6D0", my: 1 }}
      />
      {/* <Toggle name="visibleToggle" onChange={handleToggle} /> */}
    </div>
  );
};

export default ProfileSettingHeader;
