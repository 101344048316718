import { Box, Typography } from "@mui/material";
import React from "react";
import { AppointmentStatusCode, EVENT_STATUS_COLORS } from "../utils/constants";
import { Appointment, EventItem } from "../utils/event-types";
import moment from "moment";
import { useBreakpoints } from "../../../hooks/useBreakpoints";

export default function AppointmentEvent({ event }: { event: EventItem }) {
  const { data, start, end } = event;
  const { status, title } = data as Appointment;
  const background =
    EVENT_STATUS_COLORS[status as AppointmentStatusCode]?.bgColor;
  const color = EVENT_STATUS_COLORS[status as AppointmentStatusCode]?.color;
  const [height, setHeight] = React.useState(0);
  const [{ isMatchDownSM: isSM }] = useBreakpoints();

  const measuredRef = React.useCallback((node: any) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);
  return (
    <Box
      ref={measuredRef}
      pl={0.4}
      bgcolor={background}
      height="100%"
      width={"95%"}
      color="black"
      borderRadius={"4px"}
    >
      <Box
        bgcolor={color}
        height="100%"
        borderRadius={"4px"}
        display={"flex"}
        flexDirection={height > 40 ? "column" : "row"}
      >
        <Box>
          <Typography
            variant={isSM ? "caption" : "body1"}
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            width="70%"
          >
            {title ?? "(No Title)"}
          </Typography>
        </Box>
        <Box>
          <Typography variant={isSM ? "caption" : "body1"}>
            {height > 40
              ? moment(start).format("HH:mm") +
                " - " +
                moment(end).format("HH:mm")
              : ", " +
                moment(start).format("HH:mm") +
                " - " +
                moment(end).format("HH:mm")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
