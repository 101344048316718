"use client";
import { Box, CircularProgress } from "@mui/material";
import {
  APIProvider,
  AdvancedMarker,
  Map,
  MapCameraChangedEvent,
  Pin,
} from "@vis.gl/react-google-maps";
import { CSSProperties, useEffect, useState } from "react";
import { Marker } from "./Marker";
import config from "../../config/config";

export interface LatLng {
  lat: number;
  lng: number;
}

interface Props {
  position: LatLng;
  radius: number;
  style?: CSSProperties;
  width?: number | string;
  height?: number | string;
  draggable?: boolean;
  nearbyCoords?: google.maps.LatLngLiteral[];
  onDragEnd?: (latLng: LatLng) => void;
}

export default function GoogleMap(props: Props) {
  const { width = "100%", height = 420, style = {} } = props;
  const { position, nearbyCoords, radius } = props;

  const [zoom, setZoom] = useState(9);

  useEffect(() => setZoom(radius), [radius]);

  const handleZoomChange = (event: MapCameraChangedEvent) => {
    setZoom(event.detail.zoom);
  };

  const handleDragEnd = (e: google.maps.MapMouseEvent) => {
    const latLng: LatLng | undefined = e.latLng?.toJSON();
    if (!latLng) return;
    if (props.onDragEnd) props.onDragEnd(latLng);
  };

  return (
    <APIProvider apiKey={config.mapApiKey}>
      <div style={{ width, height }}>
        {!position && (
          <Box
            component={"div"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"100%"}
          >
            <CircularProgress />
          </Box>
        )}

        <Map
          zoom={zoom}
          center={props.draggable ? null : position}
          defaultCenter={props.draggable ? position : undefined}
          mapId={config.mapId}
          onZoomChanged={handleZoomChange}
          style={style}
        >
          <AdvancedMarker
            position={position}
            draggable={props.draggable}
            onDragEnd={handleDragEnd}
          >
            <Pin background="#307DF6" borderColor="#307DF6" glyphColor="#fff" />
          </AdvancedMarker>
          {nearbyCoords &&
            nearbyCoords.map((cord, index) => {
              return <Marker cord={cord} key={`${index}`} />;
            })}
        </Map>
      </div>
    </APIProvider>
  );
}
