import { useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";

export function useBreakpoints() {
  const { breakpoints: bre } = useTheme();
  const breakPointDownSM = useMediaQuery(bre.down("sm"));
  const breakPointDownMD = useMediaQuery(bre.down("md"));
  const breakPointDownLg = useMediaQuery(bre.down("lg"));
  const breakPoints = useMemo(
    () => ({
      isMatchDownSM: breakPointDownSM,
      isMatchDownMD: breakPointDownMD,
      isMatchDownLG: breakPointDownLg,
    }),
    [breakPointDownSM, breakPointDownMD, breakPointDownLg]
  );

  return [breakPoints];
}
