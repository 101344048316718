import { Box, Divider } from "@mui/material";
import TimeslotNavigator from "./TimeslotNavigator";
import WeekdayNavigator from "./WeekdayNavigator";
import { useState } from "react";
import DateUtil, { dateUtil } from "../../../../../utils/dateHelper";
import { Moment } from "moment";
import WeekCalendar from "./WeekCalendar";
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";

const TimeslotHeader = ({
  selectedDate,
  onSelectedDate,
  showDivider = false,
  getSchedule,
  onReset,
}: {
  selectedDate: Moment;
  onSelectedDate: (selectedDate: Moment) => void;
  showDivider?: boolean;
  getSchedule: ({ scheduleDate }: { scheduleDate: string }) => void;
  onReset: () => void;
}) => {
  const today = dateUtil.getToday();
  const [startDate, setStartDate] = useState(selectedDate.clone().weekday(0));
  const [endDate, setEndDate] = useState(selectedDate.clone().weekday(6));
  const [open, setOpen] = useState(false);
  const ref = useOutsideClick(() => setOpen(false));

  const dateRange = dateUtil.getDateRange(startDate, endDate);

  const onWeekDayChange = (weekDayValue: number) => {
    onSelectedDate(startDate.clone().weekday(weekDayValue));
    getSchedule({
      scheduleDate: startDate
        .clone()
        .weekday(weekDayValue)
        .format(DateUtil.DateFormats.YEAR_MONTH_DATE),
    });
    onReset();
  };

  const handleChange = (date?: Moment) => {
    if (!date) return;
    setStartDate(date.clone().weekday(0));
    setEndDate(date.clone().weekday(6));
    const format = DateUtil.DateFormats.YEAR_MONTH_DATE;
    getSchedule({
      scheduleDate: date.clone().weekday(1).format(format),
    });
    onSelectedDate(date.clone().weekday(1));
    onReset();
    setOpen(false);
  };

  const navigatePrevious = (date?: Moment) => {
    const _startDate = startDate.add(-1, "week");
    const _endDate = endDate.add(-1, "week");
    setStartDate(_startDate.clone().weekday(0));
    setEndDate(_endDate.clone().weekday(6));
    getSchedule({
      scheduleDate: _startDate
        .clone()
        .weekday(1)
        .format(DateUtil.DateFormats.YEAR_MONTH_DATE),
    });
    onSelectedDate(_startDate.clone().weekday(1));
    onReset();
  };

  const navigateNext = (date?: Moment) => {
    const _startDate = startDate.add(1, "week");
    const _endDate = endDate.add(1, "week");
    setStartDate(_startDate.clone().weekday(0));
    setEndDate(_endDate.clone().weekday(6));
    getSchedule({
      scheduleDate: _startDate
        .clone()
        .weekday(1)
        .format(DateUtil.DateFormats.YEAR_MONTH_DATE),
    });
    onSelectedDate(_startDate.clone().weekday(1));
    onReset();
  };

  return (
    <Box>
      <TimeslotNavigator
        label={`${dateUtil.formatDate(
          startDate.toISOString(),
          DateUtil.DateFormats.DD_S_MMM_S_YYYY
        )} - ${dateUtil.formatDate(
          endDate.toISOString(),
          DateUtil.DateFormats.DD_S_MMM_S_YYYY
        )}`}
        navigatePrevious={navigatePrevious}
        navigateNext={navigateNext}
        onClick={() => setOpen(true)}
      >
        <WeekCalendar
          ref={ref}
          open={open}
          value={startDate}
          onChange={handleChange}
        />
      </TimeslotNavigator>
      {showDivider && (
        <Divider
          orientation="horizontal"
          sx={{
            borderWidth: "1px",
            backgroundColor: "#C5C6D0",
            my: 2,
          }}
        />
      )}
      <WeekdayNavigator
        today={today}
        dateRange={dateRange}
        selectedDate={selectedDate}
        onWeekDayChange={onWeekDayChange}
      />
    </Box>
  );
};

export default TimeslotHeader;
