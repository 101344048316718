import { Grid } from "@mui/material";
import ProfileSetting from "../../../features/provider/settings/ProfileSetting";
import SetUpTimeSlot from "../../../features/provider/settings/SetUpTimeSlot";

const Settings = () => {
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item lg={6} sx={{ width: "100%" }}>
        <ProfileSetting />
      </Grid>
      <Grid item lg={6} sx={{ width: "100%" }}>
        <SetUpTimeSlot />
      </Grid>
    </Grid>
  );
};

export default Settings;
