import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import classes from "./ProfileSetting.module.css";

import ProfileSettingHeader from "./components/ProfileSettingHeader";
import ProfilePicture from "../../../../components/ProfilePicture";

import { useAppSelector } from "../../../../hooks/useStoreHook";
import { IProvider } from "../../../user/auth/authtypes";
import StarRating from "../../../../components/StarRating";
import { getClinicAddress } from "../../complete-profile/ProviderProfileForm/providerProfileHelper";
import ProfileLabelValue from "./components/ProfileLabelValue";
import EditProfileButton from "./components/EditProfileButton";
import { useNavigate } from "react-router-dom";
import LabelWithIcon from "../../../../components/LabelWithIcon";

const EDIT_PROFILE = "/provider/complete-profile?isEdit=true";

const ProfileSetting = (props: ProfileSettingProps) => {
  const { breakpoints, palette } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("sm"));
  const navigate = useNavigate();
  const userData = useAppSelector((state) => state.userState.user) as IProvider;

  let address = "Not Provided";
  if (userData && userData.professionalDetail.businessAddress)
    address =
      getClinicAddress(userData?.professionalDetail?.businessAddress) ?? "";

  return (
    <Box
      sx={{
        px: 3,
        py: 2,
        border: "1px solid #93CCFF",
        borderRadius: 4,
        backgroundColor: "#FCFCFF",
      }}
    >
      <ProfileSettingHeader title={"My Profile"} />
      {fullScreen ? (
        <div className={classes["profile-header-fs"]}>
          <div className={classes["profile-header"]}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ mb: 2 }}>
                <ProfilePicture name="profilePic" height={64} width={64} />
              </Box>
              {!fullScreen && (
                <EditProfileButton onClick={() => navigate(EDIT_PROFILE)} />
              )}
            </Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 12,
              }}
            >
              <div className={classes["profile-primary-details"]}>
                <Typography variant={fullScreen ? "body2" : "h4"}>
                  {userData?.fullName ?? ""}
                </Typography>
                {/* <div className={classes["profile-rating-container-fs"]}>
                  <StarRating
                    rating={userData?.rating ?? 0}
                    maxRating={5}
                  ></StarRating>
                  <Typography variant="caption" paddingLeft={1}>
                    ({userData?.noOfRatings ?? 0})
                  </Typography>
                </div> */}
              </div>
            </div>
          </div>
          <LabelWithIcon
            icon={<EmailOutlinedIcon />}
            boxSx={{ height: 24, alignSelf: "flex-start", mb: 2 }}
            value={userData?.email ?? ""}
            valueVariant="body2"
            iconSx={{ paddingRight: 2 }}
          />
          <LabelWithIcon
            icon={<LocalPhoneOutlinedIcon />}
            boxSx={{ height: 24, alignSelf: "flex-start", mb: 2 }}
            value={userData?.phoneNumber ?? ""}
            valueVariant="body2"
            iconSx={{ paddingRight: 2 }}
          />
          <LabelWithIcon
            icon={<LocalHospitalOutlinedIcon />}
            boxSx={{ height: 24, alignSelf: "flex-start", mb: 2 }}
            value={userData?.professionalDetail?.residency ?? ""}
            valueVariant="body2"
            iconSx={{ paddingRight: 2 }}
          />
          <LabelWithIcon
            icon={<LocationOnOutlinedIcon />}
            boxSx={{ height: 24, alignSelf: "flex-start", mb: 2 }}
            value={address ?? ""}
            valueVariant="body2"
            iconSx={{ paddingRight: 2 }}
          />
        </div>
      ) : (
        <div className={classes["profile-header"]}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ mb: 2 }}>
              <ProfilePicture height={192} width={192} />
            </Box>
            <EditProfileButton onClick={() => navigate(EDIT_PROFILE)} />
          </Box>
          <div className={classes["profile-primary-details"]}>
            <Typography variant="h4" pb={2}>
              {userData?.fullName ?? ""}
            </Typography>
            {/* <div className={classes["profile-rating-container"]}>
              <StarRating
                rating={userData?.rating ?? 0}
                maxRating={5}
              ></StarRating>
              <Typography variant="caption" paddingLeft={1}>
                ({userData?.noOfRatings ?? 0})
              </Typography>
            </div> */}
            <Grid
              container
              paddingBottom={2}
              direction="row"
              alignItems="center"
            >
              <EmailOutlinedIcon />
              <Typography variant="body2" paddingLeft={2}>
                {userData?.email}
              </Typography>
            </Grid>
            <Grid
              container
              paddingBottom={2}
              direction="row"
              alignItems="center"
            >
              <LocalPhoneOutlinedIcon />
              <Typography variant="body2" paddingLeft={2}>
                {userData?.phoneNumber}
              </Typography>
            </Grid>
            <Grid
              container
              paddingBottom={2}
              direction="row"
              alignItems="center"
            >
              <LocalHospitalOutlinedIcon />
              <Typography variant="body2" paddingLeft={2}>
                {userData?.professionalDetail?.residency}
              </Typography>
            </Grid>
            <Grid
              container
              paddingBottom={2}
              direction="row"
              alignItems="center"
            >
              <LocationOnOutlinedIcon />
              <Typography variant="body2" paddingLeft={2}>
                {address}
              </Typography>
            </Grid>
          </div>
        </div>
      )}
      <Typography variant="body2" marginY={4}>
        {userData?.aboutMe}
      </Typography>
      <ProfileLabelValue
        label="Experience"
        value={`${userData?.professionalDetail?.experience}`}
      />
      <ProfileLabelValue
        label="Training"
        CustomeValue={
          <div className={classes["capsules-container"]}>
            <div className={classes.capsule}>
              <Typography variant="body2">
                {userData?.professionalDetail?.medicalSchool}
              </Typography>
            </div>
            <div className={classes.capsule}>
              <Typography variant="body2">
                {userData?.professionalDetail?.residency}
              </Typography>
            </div>
            <div className={classes.capsule}>
              <Typography variant="body2">
                {userData?.professionalDetail?.fellowship}
              </Typography>
            </div>
          </div>
        }
      />
      <ProfileLabelValue label="NPI" value={userData?.npi} />
      <ProfileLabelValue
        label="Awards"
        value={userData?.professionalDetail?.awardsList?.join(", ") ?? ""}
      />
      {fullScreen && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <EditProfileButton onClick={() => navigate(EDIT_PROFILE)} />
        </Box>
      )}
    </Box>
  );
};

export default ProfileSetting;

interface ProfileSettingProps {}
