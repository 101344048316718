import { FormControlLabel, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { Controller, useFormContext } from "react-hook-form";

type Props = {
  name: string;
  label: string;
  labelPlacement: "end" | "start" | "top" | "bottom" | undefined;
} & SwitchProps;

const FormSwitch = ({ name, label, labelPlacement }: Props) => {
  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.primary,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: theme.palette.primary,
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const { control } = useFormContext();

  return (
    <Controller
      defaultValue={false}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormControlLabel
          control={
            <IOSSwitch sx={{ m: 1 }} checked={value} onClick={onChange} />
          }
          label={<Typography variant="body2">{label}</Typography>}
          labelPlacement={labelPlacement}
        />
      )}
      control={control}
      name={name}
    />
  );
};

export default FormSwitch;
