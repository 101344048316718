import { Box, Grid } from "@mui/material";
import Timeslot from "./Timeslot";

const ViewTimeslotContainer = ({ timeSlots }: { timeSlots: any }) => {
  return (
    <Box sx={{ display: "flex", flex: 1 }}>
      <Grid container spacing={2}>
        {timeSlots?.map((slot: any) => {
          return (
            <Grid key={slot.key} item xs={4}>
              <Timeslot
                id={slot.key}
                label={slot.value}
                isOccupied={slot.isOccupied}
                disabled={true}
                readOnly
                onClick={() => {}}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ViewTimeslotContainer;
