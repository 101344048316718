import { Box, SxProps, Typography, TypographyVariant } from "@mui/material";

interface LabelerProps {
  label: string;
  value: string;
  hassIcon?: boolean;
  icon?: JSX.Element;
  isDownload?: boolean;
  labelerVariant?: TypographyVariant;
  valueVariant?: TypographyVariant;
  sx?: SxProps;
  labellerSxProps?: SxProps;
  valueSxProps?: SxProps;
}
export default function Labeler(props: LabelerProps) {
  const {
    label,
    value,
    hassIcon = false,
    icon,
    isDownload = false,
    labelerVariant,
    valueVariant,
    sx,
    labellerSxProps,
    valueSxProps,
  } = props;
  return (
    <Box sx={sx}>
      <Typography variant={labelerVariant} sx={labellerSxProps}>
        {label}
      </Typography>

      {!hassIcon ? (
        <Typography variant={valueVariant} sx={valueSxProps}>
          {value}
        </Typography>
      ) : isDownload ? (
        <Box
          flexDirection={"row"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{
            borderRadius: 20,
            borderColor: "#BBE0FF",
            borderWidth: "1px",
            borderStyle: "solid",
            p: 1,
            my: 0.5,
          }}
        >
          <Typography sx={{ mx: 0.5 }} variant={valueVariant}>
            {value}
          </Typography>
          {icon}
        </Box>
      ) : (
        <Box flexDirection={"row"} display={"flex"}>
          {icon}
          <Typography sx={{ mx: 0.5 }} variant={valueVariant}>
            {value}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
