import { Box, Skeleton } from "@mui/material";

const AppointmentEventSkeleton = () => {
  return (
    <Box sx={{ my: 2 }}>
      <Skeleton variant="rectangular" width={84} height={16} sx={{ my: 0.2 }} />
      <Box height={80} color="black">
        <Skeleton
          height="100%"
          variant="rounded"
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "4px",
          }}
        />
      </Box>
    </Box>
  );
};

export default AppointmentEventSkeleton;
