import React from "react";
import ReactLottie from "react-lottie";

type Props = {
  json: any;
  size: number;
};

const Lottie: React.FC<Props> = ({ json, size = 400 }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: json,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return <ReactLottie options={defaultOptions} height={size} width={size} />;
};

export default Lottie;
