import { Box, Divider, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PatientDetailSkeleton = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="body1" sx={{ mb: 1 }}>
        {t("patient.profile.generalDetails.personal.title")}
      </Typography>{" "}
      <Skeleton variant="rectangular" width={500} height={16} />
      <Box
        sx={{
          my: 1,
        }}
      >
        <Skeleton variant="rectangular" width={500} height={24} />
      </Box>
      <Box
        sx={{
          my: 1,
        }}
      >
        <Skeleton variant="rectangular" width={350} height={16} />
      </Box>
      <Divider sx={{ my: 2 }} />
      <Typography variant="body1" sx={{ mb: 1 }}>
        {t("patient.profile.generalDetails.insurance.title")}
      </Typography>
      <Box
        sx={{
          my: 1,
        }}
      >
        <Skeleton variant="rectangular" width={350} height={16} />
        <Box />
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
        <Typography variant="body1">
          {t("patient.profile.generalDetails.additional.notes")}
        </Typography>
        <Skeleton variant="rectangular" width={200} height={16} />
      </Box>
    </Box>
  );
};

export default PatientDetailSkeleton;
