import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "../../app/store/slices/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { encrypt } from "../../utils/codec";
import config from "../../config/config";

export interface INotification {
  id: string;
  type: number;
  message: string;
  isRead: boolean;
  timeRead: Date;
  creationTime: Date;
  additionalInfo: any;
}

const notificationAdapter = createEntityAdapter({
  selectId: (item: INotification) => item.id,
});

const notificationSelector = notificationAdapter.getSelectors();

export const notificationAPI = createApi({
  reducerPath: "notificationAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAllNotification: builder.query<
      any,
      { pageNumber: number; limit: number }
    >({
      query(data) {
        const { pageNumber, limit } = data;
        const request = {
          maxResultCount: limit,
          skipCount: (pageNumber - 1) * limit,
          keyword: "",
        };

        return {
          url: "/api/services/app/NotificationService/GetAll",
          method: "GET",
          credentials: "include",
          params: request,
        };
      },
      transformResponse: (response: any, _: any, { pageNumber, limit }) => {
        return notificationAdapter.addMany(
          notificationAdapter.getInitialState({
            hasMorePages: pageNumber * limit < response?.result?.totalCount,
          }),
          response?.result?.items ?? []
        );
      },
      forceRefetch: () => true,
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        //filter values should be added here
        return `${endpointName}`;
      },
      merge: (currentState, incomingState) => {
        notificationAdapter.addMany(
          currentState,
          notificationSelector.selectAll(incomingState)
        );
        currentState.hasMorePages = incomingState.hasMorePages;
      },
    }),
  }),
});

export const { useGetAllNotificationQuery } = notificationAPI;

export { notificationAdapter, notificationSelector };
