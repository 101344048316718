import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";

const Timeslot = ({
  id,
  label,
  isOccupied = false,
  disabled = false,
  readOnly = false,
  onClick,
}: {
  id: string;
  label: string;
  isOccupied?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  onClick: (id: string) => void;
}) => {
  const { palette } = useTheme();
  const getBgColor = () => {
    if (isOccupied) {
      if (readOnly) {
        return "#757779";
      } else if (disabled) {
        return "#757779";
      } else {
        return "#328800";
      }
    }
    return "transparent";
  };
  return (
    <Button
      onClick={() => onClick(id)}
      sx={{
        margin: 0,
        borderRadius: 24,
        border: disabled ? "1px solid #1D1B2012" : "1px solid #75777F",
        minWidth: "100%",
        px: 1.5,
        py: 1.25,
        bgcolor: getBgColor(),
        ":hover": {
          bgcolor: getBgColor() + "70",
        },
      }}
    >
      <Box display={"flex"} justifyContent={"center"}>
        <Typography
          sx={{
            WebkitUserSelect: "none" /* Safari */,
            msUserSelect: "none" /* IE 10 and IE 11 */,
            userSelect: "none" /* Standard syntax */,
          }}
          variant="body1"
          fontSize={14}
          color={
            isOccupied
              ? palette.common.white
              : disabled
              ? "#191C1D38"
              : "#005AC3"
          }
        >
          {label}
        </Typography>
      </Box>
    </Button>
  );
};

export default Timeslot;
