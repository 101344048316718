import AuthLayout from "../../components/AuthLayout";
import ResetPasswordForm from "../../features/user/auth/reset/ResetPasswordForm";

export default function ResetPassword() {
  return (
    <AuthLayout>
      <ResetPasswordForm />
    </AuthLayout>
  );
}
