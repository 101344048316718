import { Box, Grid } from "@mui/material";
import ProfilePicture from "../../../../../components/ProfilePicture";
import { FormInputText } from "../../../../../components/form/FormInput";
import { FormInputDate } from "../../../../../components/form/FormDate";
import { LoadingButton } from "@mui/lab";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  IBasicDetailsSchema,
  basicDetailsSchema,
} from "../../completeProfileSchema";
import { useAppSelector } from "../../../../../hooks/useStoreHook";
import { IProvider } from "../../../../user/auth/authtypes";
import { useEffect, useMemo } from "react";
import { useCompleteProviderProfileMutation } from "../../completeProfileAPI";
import { getErrorMessage } from "../../../../../utils/serviceHelper";
import { useToast } from "../../../../../hooks/useToast";
import { useBreakpoints } from "../../../../../hooks/useBreakpoints";
import { useMask } from "@react-input/mask";

interface BasicDetailsProps {
  onNextClick: () => void;
}

const BasicDetails = (props: BasicDetailsProps) => {
  const [{ isMatchDownSM: isSM }] = useBreakpoints();
  const inputRef = useMask({ mask: "+1 (___) ___ ____", replacement: "_" });
  const userData = useAppSelector(
    (state: any) => state.userState.user
  ) as IProvider;

  const methods = useForm<IBasicDetailsSchema>({
    resolver: zodResolver(basicDetailsSchema),
    defaultValues: useMemo(() => {
      let DOB = new Date(1900, 0, 1);
      if (userData?.dateOfBirth) DOB = new Date(userData.dateOfBirth);
      return {
        firstname: userData?.firstName ?? "",
        middlename: userData?.middleName ?? "",
        surname: userData?.surname ?? "",
        dateOfBirth: DOB,
        email: userData?.email ?? "",
        contactNumber: userData?.phoneNumber ?? "",
        about: userData?.aboutMe ?? "",
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]),
  });

  const { handleSubmit } = methods;
  const [completeProfile, status] = useCompleteProviderProfileMutation();
  const { displayToast } = useToast();

  useEffect(() => {
    if (status.isError) {
      const errorMessage = getErrorMessage(status.error);
      displayToast({ message: errorMessage, type: "error" });
    }
    if (status.isSuccess) {
      props.onNextClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.isLoading]);

  const onSubmitHandler: SubmitHandler<IBasicDetailsSchema> = (values) => {
    completeProfile(values);
  };

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid container direction="row" spacing={2} marginY={0.5}>
          <Grid item xs={12} sm={3} md={3} className="flex-center">
            <ProfilePicture
              width={176}
              height={176}
              name="add profile picture"
              isEdit={true}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} marginY={0.5}>
          <Grid item xs={12} sm={4} md={4}>
            <FormInputText
              type="text"
              name="firstname"
              label="First Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FormInputText
              type="text"
              name="middlename"
              label="Middle Name"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FormInputText
              type="text"
              name="surname"
              label="Last Name"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} marginY={0.5}>
          <Grid item xs={12} sm={6} md={6}>
            <FormInputDate
              sx={{ width: "100%" }}
              name={"dateOfBirth"}
              label="Date of birth"
              minDate={new Date(1900, 0, 1)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormInputText
              name="contactNumber"
              label="Contact Number"
              inputRef={inputRef}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} marginY={0.5}>
          <Grid item xs={12} sm={12} md={12}>
            <FormInputText name="email" label="Email" fullWidth />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} marginY={0.5}>
          <Grid item xs={12} sm={12} md={12}>
            <FormInputText
              name="about"
              label="About"
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
        <Grid container display="flex" justifyContent="flex-end" marginTop={1}>
          <LoadingButton
            style={{ width: isSM ? "45%" : "auto" }}
            loading={status.isLoading}
            type="submit"
            variant="contained"
          >
            Next
          </LoadingButton>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default BasicDetails;
