import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import DateUtil, { dateUtil } from "../../../utils/dateHelper";

export interface DateSetterState {
  selectedDate?: string;
}

export const dateSetterInitialState: DateSetterState = {
  selectedDate: dateUtil.formatDate(
    dateUtil.getToday(),
    DateUtil.DateFormats.YEAR_MONTH_DATE
  ),
};

export const DateSetterSlice = createSlice({
  name: "dateSetter",
  initialState: dateSetterInitialState,
  reducers: {
    setDate: (_state, action: PayloadAction<DateSetterState>) => ({
      ...dateSetterInitialState,
      selectedDate: action.payload.selectedDate,
    }),
    setDefault: (state) => ({
      ...state,
      selectedDate: dateUtil.formatDate(
        dateUtil.getToday(),
        DateUtil.DateFormats.YEAR_MONTH_DATE
      ),
    }),
  },
});

export const dateSetterActions = DateSetterSlice.actions;
export const dateSetterReducer = DateSetterSlice.reducer;
