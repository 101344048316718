import { TextField, TextFieldProps } from "@mui/material";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import classes from "./FormSearchInput.module.css";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

type FormSearchInputProps = {
  name: string;
  label: string;
  searchResults: SearchResult[];
  onSearch: (searchValue: string) => void;
} & TextFieldProps;

export interface SearchResult {
  id: string;
  value: string;
}

function FormSearchInput(props: FormSearchInputProps) {
  const { name, label, onSearch, searchResults, ...otherProps } = props;

  const { control } = useFormContext();
  const [viewText, setsetViewText] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchResult, setIsSearchResult] = useState(true);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useOutsideClick(() => setIsSearchResult(false));

  useEffect(() => {
    if (debouncedSearchTerm) onSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, onSearch]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <div className={classes.container}>
            <TextField
              helperText={error ? error.message : null}
              error={!!error}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setsetViewText(e.target.value);
              }}
              value={viewText}
              onFocus={() => setIsSearchResult(true)}
              label={label}
              {...otherProps}
            />
            {isSearchResult && searchResults.length > 0 && (
              <div className={classes["search-container"]}>
                {searchResults?.map((result) => (
                  <p
                    className={classes["search-item"]}
                    key={result.id}
                    onClick={() => {
                      setIsSearchResult(false);
                      onChange(result.id);
                      setsetViewText(result.value);
                    }}
                  >
                    {result.value}
                  </p>
                ))}
              </div>
            )}
          </div>
        );
      }}
    />
  );
}

export default FormSearchInput;
