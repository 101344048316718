import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ToastState {
  open?: boolean;
  type?: AlertColor;
  message?: string;
  timeout?: number | null;
}

export const toastInitialState: ToastState = {
  open: false,
  type: "info",
  message: "",
  timeout: 3000,
};

export const ToastSlice = createSlice({
  name: "toast",
  initialState: toastInitialState,
  reducers: {
    addToast: (_state, action: PayloadAction<ToastState>) => ({
      ...toastInitialState,
      ...action.payload,
      open: true,
    }),
    clearToast: () => ({ ...toastInitialState }),
  },
});

export const toastActions = ToastSlice.actions;
export const toastReducer = ToastSlice.reducer;
